import React, {useState, useEffect} from "react";

import { CloseOutlined } from '@ant-design/icons';

import { Button, Popconfirm, Radio, RadioChangeEvent } from 'antd';

import { connect } from "react-redux";

import { RootState } from "../../redux";

import { ThunkDispatch } from "redux-thunk";

import { Filter, FilterRule, FilterField } from "../../redux/filter/model";

import { setFilter } from "../../redux/filter/action";

import FilterItemRule from "./filter-item-rule";

type OwnProps = {
  removable: boolean;
  filterGroup: FilterRule;
  filterGroupIndex: number;
  filterSubGroupIndex: number;
  addSubGroup: (subGroup:FilterRule) => void;
  deleteSubGroup: () => void;
};

interface StateProps {
  filter?: Filter,
  filterFields: Array<FilterField>;
}

interface DispatchProps {
  setFilter: (filter:Filter) => void;
  
}

type Props = OwnProps & StateProps & DispatchProps;

const FilterItemSubGroup: React.FC<Props> = (props): JSX.Element => {

  const [filter, setFilter] = useState<Filter>();
  const [subGroup, setSubGroup] = useState<FilterRule>({ruleClass: 'RULE', filterRules: []});

  useEffect(() => {
    setFilter(props.filter);
    if (props.filter) {
      try {
        setSubGroup(props.filter.filterRules[props.filterGroupIndex].
        filterRules[props.filterSubGroupIndex]);
      } catch (e) {
      }
    }
  }, [
    props.filter,
  ]);

  const addSubGroup = () => {

    const rule:FilterRule = {
      seqNo: 1,
      ruleClass: 'RULE',
      filterRules: [],
    }

    const subGroup:FilterRule = {
      ruleClass: 'GROUP',
      operator: 'AND',
      filterRules: [rule],
    }

    props.addSubGroup(subGroup);
  }

  const deleteSubGroup = () => {
    props.deleteSubGroup();
  }

  const addRule = () => {

    if (props.filter) {

      const tempSubGroup = props.filter.filterRules[props.filterGroupIndex].filterRules[props.filterSubGroupIndex];
      const seqNo = tempSubGroup.filterRules[tempSubGroup.filterRules.length - 1].seqNo;
      const rule:FilterRule = {
        seqNo: seqNo ? seqNo + 1 : 0,
        ruleClass: 'RULE',
        operator: 'AND',
        filterRules: [],
      }

      tempSubGroup.filterRules.push(rule);
      if (props.setFilter) props.setFilter({...props.filter});
    }
  }

  const deleteRule = (index:number) => {

    if (props.filter) {  
      const tempSubGroup = props.filter.filterRules[props.filterGroupIndex].filterRules[props.filterSubGroupIndex];
      tempSubGroup.filterRules.splice(index, 1);
      if (props.setFilter) props.setFilter({...props.filter});
    }
  }

  const operationChanged = (value:RadioChangeEvent) => {

    if (props.filter) {
      props.filter.filterRules[props.filterGroupIndex].filterRules[props.filterSubGroupIndex].operator = value.target.value;
      if (props.setFilter) props.setFilter({...props.filter});
    }
  }

  const getOperatorStyle = (highlighted:boolean) => {
    if (highlighted) {
      return {
        background: '#018033',
        borderColor: '#018033',
        color: 'white'
      }
    } else {
      return {};
    }
  }

  return (
    <div className="filter-sub-group">
      {null != subGroup.operator ?
        <div className="sub-group-operator-buttons">
        <Radio.Group value={subGroup.operator} onChange={operationChanged}>
          <Radio.Button value="AND" style={getOperatorStyle(subGroup.operator === 'AND')}>AND</Radio.Button>
          <Radio.Button value="OR" style={getOperatorStyle(subGroup.operator === 'OR')}>OR</Radio.Button>
        </Radio.Group>
        &nbsp;
        </div> : null}
      <div className="header">
          {null == subGroup.operator ? <><Button type="primary" style={{ background: '#018033', borderColor: '#018033' }} size="small" onClick={addSubGroup}>+ SUBGROUP</Button></> : null}
          <Button type="primary" style={{ background: '#d45400', borderColor: '#d45400' }} size="small" onClick={addRule}>+ RULE</Button>
          <div style={{width: 24}}>{props.removable ? 
          <>
            {props.filterSubGroupIndex ?
            <Popconfirm
              title="Are you sure you want to delete this subgroup?"
              onConfirm={deleteSubGroup}
              okText="Yes"
              cancelText="No">
              <Button type="ghost" shape="circle" size="small" icon={<CloseOutlined/>}></Button> 
            </Popconfirm> :
            <Popconfirm
              title="Are you sure you want to delete this group?"
              onConfirm={deleteSubGroup}
              okText="Yes"
              cancelText="No">
              <Button type="ghost" shape="circle" size="small" icon={<CloseOutlined/>}></Button> 
            </Popconfirm> }
          </>
            : null}
          </div>
      </div>
      <div className="filter-rules">
        {subGroup.filterRules.map((rule, index) => {
          return <FilterItemRule 
            key={index} 
            filterGroupIndex={props.filterGroupIndex}
            filterSubGroupIndex={props.filterSubGroupIndex}
            filterRuleIndex={index}
            deleteRule={() => deleteRule(index)}/>
        })}
        
      </div>
      <div>
      </div>
      
    </div>
  );
};

const mapStateToProps = (states: RootState): StateProps => {
  return {
    filter: states.filterReducer.state.filter,
    filterFields: states.filterReducer.state.filterFields,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
 
  return {
    setFilter: (filter:Filter) => dispatch(setFilter(filter)),
  };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(FilterItemSubGroup));
