import React from "react";

import { Layout } from "antd";

const { Content, Sider } = Layout;

interface Props {
  renderRSComponent: JSX.Element | JSX.Element[];
  renderLSComponent: JSX.Element | JSX.Element[];
}

const PageLayout: React.FC<Props> = ({
  renderRSComponent,
  renderLSComponent,
}): JSX.Element => {
  return (
    <Layout className={"page-layout"}>
      <Sider
        className={"page-layout-l"}
        breakpoint="md"
        collapsedWidth="0"
        onBreakpoint={(broken) => {}}
        onCollapse={(collapsed, type) => {}}
      >
        {renderLSComponent}
      </Sider>
      <Layout>
        <Content className={"page-layout-r"}>{renderRSComponent}</Content>
      </Layout>
    </Layout>
  );
};

export default React.memo(PageLayout);
