import React from "react";

import ReactDOM from "react-dom";

import { Provider } from "react-redux";

import store from "./redux";

import { RouterComponent } from "./components";

import reportWebVitals from "./reportWebVitals";

import "./assets/stylesheets/index.scss";

localStorage.removeItem("chatConversationModal");

ReactDOM.render(
  <Provider store={store}>
    <RouterComponent />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
