import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import {
  Button,
  Form,
  Input,
  Spin,
  Tabs,
  Upload,
  List,
  Checkbox,
  Radio,
  Rate,
  Select,
  Image,
  message,
} from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import BackBlueArrow from "../../assets/images/backbluearrow.png";
import { CloudUploadOutlined } from "@ant-design/icons";
import _isqual from "lodash/isEqual";
import { fetchVendor, fetchVendorsInit, postVendor, fetchVendorRequests, fetchVendorTechnicians } from "../../redux/vendors/service";
import moment from "moment";
import { setCompletedTechnicianServiceRequest, setTechnician, setBankInformation, setServiceExperience, setAvailabilities } from "../../redux/technician/action";
import { fetchCompletedTechnicianServiceRequest, fetchBankInformation, fetchServiceExperience, fetchAvailabilities, fetchTypeOfServices } from "../../redux/technician/service";

import VendorSRHistoryComponent from "./vendors-history";
import { TypeOfService } from "../../redux/technician/model";

interface ComponentProps {
  setType: any;
}
interface ReduxState {
  vendor: any;
  requests: any[];
  technicians: any[];
  typeOfServices: Array<TypeOfService>;
}
interface DispatchProps {
  postVendor: (vendor: any) => void;
  fetchVendor: (id: any) => void;
  fetchVendors: (
    action: string,
    index: number,
    status: string,
    viewableSize: number
  ) => void;
  fetchVendorRequests: (id: any) => void;
  fetchVendorTechnicians: (id: any) => void;
  setTechnician: (technician: any) => void;
  setBankInformation: (bankInfo: any) => void;
  setServiceExperience: (experience: any) => void;
  setAvailabilities: (availabilities: any) => void;
  fetchBankInformation: (id: number) => void;
  fetchServiceExperience: (id: number) => void;
  fetchAvailabilities: (id: number) => void;
  fetchCompletedTechnicianServiceRequest: (id: number) => void;
  setCompletedTechnicianServiceRequest: (requests: any) => void;
  fetchTypeOfServices: () => any;
}
type Props = ComponentProps & ReduxState & DispatchProps;
const AddVendor: React.FC<Props> = (props): JSX.Element => {

  const [submit, setSubmit] = useState(true);

  const [form] = Form.useForm();

  const [vendorData, setVendorData] = useState<any>({
    id: 0,
    status: "PENDING_PROFILE",
    companyName: null,
    companyAddress: null,
    vendorContactNumber: null,
    companyWebsite: null,
    contactName: null,
    position: null,
    contactNumber: null,
    email: null,
    notes: null,
    photo: null,
    rating: 3,
    billTo: null,
    billingContactName: null,
    billingContactNumber: null,
    vendorInvoiceEmail: null,
    bankName: null,
    nameOnBankAccount: null,
    bankAccountNumber: null,
    bankRoutingNumber: null,
    payRate: null,
    cancellationFee: null,
    tripCharge: null,
    emergencyContactName: null,
    emergencyContactNumber: null,
  });

  const [vendorLogoUpload, setVendorLogoUpload] = useState<any>({
    previewVisible: false,
    previewImage: "",
    fileList: [],
  });

  const [documentsUpload, setDocumentsUpload] = useState<any>({
    previewVisible: false,
    previewImage: "",
    fileList: [],
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [techLoadingIndex, setTechLoadingIndex] = useState<number>(-1);
  const [requestLoadingIndex, setRequestLoadingIndex] = useState<number>(-1);
  const [serviceTypes, setServiceTypes] = useState<string[]>([]);

  const isSkillEnabled = (skill: string) => {
    if (props.vendor) {
      const index = props.vendor.serviceTypes.findIndex((serviceType: any) => { return serviceType.skill === skill });
      return index >= 0;
    }
    return false;
  };

  const [skillSet, setSkillSet] = useState<any[]>([]);

  useEffect(() => {
    populateVendor();
    getTypeOfServices();
  }, [props.vendor]);

  useEffect(() => {
    if (props.typeOfServices.length != 0) {
      let skills: any = [];
      for (let i = 0; i < props.typeOfServices.length; i++) {
        const typeOfService: TypeOfService = props.typeOfServices[i];
        skills.push({
          title: typeOfService.name,
          checked: isSkillEnabled(typeOfService.name)
        })
      }
      setSkillSet(skills)
    }
  }, [props.typeOfServices])

  const getTypeOfServices = async () => {
    await props.fetchTypeOfServices();
  }

  const onChangeValue = (e: any) => {
    if (e.target.value !== "") {
      setVendorData({
        ...vendorData,
        [e.target.name]: e.target.value,
      });
    } else {
      setVendorData({
        ...vendorData,
        [e.target.name]: null,
      });
    }
  };

  const onChangeSkills = (title: string) => {

    let index = skillSet.findIndex((skill: any) => { return skill.title == title });
    skillSet[index].checked = !skillSet[index].checked;
    setSkillSet(skillSet);
  }

  const handleVendorLogoPreview = (file: any) => {
    setVendorLogoUpload({
      ...vendorLogoUpload,
      previewImage: file.thumbUrl,
      previewVisible: true,
    });
  };

  const handleDocumentsPreview = (file: any) => {
    setDocumentsUpload({
      ...documentsUpload,
      previewImage: file.thumbUrl,
      previewVisible: true,
    });
  };

  const handleVendorLogoUpload = ({ fileList }: any) => {
    setVendorLogoUpload({ ...vendorLogoUpload, fileList });
  };

  const handleDocumentsUpload = ({ fileList }: any) => {
    setDocumentsUpload({ ...documentsUpload, fileList });
  };

  const clearData = () => {
    setVendorData({
      id: 0,
      status: 'PENDING_PROFILE',
      companyName: null,
      companyAddress: null,
      vendorContactNumber: null,
      companyWebsite: null,
      contactName: null,
      position: null,
      contactNumber: null,
      email: null,
      notes: null,
      photo: null,
      rating: "3",
      billTo: null,
      billingContactName: null,
      billingContactNumber: null,
      vendorInvoiceEmail: null,
      bankName: null,
      nameOnBankAccount: null,
      bankAccountNumber: null,
      bankRoutingNumber: null,
      payRate: null,
      cancellationFee: null,
      tripCharge: null,
      emergencyContactName: null,
      emergencyContactNumber: null,
    });
    setVendorLogoUpload({
      previewVisible: false,
      previewImage: "",
      fileList: [],
    });
    setDocumentsUpload({
      previewVisible: false,
      previewImage: "",
      fileList: [],
    });
    form.resetFields();
  }

  const submitVendorData = async () => {

    if (vendorData.companyAddress !== null) {

      setSaving(true);

      let vendorServiceTypes: any[] = [];
      skillSet.forEach((skill: any) => {
        if (skill.checked) vendorServiceTypes.push({ skill: skill.title });
      });
      vendorData.serviceTypes = vendorServiceTypes;

      await props.postVendor(vendorData);
      clearData()
      await props.fetchVendors("init", 1, "inactive", 25);

      setSaving(false);

      props.setType("vendorList");
    } else {
      setSubmit(false);
      message.error("Please fill all the required fields");
    }
  };

  const populateVendor = async () => {
    clearData();
    if (props.vendor !== null) {
      setVendorData(props.vendor);
      props.fetchVendorRequests(props.vendor.id);
      props.fetchVendorTechnicians(props.vendor.id);
    } else {
      props.fetchVendorRequests(0);
      props.fetchVendorTechnicians(0);
    }
  };

  const removeVendor = async () => {
    await props.setType("vendorList")
    props.fetchVendor(0)
  }

  const getDate = (request: any) => {
    if (request.scheduleDateTimeStamp) {
      const date = moment(request.scheduleDateTimeStamp).format('MMMM DD, YYYY');
      return date;
    } else {
      return '-';
    }
  }

  const addTechnician = () => {

    props.setCompletedTechnicianServiceRequest([]);

    props.setBankInformation(null);
    props.setServiceExperience(null);
    props.setAvailabilities(null);

    props.setTechnician({
      status: 'PENDING_PROFILE',
      rating: 0,
      payRate: vendorData.payRate,
      cancellationFee: vendorData.cancellationFee,
      tripCharge: vendorData.tripCharge
    });

    props.setType("addVendorTechnician")
  }

  const editTechnician = async (ndx: number) => {

    setTechLoadingIndex(ndx);

    props.setCompletedTechnicianServiceRequest([]);

    const technician = props.technicians[ndx];

    await props.fetchBankInformation(technician.id);
    await props.fetchServiceExperience(technician.id);
    await props.fetchAvailabilities(technician.id);
    await props.fetchCompletedTechnicianServiceRequest(technician.id);

    props.setTechnician(technician);

    setTechLoadingIndex(-1);

    props.setType("addVendorTechnician");
  }

  const chat = (ndx: number) => {

    setRequestLoadingIndex(ndx);

    // TODO preparation for 
    setTimeout(() => {
      setRequestLoadingIndex(-1);
    }, 1000);
  }

  return (
    <div className="add-vendor-container">
      <div
        onClick={() => removeVendor()}
        style={{ color: "#5d97bc", cursor: "pointer" }}
      >
        <Image
          style={{ width: 30, marginRight: 10 }}
          preview={false}
          src={BackBlueArrow}
        />
        Back
      </div>
      <div className="add-vendor-button-right">
        <Button className="button-add">
          <p
            style={{ opacity: 0.7 }}
            onClick={addTechnician}
          >
            +Add New Vendor Technician
          </p>
        </Button>
      </div>
      <Form form={form}>
        <div className="add-vendor-company-info">
          <h3 style={{ fontWeight: 900 }}>Company Information</h3>
          <div className="add-vendor-company-info-details">
            <div className="add-vendor-company-sub-details">
              <Input
                defaultValue={props.vendor && props.vendor.id ? props.vendor.id : null}
                disabled={true}
                size="small"
                placeholder="Vendor ID #"
                onChange={(e) => onChangeValue(e)}
              />
              <Input
                size="small"
                className={
                  submit === false && vendorData.companyName === null
                    ? "required-input-msg"
                    : ""
                }
                name="companyName"
                placeholder="Company Name"
                style={{ marginTop: 10 }}
                onChange={(e) => onChangeValue(e)}
                value={vendorData.companyName}
              />
              <Input.TextArea
                name="companyAddress"
                className={
                  submit === false && vendorData.companyAddress === null
                    ? "required-input-msg"
                    : ""
                }
                placeholder="Company Address"
                rows={4}
                style={{ marginTop: 10 }}
                onChange={(e) => onChangeValue(e)}
                value={vendorData.companyAddress}
              />
              <Input
                size="small"
                name="vendorContactNumber"
                className={
                  submit === false && vendorData.vendorContactNumber === null
                    ? "required-input-msg"
                    : ""
                }
                placeholder="Company Contact Number"
                style={{ marginTop: 10 }}
                onChange={(e) => onChangeValue(e)}
                value={vendorData.vendorContactNumber}
              />
              <Input
                size="small"
                name="companyWebsite"
                placeholder="Company Website"
                style={{ marginTop: 10 }}
                onChange={(e) => onChangeValue(e)}
                value={vendorData.companyWebsite}
              />
            </div>
            <div className="add-vendor-company-sub-details">
              <Input
                size="small"
                name="contactName"
                className={
                  submit === false && vendorData.contactName === null
                    ? "required-input-msg"
                    : ""
                }
                placeholder="Vendor Contact Name"
                onChange={(e) => onChangeValue(e)}
                value={vendorData.contactName}
              />
              <Input
                size="small"
                name="position"
                placeholder="Position"
                style={{ marginTop: 10 }}
                onChange={(e) => onChangeValue(e)}
                value={vendorData.position}
              />
              <Input
                size="small"
                name="contactNumber"
                className={
                  submit === false && vendorData.contactNumber === null
                    ? "required-input-msg"
                    : ""
                }
                placeholder="Contact Number"
                style={{ marginTop: 10 }}
                onChange={(e) => onChangeValue(e)}
                value={vendorData.contactNumber}
              />
              <Input
                size="small"
                name="email"
                className={
                  submit === false && vendorData.email === null
                    ? "required-input-msg"
                    : ""
                }
                placeholder="Email Address"
                style={{ marginTop: 10 }}
                onChange={(e) => onChangeValue(e)}
                value={vendorData.email}
              />
              <Input.TextArea
                name="notes"
                placeholder="Notes"
                rows={4}
                style={{ marginTop: 10 }}
                onChange={(e) => onChangeValue(e)}
                value={vendorData.notes}
              />
            </div>
            <div style={{ width: "320px" }}>
              <div className="upload-container">
                <Upload.Dragger
                  listType="picture"
                  multiple={false}
                  style={{ background: "transparent", border: "0px" }}
                  fileList={vendorLogoUpload.fileList}
                  onPreview={handleVendorLogoPreview}
                  onChange={handleVendorLogoUpload}
                  beforeUpload={() => false}
                >
                  <p style={{ opacity: 0.8 }}>Upload Vendor Logo</p>
                  <CloudUploadOutlined
                    style={{ fontSize: "70px", marginTop: 10, color: "grey" }}
                  />
                </Upload.Dragger>
              </div>
              <div style={{ marginTop: 30, display: "flex" }}>
                <div style={{ marginRight: 50, lineHeight: 0.3 }}>
                  <p style={{ opacity: 0.7 }}>Vendor Rating:</p>
                  <Rate
                    defaultValue={vendorData.rating}
                    style={{ color: "#3a78b3" }}
                    onChange={(e) => setVendorData({ ...vendorData, rating: e.toFixed() })}
                  />
                </div>
                <div style={{ lineHeight: 0.3 }} className="vendor-status">
                  <p style={{ opacity: 0.7 }}>Vendor Status:</p>
                  <Select
                    value={vendorData.status}
                    suffixIcon={
                      <CaretDownOutlined
                        style={{ color: "white", fontSize: "15px" }}
                      />
                    }
                    size="small"
                    onChange={(e) =>
                      setVendorData({ ...vendorData, status: e })
                    }
                  >
                    <Select.Option value="PENDING_PROFILE">Pending Tech Profile</Select.Option>
                    <Select.Option value="COMPLETE_PROFILE">Tech Profile Complete</Select.Option>
                    <Select.Option value="ACTIVE">Active</Select.Option>
                    <Select.Option value="DEACTIVATED">Deactivated</Select.Option>
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: 48 }}>
          <h3 style={{ fontWeight: 900 }}>Type of Services</h3>
          <div style={{ display: "flex", marginTop: 20, marginLeft: 50 }}>
            <List
              grid={{ gutter: 16, column: skillSet.length > 4 ? 5 : 0 }}
              dataSource={skillSet}
              renderItem={(item) => (
                <List.Item>
                  <Checkbox defaultChecked={item.checked} onChange={(e) => onChangeSkills(item.title)}>{item.title}</Checkbox>
                </List.Item>
              )}
            />
          </div>
        </div>

        <div style={{ marginTop: 50 }}>
          <h3 style={{ fontWeight: 900 }}>Documents</h3>
          <div style={{ marginTop: 20 }}>
            <div
              className="upload-container"
              style={{
                width: "950px",
                padding: 10,
                cursor: "pointer",
              }}
            >
              <Upload.Dragger
                listType="picture"
                fileList={documentsUpload.fileList}
                onPreview={handleDocumentsPreview}
                style={{ background: "transparent", border: "0px" }}
                onChange={handleDocumentsUpload}
                beforeUpload={() => false}
              >
                <p style={{ opacity: 0.8 }}>
                  Upload pertinent documents such as w9s, Vendor insurance,
                  Agreements/Contracts signed
                </p>
                <CloudUploadOutlined
                  style={{
                    fontSize: "70px",
                    color: "grey",
                    marginTop: 20,
                    alignSelf: "center",
                  }}
                />
              </Upload.Dragger>
            </div>
          </div>
        </div>

        <div style={{ marginTop: 30 }}>
          <h3 style={{ fontWeight: 900 }}>Billing</h3>
          <div style={{ display: "flex", marginTop: 20 }}>
            <p style={{ marginLeft: "20px", marginRight: "20px" }}>
              Who to bill/pay?
            </p>
            <Radio.Group
              name="billTo"
              onChange={(e) => onChangeValue(e)}
              value={vendorData.billTo}
            >
              <Radio value={"COMPANY"}>Company</Radio>
              <Radio value={"TECHNICIAN"}>Technician</Radio>
            </Radio.Group>
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <h3 style={{ fontWeight: 900 }}>Company Billing Information</h3>
          <div style={{ marginTop: 20 }}>
            <div style={{ display: "flex" }}>
              <Input
                size="small"
                name="billingContactName"
                placeholder="Billing Contact Name"
                style={{ marginRight: 20, width: "250px" }}
                onChange={(e) => onChangeValue(e)}
                value={vendorData.billingContactName}
              />
              <Input
                size="small"
                name="billingContactNumber"
                placeholder="Billing Contact Number"
                style={{ marginRight: 20, width: "250px" }}
                onChange={(e) => onChangeValue(e)}
                value={vendorData.billingContactNumber}
              />
              <Input
                size="small"
                name="vendorInvoiceEmail"
                placeholder="Vendor Invoice Email"
                style={{ width: "250px" }}
                onChange={(e) => onChangeValue(e)}
                value={vendorData.vendorInvoiceEmail}
              />
            </div>
            <div style={{ display: "flex", marginTop: 20 }}>
              <Input
                size="small"
                name="bankName"
                placeholder="Bank Name"
                style={{ marginRight: 20, width: "250px" }}
                onChange={(e) => onChangeValue(e)}
                value={vendorData.bankName}
              />
              <Input
                size="small"
                name="nameOnBankAccount"
                placeholder="Name on Bank Account"
                style={{ marginRight: 20, width: "250px" }}
                onChange={(e) => onChangeValue(e)}
                value={vendorData.nameOnBankAccount}
              />
              <Input
                size="small"
                name="bankAccountNumber"
                placeholder="Bank Account No."
                style={{ marginRight: 20, width: "250px" }}
                onChange={(e) => onChangeValue(e)}
                value={vendorData.bankAccountNumber}
              />
              <Input
                size="small"
                name="bankRoutingNumber"
                placeholder="Bank Routing No."
                style={{ width: "250px" }}
                onChange={(e) => onChangeValue(e)}
                value={vendorData.bankRoutingNumber}
              />
            </div>
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <h3 style={{ fontWeight: 900 }}>Pay Rate</h3>
          <div style={{ display: "flex", marginTop: 20 }}>
            <Input
              size="small"
              name="payRate"
              placeholder="Pay Rate ($)"
              style={{ marginRight: 40, width: "250px" }}
              onChange={(e) => onChangeValue(e)}
              value={vendorData.payRate}
            />
            <Input
              size="small"
              name="cancellationFee"
              placeholder="Cancellation Fee ($)"
              style={{ marginRight: 40, width: "250px" }}
              onChange={(e) => onChangeValue(e)}
              value={vendorData.cancellationFee}
            />
            <Input
              size="small"
              name="tripCharge"
              placeholder="Trip Charge ($)"
              style={{ width: "250px" }}
              onChange={(e) => onChangeValue(e)}
              value={vendorData.tripCharge}
            />
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <h3 style={{ fontWeight: 900 }}>Emergency Contact</h3>
          <div className="disp-flex">
            <Input
              size="small"
              name="emergencyContactName"
              placeholder="Emergency Contact Name"
              style={{ marginRight: 40, width: "250px" }}
              onChange={(e) => onChangeValue(e)}
              value={vendorData.emergencyContactName}
            />
            <Input
              size="small"
              name="emergencyContactNumber"
              placeholder="Emergency Contact Number"
              style={{ marginRight: 40, width: "250px" }}
              onChange={(e) => onChangeValue(e)}
              value={vendorData.emergencyContactNumber}
            />
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <h3 style={{ fontWeight: 900 }}>History of Service Requests</h3>
          <div style={{ marginTop: 20, marginLeft: 50, color: "grey" }}>
            <VendorSRHistoryComponent
              loadingIndex={0}
              user={vendorData}
              type={'vendor-list'}
              setRequestLoadingIndex={null}
              setConversationType={''}
              setConversationPayload={null}
              setTechnician={null}
              setShowConversationModal={null}
            />
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <h3 style={{ fontWeight: 900 }}>Technicians</h3>
          <div style={{ marginTop: 20, marginLeft: 50, color: "grey" }}>
            {props.technicians.map((technician, index) => {
              return <div key={index} className="disp-flex">
                <p style={{ marginRight: 30, width: '240px' }}>{technician.fullName}</p>
                <p style={{ marginRight: 30, width: '240px', overflow: 'hidden' }}>{technician.email}</p>
                <p style={{ marginRight: 30, width: '144px' }}>{technician.mobileNumber}</p>
                <p style={{ marginRight: 30, width: '160px' }}>{technician.status}</p>
                <Button onClick={() => editTechnician(index)} loading={index === techLoadingIndex}>View</Button>
              </div>
            })}
            {props.technicians.length == 0 &&
              <div className="disp-flex">
                <p style={{ marginRight: 30 }}>No Technicians Registered.</p>
              </div>}
          </div>
        </div>

        <div
          style={{ marginTop: 40, display: "flex", justifyContent: "center" }}
        >
          <Button
            style={{
              color: "white",
              backgroundColor: "#ff0000",
              borderRadius: 5,
              width: "120px",
              fontSize: 12,
              fontWeight: "bold",
              marginRight: 20,
            }}
          >
            <p
              style={{ opacity: 0.8 }}
              onClick={() => props.setType("vendorList")}
            >
              CANCEL
            </p>
          </Button>
          <Button
            style={{
              color: "white",
              backgroundColor: "#00d400",
              borderRadius: 5,
              width: "120px",
              fontSize: 12,
              fontWeight: "bold",
            }}
            onClick={() => submitVendorData()}
            loading={saving}
          >
            <p style={{ opacity: 0.8, marginTop: "3px" }}>SAVE</p>
          </Button>
        </div>
        <div></div>
        <div></div>
      </Form>
    </div>
  );
};

const mapStateToProps = (states: RootState): ReduxState => {
  return {
    vendor: states.vendorsReducer.state.selectedVendor,
    requests: states.vendorsReducer.state.vendorRequests,
    technicians: states.vendorsReducer.state.vendorTechnicians,
    typeOfServices: states.technicianReducer.state.typeOfServices,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    postVendor: (vendor) => dispatch(postVendor(vendor)),
    fetchVendor: (id: number) => dispatch(fetchVendor(id)),
    fetchVendors: (
      action: string,
      index: number,
      status: string,
      viewableSize: number
    ) => dispatch(fetchVendorsInit(action, index, status, viewableSize)),
    fetchVendorRequests: (id: number) => dispatch(fetchVendorRequests(id)),
    fetchVendorTechnicians: (id: number) => dispatch(fetchVendorTechnicians(id)),
    setTechnician: (technician: any) => dispatch(setTechnician(technician)),
    fetchBankInformation: (id: number) => dispatch(fetchBankInformation(id)),
    fetchServiceExperience: (id: number) => dispatch(fetchServiceExperience(id)),
    fetchAvailabilities: (id: number) => dispatch(fetchAvailabilities(id)),
    fetchCompletedTechnicianServiceRequest: (id: number) => dispatch(fetchCompletedTechnicianServiceRequest(id)),
    setBankInformation: (bankInfo: any) => dispatch(setBankInformation(bankInfo)),
    setServiceExperience: (experience: any) => dispatch(setServiceExperience(experience)),
    setAvailabilities: (availabilities: any) => dispatch(setAvailabilities(availabilities)),
    setCompletedTechnicianServiceRequest: (requests: any) => dispatch(setCompletedTechnicianServiceRequest(requests)),
    fetchTypeOfServices: () => dispatch(fetchTypeOfServices())
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(AddVendor)
);
