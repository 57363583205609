import React from "react";
import BackgroundImage from "../../assets/images/background-image.jpg";

interface Props { children: JSX.Element; }
const BackgroundImageComponent: React.FC<Props> = ({ children }): JSX.Element => {
   return (
      <div style={{
         display: 'flex',
         backgroundImage: `url(${BackgroundImage})`,
         backgroundRepeat: 'no-repeat',
         backgroundAttachment: 'fixed',
         backgroundSize: 'cover',
         height: '100%',
      }}>
         {children}
      </div>
   );
};

export default React.memo(BackgroundImageComponent);