import { SET_COMPLETED_TECHNICIAN_SERVICE_REQUEST, SET_BANK_INFORMATION, SET_SERVICE_EXPERIENCE, SET_AVAILABILITIES, SET_TECHNICIAN, GET_TECHNICIANS_BOARD, GET_TECHNICIANS_GRID, GET_TECHNICIANS_SERVICE_REQUEST_COUNT, GET_TECHNICIAN_SERVICE_REQUEST, SET_TECHNICIANS_BOARD, SET_TECHNICIANS_GRID, SET_TECHNICIANS_SERVICE_REQUEST_COUNT, SET_TECHNICIAN_SERVICE_REQUEST, SET_TECHNICIAN_SERVICE_REQUEST_LOADING, GET_SELECTED_TECHNICIAN_ID, SET_SELECTED_TECHNICIAN_ID, SET_REMOVE_TECHNICIAN_ID, GET_REMOVE_TECHNICIAN_ID, SET_ALL_SELECTED_TECHNICIANS, GET_ALL_SELECTED_TECHNICIANS, SET_TYPE_OF_SERVICES, SET_SERVICE_LOCATIONS } from "../action-types";
import { Technician, SetTechnician, GetSRCountTechnicians, SetSRCountTechnicians, GetTechniciansBoard, SetTechniciansBoard, SetTechniciansGrid, GetTechniciansGrid, SetTypeOfServices, TypeOfService, SetServiceLocations, ServiceLocation } from "./model";
import store from "..";
import { ServiceRequestCount } from "../service-request/model";



export const setTechnician = (payload: any): SetTechnician => {
  return {
    type: SET_TECHNICIAN,
    payload
  };
};

export const getTechniciansBoard = (): GetTechniciansBoard => {
  return {
    type: GET_TECHNICIANS_BOARD,
    payload: store.getState().technicianReducer.state.techniciansBOARD
  };
};

export const setTechniciansBoard = (payload: Array<Technician>): SetTechniciansBoard => {
  return {
    type: SET_TECHNICIANS_BOARD,
    payload
  };
};

export const getTechniciansGrid = (): GetTechniciansGrid => {
  return {
    type: GET_TECHNICIANS_GRID,
    payload: store.getState().technicianReducer.state.techniciansBOARD
  };
};

export const setTechniciansGrid = (payload: Array<Technician>): SetTechniciansGrid => {
  return {
    type: SET_TECHNICIANS_GRID,
    payload
  };
};

export const getSRCountTechnicians = (): GetSRCountTechnicians => {
  return {
    type: GET_TECHNICIANS_SERVICE_REQUEST_COUNT,
    payload: store.getState().technicianReducer.state.techniciansSRCounts
  };
};

export const setSRCountTechnicians = (payload: Array<ServiceRequestCount>): SetSRCountTechnicians => {
  return {
    type: SET_TECHNICIANS_SERVICE_REQUEST_COUNT,
    payload
  };
};

export const setTechnicianSrLoading = (payload: any) => {
  return {
    type: SET_TECHNICIAN_SERVICE_REQUEST_LOADING,
    payload
  }
}

export const getTechnicianSR = () => {
  return {
    type: GET_TECHNICIAN_SERVICE_REQUEST,
    payload: store.getState().technicianReducer.state.techniciansSRCounts
  };
};

export const getRemoveTechnicianId = () => {
  return {
    type: GET_REMOVE_TECHNICIAN_ID,
    payload: store.getState().technicianReducer.state.removeTechnicianId
  };
};

export const setRemoveTechnicianId = (payload: any) => {
  return {
    type: SET_REMOVE_TECHNICIAN_ID,
    payload
  };
};

export const getSelectedTechnicianId = () => {
  return {
    type: GET_SELECTED_TECHNICIAN_ID,
    payload: store.getState().technicianReducer.state.selectedTechnicianId
  };
};

export const setSelectedTechnicianId = (payload: any) => {
  return {
    type: SET_SELECTED_TECHNICIAN_ID,
    payload
  };
};

export const getAllSelectedTechnicians = () => {
  return {
    type: GET_ALL_SELECTED_TECHNICIANS,
    payload: store.getState().technicianReducer.state.allSelectedTechnicians
  };
};

export const setAllSelectedTechnicians = (payload: any) => {
  return {
    type: SET_ALL_SELECTED_TECHNICIANS,
    payload
  };
};

export const setTechnicianSR = (payload: any) => {
  return {
    type: SET_TECHNICIAN_SERVICE_REQUEST,
    payload
  };
};

export const setCompletedTechnicianServiceRequest = (payload: any) => {
  return {
    type: SET_COMPLETED_TECHNICIAN_SERVICE_REQUEST,
    payload
  };
};

export const setBankInformation = (payload: any) => {
  return {
    type: SET_BANK_INFORMATION,
    payload
  };
};

export const setServiceExperience = (payload: any) => {
  return {
    type: SET_SERVICE_EXPERIENCE,
    payload
  };
};

export const setAvailabilities = (payload: any) => {
  return {
    type: SET_AVAILABILITIES,
    payload
  };
};

export const setTypeOfServices = (payload: Array<TypeOfService>): SetTypeOfServices => {
  return {
    type: SET_TYPE_OF_SERVICES,
    payload
  }
};

export const setServiceLocations = (payload: Array<ServiceLocation>): SetServiceLocations => {
  return {
    type: SET_SERVICE_LOCATIONS,
    payload
  }
};