import { ReactNode } from "react";
import styles from "./Card.module.scss";

interface Props {
  title: string;
  gap?: Gap;
  children?: ReactNode;
  className?: string;
}

type Gap = "small" | "medium" | "large";

export default function Card({ title, children, gap, className }: Props) {
  const cardClasses = [styles.card];

  if (gap) {
    cardClasses.push(styles[gap]);
  }

  return (
    <div className={`${cardClasses.join(" ")} ${className}`}>
      <div className={styles.title}>{title}</div>
      {children}
    </div>
  );
}
