import {
  GET_FILTERS,
  SET_FILTERS,
  SET_FILTER_FIELDS,
  SET_FILTER,
  GET_USER_FILTERS,
  SET_USER_FILTERS,
  SET_LOADING_USER_FILTERS,
  SET_LOADING_FILTER_FIELDS,
  SET_SEARCH_SR_NUMBER,
  GET_SEARCH_SR_NUMBER,
  GET_SEARCH_VALUE,
  SET_SEARCH_VALUE,
  GET_SEARCH_TYPE,
  SET_SEARCH_TYPE
} from "../action-types";

export enum SearchType {
  SERVICE_REQUEST_NO = "SERVICE_REQUEST_NO",
  ITEM_NAME = "ITEM_NAME",
  MEMBER_NAME = "MEMBER_NAME",
  TECHNICIAN_NAME = "TECHNICIAN_NAME",
};

export interface FilterTag {
  id?: number;
  tag: string;
};

export interface FilterValue {
  id: number;
  filterField: FilterField;
  value: string;
  hasOptionalValue: boolean;
};

export interface FilterField {
  id: number;
  clientId: number;
  name: string;
  fieldName: string;
  filterFieldType: string;
  hasOptions: boolean;
  //filterConditions: Array<FilterCondition>;
  filterValues: Array<FilterValue>;
  filterClass: string;
};

export interface FilterValue {
  id: number;
  clientId: number;
  userId: number;
  name: string,
  preset: boolean,
  shared: boolean,
  filterRules: Array<FilterRule>;
};

export interface FilterRule {

  id?: number;
  ruleClass: string;
  seqNo?: number;
  operator?: string;
  filterRules: Array<FilterRule>;
  filterField?: FilterField;
  filterValue?: FilterValue;
  optionalValue?: string;
}

export interface Filter {
  id?: number;
  clientId: number;
  userId?: number;
  name: string;
  preset: boolean;
  adminFilter: boolean;
  shared: boolean;
  filterClass: string;
  filterRules: Array<FilterRule>;
  filterTags: Array<FilterTag>;
};

export interface Filters {
  isLoadingUserFilters: boolean,
  isLoadingFilterFields: boolean,
  filter?: Filter;
  filters: Array<Filter>;
  userFilters: Array<Filter>;
  filterFields: Array<FilterField>;
  searchSRNumber: string;
  searchValue: string;
  searchType: SearchType;
};

export type GetSearchSRNumber = {
  type: typeof GET_SEARCH_SR_NUMBER;
  payload: string;
}

export type SetSearchSRNumber = {
  type: typeof SET_SEARCH_SR_NUMBER;
  payload: string;
};

export type GetSearchValue = {
  type: typeof GET_SEARCH_VALUE;
  payload: string;
}

export type SetSearchValue = {
  type: typeof SET_SEARCH_VALUE;
  payload: string;
};

export type GetSearchType = {
  type: typeof GET_SEARCH_TYPE;
  payload: SearchType;
}

export type SetSearchType = {
  type: typeof SET_SEARCH_TYPE;
  payload: SearchType;
};


/** Get Collection of Filters */
export type GetFilters = {
  type: typeof GET_FILTERS;
  payload: Array<Filter>;
};

/** Set Collection of Filters */
export type SetFilters = {
  type: typeof SET_FILTERS;
  payload: Array<Filter>;
};

/** Get Collection of User Filters */
export type GetUserFilters = {
  type: typeof GET_USER_FILTERS;
  payload: Array<Filter>;
};

/** Set Collection of User Filters */
export type SetUserFilters = {
  type: typeof SET_USER_FILTERS;
  payload: Array<Filter>;
};

/** Set Collection of Filters */
export type SetFilterFields = {
  type: typeof SET_FILTER_FIELDS;
  payload: Array<FilterField>;
};

/** Set Filter */
export type SetFilter = {
  type: typeof SET_FILTER;
  payload: Filter;
};

/** Set Collection of Filters */
export type SetLoadingUserFilters = {
  type: typeof SET_LOADING_USER_FILTERS;
  payload: any;
};

export type SetLoadingFilterFields = {
  type: typeof SET_LOADING_FILTER_FIELDS;
  payload: any;
};

/** Export Filter Actions  */
export type FilterActions =
  SetSearchValue |
  GetSearchValue |
  GetSearchType |
  SetSearchType |
  SetSearchSRNumber |
  GetSearchSRNumber |
  SetLoadingUserFilters |
  SetLoadingFilterFields |
  GetFilters |
  SetFilters |
  GetUserFilters |
  SetUserFilters |
  SetFilterFields |
  SetFilter;
