import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import { Avatar, Typography, Popover, Empty } from "antd";
import { ScheduleOutlined, DashboardOutlined, UserOutlined, HomeFilled, CalendarOutlined } from '@ant-design/icons';
import { Technician, Skill } from "../../redux/technician/model";
import "./TechnicianListComponent.scss";
import { getTechStatusColorCode } from "../../helpers/constants";
import { ServiceRequest, ServiceRequestCount } from "../../redux/service-request/model";
import { fetchTechniciansServiceRequest } from "../../redux/technician/service";
import { fetchCurrentServiceRequest } from "../../redux/service-request/service";

import _isUndefined from "lodash/isUndefined";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import _isNull from "lodash/isNull";
import moment from "moment";
import { SchedulerType } from "../../redux/scheduler/model";
import { setAllSelectedTechnicians, setRemoveTechnicianId, setSelectedTechnicianId } from "../../redux/technician/action";

const { Text } = Typography;

interface CurrentServiceRequest {
   isLoading?: boolean;
   technicianId?: number;
   technicianStatus?: string;
   sr?: ServiceRequest;
}

interface ComponentProps {
   activeTab: string;
   showSRCount: boolean;
   showTechId: boolean;
}

interface ReduxState {
   techniciansGrid: Array<Technician>;
   techniciansBoard: Array<Technician>;
   techServiceReqCount: Array<ServiceRequestCount>;
   schedulerType: SchedulerType;
}

interface DispatchProps {
   fetchTechnicianServiceRequest: (ids: Array<number>) => void;
   fetchCurrentServiceRequest: (technicianId: any) => any;
   setRemoveTechnicianId: (id: any) => any;
   setSelectedTechnicianId: (id: any) => any;
   setAllSelectedTechnicians: (t: any) => any;
}

type Props = ComponentProps & ReduxState & DispatchProps;
const TechnicianListComponent: React.FC<Props> = (props): JSX.Element => {
   const [technicians, setTechnicians] = React.useState<Array<Technician>>();
   const [techSRCount, setTechSRCount] = React.useState<Array<ServiceRequestCount>>([]);
   const [selectedTech, setSelectedTech] = React.useState<Array<number>>([]);
   const [selectedTechnicians, setSelectedTechnicians] = React.useState<any>([])
   const [currentSR, setCurrentSR] = React.useState<CurrentServiceRequest>();

   React.useEffect(() => {
   }, [props.techniciansBoard]);

   React.useEffect(() => {
      if (_isEqual(props.schedulerType, SchedulerType.BOARD)) {
         setTechnicians(props.techniciansBoard);
      }

      if (_isEqual(props.schedulerType, SchedulerType.GRID)) {
         setTechnicians(props.techniciansGrid);
      }

   }, [props.schedulerType, props.techniciansGrid, props.techniciansBoard]);

   React.useEffect(() => setTechSRCount(props.techServiceReqCount), [props.techServiceReqCount]);
   React.useEffect(() => props.fetchTechnicianServiceRequest(selectedTech), [selectedTech]);

   const onAddSelectedTechnician = async (technicianId: number, technician: any) => {
      let id = selectedTech.findIndex((techId: number) => _isEqual(techId, technicianId));
      if (!_isEqual(id, -1)) {
         console.log("REMOVE TECHNCIIAN ID", technicianId)
         props.setRemoveTechnicianId(technicianId)
         let temp = [...selectedTech]
         temp.splice(id, 1);
         setSelectedTech(temp);
      } else {
         console.log("ADD TECHNCIIAN ID", technicianId);
         props.setSelectedTechnicianId(technician)
         let temp: Array<number> = [];
         temp.push(technicianId)
         let finalArr = [...temp, ...selectedTech]
         setSelectedTech(finalArr);
      }
      let existing = selectedTechnicians.find((f: any) => f.id === technicianId)
      if (existing) {
         let newArray = selectedTechnicians.filter((f: any) => f.id !== technicianId)
         await setSelectedTechnicians(newArray)
         props.setAllSelectedTechnicians(newArray)
      } else {
         await setSelectedTechnicians([...selectedTechnicians, technician])
         props.setAllSelectedTechnicians([...selectedTechnicians, technician])
      }
   }

   const renderTechnicianInfo = (technician: Technician) => (
      <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 16, cursor: 'default', width: '80%' }}>
         <Text ellipsis={true} className='font-color' style={{ fontSize: 18, fontWeight: 700 }}>{technician.fullName}</Text>
         <Text ellipsis={true} className='font-color' style={{ fontSize: 12 }}>
            {technician.skills.map((skill: Skill, index: number) => {
               return <span key={`skill-${index}`}>{index > 0 ? ', ' : ''}{skill.skill}</span>
            })}
         </Text>
         {props.showTechId && (<Text ellipsis={true} className='font-color' style={{ fontSize: 12 }}>TID # {technician.id}</Text>)}
      </div>
   );

   const onVisibleChange = async (technician: Technician) => {
      if (_isEqual(props.activeTab, 'map')) {
         const resp: ServiceRequest = await props.fetchCurrentServiceRequest(technician.id);
         if (resp && !_isEmpty(resp)) {
            setCurrentSR({
               isLoading: true,
               technicianId: technician.id,
               technicianStatus: resp.currentStatus,
               sr: resp
            })
         }
      }
   }

   const renderCurrentSR = (technicianId: number) => {
      if (currentSR && _isEqual(currentSR.technicianId, technicianId)) {
         return currentSR;
      }
   }

   const getRequestFormValue = (request: any, shortFieldName: string) => {
      if (!_isUndefined(request.requestFormValues)) {
         const value = request.requestFormValues.find((value: any) => { return value.field.shortName === shortFieldName });
         return value ? value.value : null;
      }
      return null;
   }

   const renderTechnicianList = () => {
      if (!_isUndefined(technicians) && !_isEqual(technicians?.length, 0)) {
         return technicians?.map((obj: Technician, technicianIndex: number) => (
            <Popover
               key={`map-technician-${technicianIndex}`}
               onVisibleChange={(visible: any) => onVisibleChange(obj)}
               overlayClassName={"current-service-popover"}
               placement="rightTop"
               trigger={_isEqual(props.activeTab, 'map') ? "click" : ""}
               title={_isEqual(props.activeTab, 'map') && (() => {
                  return (
                     <div className={"current-service-request-header"}>
                        <div className={"current-service-request-title"}>{renderCurrentSR(obj.id) ? renderCurrentSR(obj.id)?.sr?.title : <br />}</div>
                        <div className={"current-service-request-member"}>{renderCurrentSR(obj.id) ? (renderCurrentSR(obj.id)?.sr?.members?.name || <br />) : <br />}</div>
                     </div>
                  )
               })}
               content={_isEqual(props.activeTab, 'map') && (() => {
                  if (!_isUndefined(renderCurrentSR(obj.id))) {
                     const sr = renderCurrentSR(obj.id)?.sr;
                     return (
                        <div className={"current-service-request-content"}>
                           <ScheduleOutlined />&#160;&#160;{`SR# ${sr?.serviceRequestNo || ""}`} <br />
                           <HomeFilled /> &#160;{getRequestFormValue(sr, 'PRPA')}, {getRequestFormValue(sr, 'PRPC')}, {getRequestFormValue(sr, 'PRPS')}<br />
                           <CalendarOutlined />&#160;&#160;{moment(renderCurrentSR(obj.id)?.sr?.scheduleDateTimeStamp).format("LL")} <br />
                           <DashboardOutlined />&#160;&#160;{`${sr?.durationInMinutes ? sr?.durationInMinutes / 60 : ""} ${sr?.durationInMinutes && (sr?.durationInMinutes / 60) > 1 ? 'hours' : 'hour'}`} <br />
                        </div>
                     )
                  } else {
                     return <Empty />
                  }
               })}
            >
               <div
                  key={`technician_${technicianIndex}`}
                  id={`technician_ref_${obj.id}`}
                  className='border-color-right border-color-bottom'
                  style={selectedTech.includes(obj.id) ?
                     {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        height: 100,
                        paddingLeft: 16,
                        paddingRight: 16,
                        border: _isEqual(props.activeTab, 'map') ? '2px solid' + getTechStatusColorCode(obj.currentStatus) : '',
                        backgroundColor: _isEqual(props.activeTab, 'map') ? '#8989ffff' : '',
                        borderRadius: 8
                     } : {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        height: 100,
                        paddingLeft: 16,
                        paddingRight: 16
                     }
                  }
                  onClick={() => onAddSelectedTechnician(obj.id, obj)}
               >
                  {!_isEqual(props.activeTab, 'map') && (<Avatar
                     style={{ backgroundColor: '#666666ff', width: '20%' }}
                     size={"large"}
                     icon={<UserOutlined />} />)}

                  {_isEqual(props.activeTab, 'map') && (
                     <Avatar
                        className={"map-technician-avatar"}
                        style={!selectedTech.includes(obj.id)
                           ? { backgroundColor: getTechStatusColorCode(obj.currentStatus) }
                           : { backgroundColor: '#ffffffff', border: '2px solid' + getTechStatusColorCode(obj.currentStatus), color: '#4d4d4dff', fontWeight: 700 }}
                        size={"large"}>
                        {technicianIndex + 1}
                     </Avatar>
                  )}

                  {renderTechnicianInfo(obj)}
               </div>
            </Popover>
         ))
      }
      return <></>;
   }

   const renderTechnicianServiceReqCount = () => {
      if (!_isUndefined(technicians) && !_isEqual(technicians?.length, 0)) {
         return technicians?.map((obj: Technician) => renderCount(obj.id))
      }
      return <></>;
   }

   const renderCount = (technicianId: number) => {
      const ndx: number = techSRCount.findIndex(obj => _isEqual(obj.technicianId, technicianId))
      if (!_isEqual(ndx, -1)) {
         return (
            <div
               key={`map-technician-${technicianId}`}
               style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 100
               }}
            >
               {`${techSRCount[ndx].completed}/${techSRCount[ndx].total}`}
            </div>
         )
      }
      return <div
         key={`map-technician-${technicianId}`}
         style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 100
         }}
      >
         0/0
      </div>;
   }

   return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
         <div style={{ display: 'flex', flexDirection: 'column', width: 240, minWidth: 240 }}>
            {props.showSRCount && (
               <div className='border-color-right border-color-bottom'
                  style={{
                     display: 'flex',
                     padding: 16,
                     justifyContent: 'center',
                     alignItems: 'center'
                  }}
               >Technician Name</div>
            )}
            {renderTechnicianList()}
         </div>
         {props.showSRCount ?
            <div style={{ display: 'flex', flexDirection: 'column' }}>
               <div className='border-color-bottom'
                  style={{
                     display: 'flex',
                     padding: 16,
                     justifyContent: 'center',
                     alignItems: 'center'
                  }}
               >SR's Today</div>
               {renderTechnicianServiceReqCount()}
            </div> : null}
      </div>
   );
}

const mapStateToProps = (states: RootState): ReduxState => {
   return {
      techServiceReqCount: states.technicianReducer.state.techniciansSRCounts,
      techniciansGrid: states.technicianReducer.state.techniciansGRID,
      techniciansBoard: states.technicianReducer.state.techniciansBOARD,
      schedulerType: states.schedulerReducer.state.schedulerType
   };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
   return {
      fetchTechnicianServiceRequest: (ids) => dispatch(fetchTechniciansServiceRequest(ids)),
      fetchCurrentServiceRequest: (technicianId) => dispatch(fetchCurrentServiceRequest(technicianId)),
      setRemoveTechnicianId: (id) => dispatch(setRemoveTechnicianId(id)),
      setSelectedTechnicianId: (id) => dispatch(setSelectedTechnicianId(id)),
      setAllSelectedTechnicians: (t) => dispatch(setAllSelectedTechnicians(t))
   };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(TechnicianListComponent));
