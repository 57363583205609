import React from "react";

import { Typography, Image, Card, Row, Col } from "antd";

import Logo from "../../../assets/images/logo2.png";

const { Text } = Typography;

interface Props {
  rowProps?: {};
  colProps?: {};
  children: JSX.Element | JSX.Element[];
}

const LoginLayout: React.FC<Props> = ({ children }): JSX.Element => {
  return (
    <>
      <Row className={"login-layout"} wrap={false}>
        <Col className={"login-layout-l"}></Col>
        <Col className={"login-layout-r"}>
          <Card className="login-card" bordered={false}>
            <div className="login-logo-wrapper">
              <Image
                className={"login-logo"}
                preview={false}
                src={Logo}
              />
            </div>
            <Text className={"life-made-simple"}>
              Life Made Simple
            </Text>
            {children}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(LoginLayout);
