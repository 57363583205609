import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import { Avatar, Typography } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { Dispatcher } from "../../redux/dispatcher/model";
import { setDispatcher } from "../../redux/dispatcher/action";
import { fetchDispatchers } from "../../redux/dispatcher/service";



import "./dispatcher-list.scss";

import _isUndefined from "lodash/isUndefined";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import _isNull from "lodash/isNull";

const { Text } = Typography;

interface ComponentProps {
}

interface ReduxState {
   dispatchers: Array<Dispatcher>;
}

interface DispatchProps {
   fetchDispatchers: () => void;
   setDispatcher: (dispatcher: Dispatcher) => void;
   // setRemoveDispatcherId: (id: any) => any;
   // setSelectedDispatcherId: (id: any) => any;
}

type Props = ComponentProps & ReduxState & DispatchProps;
const DispatcherList: React.FC<Props> = (props): JSX.Element => {
   const [dispatchers, setDispatchers] = React.useState<Array<Dispatcher>>();
   const [selectedDispatcher, setSelectedDispatcher] = React.useState<Dispatcher>();
   
   // React.useEffect(() => {
   //    console.log("**DISPATCHERS**", dispatchers)
   // }, [props.dispatchers]);

   React.useEffect(() => {
      setDispatchers(props.dispatchers);
   }, [props.dispatchers]);
   
   React.useEffect(() => {
      props.fetchDispatchers();
   }, []);

   // React.useEffect(() => props.fetchTechnicianServiceRequest(selectedTech), [selectedTech]);

   const renderDispatcherInfo = (dispatcher: Dispatcher) => (
      <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 16, cursor: 'default', width: '80%' }}>
         <Text ellipsis={true} className='font-color' style={{ fontSize: 18, fontWeight: 700 }}>{dispatcher.fullName}</Text>
         <Text ellipsis={true} className='font-color' style={{ fontSize: 14, fontWeight: 400 }}>{dispatcher.email}</Text>
      </div>
   );

   const selectDispatcher = (dispatcher: Dispatcher) => {
      setSelectedDispatcher(dispatcher);
      props.setDispatcher(dispatcher);
   }

   const renderDispatcherList = () => {

      if (!_isUndefined(dispatchers) && !_isEqual(dispatchers?.length, 0)) {
         return dispatchers?.map((dispatcher: Dispatcher, dispatcherIndex: number) => (
               <div
                  key={`dispatcher_${dispatcherIndex}`}
                  id={`dispatcher_ref_${dispatcher.id}`}
                  className='border-color-right border-color-bottom'
                  style={(selectedDispatcher?.id === dispatcher.id) ?
                     {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: 320,
                        height: 100,
                        paddingLeft: 16,
                        paddingRight: 16,
                        border: '2px solid gray',
                        backgroundColor: '#8989ffff',
                        // border: _isEqual(props.activeTab, 'map') ? '2px solid' + getTechStatusColorCode(obj.currentStatus) : '',
                        // backgroundColor: _isEqual(props.activeTab, 'map') ? '#8989ffff' : '',
                        borderRadius: 8
                     } : {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: 320,
                        height: 100,
                        paddingLeft: 16,
                        paddingRight: 16
                     }
                  }
                  onClick={() => selectDispatcher(dispatcher)}
               >
                  <Avatar
                     style={{ backgroundColor: '#666666ff', width: '20%' }}
                     size={"large"}
                     icon={<UserOutlined />} />
                  
                  {renderDispatcherInfo(dispatcher)}
               </div>
         ))
      }
      return <></>;
   }

   return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
         <div style={{ display: 'flex', flexDirection: 'column', width: 240, minWidth: 240 }}>
            {renderDispatcherList()}
         </div>
      </div>
   );
}

const mapStateToProps = (states: RootState): ReduxState => {
   return {
      dispatchers: states.dispatcherReducer.state.dispatchers,
   };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
   return {
      setDispatcher: (dispatcher: Dispatcher) => dispatch(setDispatcher(dispatcher)),
      fetchDispatchers: () => dispatch(fetchDispatchers()),
   };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(DispatcherList));
