import { GET_ALL_UNREAD_NOTIFICATIONS, SET_ALL_UNREAD_NOTIFICATIONS, READ_NOTIFICATION } from "../action-types";

import { GetAllUnreadNotifications, SetAllUnreadNotifications } from "./model";

import store from "..";

export const getAllUnreadNotifications = (): GetAllUnreadNotifications => {
  return {
    type: GET_ALL_UNREAD_NOTIFICATIONS,
    payload: store.getState().notificationsReducer.state.Notifications
  };
};

export const setAllUnreadNotifications = (payload: any): SetAllUnreadNotifications => {
  return {
    type: SET_ALL_UNREAD_NOTIFICATIONS,
    payload
  };
};

export const readNotification = ( payload: any ) => {
  return {
    type: READ_NOTIFICATION,
    payload
  }
}