import Card from "./Card";
import styles from "./Invoices.module.scss";
import ViewMoreLink from "./ViewMoreLink";

export default function Invoices() {
  return (
    <Card title="Invoices" gap="small" className={styles.invoices}>
      <div className={styles.container}>
        <div>
          <div className={styles["total-invoiced"]}>$6,720.00</div>
          <div className={styles.label}>Total Invoiced</div>
        </div>
        <div>
          <div className={styles.outstanding}>$240.00</div>
          <div className={styles.label}>Outstanding</div>
        </div>
        <div className={styles.bar}>
          <div className={styles["bar-invoices"]} />
          <div className={styles["bar-outstanding"]} />
        </div>
      </div>

      <ViewMoreLink />
    </Card>
  );
}
