import { History } from "history";
import { SET_SESSION_CREDENTIALS, SET_RESET_PASSWORD, SET_IS_AUTHENTICATING, SET_USER_DETAILS, GET_USER_DETAILS, GET_CLIENT_ID, SET_CLIENT_ID } from "../action-types";
import { SessionCredentials, SetSessionCredentials, SetResetPassword, SetIsAuthenticating, SetUserDetails, UserDetails, GetUserDetails } from "./model";
import store from "..";




export const setResetPasswordfields = (email: string): SetResetPassword => {
  return {
    type: SET_RESET_PASSWORD,
    email,
  };
};

export const setSessionCredentials = (sessionCredentials: SessionCredentials): SetSessionCredentials => {
  return {
    type: SET_SESSION_CREDENTIALS,
    payload: sessionCredentials
  };
};


export const setIsAuthenticating = (isAuthenticating: boolean): SetIsAuthenticating => {
  return {
    type: SET_IS_AUTHENTICATING,
    payload: isAuthenticating
  };
};

export const setUserDetails = (userDetails: UserDetails): SetUserDetails => {
  return {
    type: SET_USER_DETAILS,
    payload: userDetails
  };
};

export const getUserDetails = (): GetUserDetails=> {
  return {
    type: GET_USER_DETAILS,
    payload: store.getState().auth.state.userDetails
  };
};

export const setClientId = (id: string) => {
  return () => localStorage.setItem("client", id);
};

export const getClientId = () => {
  return () => localStorage.getItem("client");
}

export const setSessionCredentialsToStorage = (sessionCredentials: SessionCredentials) => {
  return () => localStorage.setItem("SESSION_CREDENTIALS", JSON.stringify(sessionCredentials));
};

export const getSessionCredentialsFromStorage = () => {
  return () => {
    const session: any = localStorage.getItem("SESSION_CREDENTIALS");
    return JSON.parse(session);
  };
};

export const removeSessionFromStorage = (history: History) => {
  return () => {
    localStorage.removeItem("SESSION_CREDENTIALS");
    history.push("/");
  };
};




