import { 
  SET_DISPATCHER, 
  GET_DISPATCHERS, 
  SET_DISPATCHERS, 
  GET_SELECTED_DISPATCHER_ID, 
  SET_SELECTED_DISPATCHER_ID, 
  SET_REMOVE_DISPATCHER_ID, 
  GET_REMOVE_DISPATCHER_ID, 
  SET_ALL_SELECTED_DISPATCHERS, 
  GET_ALL_SELECTED_DISPATCHERS } from "../action-types";

import { Dispatcher, SetDispatcher, GetDispatchers, SetDispatchers } from "./model";

import store from "..";

export const setDispatcher = (payload: any): SetDispatcher => {
  return {
    type: SET_DISPATCHER,
    payload
  };
};

export const getDispatchers = (): GetDispatchers => {
  return {
    type: GET_DISPATCHERS,
    payload: store.getState().dispatcherReducer.state.dispatchers
  };
};

export const setDispatchers = (payload: Array<Dispatcher>): SetDispatchers => {
  return {
    type: SET_DISPATCHERS,
    payload
  };
};

export const getRemoveDispatcherId = () => {
  return {
    type: GET_REMOVE_DISPATCHER_ID,
    payload: store.getState().dispatcherReducer.state.removeDispatcherId
  };
};

export const setRemoveDispatcherId = (payload: any) => {
  return {
    type: SET_REMOVE_DISPATCHER_ID,
    payload
  };
};

export const getSelectedDispatcherId = () => {
  return {
    type: GET_SELECTED_DISPATCHER_ID,
    payload: store.getState().dispatcherReducer.state.selectedDispatcherId
  };
};

export const setSelectedDispatcherId = (payload: any) => {
  return {
    type: SET_SELECTED_DISPATCHER_ID,
    payload
  };
};

export const getAllSelectedDispatchers = () => {
  return {
    type: GET_ALL_SELECTED_DISPATCHERS,
    payload: store.getState().dispatcherReducer.state.allSelectedDispatchers
  };
};

export const setAllSelectedDispatchers = (payload: any) => {
  return {
    type: SET_ALL_SELECTED_DISPATCHERS,
    payload
  };
};