import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import { Typography, Avatar, Collapse, Tag, message, Modal, Button } from "antd";
import { ExclamationCircleOutlined, UserOutlined, HomeFilled, CalendarFilled, PaperClipOutlined } from "@ant-design/icons";
import { Day, Schedules } from "../../redux/scheduler/model";
import { Technician } from "../../redux/technician/model";
import { ServiceRequest } from "../../redux/service-request/model";
import "./SchedulerGridComponent.scss";

import _isNull from "lodash/isNull";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import _isUndefined from "lodash/isUndefined";
import moment from "moment";
import { populateAssignedServiceRequest } from "../../redux/scheduler/service";
import { cardColorCode, getStatusColorCode } from "../../helpers/constants";

import { getAssignedServiceRequests, setAssignedServiceRequests } from "../../redux/service-request/action";
import ServiceRequestDetailsLayout from "../layouts/modal/service-request-details-layout";
import { UnassignedSR } from "../../redux/unassign-service-request/model";

const { Text } = Typography;
const { Panel } = Collapse;
interface ComponentProps { }
interface ReduxState {
   assignedServiceRequests: Array<ServiceRequest>;
   technicians: Array<Technician>;
   grid: Array<Schedules>;
   week: Array<Day>;
   unassignedSR: Array<UnassignedSR>;
}
interface DispatchProps { }
type Props = ComponentProps & ReduxState & DispatchProps;
const GridSchedulerWeekly: React.FC<Props> = (props): JSX.Element => {
   const [technicians, setTechnicians] = React.useState<Array<Technician>>();
   const [grid, setGrid] = React.useState<Array<Schedules>>();
   const [week, setWeek] = React.useState<Array<Day>>();
   const [activeCollapse, setActiveCollapse] = React.useState([]);
   const [srNo, setSrNo] = React.useState<any>(null);

   React.useEffect(() => {
      setTechnicians(props.technicians);
      setGrid(props.grid);
      setWeek(props.week);
   }, [props.grid])

   const redirectSR = localStorage.getItem("redirectSR")
   React.useEffect(() => {
      setSrNo(redirectSR);
      localStorage.removeItem("redirectSR");
   }, []);

   React.useEffect(() => {
      if (!_isNull(srNo)) {
         const canFindSRA = props.assignedServiceRequests.find((sr) => sr.serviceRequestNo === `${srNo}`)
         const canFindSRA2 = props.unassignedSR.find((sr) => sr.serviceRequestNo === `${srNo}`);
         if (_isUndefined(canFindSRA) && _isUndefined(canFindSRA2)) {
            message.error("Cannot find Service Request in Schedule Board.")
         }
      }
   }, [srNo])

   React.useEffect(() => calculateCellHeight(), [grid]);

   const onChangeActiveCollapse = (activeItem: any) => setActiveCollapse(activeItem);

   const calculateCellHeight = () => {
      if (!_isEqual(technicians?.length, 0)) {
         technicians?.forEach((technician: Technician) => {
            const weeklyCellElement: HTMLElement | null = document.getElementById(`weekly_grid_cell_${technician.id}`)
            const technicianElement: HTMLElement | null = document.getElementById(`technician_ref_${technician.id}`)
            if (!_isNull(weeklyCellElement) && !_isNull(technicianElement)) {
               technicianElement.style.minHeight = `${weeklyCellElement.offsetHeight}px`;
            }
         });
      }
   }

   const openServiceRequestDetails = (serviceRequest: ServiceRequest, technicians: Array<Technician>) => {
      window.dispatchEvent(new CustomEvent('serviceRequestDetailsRequested', { detail: { request: serviceRequest, technicians: technicians } }));
   }

   const renderCell = (technicianCellIndex: number, dayIndex: number) => {
      if (!_isUndefined(week) &&
         !_isEqual(week.length, 0) &&
         !_isUndefined(grid) &&
         !_isUndefined(grid[technicianCellIndex]) &&
         !_isUndefined(grid[technicianCellIndex][dayIndex]) &&
         !_isEmpty(grid[technicianCellIndex][dayIndex])) {
         return grid[technicianCellIndex][dayIndex].map(
            (obj: ServiceRequest) => (
               <Collapse
                  className="weekly-grid-cell-collapse border-color-bottom"
                  key={`weekly_grid_cell_inner${technicianCellIndex}_${dayIndex}_${obj.id}`}
                  onChange={onChangeActiveCollapse}
                  activeKey={activeCollapse}
                  style={{
                     border: _isEqual(
                        obj.serviceRequestNo,
                        srNo
                     )
                        ? "3px solid #3a78b3ff"
                        : "none",
                     position: "relative",
                     display: "flex",
                     flexDirection: "column",
                     width: "100%",
                     padding: 0,
                     minWidth: 100,
                     backgroundColor: _isEqual(obj.linked, true)
                        ? "#ccccffff"
                        : "#ccffccff",
                     cursor: "default",
                     zIndex: _isEqual(
                        activeCollapse,
                        `weekly_grid_cell_panel_${obj.id}`
                     )
                        ? 12
                        : 8,
                  }}
                  accordion
               >
                  <Panel
                     key={`weekly_grid_cell_panel_${obj.id}`}
                     showArrow={false}
                     header={
                        <div style={{ display: "flex", flexDirection: "column" }}>
                           {_isEqual(obj.linked, true) ? (
                              <PaperClipOutlined
                                 style={{ position: "absolute", alignSelf: "flex-end" }}
                              />
                           ) : null}
                           <Text
                              ellipsis={true}
                              className="font-color-2 sr-header"
                              style={{
                                 fontSize: 14,
                                 fontWeight: 700,
                                 width: 100,
                              }}
                              onClick={(event: any) => {
                                 event.stopPropagation();
                                 openServiceRequestDetails(obj, props.technicians);
                              }}
                           >
                              {obj.title}
                           </Text>
                           {!obj.primary &&
                              (<Text
                                 style={{
                                    backgroundColor: _isEqual(obj.linked, true)
                                       ? "#e6e6ff"
                                       : "#e6ffe6",
                                    color: _isEqual(obj.linked, true)
                                       ? "#4d4dff"
                                       : "#4dff4d",
                                 }}

                              >Helper</Text>)}
                           <Text className="font-color-2" style={{ fontSize: 12 }}>
                              {`SR# ${obj.serviceRequestNo}`} <br />
                              {`${obj.timeStamp.startTime} - ${obj.timeStamp.endTime}`}
                           </Text>
                           {/* <Tag
                           className='font-color-2'
                           style={{
                              position: 'absolute',
                              bottom: 8,
                              right: 2,
                              fontSize: 10,
                              fontWeight: 700,
                              alignSelf: 'flex-end',
                              backgroundColor: getStatusColorCode(obj.currentStatus)
                           }}
                        >
                           {obj.currentStatus.replace(/_/g, ' ')}
                        </Tag> */}
                        </div>
                     }
                  >
                     <UserOutlined />
                     &#160;&#160;
                     <Text className="font-color-2" style={{ fontSize: 12 }}>
                        {obj.members.name}
                     </Text>{" "}
                     <br />
                     <HomeFilled />
                     &#160;&#160;
                     <Text className="font-color-2" style={{ fontSize: 12 }}>
                        {obj.members.propertyAddress}
                     </Text>{" "}
                     <br />
                     <CalendarFilled />
                     &#160;&#160;
                     <Text className="font-color-2" style={{ fontSize: 12 }}>
                        {moment(obj.createDateTimeStamp).format("LL")}
                     </Text>{" "}
                     <br />
                  </Panel>
               </Collapse>
            )
         );
      }
      return null;
   }

   return (
      <>
         {!_isEqual(technicians?.length, 0) && technicians?.map((obj: Technician, technicianCellIndex: number) => (
            <div
               key={`weekly_grid_cell_${technicianCellIndex}`}
               id={`weekly_grid_cell_${obj.id}`}
               style={{
                  display: 'flex',
                  flexDirection: 'row',
                  minHeight: 100
               }}
            >
               {!_isEqual(week?.length, 0) && week?.map((obj: Day, dayIndex: number) => (
                  <div
                     className='border-color-bottom border-color-right'
                     key={`weekly_grid_cell_inner${technicianCellIndex}_${dayIndex}`}
                     style={{
                        background: '#fafafa',
                        width: 'calc(100% / 7)',
                        minWidth: 100,
                        fontSize: 15,
                        cursor: 'default'
                     }}>
                     {renderCell(technicianCellIndex, dayIndex)}
                  </div>
               ))}
            </div>
         ))}
         {/* <ServiceRequestDetailsLayout/> */}
      </>
   );
}

const mapStateToProps = (states: RootState): ReduxState => {
   return {
      assignedServiceRequests: states.serviceRequestReducer.state.assignedServiceRequests,
      technicians: states.technicianReducer.state.techniciansGRID,
      grid: states.schedulerReducer.state.grid,
      week: states.schedulerReducer.state.sevenDays,
      unassignedSR: states.unassignedSRReducer.state.unassignedSR,
   };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
   return {};
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(GridSchedulerWeekly));



















