import React, {useState, useEffect} from "react";

import { connect } from "react-redux";

import { RootState } from "../../redux";

import { Filter } from "../../redux/filter/model";

type OwnProps = {
  filter: Filter,
  togglePreset: (e:React.MouseEvent<HTMLElement>) => void;
  selectFilter: (item:Filter) => void
};

type StateProps = {
  selectedFilter?: Filter,
}

type Props = OwnProps & StateProps;

const FilterItem: React.FC<Props> = (props): JSX.Element => {

  const [item, setItem] = useState<Filter>(props.filter);

  useEffect(() => {
    setItem(props.filter);
  }, [
    props.filter,
  ]);
  
  const selectFilter = () => {
    props.selectFilter(item);
  }

  const getItemStyle = () => {
    return props.selectedFilter && item.id == props.selectedFilter.id ?
    {backgroundColor: '#eee'} : {backgroundColoror : 'white'};
  }

  const getItemTextStyle = () => {
    return props.selectedFilter && item.id == props.selectedFilter.id ?
    {color: '#1e5c8bff', fontWeight: 700} : {color : 'gray'};
  }

  return (
    <div className="filter-item" style={getItemStyle()} onClick={selectFilter}>
      <div style={getItemTextStyle()}>{item.name}</div>
      <div className="outer">
        {item.preset ? <div className="inner"></div> : null }
      </div>
    </div>
  );
};

const mapStateToProps = (states: RootState): StateProps => {
  return {
    selectedFilter: states.filterReducer.state.filter,
  };
};

export default React.memo(connect(mapStateToProps, {})(FilterItem))
