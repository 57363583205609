import { combineReducers } from "redux";

import { SET_FILTERS, SET_FILTER_FIELDS, SET_FILTER, SET_USER_FILTERS, SET_LOADING_USER_FILTERS, SET_LOADING_FILTER_FIELDS, SET_SEARCH_SR_NUMBER, SET_SEARCH_VALUE, SET_SEARCH_TYPE } from "../action-types";

import { FilterActions, Filters, SearchType } from "./model";

export interface FiltersState {
  state: Filters;
};

const INITIAL_STATE = {
  isLoadingUserFilters: false,
  isLoadingFilterFields: false,
  filter: undefined,
  filters: [],
  userFilters: [],
  filterFields: [],
  searchSRNumber: "",
  searchValue: "",
  searchType: SearchType.SERVICE_REQUEST_NO
};

const state = (
  state: Filters = INITIAL_STATE,
  action: FilterActions
): Filters => {
  switch (action.type) {


    case SET_FILTER:
      return { ...state, filter: action.payload };

    case SET_FILTERS:
      return { ...state, filters: action.payload };

    case SET_LOADING_USER_FILTERS:
      return { ...state, isLoadingUserFilters: action.payload }

    case SET_USER_FILTERS:
      return { ...state, userFilters: action.payload, isLoadingUserFilters: false }

    case SET_LOADING_FILTER_FIELDS:
      return { ...state, isLoadingFilterFields: action.payload }

    case SET_FILTER_FIELDS:
      return { ...state, filterFields: action.payload, isLoadingFilterFields: false };

    case SET_SEARCH_SR_NUMBER:
      return { ...state, searchSRNumber: action.payload };

    case SET_SEARCH_VALUE:
      return { ...state, searchValue: action.payload };

    case SET_SEARCH_TYPE:
      return { ...state, searchType: action.payload };

    default:
      return state;
  };
};

export default combineReducers<FiltersState>({ state });