import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import { Tabs } from "antd";
import { FilterBuilder } from "../../components";
import { Dispatchers } from "../../components";
import { fetchFilters, fetchFilterFields } from "../../redux/filter/service";
import { fetchDispatchers } from "../../redux/dispatcher/service";
import ServiceTypeComponent from "../../components/service-type/service-type";
import { fetchServiceLocations, fetchTypeOfServices } from "../../redux/technician/service";
import ServiceLocationComponent from "../../components/service-location/service-location";

const { TabPane } = Tabs;

interface ComponentProps { }
interface ReduxState {

}
interface DispatchProps {
  fetchFilters: () => void;
  fetchFilterFields: (filterClass: string) => void;
  fetchDispatchers: () => void;
  fetchTypeOfServices: () => any;
  fetchServiceLocations: () => any;
}
type Props = ComponentProps & ReduxState & DispatchProps;
const AdminPage: React.FC<Props> = (props): JSX.Element => {
  const [activeKey, setActiveKey] = React.useState('admin-page-filters');
  React.useEffect(() => {
    const onInit = () => {
      switch (activeKey) {
        case 'admin-page-filters':
          props.fetchFilters();
          break;
        case 'admin-page-users':
          props.fetchDispatchers();
          break;
        case 'admin-page-service-type':
          getTypeOfService();
          break;
        case 'admin-page-service-locations':
          getServiceLocations();
          break;
      }
    }
    onInit();
  }, [activeKey]);
  React.useEffect(() => {
    window.addEventListener("refetchTypeOfService", getTypeOfService);
    window.addEventListener("refetchServiceLocations", getServiceLocations);
    return () => {
      window.removeEventListener("refetchTypeOfService", getTypeOfService);
      window.removeEventListener("refetchServiceLocations", getServiceLocations);
    }
  }, [])

  const getTypeOfService = async () => {
    await props.fetchTypeOfServices();
  }

  const getServiceLocations = async () => {
    await props.fetchServiceLocations();
  }

  return (
    <Tabs className={"scheduler-page-tab"} type="card" onChange={(activeKey) => setActiveKey(activeKey)}>
      <TabPane key={"admin-page-filters"} tab="Filters" >
        <FilterBuilder />
      </TabPane>
      <TabPane key={"admin-page-users"} tab="Users" >
        <Dispatchers />
      </TabPane>
      <TabPane key={"admin-page-service-type"} tab="Service Type" >
        <ServiceTypeComponent />
      </TabPane>
      <TabPane key={"admin-page-service-locations"} tab="Service Locations" >
        <ServiceLocationComponent />
      </TabPane>
    </Tabs>
  );
};

const mapStateToProps = (states: RootState): ReduxState => {
  return {

  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    fetchFilters: () => dispatch(fetchFilters()),
    fetchFilterFields: (filterClass) => dispatch(fetchFilterFields(filterClass)),
    fetchDispatchers: () => dispatch(fetchDispatchers()),
    fetchTypeOfServices: () => dispatch(fetchTypeOfServices()),
    fetchServiceLocations: () => dispatch(fetchServiceLocations())
  };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(AdminPage));
