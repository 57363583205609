import { combineReducers } from "redux";
import { SET_LOGIN_ERROR_MESSAGE } from "../action-types";

import { ErrorMessageActions, ErrorMessageModel } from "./model";

export interface ErrorMessageState {
  state: ErrorMessageModel;
}

const InitialState = {
  code: 0,
  message: "",
  messageDetail: "",
};

const state = (
  state: ErrorMessageModel = InitialState,
  action: ErrorMessageActions
): ErrorMessageModel => {
  switch (action.type) {
    case SET_LOGIN_ERROR_MESSAGE:
      return {
        ...state,
        code: action.errMsg.code,
        message: action.errMsg.message,
        messageDetail: action.errMsg.messageDetail,
      };
    default:
      return state;
  }
};

export default combineReducers<ErrorMessageState>({ state });
