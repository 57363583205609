import BarChart from "./BarChart";
import Card from "./Card";
import CustomReportGenerator from "./CustomReportGenerator";
import styles from "./DashboardGrid.module.scss";
import Invoices from "./Invoices";
import JobHeatMapCard from "./JobHeatMapCard";
import LineEdgedTable from "./LineEdgedTable";
import MembersSubsActivities from "./MembersSubsActivities";
import OutstandingServiceRequests from "./OutstandingServiceRequest";
import Payments from "./Payments";
import PeriodSelectorButton from "./PeriodSelectorButton";
import Revenue from "./Revenue";
import SubscribersSurvey from "./SubscribersSurvey";
import TopListCard from "./TopListCard";
import UserSummaryCard from "./UserSummaryCard";
import ViewMoreLink from "./ViewMoreLink";

export default function DashboardGrid() {
  return (
    <div className={styles.container}>
      <PeriodSelectorButton />
      <div className={styles["grid-primary"]}>
        <UserSummaryCard
          className={styles.technicians}
          userType="Technicians"
          newUsersCount={14}
          isPositive
          userStatusSummaries={[
            { status: "Active", count: 45 },
            { status: "Idle", count: 45 },
            { status: "Offline", count: 19 },
          ]}
          userSummaries={[
            {
              name: "Mason Jefferson",
              status: "Active",
              lastUpdate: "Mar 16, 08:00 AM",
            },
            {
              name: "Bobby Smith",
              status: "Active",
              lastUpdate: "Mar 16, 08:00 AM",
            },
            {
              name: "Benjamin Hernandez",
              status: "Idle",
              lastUpdate: "Mar 16, 08:00 AM",
            },
            {
              name: "Sammy Lewis",
              status: "Offline",
              lastUpdate: "Mar 16, 08:00 AM",
            },
            {
              name: "Keith Oscar Maru",
              status: "Active",
              lastUpdate: "Mar 16, 08:00 AM",
            },
          ]}
        />
        <UserSummaryCard
          className={styles.members}
          userType="Members & Subscribers"
          newUsersCount={62}
          userStatusSummaries={[
            { status: "Logged in", count: 45 },
            { status: "Logged out", count: 19 },
          ]}
          userSummaries={[
            {
              name: "Leigh Hannessa White",
              status: "Logged in",
              lastUpdate: "Mar 16, 08:00 AM",
            },
            {
              name: "Noah Jones Miller",
              status: "Logged in",
              lastUpdate: "Mar 16, 08:00 AM",
            },
            {
              name: "Kaylynn Heinrich",
              status: "Logged in",
              lastUpdate: "Mar 16, 08:00 AM",
            },
            {
              name: "James Kenneth Day",
              status: "Logged out",
              lastUpdate: "Mar 16, 08:00 AM",
            },
            {
              name: "Harper Marie Garcia",
              status: "Logged in",
              lastUpdate: "Mar 16, 08:00 AM",
            },
          ]}
        />

        <Card title="Jobs (Service Requests)" className={styles.jobs}>
          <BarChart
            data={[
              { label: "Completed", color: "#1ACE6D", value: 130 },
              { label: "Cancelled", color: "#A5A5A5", value: 8 },
              { label: "Outstanding", color: "#F9665F", value: 25 },
              { label: "Recurring", color: "#06BAF3", value: 155 },
            ]}
          />
        </Card>
        <OutstandingServiceRequests
          data={[
            {
              name: "Kaylynn Heinrich",
              propertyId: "PRP-000-000-00038",
              serviceType: "Microwave - Microwave making weird noises and",
              daysOverdue: 17,
            },
            {
              name: "James Kenneth Day",
              propertyId: "PRP-000-000-00038",
              serviceType: "Microwave - Microwave making weird noises and",
              daysOverdue: 7,
            },
            {
              name: "Cevi Louise Sans",
              propertyId: "PRP-000-000-00038",
              serviceType: "Microwave - Microwave making weird noises and",
              daysOverdue: 7,
            },
            {
              name: "Grace Young",
              propertyId: "PRP-000-000-00038",
              serviceType: "Microwave - Microwave making weird noises and",
              daysOverdue: 7,
            },
            {
              name: "Jimothy Goodman",
              propertyId: "PRP-000-000-00046",
              serviceType: "Microwave - Microwave making weird noises and",
              daysOverdue: 1,
            },
          ]}
        />
        <Payments />
        <Revenue />
        <Invoices />
        <MembersSubsActivities
          activities={[
            { label: "Svc Requests", color: "#FD783F", value: 150 },
            { label: "Orders", color: "#06BAF3", value: 50 },
            { label: "Delivers", color: "#F53463", value: 71 },
            { label: "Pet Services", color: "#594CEA", value: 8 },
            { label: "Others", color: "#A5A5A5", value: 17 },
          ]}
        />
      </div>

      <div className={styles["grid-secondary"]}>
        <Card className={styles.highest} title={"Highest Rated Technicians"}>
          <LineEdgedTable
            columns={["Ranking", "Name", "Rating"]}
            data={[
              { ranking: 1, name: "Mason Jefferson", rating: 4.28 },
              { ranking: 2, name: "Bobby Smith", rating: 4.0 },
              { ranking: 3, name: "Benjamin Hernandez", rating: 3.57 },
              { ranking: 4, name: "Sammy Lewis", rating: 3.21 },
              { ranking: 5, name: "Keith Oscar Maru", rating: 3.18 },
            ]}
          />
          <ViewMoreLink />
        </Card>
        <Card className={styles.lowest} title={"Lowest Rated Technicians"}>
          <LineEdgedTable
            columns={["Ranking", "Name", "Rating"]}
            data={[
              { ranking: 250, name: "Mason Jefferson", rating: 1.07 },
              { ranking: 249, name: "Bobby Smith", rating: 1.1 },
              { ranking: 248, name: "Benjamin Hernandez", rating: 1.19 },
              { ranking: 247, name: "Sammy Lewis", rating: 1.5 },
              { ranking: 246, name: "Keith Oscar Maru", rating: 2.14 },
            ]}
          />
          <ViewMoreLink />
        </Card>

        <TopListCard
          className={styles.requests}
          title="Top Service Requests"
          data={[
            { label: "General", value: 2 },
            { label: "Lighting/Ceiling Fan", value: 1 },
            { label: "Plumbing", value: 1 },
            { label: "Electronic Device", value: 1 },
            { label: "Appliance", value: 1 },
          ]}
        />
        <TopListCard
          className={styles.orders}
          title="Top Orders"
          data={[
            { label: "General", value: 2 },
            { label: "Lighting/Ceiling Fan", value: 1 },
            { label: "Plumbing", value: 1 },
            { label: "Electronic Device", value: 1 },
            { label: "Appliance", value: 1 },
          ]}
        />
        <JobHeatMapCard />
      </div>

      <div className={styles["grid-tertiary"]}>
        <SubscribersSurvey />
        <CustomReportGenerator />
      </div>
    </div>
  );
}
