import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import { Typography } from "antd";
import { DailyWeekly, Day, Time, Toolbar } from "../../redux/scheduler/model";
import "./SchedulerGridComponent.scss";
import _isEqual from "lodash/isEqual";
const { Text } = Typography;


interface ComponentProps {
   onScrollTime: () => void;
   scrollTimeElement: any;
}
interface ReduxState {
   toolbar: Toolbar;
   week: Array<Day>;
   clock: Array<Time>;
}
interface DispatchProps { }
type Props = ComponentProps & ReduxState & DispatchProps;
const GridSchedulerHeader: React.FC<Props> = (props): JSX.Element => {
   const [toolbar, setToolbar] = React.useState<Toolbar>();
   const [week, setWeek] = React.useState<Array<Day>>();
   const [timeLine, setTimeLine] = React.useState<Array<Time>>();

   React.useEffect(() => setToolbar(props.toolbar), [props.toolbar]);
   React.useEffect(() => setWeek(props.week), [props.week]);
   React.useEffect(() => setTimeLine(props.clock), [props.clock]);

   const renderWeeklyCell = () => {
      if (!_isEqual(week?.length, 0)) {
         return week?.map((obj: Day, dayIndex: number) => (
            <div
               key={`weekly_cell_${dayIndex}`}
               style={{
                  width: 'calc(100% / 7)',
                  minWidth: 100,
                  textAlign: 'center',
                  fontSize: 15
               }}>
               {obj.day}&#160;{`${obj.mm}/${obj.dd}`}
            </div>
         ));
      }
   }

   const renderDailyCell = () => {
      if (!_isEqual(timeLine?.length, 0)) {
         return timeLine?.map((obj: Time, timeIndex: number) => (
            <div
               id={`daily_cell_time_${obj.time}`}
               key={`daily_cell_${timeIndex}`}
               style={{
                  minWidth: 80,
                  fontSize: 15
               }}>
               {obj.display}
            </div>
         ));
      }
   }

   const renderHeader = () => {
      if (_isEqual(toolbar?.D_W, DailyWeekly.WEEKLY)) {
         return (
            <div style={{
               display: 'flex',
               flexDirection: 'row',
               width: '100%'
            }}>
               {renderWeeklyCell()}
            </div>
         );
      }

      if (_isEqual(toolbar?.D_W, DailyWeekly.DAILY)) {
         return (
            <div
               onScroll={props.onScrollTime}
               ref={props.scrollTimeElement}
               style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  overflowX: 'scroll'
               }}>
               {renderDailyCell()}
            </div>
         );
      }
   }

   return (
      <div style={{
         position: 'absolute',
         top: 20,
         zIndex: 2,
         width: '100%',
         height: '100%',
         display: 'flex',
         flexDirection: 'row'
      }}>
         <Text style={{ fontSize: 15, textAlign: 'center', width: 240, minWidth: 240 }}>Technician Name</Text>
         {renderHeader()}
      </div>
   );
}


const mapStateToProps = (states: RootState): ReduxState => {
   return {
      toolbar: states.schedulerReducer.state.toolbar,
      week: states.schedulerReducer.state.sevenDays,
      clock: states.schedulerReducer.state.clock,
   };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
   return {

   };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(GridSchedulerHeader));
















