import { SET_LOGIN_ERROR_MESSAGE } from "../action-types";

import { SetLoginErrorMessage } from "./model";

export const setLoginErrorMessage = (
  code: number,
  message: string,
  messageDetail: string
): SetLoginErrorMessage => {
  return {
    type: SET_LOGIN_ERROR_MESSAGE,
    errMsg: {
      code,
      message,
      messageDetail,
    },
  };
};
