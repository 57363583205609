import { Button, Modal, Upload } from "antd";
import React, { useState, useEffect } from "react";
import { FileTextOutlined } from "@ant-design/icons";
import '../../../assets/stylesheets/layout-style/modal-layout.scss'
import { RootState } from "../../../redux";
import { ThunkDispatch } from "redux-thunk";
import { importTechnicians } from "../../../redux/technician/service";
import { connect } from "react-redux";

interface StateProps { }

interface DispatchProps {
  importTechnicians: (clientId: number, data: string) => any;
}
type Props = StateProps & DispatchProps;
const ImportTechLayout: React.FC<Props> = ({
  importTechnicians
}): JSX.Element => {

  const [isOpen, actionModal] = useState(false);
  const [isUploadEnabled, setUploadEnabled] = useState(false);

  const [fileList, setFileList] = useState<any[]>([]);
  const [FileSend, setFileSend] = useState<any[]>([]);

  useEffect(() => {

    window.addEventListener("openImportTech", openImportTech);
    return () => window.removeEventListener("openImportTech", openImportTech);

  }, [actionModal]);

  const propsUpload = {

    onRemove: (file: any) => {

      const index = fileList.indexOf(file);
      const newFileList: any = fileList.slice();
      newFileList.splice(index, 1);

      return setFileList(newFileList)
    },

    beforeUpload: (file: any) => {
      setFileList([...fileList, file]);
      return false;
    },

    onChange(info: any) {

      const listFiles = info.fileList.slice(-3);

      const newArrayFiles = listFiles.map((file: any) => file.originFileObj ? (file.originFileObj) : file);

      const anAsyncFunction = async (item: any) => {
        return convertBase64(item)
      }

      const getData = async () => {
        return Promise.all(newArrayFiles.map((item: any) => anAsyncFunction(item)))
      }
      getData().then(data => {
        setFileSend(data)
      })

      setUploadEnabled(info.fileList.length > 0);
    },

    multiple: true,
    fileList: fileList,
  };

  const openImportTech = (event: Event) => {

    setFileList([]);
    actionModal(true);
  }

  const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader?.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const onSubmit = async () => {
    const response = await importTechnicians(1, FileSend[0]);
    if (response) {
      window.dispatchEvent(new Event('reloadTechnicians'));
      actionModal(false);
    }
  }

  return (
    <div>
      <Modal
        visible={isOpen}
        centered={true}
        className={"modal-body"}
        onCancel={() => actionModal(false)}
        closable={false}
        footer={null}
        width='25%'>
        <br />
        <h3>Import Technicians</h3>
        <br />
        <Upload.Dragger
          name="files"
          style={{ 'width': '350px' }}
          {...propsUpload}>
          <p className="ant-upload-drag-icon">
            <FileTextOutlined style={{ color: 'gray' }} />
          </p>
          <p className="ant-upload-hint">
            Click on this icon or drag the file you<br />want to upload into this box.
          </p>
        </Upload.Dragger>
        <br />
        <div className={'button-container'}>
          <Button
            block={true}
            style={{ 'backgroundColor': '#3A78B3', 'color': ' white', 'width': '100px', 'marginRight': '10px', opacity: isUploadEnabled ? 1 : 0.5 }}
            type="primary" onClick={onSubmit}
            disabled={!isUploadEnabled}
          >
            Upload
          </Button>
          <Button style={{ 'backgroundColor': '#3A78B3', 'color': ' white', 'width': '100px' }}
            block={true}
            onClick={() => actionModal(false)}
            type="primary" htmlType="submit">
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (states: RootState) => {
  return {}
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    importTechnicians: (clientId: number, data: string) => dispatch(importTechnicians(clientId, data))
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(ImportTechLayout)
);

