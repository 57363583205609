import React, {useState, useEffect} from "react";

import { CloseOutlined } from '@ant-design/icons';

import { Button, Input, Popconfirm, Radio, RadioChangeEvent, Select } from 'antd';

import { connect } from "react-redux";

import { RootState } from "../../redux";

import { ThunkDispatch } from "redux-thunk";

import { Filter, FilterRule, FilterField, FilterValue } from "../../redux/filter/model";

import { setFilter } from "../../redux/filter/action";

const { Option } = Select;


type OwnProps = {
  filterGroupIndex: number;
  filterSubGroupIndex: number;
  filterRuleIndex: number;
  deleteRule: () => void;
};

interface StateProps {
  filter?: Filter;
  filterFields: Array<FilterField>;
}

interface DispatchProps {
  setFilter?: (filter:Filter) => void;
  
}

type Props = OwnProps & StateProps & DispatchProps;

const FilterItemRule: React.FC<Props> = (props): JSX.Element => {

  const [rule, setRule] = useState<FilterRule>({ruleClass: 'RULE', filterRules: []});
  const [field, setField] = useState<FilterField>();
  const [value, setValue] = useState<FilterValue>();
  
  useEffect(() => {
    if (props.filter && props.filter.filterRules) {
      try {
        let rule = props.filter.filterRules[props.filterGroupIndex]
            .filterRules[props.filterSubGroupIndex].filterRules[props.filterRuleIndex];
        setRule(rule);
        setField(rule.filterField);
        setValue(rule.filterValue);
      } catch (e) {
      }
    }
  }, [
    props.filter,
  ]);
  
  const deleteRule = () => {
    props.deleteRule();
  }

  const filterFieldChanged = (id:number) => {

    if (props.filter) {
      const field = props.filterFields.find((field:FilterField) => { return field.id == id});
      let rule = props.filter.filterRules[props.filterGroupIndex]
          .filterRules[props.filterSubGroupIndex].filterRules[props.filterRuleIndex];
      rule.optionalValue = '';
      rule.filterField = field;
      rule.filterValue = undefined;
      if (props.setFilter) props.setFilter({...props.filter});
    }
  }

  const filterValueChanged = (id:number) => {

    if (props.filter) {
      const value = field?.filterValues.find((value:FilterValue) => { return value.id == id});
      let rule = props.filter.filterRules[props.filterGroupIndex]
          .filterRules[props.filterSubGroupIndex].filterRules[props.filterRuleIndex];
      rule.filterValue = value;
      setValue(value);
      if (props.setFilter) props.setFilter({...props.filter});
    }
  }

  const optionalValueChanged = (e:any) => {

    if (props.filter) {
      let rule = props.filter.filterRules[props.filterGroupIndex]
          .filterRules[props.filterSubGroupIndex].filterRules[props.filterRuleIndex];
      rule.optionalValue = e.target.value;
      if (props.setFilter) props.setFilter({...props.filter});
    }
  }

  const operationChanged = (value:RadioChangeEvent) => {

    if (props.filter) {
      props.filter.filterRules[props.filterGroupIndex].filterRules[props.filterSubGroupIndex]
      .filterRules[props.filterRuleIndex].operator = value.target.value;
      if (props.setFilter) props.setFilter({...props.filter});
    }
  }

  const getOperatorStyle = (highlighted:boolean) => {
    if (highlighted) {
      return {
        background: '#d45400',
        borderColor: '#d45400',
        color: 'white'
      }
    } else {
      return {};
    }
  }

  return (
    <div className="filter-rule">
      <div>
        {/* Lead Operator */}
        {null != rule.operator &&
        <>
        <Radio.Group value={rule.operator} size="small" onChange={operationChanged}>
          <Radio.Button value="AND" style={getOperatorStyle(rule.operator === 'AND')}>AND</Radio.Button>
          <Radio.Button value="OR" style={getOperatorStyle(rule.operator === 'OR')}>OR</Radio.Button>
        </Radio.Group>
        &nbsp;
        </>}
        {/* Filter */}
        <Select style={{ width: 160 }} value={rule.filterField?.id} onChange={filterFieldChanged}>
          {props.filterFields.map((field, index) => {
            return <Option key={index} value={field.id}>{field.name}</Option>
          })}
        </Select>

        {rule.filterField?.hasOptions &&
        <>
        &nbsp;&nbsp;
        <Select style={{ width: 160 }} value={rule.filterValue?.id} onChange={filterValueChanged}>
          {field && <>
          {field.filterValues.map((value, index) => {
            return <Option key={index} value={value.id}>{value.value}</Option>
          })}
          </>}
        </Select>
        </>}

        &nbsp;&nbsp;
        {(value && value.hasOptionalValue || !rule.filterField?.hasOptions) && <Input style={{ width: 160 }} value={rule.optionalValue} onChange={optionalValueChanged}/>}
      </div>
      <div>
        {rule.operator ?
        <Popconfirm
          title="Are you sure you want to delete this rule?"
          onConfirm={deleteRule}
          okText="Yes"
          cancelText="No">
          <Button type="ghost" shape="circle" size="small" icon={<CloseOutlined/>}></Button> 
        </Popconfirm>
        : null }
      </div>
    </div>
  );
};

const mapStateToProps = (states: RootState): StateProps => {
  return {
    filter: states.filterReducer.state.filter,
    filterFields: states.filterReducer.state.filterFields,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
 
  return {
    setFilter: (filter:Filter) => dispatch(setFilter(filter)),
  };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(FilterItemRule))
