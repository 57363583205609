import Card from "./Card";
import styles from "./JobHeatMapCard.module.scss";

export default function JobHeatMapCard() {
  const numbers = [50, 25, 10, 5, "<5"];

  return (
    <Card title="Estimate & Job Heat Map" gap="medium" className={styles.map}>
      <div className={styles["job-heat-map"]}>
        <img
          className={styles["image-map"]}
          src="job-heat-map/map.png"
          alt="Job Heat Map"
        />
        <img
          className={styles["job-heat-map-1"]}
          src="job-heat-map/1.svg"
          alt="Heat Map 1"
        />
        <img
          className={styles["job-heat-map-2"]}
          src="job-heat-map/2.svg"
          alt="Heat Map 2"
        />
        <img
          className={styles["job-heat-map-3"]}
          src="job-heat-map/3.svg"
          alt="Heat Map 3"
        />
        <img
          className={styles["job-heat-map-4"]}
          src="job-heat-map/4.svg"
          alt="Heat Map 4"
        />
      </div>

      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.label}>Estimates</div>
          <div className={styles.numbers}>
            {numbers.map((n, i) => (
              <div
                key={i}
                style={{
                  backgroundColor: `rgba(12, 113, 195, ${1 - i * 0.1})`,
                }}
                className={styles.number}>
                {n}
              </div>
            ))}
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Jobs</div>
          <div className={styles.numbers}>
            {numbers.map((n, i) => (
              <div
                key={i}
                style={{
                  backgroundColor: `rgba(253, 103, 62, ${1 - i * 0.1})`,
                }}
                className={styles.number}>
                {n}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
}
