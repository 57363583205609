import { combineReducers } from "redux";
import { SET_UNASSIGNED_SR } from "../action-types";
import { UnassignedSRActions, UnAssignedSRs } from "./model";

export interface UnAssignedSRState {
  state: UnAssignedSRs;
}

const INITIAL_STATE = {
  unassignedSR: [],
};

const state = (
  state: UnAssignedSRs = INITIAL_STATE,
  action: UnassignedSRActions
): UnAssignedSRs => {
  switch (action.type) {
    case SET_UNASSIGNED_SR:
      return { ...state, unassignedSR: action.payload };
    default:
      return state;
  }
};

export default combineReducers<UnAssignedSRState>({ state });
