import React from "react";

import { EnvironmentFilled, HomeFilled } from "@ant-design/icons";
import { getTechStatusColorCode } from "../../helpers/constants";
interface MarkerProps {
  lat: number;
  lng: number;
  onClick: any;
  currentStatus: string;
  id: number;
  children?: JSX.Element;
  type: any;
}

const LocationMarker: React.FC<MarkerProps> = ({
  onClick,
  currentStatus,
  id,
  type,
}): JSX.Element => {
  const displayMarker = () => {
    if (type === "technician") {
      return (
        <div>
          <EnvironmentFilled />
          <p
            style={{
              color: getTechStatusColorCode(currentStatus),
              fontSize: "12px",
            }}
          >
            {" "}
            {`TID#${id}`}
          </p>
        </div>
      );
    } else if (type === "SR") {
      return (
        <HomeFilled
          style={{ color: getTechStatusColorCode(currentStatus) }}
          className={"service-request-home-icon"}
        />
      );
    }
  };

  return (
    <div
      style={{
        fontSize: "2.5em",
        color: getTechStatusColorCode(currentStatus),
      }}
      onClick={onClick}
    >
      {displayMarker()}
    </div>
  );
};

export default React.memo(LocationMarker);
