import { SET_CLOCK, SET_ISLOADING_SCHEDULER, SET_SCHEDULER_GRID, SET_SCHEDULER_GRID_D, SET_SCHEDULER_TOOLBAR, SET_SCHEDULER_TYPE, SET_SEVEN_DAYS } from "../action-types";
import { SchedulerActions, SchedulerModel, DailyWeekly, ClockType, SchedulerType } from "./model";
import { combineReducers } from "redux";
import moment from "moment";

export interface SchedulerState {
  state: SchedulerModel;
};

const INITIAL_STATE = {
  isLoading: false,
  clock: [],
  sevenDays: [],
  toolbar: {
    typeFilter: null,
    dateFilter: moment(),
    D_W: DailyWeekly.WEEKLY,
    clockType: ClockType.TWELVE_HOUR
  },
  grid: [],
  gridD: [],
  schedulerType: SchedulerType.GRID
};

const state = (
  state: SchedulerModel = INITIAL_STATE,
  action: SchedulerActions
): SchedulerModel => {
  switch (action.type) {
    case SET_ISLOADING_SCHEDULER:
      return { ...state, isLoading: action.payload };
    case SET_CLOCK:
      return { ...state, clock: action.payload };
    case SET_SEVEN_DAYS:
      return { ...state, sevenDays: action.payload };
    case SET_SCHEDULER_TOOLBAR:
      return { ...state, toolbar: action.payload };
    case SET_SCHEDULER_GRID:
      return { ...state, grid: action.payload };
    case SET_SCHEDULER_GRID_D:
      return { ...state, gridD: action.payload };
    case SET_SCHEDULER_TYPE:
      return { ...state, schedulerType: action.payload };
    default:
      return state;
  };
};

export default combineReducers<SchedulerState>({ state });