import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Spin,
  message,
  Input,
  Radio,
  Upload,
  Image,
  Form,
} from "antd";
import {
  FlagOutlined,
  CameraOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  BookOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import _isUndefined from "lodash/isUndefined";
import _isNull from "lodash/isNull";
import {
  updateServiceRequestStatus,
  acceptServiceRequest,
  submitCloseForm,
  flagException,
  fetchAllNotes,
  addNotes,
} from "../../../redux/service-request/service";
import NoStatus from "../../../assets/fsa-icons/svg/nostatus.svg";
import Accepted from "../../../assets/fsa-icons/svg/onmyway.svg";
import OnMyWay from "../../../assets/fsa-icons/svg/onsite.svg";
import OnSite from "../../../assets/fsa-icons/svg/jobcompleted.svg";
import JobCompleted from "../../../assets/fsa-icons/svg/completionform.svg";
import moment from "moment";
import { SERVER_URL } from "../../../configs/service-config";
import Exception from "./exception";
import AddNote from "./add-note";
import ViewNote from "./view-note";

interface StateProps {}

interface DispatchProps {
  updateServiceRequestStatus: (updatedSR: any) => any;
  acceptServiceRequest: (serviceId: any, techId: any) => any;
  submitCloseForm: (data: any) => any;
  flagException: (exception: any) => any;
  fetchAllNotes: (notes: any) => any;
  addNote: (note: any) => any;
}
type Props = StateProps & DispatchProps;

const EditServiceRequestDetailsLayout: React.FC<Props> = (
  props
): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [notesOpen, setNotesOpen] = useState(false);
  const [exceptionVisible, setExceptionVisible] = useState(false);
  const [addNotesVisible, setAddNotesVisible] = useState(false);
  const [viewNotesVisible, setViewNotesVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [notes, setNotes] = useState("");
  const [hours, setHours] = useState("");
  const [checkedItem, setCheckedItem] = useState("second");
  const [checkedPart, setCheckedPart] = useState("first");
  const [partsEquipment, setPartsEquipment] = useState("");
  const [notesList, setNotesList] = useState<any>([]);
  const [isAddReplacedPart, setIsAddReplacedPart] = React.useState(true);
  const [isAddNewPart, setIsAddNewPart] = React.useState<any>(true);
  const [itemName, setItemName] = useState<any>(null);
  const [oldBrand, setOldBrand] = useState<any>(null);
  const [oldModel, setOldModel] = useState<any>(null);
  const [oldSerialNumber, setOldSerialNumber] = useState<any>(null);
  const [isAddNewAttachment, setIsAddNewAttachment] = React.useState<any>(true);
  const [changes, setChanges] = React.useState<any>(false);
  const [itemModel, setItemModel] = useState<any>("");
  const [itemBrand, setItemBrand] = useState<any>("");
  const [itemSerialNumber, setItemSerialNumber] = useState("");
  const [itemBase64Photo, setItemBase64Photo] = useState("");
  const [partsAmount, setPartsAmount] = useState("");
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serviceRequest, setServiceRequest] = useState<any>(null);
  const [invoiceItems, setInvoiceItems] = useState<any>([
    {
      part: null,
      amount: null,
    },
  ]);
  const [replacedParts, setReplacedParts] = useState([
    {
      name: null,
      quantity: null,
      serialNumber: null,
      brand: null,
      model: null,
      base64Photo: null,
      photo: null,
    },
  ]);

  const [newParts, setNewParts] = useState([
    {
      name: null,
      quantity: null,
      serialNumber: null,
      brand: null,
      model: null,
      photo: null,
      base64Photo: null,
    },
  ]);
  // attachments //
  const [attachments, setAttachments] = useState([
    {
      attachment: null,
      base64Attachment: null,
    },
  ]);
  const [form] = Form.useForm();
  const [isInvoiceDisable, setIsInvoiceDisable] = React.useState(true);

  useEffect(() => {
    window.addEventListener(
      "editServiceRequestDetailRequested",
      openEditServiceRequest
    );
    // window.addEventListener("pushServiceRequestStatus", trackSRStatus);
    return () => {
      window.removeEventListener(
        "editServiceRequestDetailRequested",
        openEditServiceRequest
      );
      // window.removeEventListener("pushServiceRequestStatus", trackSRStatus);
    };
  }, []);

  useEffect(() => {
    checkReplacedPart();
    checkNewPart();
    checkNewAttachment();
    checkInvoiceItems();
    totalPartsCost();
    totalInvoiceAmount();
    setAttachments((prevObj) => [...prevObj]);
  }, [changes]);


  const fetchAllNotes = async (request: any) => {
    const response = await props.fetchAllNotes(request.id);
    if (response.statusCode === 200) {
      setNotesList(response.body);
    }
  };

  const totalPartsCost = () => {
    var total = 0;
    for (var i in invoiceItems) {
      total += invoiceItems[i].amount;
    }

    return total;
  };

  const totalInvoiceAmount = () => {
    return totalServiceCost() + totalPartsCost();
  };

  const openEditServiceRequest = (event: Event) => {
    //@ts-ignore
    const { request } = event.detail;
    setServiceRequest(request);
    fetchAllNotes(request);
    setOpen(true);
  };

  // const trackSRStatus = (event: Event) => {
  //   //@ts-ignore
  //   const { request } = event.detail;
  //   if (request) {
  //     //@ts-ignore
  //     setServiceRequest((prev) => ({ ...prev, currentStatus: request.status }));
  //   }
  // };

  const acceptTask = async () => {
    setAcceptLoading(true);
    const response = await props.acceptServiceRequest(
      serviceRequest.id,
      serviceRequest.technicianId
    );
    if (response.statusCode === 200) {
      setServiceRequest({ ...response.body, title: serviceRequest.title });
    } else {
      message.error("An error occured while accepting the service request.");
    }
    setAcceptLoading(false);
  };

  const onMyWay = async () => {
    setLoading(true);
    const response = await props.updateServiceRequestStatus({
      id: serviceRequest.id,
      status: "ENROUTE",
    });
    if (response.statusCode === 200) {
      setServiceRequest({ ...response.body, title: serviceRequest.title });
    } else {
      message.error(
        "An error occured while updating the status of service request."
      );
    }
    setLoading(false);
  };

  const onSiteSubmit = async () => {
    setLoading(true);
    const response = await props.updateServiceRequestStatus({
      id: serviceRequest.id,
      status: "ONSITE",
    });
    if (response.statusCode === 200) {
      setServiceRequest({ ...response.body, title: serviceRequest.title });
    } else {
      message.error(
        "An error occured while updating the status of service request."
      );
    }
    setLoading(false);
  };

  const onJobCompleted = async () => {
    setLoading(true);
    const response = await props.updateServiceRequestStatus({
      id: serviceRequest.id,
      status: "SERVICE_COMPLETED",
    });
    if (response.statusCode === 200) {
      setServiceRequest({ ...response.body, title: serviceRequest.title });
    } else {
      message.error(
        "An error occured while updating the status of service request."
      );
    }
    setLoading(false);
  };

  const noStatus = (status: any) => {
    switch (status) {
      case "ASSIGNED":
      case "ENROUTE":
      case "ONSITE":
      case "SERVICE_COMPLETED":
      case "INVOICE_SUBMITTED":
      case "COMPLETED":
        return false;
      default:
        return true;
    }
  };

  const totalServiceCost = () => {
    return parseFloat(serviceRequest?.payRate) * parseFloat(hours);
  };

  const submit = async () => {
    setLoading(true);

    const replacedPartsArray = replacedParts.filter(
      (r) => r.name !== null && r.serialNumber !== null && r.quantity !== null
    );

    const newPartsArray = newParts.filter(
      (n) => n.name !== null && n.serialNumber !== null && n.quantity !== null
    );

    const attachmentsArray = attachments.filter(
      (a) => a.base64Attachment !== null
    );

    const data = {
      serviceRequest,
      partReplaced: checkedPart == "first" ? true : false,
      itemReplaced: checkedItem == "first" ? true : false,
      replacedParts: replacedPartsArray.length > 0 ? replacedPartsArray : [],
      newParts: newPartsArray.length > 0 ? newPartsArray : [],
      attachments: attachmentsArray.length > 0 ? attachmentsArray : [],
      notesSummary: notes,
      itemSerialNumber,
      itemBrand,
      itemModel,
      base64ItemPhoto: itemBase64Photo,
      invoice: {
        serviceRequest,
        dateTimeStamp: new Date(),
        payRate: serviceRequest.payRate,
        duration: hours,
        invoiceItems: invoiceItems,
      },
    };

    const closeFormResponse = await props.submitCloseForm(data);
    if (closeFormResponse.statusCode == 200) {
      const request = {
        ...closeFormResponse.body.serviceRequest,
        currentStatus: "INVOICE_SUBMITTED",
      };
      setServiceRequest(request);
      form.resetFields();
      resetAllFields();
      setLoading(false);
      setOpen(false);
    } else {
      setLoading(false);
    }
  };

  const resetAllFields = () => {
    setReplacedParts([
      {
        name: null,
        quantity: null,
        serialNumber: null,
        brand: null,
        model: null,
        base64Photo: null,
        photo: null,
      },
    ]);
    setNewParts([
      {
        name: null,
        quantity: null,
        serialNumber: null,
        brand: null,
        model: null,
        photo: null,
        base64Photo: null,
      },
    ]);
    setAttachments([
      {
        attachment: null,
        base64Attachment: null,
      },
    ]);
    setInvoiceItems([
      {
        part: null,
        amount: null,
      },
    ]);
  }
  const addPartReplaced = () => {
    const object = {
      name: null,
      quantity: null,
      serialNumber: null,
      brand: null,
      model: null,
      base64Photo: null,
      photo: null,
    };
    setReplacedParts((prevObj: any) => {
      setIsAddReplacedPart(true);
      return [...prevObj, object];
    });
  };

  const checkReplacedPart = () => {
    let check = replacedParts[replacedParts.length - 1];
    if (
      check.name == null ||
      check.model == null ||
      check.quantity == null ||
      check.serialNumber == null
    ) {
      setIsAddReplacedPart(true);
    } else {
      setIsAddReplacedPart(false);
    }
  };

  const addNewPart = () => {
    const object = {
      name: null,
      quantity: null,
      serialNumber: null,
      brand: null,
      model: null,
      base64Photo: null,
      photo: null,
    };
    setNewParts((prevObj: any) => {
      setIsAddNewPart(true);
      return [...prevObj, object];
    });
  };

  const checkNewPart = () => {
    let check = newParts[newParts.length - 1];
    if (
      check.name == null ||
      check.model == null ||
      check.quantity == null ||
      check.serialNumber == null
    ) {
      setIsAddNewPart(true);
    } else {
      setIsAddNewPart(false);
    }
  };

  const addInvoiceItems = async () => {
    const object = {
      part: null,
      amount: null,
    };
    setInvoiceItems((prevObj: any) => {
      setIsInvoiceDisable(true);
      return [...prevObj, object];
    });
  };

  const addAttachment = () => {
    const object = {
      attachment: null,
      base64Attachment: null,
    };
    setAttachments((prevObj: any) => {
      setIsAddNewAttachment(true);
      return [...prevObj, object];
    });
  };

  const checkNewAttachment = () => {
    let check = attachments[attachments.length - 1];
    if (check.base64Attachment == null) {
      setIsAddNewAttachment(true);
    } else {
      setIsAddNewAttachment(false);
    }
  };

  const checkInvoiceItems = () => {
    let check = invoiceItems[invoiceItems.length - 1];
    if (check.part == null || check.amount == null) {
      setIsInvoiceDisable(true);
    } else {
      setIsInvoiceDisable(false);
    }
  };

  const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader?.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 18, color: "white" }} spin />
  );

  return (
    <Modal
      visible={open}
      centered={true}
      onCancel={() => {
        setOpen(false);
      }}
      className={"request-modal"}
      closable={false}
      footer={null}
      width={
        serviceRequest && serviceRequest.currentStatus === "PENDING_ASSIGNMENT"
          ? "30%"
          : "50%"
      }
    >
      {serviceRequest ? (
        <div
          style={{
            flex: 1,
            width: "100%",
          }}
        >
          {serviceRequest.currentStatus === "PENDING_ASSIGNMENT" ? (
            <div style={{ padding: 20 }}>
              <div style={{ textAlign: "center", fontSize: 20 }}>
                Do you want to accept this service request?
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#3A78B3",
                    color: " white",
                    marginRight: 10,
                    width: "15%",
                  }}
                  type="primary"
                  size="large"
                  htmlType="button"
                  onClick={() => {
                    acceptTask();
                  }}
                >
                  {acceptLoading ? <Spin indicator={antIcon} /> : "Yes"}
                </Button>
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "#3A78B3",
                    marginRight: "4px",
                    width: "15%",
                  }}
                  size="large"
                  htmlType="button"
                  type="primary"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  No
                </Button>
              </div>
            </div>
          ) : (
            <Form name="form" form={form} onFinish={submit}>
              {serviceRequest.currentStatus === "PENDING_ASSIGNMENT" ? (
                <div
                  style={{
                    zIndex: 15,
                    backgroundColor: `rgba(0, 0, 0, 0.8)`,
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                />
              ) : null}
              <div
                style={{
                  flex: 1,
                  width: "100%",
                  display: "flex",
                  fontFamily: "Arial, Helvetica, sans-serif",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    zIndex: 5,
                    marginTop: "2.5%",
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      alignSelf: "center",
                      textAlign: "center",
                      color:
                        serviceRequest?.currentStatus === "ASSIGNED"
                          ? "white"
                          : "#202020",
                      fontSize: 15,
                    }}
                  >
                    Accepted
                  </div>
                  <div
                    style={{
                      flex: 1,
                      alignSelf: "center",
                      textAlign: "center",
                      color:
                        serviceRequest?.currentStatus === "ENROUTE"
                          ? "white"
                          : "#202020",
                      fontSize: 15,
                    }}
                  >
                    On My Way
                  </div>
                  <div
                    style={{
                      flex: 1,
                      alignSelf: "center",
                      textAlign: "center",
                      color:
                        serviceRequest?.currentStatus === "ONSITE"
                          ? "white"
                          : "#202020",
                      fontSize: 15,
                    }}
                  >
                    On Site
                  </div>
                  <div
                    style={{
                      flex: 1,
                      alignSelf: "center",
                      textAlign: "center",
                      color:
                        serviceRequest?.currentStatus === "SERVICE_COMPLETED"
                          ? "white"
                          : "#202020",
                      fontSize: 15,
                    }}
                  >
                    Job{"\n"}Completed
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    height: 60,
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1,
                  }}
                >
                  {serviceRequest?.currentStatus === "ASSIGNED" ? (
                    <img
                      src={Accepted}
                      alt="On My Way"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : null}
                  {serviceRequest?.currentStatus === "ENROUTE" ? (
                    <img
                      src={OnMyWay}
                      alt="On Site"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : null}
                  {serviceRequest?.currentStatus === "ONSITE" ? (
                    <img
                      src={OnSite}
                      alt="On Site"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : null}
                  {serviceRequest?.currentStatus === "SERVICE_COMPLETED" ? (
                    <img
                      src={JobCompleted}
                      alt="Job Completed"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : null}
                  {noStatus(serviceRequest?.currentStatus) ? (
                    <img
                      src={NoStatus}
                      alt="Default"
                      style={{ width: "100%" }}
                    />
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  padding: 60,
                  overflowY:
                    serviceRequest.currentStatus === "SERVICE_COMPLETED" ||
                    (serviceRequest.currentStatus === "ONSITE" && notesOpen)
                      ? "scroll"
                      : "hidden",
                }}
              >
                {serviceRequest?.currentStatus === "ENROUTE" ||
                (serviceRequest?.currentStatus === "ONSITE" && !notesOpen) ||
                serviceRequest?.currentStatus === "ASSIGNED" ? (
                  <div>
                    <div
                      style={{
                        alignSelf: "center",
                        textAlign: "center",
                        fontSize: 30,
                        fontWeight: "bold",
                      }}
                    >
                      {serviceRequest?.title}
                      {serviceRequest?.currentStatus === "ONSITE" ? (
                        <FlagOutlined
                          size={25}
                          style={{
                            position: "absolute",
                            right: 50,
                            marginTop: 10,
                          }}
                          onClick={() => {
                            setExceptionVisible(true);
                          }}
                        />
                      ) : null}
                    </div>
                    <p
                      style={{
                        alignSelf: "center",
                        textAlign: "center",
                        fontSize: 20,
                      }}
                    >
                      SR#{serviceRequest.serviceRequestNo}
                    </p>
                    <div style={{ display: "flex", fontSize: 15 }}>
                      <div style={{ flex: 1, alignItems: "flex-start" }}>
                        <p>Date:</p>
                        <p>Time:</p>
                        <p>Address:</p>
                        <p>Member:</p>
                      </div>
                      <div style={{ flex: 3, alignItems: "flex-start" }}>
                        <p>
                          {moment(serviceRequest.scheduleDateTimeStamp).format(
                            "ll"
                          )}
                        </p>
                        <p>
                          {" "}
                          {moment(serviceRequest.scheduleDateTimeStamp).format(
                            "LT"
                          )}
                        </p>
                        <p>{serviceRequest?.requestFormValues[4]?.value}</p>
                        <p> {serviceRequest?.requestFormValues[1]?.value}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
                {serviceRequest?.currentStatus === "ONSITE" && notesOpen ? (
                  <div style={{ height: "500px" }}>
                    <div style={{ fontSize: 30, fontWeight: "bold" }}>
                      NOTES
                    </div>
                    {notesList && notesList.length > 0 ? (
                      notesList.map((note: any, i: number) => {
                        return (
                          <div
                            key={i}
                            style={{ marginTop: 10, cursor: "pointer" }}
                            onClick={async () => {
                              setSelectedNote(note);
                              setViewNotesVisible(true);
                            }}
                          >
                            <div style={{ color: "#345d7d", fontSize: 25 }}>
                              SR #{serviceRequest.serviceRequestNo}{" "}
                              {serviceRequest.requestFormValues[6].value}
                            </div>
                            <div style={{ color: "gray", fontSize: 20 }}>
                              {moment(note.dateTimeStamp).format("LLL")}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div
                        style={{ marginTop: 10, fontSize: 25, color: "grey" }}
                      >
                        You do not have any notes for this service request.
                      </div>
                    )}
                  </div>
                ) : null}
                {serviceRequest?.currentStatus === "SERVICE_COMPLETED" ? (
                  <div style={{ height: "500px" }}>
                    <div
                      style={{
                        alignSelf: "center",
                        textAlign: "center",
                        fontSize: 25,
                        fontWeight: "bold",
                      }}
                    >
                      SR CLOSE FORM
                    </div>
                    <div
                      style={{
                        fontSize: 25,
                        marginTop: 30,
                        fontWeight: "bold",
                      }}
                    >
                      GENERAL INFORMATION
                    </div>
                    <div style={{ marginTop: 30 }}>
                      <div>
                        SR Issue
                        <Input
                          value={serviceRequest.requestFormValues[7].value}
                          disabled={true}
                        />
                      </div>
                      <div style={{ marginTop: 10 }}>
                        SR Number
                        <Input
                          value={serviceRequest.serviceRequestNo}
                          disabled={true}
                        />
                      </div>
                      <div style={{ marginTop: 10, display: "flex" }}>
                        <div style={{ flex: 1, marginRight: 10 }}>
                          Date{" "}
                          <Input
                            value={moment(
                              serviceRequest.scheduleDateTimeStamp
                            ).format("ll")}
                            disabled={true}
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          Time{" "}
                          <Input
                            value={moment(
                              serviceRequest.scheduleDateTimeStamp
                            ).format("LT")}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div style={{ marginTop: 10 }}>
                        Property Name
                        <Input
                          value={serviceRequest.requestFormValues[3].value}
                          disabled={true}
                        />
                      </div>
                      <div style={{ marginTop: 10 }}>
                        Property Address
                        <Input
                          value={serviceRequest.requestFormValues[4].value}
                          disabled={true}
                        />
                      </div>
                      <div style={{ marginTop: 10, display: "flex" }}>
                        <div style={{ flex: 1.5, marginRight: 10 }}>
                          City{" "}
                          <Input
                            value={serviceRequest.requestFormValues[6].value}
                            disabled={true}
                          />
                        </div>
                        <div style={{ flex: 1, marginRight: 10 }}>
                          State{" "}
                          <Input
                            value={serviceRequest.requestFormValues[5].value}
                            disabled={true}
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          ZIP Code{" "}
                          <Input
                            value={serviceRequest.requestFormValues[7].value}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div style={{ marginTop: 10 }}>
                        Member's Name
                        <Input
                          value={serviceRequest.requestFormValues[1].value}
                          disabled={true}
                        />
                      </div>
                      <div
                        style={{
                          fontSize: 25,
                          marginTop: 30,
                          fontWeight: "bold",
                        }}
                      >
                        NOTES
                      </div>
                      <div style={{ marginTop: 10 }}>
                        Notes Summary
                        <Input.TextArea
                          value={notes}
                          onChange={(e: any) => setNotes(e.target.value)}
                          autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                      </div>
                      <div
                        style={{
                          fontSize: 25,
                          marginTop: 30,
                          fontWeight: "bold",
                        }}
                      >
                        PARTS/MATERIALS USED
                      </div>

                      <div style={{ marginTop: 20 }}>
                        <div style={{ display: "flex" }}>
                          <p
                            style={{
                              marginRight: 15,
                              fontSize: 18,
                              fontWeight: "bold",
                            }}
                          >
                            Was an item replaced?
                          </p>
                          <Radio.Group
                            value={checkedItem}
                            onChange={(e) => {
                              setCheckedItem(e.target.value);
                            }}
                          >
                            <Radio value={"first"}>Yes</Radio>
                            <Radio value={"second"}>No</Radio>
                          </Radio.Group>
                        </div>
                        {checkedItem === "first" ? (
                          <div style={{ marginTop: 20, marginBottom: 20 }}>
                            <div style={{ marginTop: 10 }}>
                              Item Name
                              <Input value={itemName} disabled={true} />
                            </div>
                            <div style={{ marginTop: 10 }}>
                              Serial Number
                              <Input value={itemSerialNumber} disabled={true} />
                            </div>
                            <div style={{ marginTop: 10 }}>
                              Brand
                              <Input value={itemBrand} disabled={true} />
                            </div>
                            <div style={{ marginTop: 10 }}>
                              Model
                              <Input value={itemModel} disabled={true} />
                            </div>
                            <div style={{ marginTop: 10 }}>
                              Photo
                              <div
                                style={{
                                  backgroundColor: "white",
                                  height: 200,
                                  border: "1px solid lightgray",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {serviceRequest.requestFormValues.filter(
                                  (requestFormValue: any) =>
                                    requestFormValue.field.shortName == "IPH"
                                ).value ? (
                                  <Image
                                    width={200}
                                    height={150}
                                    src={`${SERVER_URL.ITEM_PHOTO}/${
                                      serviceRequest.requestFormValues.filter(
                                        (requestFormValue: any) =>
                                          requestFormValue.field.shortName ==
                                          "IPH"
                                      ).value
                                    }`}
                                  />
                                ) : (
                                  <Upload
                                    showUploadList={false}
                                    beforeUpload={() => false}
                                    multiple={false}
                                    disabled={true}
                                  >
                                    <CameraOutlined
                                      style={{
                                        fontSize: 100,
                                        color: "#2e71a4",
                                      }}
                                    />
                                  </Upload>
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                alignSelf: "center",
                                justifyContent: "center",
                                display: "flex",
                                marginTop: 20,
                              }}
                            >
                              New Item Installed
                            </div>
                            <div style={{ marginTop: 10 }}>
                              Item Name
                              <Input value={itemName} disabled={true} />
                            </div>
                            <div style={{ marginTop: 10 }}>
                              Serial Number
                              <Input value={oldSerialNumber} />
                            </div>
                            <div style={{ marginTop: 10 }}>
                              Brand
                              <Input value={oldBrand} />
                            </div>
                            <div style={{ marginTop: 10 }}>
                              Model
                              <Input value={oldModel} />
                            </div>
                            <div style={{ marginTop: 10 }}>
                              Photo
                              <div
                                style={{
                                  backgroundColor: "white",
                                  height: 200,
                                  border: "1px solid lightgray",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {itemBase64Photo ? (
                                  <Image
                                    width={200}
                                    height={150}
                                    src={`data:image/png;base64,${itemBase64Photo}`}
                                  />
                                ) : (
                                  <Upload
                                    showUploadList={false}
                                    beforeUpload={() => false}
                                    multiple={false}
                                    onChange={async (file: any) => {
                                      let base64 = await convertBase64(
                                        file.file
                                      );
                                      if (base64) {
                                        setChanges(!changes);
                                        setItemBase64Photo(
                                          (base64 as string).split(",")[1]
                                        );
                                      }
                                    }}
                                  >
                                    <CameraOutlined
                                      style={{
                                        fontSize: 100,
                                        color: "#2e71a4",
                                      }}
                                    />
                                  </Upload>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div style={{ display: "flex" }}>
                          <p
                            style={{
                              marginRight: 12,
                              fontSize: 18,
                              fontWeight: "bold",
                            }}
                          >
                            Was the part replaced?
                          </p>
                          <Radio.Group
                            value={checkedPart}
                            onChange={(e) => {
                              setCheckedPart(e.target.value);
                            }}
                          >
                            <Radio value={"first"}>Yes</Radio>
                            <Radio value={"second"}>No</Radio>
                          </Radio.Group>
                        </div>
                        {checkedPart === "first" ? (
                          <div style={{ marginTop: 20, marginBottom: 20 }}>
                            <div
                              style={{
                                alignSelf: "center",
                                textAlign: "center",
                              }}
                            >
                              Replaced Parts
                            </div>
                            {replacedParts.map((part: any, i: any) => {
                              return (
                                <div style={{ marginTop: 10 }} key={i}>
                                  <div
                                    style={{ display: "flex", marginTop: 10 }}
                                  >
                                    <div style={{ flex: 3, marginRight: 10 }}>
                                      Part Name
                                      <Form.Item
                                      name={`partname${i}`}
                                      >
                                      <Input
                                        value={part.name}
                                        onChange={(e) => {
                                          setChanges(!changes);
                                          return (part.name = e.target.value);
                                        }}
                                      />
                                      </Form.Item>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                      Qty
                                      {/* <Form.Item
                                      name={`quantity${i}`}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Quantity is required!",
                                        },
                                      ]}
                                    > */}
                                      <Input
                                        value={part.quantity}
                                        type={"number"}
                                        onChange={(e) => {
                                          setChanges(!changes);
                                          return (part.quantity =
                                            e.target.value);
                                        }}
                                      />
                                      {/* </Form.Item> */}
                                    </div>
                                  </div>
                                  <div style={{ marginTop: 10 }}>
                                    Serial Number
                                    {/* <Form.Item
                                    name={`serial${i}`}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Serial number is required!",
                                      },
                                    ]}
                                  > */}
                                    <Input
                                      value={part.serialNumber}
                                      onChange={(e) => {
                                        setChanges(!changes);
                                        return (part.serialNumber =
                                          e.target.value);
                                      }}
                                    />
                                    {/* </Form.Item> */}
                                  </div>
                                  <div style={{ marginTop: 10 }}>
                                    Brand
                                    <Input
                                      value={part.brand}
                                      onChange={(e) => {
                                        setChanges(!changes);
                                        return (part.brand = e.target.value);
                                      }}
                                    />
                                  </div>
                                  <div style={{ marginTop: 10 }}>
                                    Model
                                    {/* <Form.Item
                                    name={`model${i}`}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Model is required!",
                                      },
                                    ]}
                                  > */}
                                    <Input
                                      value={part.model}
                                      onChange={(e) => {
                                        setChanges(!changes);
                                        return (part.model = e.target.value);
                                      }}
                                    />
                                    {/* </Form.Item> */}
                                  </div>
                                  <div style={{ marginTop: 10 }}>
                                    Photo
                                    <div
                                      style={{
                                        backgroundColor: "white",
                                        height: 200,
                                        border: "1px solid lightgray",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {part.base64Photo ? (
                                        <Upload
                                          showUploadList={false}
                                          beforeUpload={() => false}
                                          multiple={false}
                                          onChange={async (file: any) => {
                                            let base64 = await convertBase64(
                                              file.file
                                            );
                                            if (base64) {
                                              setChanges(!changes);
                                              return (part.base64Photo = (base64 as string).split(
                                                ","
                                              )[1]);
                                            }
                                          }}
                                        >
                                          <Image
                                            width={200}
                                            height={150}
                                            preview={false}
                                            src={`data:image/png;base64,${part.base64Photo}`}
                                          />
                                        </Upload>
                                      ) : (
                                        <Upload
                                          showUploadList={false}
                                          beforeUpload={() => false}
                                          multiple={false}
                                          onChange={async (file: any) => {
                                            let base64 = await convertBase64(
                                              file.file
                                            );
                                            if (base64) {
                                              setChanges(!changes);
                                              return (part.base64Photo = (base64 as string).split(
                                                ","
                                              )[1]);
                                            }
                                          }}
                                        >
                                          <CameraOutlined
                                            style={{
                                              fontSize: 100,
                                              color: "#2e71a4",
                                            }}
                                          />
                                        </Upload>
                                      )}
                                    </div>
                                  </div>
                                  {replacedParts.length - 1 == i ? (
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <button
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          backgroundColor: "white",
                                          border: "none",
                                          alignItems: "center",
                                          textAlign: "center",
                                          cursor: "pointer",
                                          marginTop: 10,
                                        }}
                                        onClick={() => {
                                          addPartReplaced();
                                        }}
                                        disabled={isAddReplacedPart}
                                      >
                                        <PlusCircleOutlined
                                          style={{
                                            fontSize: 25,
                                            marginRight: 10,
                                            color: isAddReplacedPart
                                              ? "grey"
                                              : "#2e71a4",
                                          }}
                                        />
                                        <div
                                          style={{
                                            color: isAddReplacedPart
                                              ? "grey"
                                              : "black",
                                          }}
                                        >
                                          Add Parts/Equipment
                                        </div>
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                        {checkedPart === "first" ? (
                          <div style={{ marginTop: 20, marginBottom: 20 }}>
                            <div
                              style={{
                                alignSelf: "center",
                                textAlign: "center",
                              }}
                            >
                              List of New Parts installed
                            </div>
                            {newParts.map((part: any, i: any) => {
                              return (
                                <div style={{ marginTop: 10 }} key={i}>
                                  <div
                                    style={{ display: "flex", marginTop: 10 }}
                                  >
                                    <div style={{ flex: 3, marginRight: 10 }}>
                                      Part Name
                                      <Input
                                        value={part.name}
                                        onChange={(e) => {
                                          setChanges(!changes);
                                          return (part.name = e.target.value);
                                        }}
                                      />
                                    </div>
                                    <div style={{ flex: 1 }}>
                                      Qty
                                      <Input
                                        value={part.quantity}
                                        type={"number"}
                                        onChange={(e) => {
                                          setChanges(!changes);
                                          return (part.quantity =
                                            e.target.value);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginTop: 10 }}>
                                    Serial Number
                                    <Input
                                      value={part.serialNumber}
                                      onChange={(e) => {
                                        setChanges(!changes);
                                        return (part.serialNumber =
                                          e.target.value);
                                      }}
                                    />
                                  </div>
                                  <div style={{ marginTop: 10 }}>
                                    Brand
                                    <Input
                                      value={part.brand}
                                      onChange={(e) => {
                                        setChanges(!changes);
                                        return (part.brand = e.target.value);
                                      }}
                                    />
                                  </div>
                                  <div style={{ marginTop: 10 }}>
                                    Model
                                    <Input
                                      value={part.model}
                                      onChange={(e) => {
                                        setChanges(!changes);
                                        return (part.model = e.target.value);
                                      }}
                                    />
                                  </div>
                                  <div style={{ marginTop: 10 }}>
                                    Photo
                                    <div
                                      style={{
                                        backgroundColor: "white",
                                        height: 200,
                                        border: "1px solid lightgray",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {part.base64Photo ? (
                                        <Upload
                                          showUploadList={false}
                                          beforeUpload={() => false}
                                          multiple={false}
                                          onChange={async (file: any) => {
                                            let base64 = await convertBase64(
                                              file.file
                                            );
                                            if (base64) {
                                              setChanges(!changes);
                                              return (part.base64Photo = (base64 as string).split(
                                                ","
                                              )[1]);
                                            }
                                          }}
                                        >
                                          <Image
                                            width={200}
                                            height={150}
                                            preview={false}
                                            src={`data:image/png;base64,${part.base64Photo}`}
                                          />
                                        </Upload>
                                      ) : (
                                        <Upload
                                          showUploadList={false}
                                          beforeUpload={() => false}
                                          multiple={false}
                                          onChange={async (file: any) => {
                                            let base64 = await convertBase64(
                                              file.file
                                            );
                                            if (base64) {
                                              setChanges(!changes);
                                              return (part.base64Photo = (base64 as string).split(
                                                ","
                                              )[1]);
                                            }
                                          }}
                                        >
                                          <CameraOutlined
                                            style={{
                                              fontSize: 100,
                                              color: "#2e71a4",
                                            }}
                                          />
                                        </Upload>
                                      )}
                                    </div>
                                  </div>
                                  {newParts.length - 1 == i ? (
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <button
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          backgroundColor: "white",
                                          border: "none",
                                          alignItems: "center",
                                          textAlign: "center",
                                          cursor: "pointer",
                                          marginTop: 10,
                                        }}
                                        onClick={() => {
                                          addNewPart();
                                        }}
                                        disabled={isAddNewPart}
                                      >
                                        <PlusCircleOutlined
                                          style={{
                                            fontSize: 25,
                                            marginRight: 10,
                                            color: isAddNewPart
                                              ? "grey"
                                              : "#2e71a4",
                                          }}
                                        />
                                        <div
                                          style={{
                                            color: isAddNewPart
                                              ? "grey"
                                              : "black",
                                          }}
                                        >
                                          Add Parts/Equipment
                                        </div>
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                      <div
                        style={{
                          fontSize: 25,
                          marginTop: 30,
                          fontWeight: "bold",
                        }}
                      >
                        SERVICE INVOICE
                      </div>
                      <div style={{ marginTop: 10 }}>
                        Invoice Date
                        <Input
                          value={moment(
                            serviceRequest.scheduleDateTimeStamp
                          ).format("ll")}
                          disabled={true}
                        />
                      </div>
                      <div style={{ marginTop: 10 }}>
                        SR Number
                        <Input
                          value={serviceRequest.serviceRequestNo}
                          disabled={true}
                        />
                      </div>
                      <div style={{ marginTop: 10 }}>
                        Property Address
                        <Input
                          value={serviceRequest.requestFormValues[4].value}
                          disabled={true}
                        />
                      </div>
                      <div style={{ marginTop: 10, display: "flex" }}>
                        <div style={{ flex: 1.5, marginRight: 10 }}>
                          City{" "}
                          <Input
                            value={serviceRequest.requestFormValues[6].value}
                            disabled={true}
                          />
                        </div>
                        <div style={{ flex: 1, marginRight: 10 }}>
                          State{" "}
                          <Input
                            value={serviceRequest.requestFormValues[5].value}
                            disabled={true}
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          ZIP Code{" "}
                          <Input
                            value={serviceRequest.requestFormValues[7].value}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: 15,
                          marginTop: 25,
                        }}
                      >
                        COST BREAKDOWN
                      </div>
                      <div style={{ marginTop: 10 }}>
                        Pay Rate
                        <Input value={serviceRequest.payRate} disabled={true} />
                      </div>
                      <div style={{ marginTop: 10 }}>
                        Number of Hours Spent
                        <Form.Item
                          name={`hours`}
                          rules={[
                            {
                              required: true,
                              message: "Number of hours spent is required!",
                            },
                          ]}
                        >
                          <Input
                            value={hours}
                            onChange={(e: any) => setHours(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          display: "flex",
                          textAlign: "center",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 600,
                            marginRight: 40,
                            fontSize: 15,
                          }}
                        >
                          Service Cost:
                        </p>
                        <p style={{ fontWeight: "bold", fontSize: 18 }}>
                          ${" "}
                          {totalServiceCost()
                            ? totalServiceCost().toFixed(2)
                            : "0.00"}
                        </p>
                      </div>
                      {invoiceItems.map((invoice: any, i: any) => {
                        return (
                          <div style={{ marginTop: 20 }} key={i}>
                            <div>
                              Parts/Equipment
                              <Input
                                value={invoice.part}
                                onChange={(e: any) => {
                                  setChanges(!changes);
                                  return (invoice.part = e.target.value);
                                }}
                              />
                            </div>
                            <div style={{ marginTop: 10 }}>
                              Amount
                              <Input
                                type={"number"}
                                value={invoice.amount}
                                onChange={(e: any) => {
                                  setChanges(!changes);
                                  return (invoice.amount = parseFloat(
                                    e.target.value
                                  ));
                                }}
                              />
                            </div>
                            {invoiceItems.length - 1 == i ? (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    backgroundColor: "white",
                                    border: "none",
                                    alignItems: "center",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    marginTop: 10,
                                  }}
                                  onClick={() => {
                                    addInvoiceItems();
                                  }}
                                  disabled={isInvoiceDisable}
                                >
                                  <PlusCircleOutlined
                                    style={{
                                      fontSize: 25,
                                      marginRight: 10,
                                      color: isInvoiceDisable
                                        ? "grey"
                                        : "#2e71a4",
                                    }}
                                  />
                                  <div
                                    style={{
                                      color: isInvoiceDisable
                                        ? "grey"
                                        : "black",
                                    }}
                                  >
                                    Add Parts/Equipment
                                  </div>
                                </button>
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                      <div
                        style={{
                          marginTop: 10,
                          display: "flex",
                          textAlign: "center",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 700,
                            marginRight: 40,
                            fontSize: 15,
                          }}
                        >
                          Parts/Equipment Cost:
                        </div>
                        <div style={{ fontWeight: "bold", fontSize: 18 }}>
                          ${" "}
                          {totalPartsCost()
                            ? totalPartsCost().toFixed(2)
                            : "0.00"}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          textAlign: "center",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          flex: 1,
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 700,
                            marginRight: 40,
                            fontSize: 15,
                          }}
                        >
                          TOTAL INVOICE AMOUNT:
                        </div>
                        <div style={{ fontWeight: "bold", fontSize: 18 }}>
                          ${" "}
                          {totalInvoiceAmount()
                            ? totalInvoiceAmount().toFixed(2)
                            : "0.00"}
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: 25,
                          marginTop: 30,
                          fontWeight: "bold",
                        }}
                      >
                        ATTACHMENTS
                      </div>
                      <div style={{ marginTop: 20, paddingBottom: 50 }}>
                        {attachments.map((attachment: any, i: any) => {
                          return (
                            <div>
                              <div style={{ marginTop: 10 }} key={i}>
                                Attachment #{i + 1}
                                <div
                                  style={{
                                    backgroundColor: "white",
                                    height: 100,
                                    border: "1px solid lightgray",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {attachment.base64Attachment ? (
                                    <Upload
                                      showUploadList={false}
                                      beforeUpload={() => false}
                                      multiple={false}
                                      onChange={async (file: any) => {
                                        let base64 = await convertBase64(
                                          file.file
                                        );
                                        if (base64) {
                                          setChanges(!changes);
                                          return (attachment.base64Attachment = (base64 as string).split(
                                            ","
                                          )[1]);
                                        }
                                      }}
                                    >
                                      <Image
                                        width={200}
                                        height={100}
                                        preview={false}
                                        src={`data:image/png;base64,${attachment.base64Attachment}`}
                                      />
                                    </Upload>
                                  ) : (
                                    <Upload
                                      showUploadList={false}
                                      beforeUpload={() => false}
                                      multiple={false}
                                      onChange={async (file: any) => {
                                        let base64 = await convertBase64(
                                          file.file
                                        );
                                        if (base64) {
                                          setChanges(!changes);
                                          return (attachment.base64Attachment = (base64 as string).split(
                                            ","
                                          )[1]);
                                        }
                                      }}
                                    >
                                      <CameraOutlined
                                        style={{
                                          fontSize: 100,
                                          color: "#2e71a4",
                                        }}
                                      />
                                    </Upload>
                                  )}
                                </div>
                              </div>
                              {attachments.length - 1 == i ? (
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <button
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      backgroundColor: "white",
                                      border: "none",
                                      alignItems: "center",
                                      textAlign: "center",
                                      cursor: "pointer",
                                      marginTop: 10,
                                    }}
                                    onClick={() => {
                                      addAttachment();
                                    }}
                                    disabled={isAddNewAttachment}
                                  >
                                    <PlusCircleOutlined
                                      style={{
                                        fontSize: 25,
                                        marginRight: 10,
                                        color: isAddNewAttachment
                                          ? "grey"
                                          : "#2e71a4",
                                      }}
                                    />
                                    <div
                                      style={{
                                        color: isAddNewAttachment
                                          ? "grey"
                                          : "black",
                                      }}
                                    >
                                      Add Attachment
                                    </div>
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              {serviceRequest.currentStatus === "ASSIGNED" ? (
                <button
                  style={{
                    backgroundColor: "green",
                    width: "100%",
                    color: "white",
                    padding: 15,
                    fontSize: 20,
                    border: "none",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    onMyWay();
                  }}
                  type="button"
                >
                  {loading ? <Spin indicator={antIcon} /> : "ON MY WAY"}
                </button>
              ) : null}

              {serviceRequest?.currentStatus === "ENROUTE" ? (
                <button
                  style={{
                    backgroundColor: "green",
                    width: "100%",
                    color: "white",
                    padding: 15,
                    fontSize: 20,
                    border: "none",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  type="button"
                  onClick={() => {
                    onSiteSubmit();
                  }}
                >
                  {loading ? <Spin indicator={antIcon} /> : "ON-SITE"}
                </button>
              ) : null}

              {serviceRequest?.currentStatus === "ONSITE" && !notesOpen ? (
                <div style={{ display: "flex", width: "100%", color: "white" }}>
                  <div style={{ flex: 1, display: "flex" }}>
                    <button
                      style={{
                        width: "100%",
                        backgroundColor: "#2a76a7",
                        border: "none",
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                      type="button"
                      onClick={() => {
                        setNotesOpen(true);
                      }}
                    >
                      <BookOutlined style={{ fontSize: 25, marginRight: 10 }} />
                      <div
                        style={{
                          textAlign: "center",
                          alignSelf: "center",
                          fontSize: 20,
                        }}
                      >
                        {" "}
                        Notes
                      </div>
                    </button>
                    {/* <button
                      style={{
                        width: "100%",
                        padding: 15,
                        backgroundColor: "blue",
                        border: "none",
                        cursor: "pointer",
                      }}
                      type="button"
                    >
                      Parts
                    </button> */}
                  </div>
                  <div style={{ flex: 3, fontSize: 20 }}>
                    <button
                      style={{
                        width: "100%",
                        padding: 15,
                        backgroundColor: "green",
                        border: "none",
                        cursor: "pointer",
                      }}
                      type="button"
                      onClick={() => {
                        onJobCompleted();
                      }}
                    >
                      {loading ? <Spin indicator={antIcon} /> : "JOB COMPLETED"}
                    </button>
                  </div>
                </div>
              ) : null}

              {serviceRequest?.currentStatus === "ONSITE" && notesOpen ? (
                <div style={{ display: "flex", width: "100%", color: "white" }}>
                  <div style={{ flex: 1, display: "flex" }}>
                    <button
                      style={{
                        width: "100%",
                        backgroundColor: "red",
                        border: "none",
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                      type="button"
                      onClick={() => {
                        setNotesOpen(false);
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          alignSelf: "center",
                          fontSize: 20,
                        }}
                      >
                        {" "}
                        Back
                      </div>
                    </button>
                    {/* <button
                      style={{
                        width: "100%",
                        padding: 15,
                        backgroundColor: "blue",
                        border: "none",
                        cursor: "pointer",
                      }}
                      type="button"
                    >
                      Parts
                    </button> */}
                  </div>
                  <div style={{ flex: 3, fontSize: 20 }}>
                    <button
                      style={{
                        width: "100%",
                        padding: 15,
                        backgroundColor: "green",
                        border: "none",
                        cursor: "pointer",
                      }}
                      type="button"
                      onClick={() => {
                        setAddNotesVisible(true);
                      }}
                    >
                      Add Notes
                    </button>
                  </div>
                </div>
              ) : null}

              {serviceRequest?.currentStatus === "SERVICE_COMPLETED" ? (
                <button
                  style={{
                    backgroundColor: "green",
                    width: "100%",
                    color: "white",
                    padding: 15,
                    fontSize: 20,
                    border: "none",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  {loading ? <Spin indicator={antIcon} /> : "SUBMIT"}
                </button>
              ) : null}
            </Form>
          )}
          <Exception
            visible={exceptionVisible}
            setExceptionVisible={setExceptionVisible}
            serviceRequest={serviceRequest}
            setOpen={setOpen}
            flagException={props.flagException}
          />
          <AddNote
            visible={addNotesVisible}
            setAddNotesVisible={setAddNotesVisible}
            serviceRequest={serviceRequest}
            setOpen={setOpen}
            addNote={props.addNote}
            fetchAllNotes={fetchAllNotes}
          />
          <ViewNote
            visible={viewNotesVisible}
            setViewNotesVisible={setViewNotesVisible}
            serviceRequest={serviceRequest}
            setOpen={setOpen}
            selectedNote={selectedNote}
          />
        </div>
      ) : null}
    </Modal>
  );
};

const mapStateToProps = (states: RootState) => {
  return {};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    updateServiceRequestStatus: (updatedSR: any) =>
      dispatch(updateServiceRequestStatus(updatedSR)),
    acceptServiceRequest: (serviceId: any, techId: any) =>
      dispatch(acceptServiceRequest(serviceId, techId)),
    submitCloseForm: (data: any) => dispatch(submitCloseForm(data)),
    flagException: (exception: any) => dispatch(flagException(exception)),
    fetchAllNotes: (notes: any) => dispatch(fetchAllNotes(notes)),
    addNote: (note: any) => dispatch(addNotes(note)),
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(EditServiceRequestDetailsLayout)
);
