import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styles from "./ViewMoreLink.module.scss";

interface Props {
  label?: string;
  href?: string;
}

export default function ViewMoreLink({
  label = "View All",
  href = "#",
}: Props) {
  return (
    <a href={href} className={styles.link}>
      <div className={styles.label}>{label}</div>
      <ChevronRightIcon />
    </a>
  );
}
