import React, { useEffect } from "react";
import {
  DatePicker,
  TimePicker,
  Radio,
  Button,
  Modal,
  Select,
  Form,
  Upload,
  Spin
} from "antd";
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import moment from "moment";
import { useState } from "react";
import { FileImageOutlined, VideoCameraOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import _debounce from "lodash/debounce";
import _isUndefined from "lodash/isUndefined";
import _isNull from "lodash/isNull";
import { ServiceRequest } from "../../../redux/service-request/model";
import { UnassignedSR } from "../../../redux/unassign-service-request/model";

import homeownerTypes from "../../../jsons/homeowner/homeowner-type.json";
import { getServiceTypeService, saveServiceRequest } from "../../../redux/service-request/service";
import { getMemberProperties, getMembers, getSubscribers } from "../../../redux/members/service";
import { getAvailableTechnicians } from "../../../redux/technician/service";

const MEMBER_ID_FIELD = "MID";
const MEMBER_FIELD = "MEM";
const PROPERTY_ID_FIELD = "PID";
const PROPERTY_FIELD = "PRP";
const PROPERTY_ADDRESS_FIELD = "PRPA";
const PROPERTY_STATE_FIELD = "PRPS";
const PROPERTY_CITY_FIELD = "PRPC";
const PROPERTY_ZIPCODE_FIELD = "PRPZ";
const SERVICE_TYPE_FIELD = "SVT";
const ROOM_FIELD = "RM";
const ITEM_ID_FIELD = "IID";
const ITEM_FIELD = "ITM";
const BRAND_FIELD = "BRN";
const MODEL_FIELD = "MDL";
const SERIAL_NUMBER_FIELD = "ISN";
const ITEM_PHOTO_FIELD = "IPH";
const DETAIL_FIELD = "DTL";
const MEMBER_NO_FIELD = "MNO";

interface Photo {
  id?: number;
  photo?: string;
  base64Photo?: string;
  version: number;
}
interface itemProps {
  id: number;
  seqNo: number;
  name: string;
  shortName: string;
  type: string;
  page: number;
  showField: boolean;
  displayWidth: number;
  clientId: number;
  values: [];
  version: number;
  technicianId: number;
  permitTechnicianEntry: boolean;
}

interface StateProps { }

interface DispatchProps {
  getServiceType: (id: any) => any;
  getMembersService: (q: string) => any;
  getSubscribersService: (q: string) => any;
  saveServiceRequest: (data: any) => any;
  getMemberProperties: (memberId: number) => any;
  getAvailableTechnicians: (propertyId: number, serviceTypeId: number, serviceType: string, date: string) => any;
}
type Props = StateProps & DispatchProps;

const ServiceRequestLayout: React.FC<Props> = ({
  getServiceType,
  getMembersService,
  getSubscribersService,
  saveServiceRequest,
  getMemberProperties,
  getAvailableTechnicians
}): JSX.Element => {
  const [isOpen, actionModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typeValue, setServiceType] = useState(1);
  const [permission, setPermission] = useState(false);
  const [requestFields, setRequestFields] = useState<any>([]);
  //const [formItem, addItem] = useState([] as itemProps[]);
  const [technicianId, setTechnicianId] = useState(0);
  const [vendorId, setVendorId] = useState(0);
  const [scheduledDate, setScheduledDate] = useState(moment());
  const [scheduledTime, setScheduledTime] = useState(moment());
  const [homeowners, setHomeowners] = useState([]);
  const [memberId, setMemberId] = useState(-1);
  const [properties, setProperties] = useState([]);
  const [property, setProperty] = useState<any>();
  const [room, setRoom] = useState<any>();
  const [item, setItem] = useState<any>();
  const [technicians, setTechnicians] = useState<any>([]);
  const [dateDisabled, setDateDisabled] = useState(false);
  const [form] = Form.useForm();

  const optionsRequest = [
    { label: "General Service Request", value: 1 },
    { label: "Service Request (Item)", value: 2 },
  ];

  const optionsPermission = [
    { id: 1, label: "Yes, Access will be provided to the tech", value: true },
    {
      id: 2,
      label: "No, Member must have a rep present to provide technician access",
      value: false,
    },
  ];

  const { Option } = Select;

  const formItemLayout = {
    wrapperCol: {
      span: 32,
      offset: 0,
    },
  };

  const serviceTypes: any = [
    { value: "HOUSE_CLEANING", label: "House Cleaning" },
    { value: "LAWN_CARE", label: "Lawn Care" },
    { value: "CARPET_TILE_CLEANING", label: "Carpet Title Cleaning" },
    // {value: 'SPRINKLER_SYSTEM', label: 'Sprinkler System'},
    { value: "PET_SERVICES", label: "Pet Services" },
    { value: "PEST_CONTROL", label: "Pest Control" },
    { value: "WINDOW_CLEANING", label: "Window Cleaning" },
    // {value: 'POOL_HOT_TUB_CLEANING', label: 'Pool / Hot Tub Cleaning'},
    { value: "SEASONAL_LANDSCAPE", label: "Seasonal Landscape" },
    { value: "POWER_WASHING", label: "Power Washing" },
    { value: "FENCING", label: "Fencing" },
    { value: "CHRISTMAS_LIGHTNING_DECO", label: "Christmas Lighting Deco" },
    { value: "REMODEL", label: "Remodel" },
    { value: "EVENT_COORDINATION", label: "Event Coordination" },
    { value: "GENERAL", label: "General" },
  ];

  useEffect(() => {
    fetchForm(typeValue);
    window.addEventListener("openCreateRequest", openCreateRequest);
    return () =>
      window.removeEventListener("openCreateRequest", openCreateRequest);
  }, [actionModal]);

  const openCreateRequest = (event: Event) => {
    //@ts-ignore
    const { detail } = event;


    form.resetFields();
    setProperty(null);

    if (detail) {
      setScheduledDate(moment(detail.date));
      setScheduledTime(moment(detail.time.time, "hh:mm a"));
      setTechnicianId(detail.technicianId);
      setVendorId(detail.vendorId);

      setDateDisabled(true);
    } else {
      setTechnicianId(0);
      setVendorId(0);

      let currentDate = moment();
      if (currentDate.minute() > 0 && currentDate.minute() < 30) {
        currentDate.set("minute", 30);
      } else if (currentDate.minute() > 30) {
        currentDate.set("minute", 0);
        currentDate.add("hour", 1);
      }
      setScheduledDate(currentDate);
      setScheduledTime(currentDate);

      setDateDisabled(false);
    }
    actionModal(true);
  };


  const getHomeowners = (query: string) => {
    const homeownerType = form.getFieldValue("homeownerType");
    switch (homeownerType) {
      case "MEMBER": {
        getMembers(query);
        break;
      }
      case "SUBSCRIBER": {
        getSubscribers(query);
        break;
      }
      default:
        return false;
    }
  };

  const [debounceGetHomeowners] = useState(() => _debounce(getHomeowners, 200));

  const getMembers = async (query: string) => {
    setLoading(true)
    try {
      const data: any = await getMembersService(query)
      setHomeowners(data);
    }

    catch (err) {
      console.log("ERROR", err)
    }
    setLoading(false)
  };

  const getSubscribers = async (query: string) => {
    setLoading(true)
    try {
      const data: any = await getSubscribersService(query)
      setHomeowners(data);
    }

    catch (err) {
      console.log("ERROR", err)
    }
    setLoading(false)
  };

  const range = (start: any, end: any) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };

  const disabledDateTime = () => {
    return {
      disabledHours: () => range(0, 24).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  };

  const fetchForm = async (type: number) => {
    setServiceType(type);
    try {
      const response = await getServiceType(type.toString())
      setRequestFields(response.body)
    } catch (error) {
      console.log(error)
    }
  }

  const toBase64 = (file: any): any => {
    const ufile = file.originFileObj ? file.originFileObj : file;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(ufile);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const onFinish = async (formData: any) => {
    let requestFormValues: {
      seqNo: number;
      field: itemProps;
      value: any;
    }[] = [];

    let index = 1;

    const homeowner: any = homeowners.find((owner: any) => {
      return formData.homeowner == owner.id;
    });
    const memberIdField = requestFields.find((field: any) => {
      return field.shortName == MEMBER_ID_FIELD;
    });
    requestFormValues.push({
      seqNo: index++,
      field: memberIdField,
      value: `${homeowner.id}`,
    });
    const memberField = requestFields.find((field: any) => {
      return field.shortName == MEMBER_FIELD;
    });
    requestFormValues.push({
      seqNo: index++,
      field: memberField,
      value: `${homeowner.firstName} ${homeowner.lastName}`,
    });

    const property: any = properties.find((property: any) => {
      return formData.property == property.id;
    });
    const propertyIdField = requestFields.find((field: any) => {
      return field.shortName == PROPERTY_ID_FIELD;
    });
    requestFormValues.push({
      seqNo: index++,
      field: propertyIdField,
      value: `${property.id}`,
    });
    const propertyField = requestFields.find((field: any) => {
      return field.shortName == PROPERTY_FIELD;
    });
    requestFormValues.push({
      seqNo: index++,
      field: propertyField,
      value: property.name,
    });
    const propertyAddressField = requestFields.find((field: any) => {
      return field.shortName == PROPERTY_ADDRESS_FIELD;
    });
    requestFormValues.push({
      seqNo: index++,
      field: propertyAddressField,
      value: `${property.address1 ? property.address1 : ""} ${property.address2 ? property.address2 : ""
        }`.trim(),
    });
    const propertyStateField = requestFields.find((field: any) => {
      return field.shortName == PROPERTY_STATE_FIELD;
    });
    requestFormValues.push({
      seqNo: index++,
      field: propertyStateField,
      value: property.state,
    });
    const propertyCityField = requestFields.find((field: any) => {
      return field.shortName == PROPERTY_CITY_FIELD;
    });
    requestFormValues.push({
      seqNo: index++,
      field: propertyCityField,
      value: property.city,
    });
    const propertyZipCodeField = requestFields.find((field: any) => {
      return field.shortName == PROPERTY_ZIPCODE_FIELD;
    });
    requestFormValues.push({
      seqNo: index++,
      field: propertyZipCodeField,
      value: property.zipCode,
    });

    if (typeValue == 1) {
      // Service Type is General
      const serviceType: any = serviceTypes.find((serviceType: any) => {
        return formData.serviceType == serviceType.value;
      });
      const serviceTypeField = requestFields.find((field: any) => {
        return field.shortName == SERVICE_TYPE_FIELD;
      });
      requestFormValues.push({
        seqNo: index++,
        field: serviceTypeField,
        value: serviceType.value,
      });
    } else {
      const room: any = property.rooms.find((room: any) => {
        return formData.room == room.id;
      });

      const roomField = requestFields.find((field: any) => {
        return field.shortName == ROOM_FIELD;
      });
      requestFormValues.push({
        seqNo: index++,
        field: roomField,
        value: room.name,
      });

      const itemIdField = requestFields.find((field: any) => {
        return field.shortName == ITEM_ID_FIELD;
      });
      requestFormValues.push({
        seqNo: index++,
        field: itemIdField,
        value: `${item.id}`,
      });

      const itemField = requestFields.find((field: any) => {
        return field.shortName == ITEM_FIELD;
      });
      requestFormValues.push({
        seqNo: index++,
        field: itemField,
        value: item.name,
      });

      const brandField = requestFields.find((field: any) => {
        return field.shortName == BRAND_FIELD;
      });
      requestFormValues.push({
        seqNo: index++,
        field: brandField,
        value: item.brand,
      });

      const modelField = requestFields.find((field: any) => {
        return field.shortName == MODEL_FIELD;
      });
      requestFormValues.push({
        seqNo: index++,
        field: modelField,
        value: item.model,
      });

      const serialNumberField = requestFields.find((field: any) => {
        return field.shortName == SERIAL_NUMBER_FIELD;
      });
      requestFormValues.push({
        seqNo: index++,
        field: serialNumberField,
        value: item.serialNumber,
      });

      const itemPhotoField = requestFields.find((field: any) => {
        return field.shortName == ITEM_PHOTO_FIELD;
      });
      requestFormValues.push({
        seqNo: index++,
        field: itemPhotoField,
        value: item.photo,
      });
    }

    const detailField = requestFields.find((field: any) => {
      return field.shortName == DETAIL_FIELD;
    });
    requestFormValues.push({
      seqNo: index++,
      field: detailField,
      value: formData.details,
    });

    const memberNoField = requestFields.find((field: any) => {
      return field.shortName == MEMBER_NO_FIELD;
    });
    requestFormValues.push({
      seqNo: index++,
      field: memberNoField,
      value: homeowner.mobileNumber,
    });

    let momentDate = formData.date || scheduledDate;
    let momentTime = formData.time || scheduledTime;

    momentDate.set("hour", momentTime.get("hour"));
    momentDate.set("minute", momentTime.get("minute"));
    momentDate.set("second", 0);
    momentDate.set("millisecond", 0);

    let photos: any[] = [];

    if (formData.reqPhoto) {
      for (let i = 0; i < formData.reqPhoto.fileList.length; i++) {
        const file = formData.reqPhoto.fileList[i];
        const photoData = await toBase64(file);
        const base64 = photoData.split(",")[1];
        photos.push({ base64Photo: base64 });
      }
    }

    let videos: any[] = [];

    if (formData.reqVideo) {
      for (let i = 0; i < formData.reqVideo.fileList.length; i++) {
        const file = formData.reqVideo.fileList[i];
        const videoData = await toBase64(file);
        const base64 = videoData.split(",")[1];
        videos.push({ base64Video: base64 });
      }
    }

    let linkedTechnicians: any[] = [];

    let technicianIds: any[] = [];

    if (formData.linkedTechnicians) {
      for (let i = 0; i < formData.linkedTechnicians.length; i++) {
        const technician = technicians.find((tech: any) => {
          return formData.linkedTechnicians[i] == tech.fullName;
        });
        linkedTechnicians.push({ technicianId: technician.id });
        technicianIds.push(technician.id);
      }
    }

    const clientId = localStorage.getItem("client");

    let data = {
      id: 0,
      clientId: clientId,
      lat: property.lat,
      lng: property.lng,
      permitTechnicianEntry: permission,
      //durationInMinutes: 60,
      technicianId: technicianId !== 0 ? technicianId : null,
      vendorId: vendorId !== 0 ? vendorId : null,
      scheduleDateTimeStamp: momentDate.toDate(), //moment(momentDate.toDate()).format("YYYY-MM-DDTHH:mm:ss"),
      requestFormValues: [...requestFormValues],
      currentStatus: technicianId !== 0 ? "PENDING_ASSIGNMENT" : "UNASSIGNED",
      photos: photos,
      videos: videos,
      technicians: linkedTechnicians,
      technicianIds,
    };

    const result = await saveServiceRequest(data);
    if (!_isUndefined(result) && !_isNull(result)) {
      actionModal(false);

      if (result.technicianId) {
        // notify that a new scheduled request has been created
        const item: ServiceRequest = createScheduledServiceRequest(result);
        window.dispatchEvent(
          new CustomEvent("newScheduledRequestCreated", {
            detail: { request: item },
          })
        );
      }
      // } else {

      //   const item: UnassignedSR = createUnassignedServiceRequest(res);
      //   window.dispatchEvent(new CustomEvent('newUnscheduledRequestCreated', { detail: { request: item } }));
      //   //window.dispatchEvent(new CustomEvent('reloadSchedulerSideBar'));
      // }

    }
  };

  /**
   * Creates the scheduled service request from the json instance returned by the server.
   *
   * @param res the data returned by the server
   * @returns the scheduled service request
   */
  const createScheduledServiceRequest = (res: any): ServiceRequest => {
    const startTime: any = moment(res.scheduleDateTimeStamp).format("h:mm A");
    const endTime: any = moment(res.scheduleDateTimeStamp)
      .add(res.durationInMinutes, "minutes")
      .format("h:mm A");

    const item: ServiceRequest = {
      id: res.id,
      title: res.title,
      currentStatus: res.currentStatus,
      durationInMinutes: res.durationInMinutes,
      requestFormValues: res.requestFormValues,
      scheduleDateTimeStamp: res.scheduleDateTimeStamp,
      createDateTimeStamp: res.scheduleDateTimeStamp,
      technicianId: res.technicianId,
      technicianName: res.technicianName,
      lat: res.lat,
      lng: res.lng,
      timeStamp: { startTime: startTime, endTime: endTime },
      acceptedStartTime: res.acceptedStartTime,
      acceptedEndTime: res.acceptedEndTime,
      linked: res.linked,
      filterType: res.filterType,
      photos: res.photo,
      //collectionIndexes: [],
      videos: res.video,
      caculatedWidth: 0,
      members: {
        name: "",
        propertyAddress: "",
      },
      serviceRequestNo: res.serviceRequestNo,
      permitTechnicianEntry: res.permitTechnicianEntry,
    };

    return item;
  };

  /**
   * Creates the unassigned service request from the json instance returned by the server.
   * @param res the data returned by the server
   * @returns the unassigned service request
   */
  const createUnassignedServiceRequest = (res: any): UnassignedSR => {
    const item: UnassignedSR = {
      id: res.id,
      serviceRequestNo: res.serviceRequestNo,
      currentStatus: res.currentStatus,
      durationInMinutes: res.durationInMinutes,
      requestFormValues: res.requestFormValues,
      scheduleDateTimeStamp: res.scheduleDateTimeStamp,
      createDateTimeStamp: res.scheduleDateTimeStamp,
      technicianId: res.technicianId,
      technicianName: res.technicianName,
      isCollapse: false,
      permitTechnicianEntry: res.permitTechnicianEntry,
    };

    return item;
  };

  const handleHomeownerTypeChange = () => {
    form.setFieldsValue({
      homeowner: undefined,
      property: undefined,
      serviceType: undefined,
      room: undefined,
      item: undefined,
      linkedTechnicians: undefined,
    });

    debounceGetHomeowners("")
  };

  const clearFormValues = () => {
    setProperty(null);
    form.setFieldsValue({
      property: undefined,
      serviceType: undefined,
      room: undefined,
      item: undefined,
      linkedTechnicians: undefined,
    });
  }

  /**
   * Handles the data whenever the members field (select) changes.
   * The properties associated with that member is fetched from the server and
   * set in the properties field (select).
   *
   * @param memberId the id of the member
   */
  const handleMemberChange = async (memberId: number) => {
    setMemberId(memberId);
    const result = await getMemberProperties(memberId);
    if (!_isUndefined(result) && !_isNull(result)) {
      setProperties(result);
      setProperty(null);
      form.setFieldsValue({
        property: undefined,
        serviceType: undefined,
        room: undefined,
        item: undefined,
        linkedTechnicians: undefined,
      });
    }
  };

  const handlePropertyChange = async (propertyId: number) => {
    const property: any = properties.find((property: any) => {
      return property.id == propertyId;
    });
    setProperty(property);

    form.setFieldsValue({
      serviceType: undefined,
      room: undefined,
      item: undefined,
      linkedTechnicians: undefined,
    });

    const availableTechnicians = await getAvailableTechnicians(property.id, 0, "dummy", "dummy");
    if (!_isNull(availableTechnicians)) {
      setTechnicians(availableTechnicians);
    }
  };

  const handleRoomChange = async (roomId: number) => {
    const room: any = property.rooms.find((room: any) => {
      return room.id == roomId;
    });
    setRoom(room);
    form.setFieldsValue({
      item: undefined,
    });


    const availableTechnicians = await getAvailableTechnicians(property.id, 0, "dummy", "dummy");
    if (!_isNull(availableTechnicians)) {
      setTechnicians(availableTechnicians);
    }
  };

  const handleItemChange = (itemId: number) => {
    const item: any = room.items.find((item: any) => {
      return item.id == itemId;
    });
    setItem(item);
  };

  const handleTechnicianChange = (technicianId: number) => { };

  return (
    <div>
      <Modal
        visible={isOpen}
        centered={true}
        className={"modal-body"}
        onCancel={() => actionModal(false)}
        closable={false}
        footer={null}
        width="25%"
      >
        <br />
        <h3>SERVICE REQUEST</h3>
        <br />
        <Form
          form={form}
          style={{ width: "80%" }}
          name="requestForm"
          {...formItemLayout}
          onFinish={onFinish}
          initialValues={{
            ["radio-group"]: 1,
          }}
        >
          <Form.Item wrapperCol={{ offset: 2 }} name="radio-group">
            <Radio.Group
              options={optionsRequest}
              value={typeValue}
              defaultValue={typeValue}
              onChange={(e) => fetchForm(e.target.value)}
            />
          </Form.Item>

          <Form.Item name={"homeownerType"} rules={[{ required: true }]} data-testid="homeownerType">
            <Select
              placeholder="Homeowner Type"
              onChange={handleHomeownerTypeChange}
            >
              {homeownerTypes.map((type: any, index: number) => {
                return (
                  <Option key={index} value={type.value}>
                    {type.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name={"homeowner"}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Homeowner"
              onSearch={debounceGetHomeowners}
              onChange={handleMemberChange}
              filterOption={false}
              suffixIcon={loading ? <LoadingOutlined style={{ fontSize: 15 }} spin /> : <SearchOutlined style={{ fontSize: 15 }} />}
            >
              {homeowners.length > 0 &&
                homeowners.map((homeowner: any, index: number) => {
                  return (
                    <Option key={index} value={homeowner.id}>
                      <div>
                        {homeowner.firstName} {homeowner.lastName}
                      </div>
                      <div style={{ opacity: 0.6, fontStyle: "italic" }}>{homeowner.email}</div>
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item name={"property"} rules={[{ required: true }]}>
            <Select placeholder="Property" onChange={handlePropertyChange}>
              {properties.length > 0 &&
                properties.map((property: any, index: number) => {
                  return (
                    <Option key={index} value={property.id}>
                      {property.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <div className="fake-field">
            {(property && (
              <div>
                {property.address1} {property.address2} {property.city}{" "}
                {property.state} {property.zipCode}
              </div>
            )) || <span style={{ color: "#aaa" }}>Property Address</span>}
          </div>
          <br />

          {typeValue === 1 && (
            <Form.Item name={"serviceType"} rules={[{ required: true }]}>
              <Select placeholder="ServiceType">
                {serviceTypes.map((serviceType: any, index: number) => {
                  return (
                    <Option key={index} value={serviceType.value}>
                      {serviceType.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}

          {typeValue === 2 && (
            <>
              <Form.Item name={"room"} rules={[{ required: true }]}>
                <Select placeholder="Room" onChange={handleRoomChange}>
                  {property && property.rooms ? (
                    <>
                      {property.rooms.map((room: any, index: number) => {
                        return (
                          <Option key={index} value={room.id}>
                            {room.name}
                          </Option>
                        );
                      })}
                    </>
                  ) : null}
                </Select>
              </Form.Item>

              <Form.Item name={"item"} rules={[{ required: true }]}>
                <Select placeholder="Item" onChange={handleItemChange}>
                  {room && room.items ? (
                    <>
                      {room.items.map((item: any, index: number) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </>
                  ) : null}
                </Select>
              </Form.Item>
            </>
          )}

          <div style={{ display: "grid", gridTemplateColumns: "49% 2% 49%" }}>
            <Form.Item name={"date"}>
              <DatePicker
                className={"custom-datepicker"}
                disabled={dateDisabled}
                defaultValue={scheduledDate}
                name={"date"}
                bordered={true}
                placeholder="Date"
              />
            </Form.Item>
            <div></div>
            <Form.Item name={"time"}>
              <TimePicker
                className={"custom-datepicker"}
                use12Hours
                format="h:mm a"
                minuteStep={30}
                disabled={dateDisabled}
                defaultValue={scheduledTime}
                name={"time"}
                bordered={true}
              />
            </Form.Item>
          </div>

          <Form.Item name={"details"}>
            <TextArea name={"details"} placeholder="Description"></TextArea>
          </Form.Item>

          <div className={"upload-container"}>
            <Form.Item name={"reqPhoto"} noStyle>
              <Upload.Dragger
                beforeUpload={() => {
                  return false;
                }}
                style={{ background: "transparent", border: "0px" }}
              >
                <p className="ant-upload-drag-icon">
                  <FileImageOutlined style={{ color: "gray" }} />
                </p>
                <p className="ant-upload-hint">Add Photo</p>
              </Upload.Dragger>
            </Form.Item>
          </div>

          <div className={"upload-container"}>
            <Form.Item name={"reqVideo"} noStyle>
              <Upload.Dragger
                beforeUpload={() => {
                  return false;
                }}
                style={{ background: "transparent", border: "0px" }}
              >
                <p className="ant-upload-drag-icon">
                  <VideoCameraOutlined style={{ color: "gray" }} />
                </p>
                <p className="ant-upload-hint">Add Video</p>
              </Upload.Dragger>
            </Form.Item>
          </div>

          <Form.Item name={"linkedTechnicians"}>
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select technicians"
              onChange={handleTechnicianChange}
            >
              {technicians.map((technician: any, index: number) => {
                return (
                  <Option key={index} value={technician.fullName}>
                    {technician.fullName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            Does the technician have permission to enter the home if no one is
            there?
            <Radio.Group
              options={optionsPermission}
              value={permission}
              defaultValue={permission}
              onChange={(e) => setPermission(e.target.value)}
            />
            <h4>*No show fee may be applied.</h4>
          </Form.Item>

          <Form.Item className={"button-container"}>
            <div className={"button-container"}>
              <Button
                style={{
                  backgroundColor: "#3A78B3",
                  color: " white",
                  marginRight: "20px",
                }}
                block={true}
                type="primary"
                onClick={() => actionModal(false)}
                role="button"
              >
                Cancel
              </Button>
              <Button
                role="button"
                block={true}
                name="submit"
                style={{ backgroundColor: "#3A78B3", color: " white" }}
                type="primary"
                htmlType="submit"
              >
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

const mapStateToProps = (states: RootState) => {
  return {}
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    getServiceType: (id: any) => dispatch(getServiceTypeService(id)),
    getMembersService: (q: string) => dispatch(getMembers(q)),
    getSubscribersService: (q: string) => dispatch(getSubscribers(q)),
    saveServiceRequest: (data: any) => dispatch(saveServiceRequest(data)),
    getMemberProperties: (memberId: number) => dispatch(getMemberProperties(memberId)),
    getAvailableTechnicians: (propertyId: number, serviceTypeId: number, serviceType: string, date: string) => dispatch(getAvailableTechnicians(propertyId, serviceTypeId, serviceType, date))
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(ServiceRequestLayout)
);

export { ServiceRequestLayout }

