export const getStatusColorCode = (status: string) => {
  switch (status) {
    case "COMPLETED":
    case "ASSIGNED":
      return "#00ff00ff"
    case "EXCEPTIONS":
      return "#00ff00ff"
    case "UNASSIGNED":
      return "#00ff00ff"
    case "PENDING_SCHEDULE":
      return "#fa8c16ff"
    case "PENDING_ASSIGNMENT":
      return "#fa8c16ff"
    case "PENDING_PARTS":
      return "#d46b08ff";
    case "ENROUTE":
      return "#0000ffff";
    case "ONSITE":
      return "#ffff00ff";
    case "SERVICE_COMPLETED":
      return "#8c8c8cff";
    case "INVOICE_SUBMITTED":
      return "#8c8c8cff";
    case "RUNNING_LATE":
      return "#ff4d4fff";
    case "SERVICING":
      return "#610b00ff";
    default:
      return "";
  }
}

export const getTechStatusColorCode = (status: string) => {
  switch (status) {
    case "COMPLETED":
    case "AVAILABLE":
      return "#389e0d"
    case "PENDING_SCHEDULE":
      return "#fa8c16"
    case "PENDING_PARTS":
      return "#d46b08";
    case "ENROUTE":
      return "#1d39c4";
    case "RUNNING_LATE":
      return "#ff4d4f";
    case "ONSITE":
      return "#a0d911";
    case "ISSUE":
      return "#610b00";
    case "OFFLINE":
    case "UNAVAILABLE":
    case "ON_LEAVE":
      return "#8c8c8c";
    default:
      return "";
  }
}

export const cardColorCode = (status: string) => {
  switch (status) {
    case "COMPLETED":
    case "ASSIGNED":
      return "#ccffccff"
    case "EXCEPTIONS":
      return "#00ff00ff"
    case "UNASSIGNED":
      return "#00ff00ff"
    case "PENDING_SCHEDULE":
      return "#ffe0beff"
    case "PENDING_ASSIGNMENT":
      return "#fcbd7aff"
    case "PENDING_PARTS":
      return "#f3cca8ff";
    case "ENROUTE":
      return "#8989ffff";
    case "ONSITE":
      return "#ffffccff";
    case "SERVICE_COMPLETED":
      return "#9b534aff";
    case "INVOICE_SUBMITTED":
      return "#9b534aff";
    case "RUNNING_LATE":
      return "#ffa0a1ff";
    case "SERVICING":
      return "#dbdbdbff";
    default:
      return "";
  }
}

