import React from "react";
import { Typography, Button } from "antd";
import "./button.scss";
const { Text } = Typography;

interface Props {
   label: string;
   loading: boolean;
}

const SubmitButtonComponent: React.FC<Props> = (props): JSX.Element => {
   const [isLoadingBtn, setLoadingBtn] = React.useState<boolean>(false);
   const [attr, setAttrib] = React.useState<any>({ label: '' });
   React.useEffect(() => setLoadingBtn(props.loading), [props.loading]);
   React.useEffect(() => setAttrib({ label: props.label }), [props.label]);

   return (
      <Button
         className='submit-button'
         htmlType={"submit"}
         loading={isLoadingBtn}
         size='middle'
         block
      >
         <Text className='submit-button-text'>
            {attr.label}
         </Text>
      </Button>
   );
};

export default React.memo(SubmitButtonComponent);
