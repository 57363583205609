import {
  GET_VENDOR,
  GET_VENDORS,
  GET_VENDOR_PAGINATION,
  SET_VENDOR,
  SET_VENDORS,
  SET_VENDOR_PAGINATION,
  GET_VENDOR_REQUESTS,
  SET_VENDOR_REQUESTS,
  GET_VENDOR_TECHNICIANS,
  SET_VENDOR_TECHNICIANS,
  GET_VENDOR_SERVICE_REQUEST_HISTORY,
  SET_VENDOR_SERVICE_REQUEST_HISTORY,
  GET_VENDOR_TECH_SERVICE_REQUEST_HISTORY,
  SET_VENDOR_TECH_SERVICE_REQUEST_HISTORY,
} from "../action-types";
import store from "..";

export const getVendor = () => {
  return {
    type: GET_VENDOR,
    payload: store.getState().vendorsReducer.state.selectedVendor,
  };
};

export const setVendor = (payload: any) => {
  return {
    type: SET_VENDOR,
    payload,
  };
};

export const getVendorsList = () => {
  return {
    type: GET_VENDORS,
    payload: store.getState().vendorsReducer.state.vendors,
  };
};

export const setVendorsList = (payload: any) => {
  return {
    type: SET_VENDORS,
    payload,
  };
};

export const getVendorPagination = () => {
  return {
    type: GET_VENDOR_PAGINATION,
    payload: store.getState().vendorsReducer.state.vendorPagination,
  };
};

export const setVendorPagination = (payload: any) => {
  return {
    type: SET_VENDOR_PAGINATION,
    payload,
  };
};

export const getVendorRequests = () => {
  return {
    type: GET_VENDOR_REQUESTS,
    payload: store.getState().vendorsReducer.state.vendorRequests,
  };
};

export const setVendorRequests = (payload: any) => {
  return {
    type: SET_VENDOR_REQUESTS,
    payload,
  };
};

export const getVendorTechnicians = () => {
  return {
    type: GET_VENDOR_TECHNICIANS,
    payload: store.getState().vendorsReducer.state.vendorTechnicians,
  };
};

export const setVendorTechnicians = (payload: any) => {
  return {
    type: SET_VENDOR_TECHNICIANS,
    payload,
  };
};

export const getVendorServiceRequestHistory = () => {
  return {
    type: GET_VENDOR_SERVICE_REQUEST_HISTORY,
    payload: store.getState().vendorsReducer.state.vendorServReqHistory,
  };
};

export const setVendorServiceRequestHistory = (payload: any) => {
  return {
    type: SET_VENDOR_SERVICE_REQUEST_HISTORY,
    payload,
  };
};

export const getVendorTechServiceRequestHistory = () => {
  return {
    type: GET_VENDOR_TECH_SERVICE_REQUEST_HISTORY,
    payload: store.getState().vendorsReducer.state.vendorServReqHistory,
  };
};

export const setVendorTechServiceRequestHistory = (payload: any) => {
  return {
    type: SET_VENDOR_TECH_SERVICE_REQUEST_HISTORY,
    payload,
  };
};



