import { combineReducers } from "redux";

import { SET_ALL_UNREAD_NOTIFICATIONS, READ_NOTIFICATION } from "../action-types";

import { NotificationsActions, AllNotifications } from "./model";

export interface NotificationsState {
  state: AllNotifications;
};

const INITIAL_STATE: AllNotifications = {
  Notifications: []
};

const state = (
  state: AllNotifications = INITIAL_STATE,
  action: NotificationsActions
): AllNotifications => {
  switch (action.type) {
    case SET_ALL_UNREAD_NOTIFICATIONS:
      return { ...state, Notifications: action.payload };
    case READ_NOTIFICATION:
      return { ...state, Notifications: state.Notifications.filter(notif => notif.id !== action.payload.id)}
    default:
      return state;
  };
};

export default combineReducers<NotificationsState>({ state });