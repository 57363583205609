import Card from "./Card";
import styles from "./CustomReportGenerator.module.scss";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DropdownIcon from "../../../assets/fsa-icons/svg/dropdown.svg";
import Radio from "../../../assets/fsa-icons/svg/radio.svg";

export default function CustomReportGenerator() {
  return (
    <Card title="Custom Report Generator" gap="medium">
      <div className={styles["grid-form"]}>
        <label>
          Type of Report
          <div className={styles.select}>
            <input type="text" placeholder="Select report type" />
            <img
              className={styles.dropdown}
              src={DropdownIcon}
              alt="Dropdown Icon"
            />
          </div>
        </label>
        <label>
          Date Range
          <div className={styles.select}>
            <input type="text" placeholder="Select range" />
            <img
              className={styles.dropdown}
              src={DropdownIcon}
              alt="Dropdown Icon"
            />
          </div>
        </label>

        <label>
          From
          <div className={styles.select}>
            <input type="text" placeholder="Jan-01-2000" />
            <CalendarTodayIcon className={styles.calendar} color="inherit" />
          </div>
        </label>

        <label>
          To
          <div className={styles.select}>
            <input type="text" placeholder="Jan-01-2000" />
            <CalendarTodayIcon className={styles.calendar} color="inherit" />
          </div>
        </label>

        <div className={styles["radio-row"]}>
          <img src={Radio} alt="Radio Button" />
          <div className={styles["radio-label"]}>View Report Only</div>
        </div>

        <div className={styles["radio-row"]}>
          <div className={styles["radio-empty"]} />
          <div className={styles["radio-label"]}>Download Report (PDF)</div>
        </div>
      </div>

      <button className={styles["btn-generate"]}>Generate Report Now</button>
    </Card>
  );
}
