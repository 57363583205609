import { combineReducers } from "redux";
import { SET_CLIENT_ID, SET_IS_AUTHENTICATING, SET_RESET_PASSWORD, SET_SESSION_CREDENTIALS, SET_USER_DETAILS, } from "../action-types";
import { AuthenticationModel, AuthActions } from "./model";
export interface AuthState {
  state: AuthenticationModel;
}

const InitialState = {
  formFields: {
    username: '',
    password: ''
  },
  sessionCredentials: {
    accessToken: "",
    refreshToken: "",
  },
  isAuthenticating: false,
  userDetails: {
    email: "",
    fullName: "",
    id: 0,
    position: {
      name: "",
      roles: []
    }
  }
};

const state = (state: AuthenticationModel = InitialState, action: AuthActions): AuthenticationModel => {
  switch (action.type) {
    case SET_IS_AUTHENTICATING:
      return { ...state, isAuthenticating: action.payload };
    case SET_USER_DETAILS:
      return { ...state, userDetails: action.payload };
    case SET_SESSION_CREDENTIALS:
      return { ...state, sessionCredentials: action.payload };
    case SET_RESET_PASSWORD:
      return { ...state, email: action.email };
    default:
      return state;
  }
};

export default combineReducers<AuthState>({ state });
