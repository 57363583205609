import { Fragment } from "react";
import MinusIcon from "../../../assets/fsa-icons/svg/minus.svg";
import PlusIcon from "../../../assets/fsa-icons/svg/plus.svg";
import type { Status, UserType } from "../types";
import LineEdgedTable from "./LineEdgedTable";
import StatusChip from "./StatusChip";
import styles from "./UserSummaryCard.module.scss";
import ViewMoreLink from "./ViewMoreLink";

interface Props {
  userSummaries: Record<string, string | number>[];
  userType: UserType;
  newUsersCount?: number;
  userStatusSummaries: Record<string, string | number>[];
  isPositive?: boolean;
  className?: string;
}

export default function UserSummaryCard({
  userSummaries,
  userStatusSummaries,
  userType,
  newUsersCount = 0,
  isPositive,
  className,
}: Props) {
  return (
    <div className={`${styles["user-summary"]} ${className}`}>
      <div
        className={`${styles["new-users"]} ${
          userType === "Technicians" ? styles.technicians : styles.members
        }`}>
        <div className={styles["new-users-title"]}>
          {userType === "Technicians"
            ? "New Technician"
            : "New Members and Subscribers"}
        </div>
        <div className={styles["new-users-count"]}>{newUsersCount}</div>
        <div className={styles["new-users-percentage"]}>
          <img src={isPositive ? PlusIcon : MinusIcon} alt="" />
          <div>14.5%change</div>
        </div>

        <div className={styles["new-users-grid"]}>
          <div className={styles["new-users-grid-item"]}>Status</div>
          <div className={styles["new-users-grid-item"]}>Count</div>
          {userStatusSummaries.map((uss, index) => (
            <Fragment key={index}>
              <StatusChip status={uss.status as Status} />
              <div>{uss.count}</div>
            </Fragment>
          ))}
        </div>
      </div>

      <div className={styles["user-summary-table"]}>
        <div className={styles["user-summary-title"]}>
          {userType === "Technicians"
            ? "Technicians"
            : "Members and Subscribers"}
        </div>
        <LineEdgedTable
          tableLayout="fixed"
          columns={["Name", "Status", "Last Update"]}
          data={userSummaries}
        />
        <ViewMoreLink />
      </div>
    </div>
  );
}
