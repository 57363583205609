import styles from "./BarChart.module.scss";
interface Props {
  data: Info[];
}

interface Info {
  label: string;
  value: number;
  color: string;
}

export default function BarChart({ data }: Props) {
  return (
    <div className={styles.container}>
      {data.map((d, index) => (
        <div key={index} className={styles["bar-col"]}>
          <div className={styles["bar-container"]}>
            <div
              style={{ height: `${d.value / 16}rem`, backgroundColor: d.color }}
              className={styles.bar}
            />
          </div>
          <div className={styles.label}>{d.label}</div>
          <div style={{ color: d.color }} className={styles.value}>
            {d.value}
          </div>
        </div>
      ))}
    </div>
  );
}
