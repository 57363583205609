import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import _isNull from "lodash/isNull";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import _isUndefined from "lodash/isUndefined";
import _debounce from "lodash/debounce";

import { Button, Input, Image, Modal, Spin, message } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import {
  ChatResponse,
  ChatType,
  createConversation,
  createConversationMessages,
  getConversationMessages,
  getConversationMessagesFromURL,
  getConversations,
  getMediaChat,
  notifAdminToTech,
  removeConversations,
  uploadMediaChat,
} from "../../redux/chat/chat-service";
import {
  RequestFormValue,
  ServiceRequest,
} from "../../redux/service-request/model";
import { Technician } from "../../redux/technician/model";
import { SERVER_URL } from "../../configs/service-config";

const { TextArea } = Input;
interface ComponentProps {
  show: boolean;
  setShow: any;
  type: any;
  payload: any;
  setLoadingIndex: any;
  user: any;
  comesFromNotification?: any;
}
interface ReduxState {}
interface DispatchProps {
  notifAdminToTech: (type: any, chatId: any, recipientId: any) => any;
  getConversations: (pageSize: number, type: ChatType) => any;
  createConversation: (sr: string, type: ChatType) => any;
  removeConversations: (channel: string, type: ChatType) => any;
  getConversationMessages: (channel: string, type: ChatType) => any;
  getConversationMessagesFromURL: (url: string) => any;
  createConversationMessages: (
    channel: string,
    type: ChatType,
    msg: string,
    media?: any
  ) => any;
  uploadMediaChat: (f: any, type: ChatType) => any;
  getMediaChat: (f: any, type: ChatType) => any;
}
type Props = ComponentProps & ReduxState & DispatchProps;
const ConversationModalComponent: React.FC<Props> = (props): JSX.Element => {
  const [user, setUser] = React.useState<Technician>();
  const [identifer, setIdentifier] = React.useState("");
  const [chatType, setChatType] = React.useState<ChatType>(
    ChatType.WITH_ML_REPRESENTATIVE
  );
  const [isSpinning, setSpinning] = React.useState<boolean>(false);
  const [messages, setMessages] = React.useState<Array<any>>([]);
  const [fileValue, setFileValue] = React.useState("");
  const [textMessage, setTextMessage] = React.useState("");
  const [conversationType, setConversationType] = React.useState("");
  const [serviceRequest, setServiceRequest] = React.useState<ServiceRequest>();
  const [twilioSSID, setTwilioSSID] = React.useState("");

  React.useEffect(() => {
    window.addEventListener("chat-notification", refresher);
    return () => {
      window.removeEventListener("chat-notification", refresher);
    };
  }, []);

  React.useEffect(() => {
    componentIsMounting();
  }, [props.show]);

  React.useEffect(() => {
    if (_isEqual(props.show, true)) {
      setUser(props.user);
      setIdentifier(props.comesFromNotification);
      setConversationType(props.type);
      setServiceRequest(props.payload);
    }
  }, [props]);

  React.useEffect(() => {
    initialize();
  }, [conversationType]);

  React.useEffect(() => {
    initialize();
  }, [serviceRequest?.id]);

  React.useEffect(() => {
    let elem: HTMLElement | null = document.getElementById(
      "chat-content-scroller"
    );
    if (elem) {
      elem.scrollTop = elem.scrollHeight - elem.clientHeight;
    }
  }, [messages]);

  const componentIsMounting = () => {
    props.setLoadingIndex(-1);
  };

  const refresher = (params?: any) => {
    if (params && params.detail) {
      const { chatId, senderName } = params.detail;
      if (!_isEqual(chatId, "global") && !_isEqual(senderName, "Member")) {
        messageHasReceive(
          "MLFSA_WITHREP_CHANNEL" + chatId,
          ChatType.WITH_ML_REPRESENTATIVE
        );
      }

      if (_isEqual(chatId, "global")) {
        messageHasReceive("GLOBAL_CHAT" + senderName, ChatType.GENERAL);
      }
      if (!_isEqual(chatId, "global") && _isEqual(senderName, "Member")) {
        messageHasReceive(
          "MLFSA_WITHMEMBER_CHANNEL" + chatId,
          ChatType.WITH_MEMBER
        );
      }
    } else {
      if (!_isEmpty(twilioSSID)) {
        messageHasReceive(twilioSSID, chatType);
      }
    }
  };

  const initialize = () => {
    setSpinning(true);
    if (
      !_isUndefined(identifer) &&
      _isEqual(conversationType, "chat-with-service-request")
    ) {
      setter(
        ChatType.WITH_ML_REPRESENTATIVE,
        identifer,
        "MLFSA_WITHREP_CHANNEL"
      );
    }

    if (
      !_isUndefined(identifer) &&
      _isEqual(conversationType, "chat-with-global")
    ) {
      setter(ChatType.GENERAL, identifer, "GLOBAL_CHAT");
    }

    if (
      !_isUndefined(identifer) &&
      _isEqual(conversationType, "chat-with-member")
    ) {
      setter(ChatType.WITH_MEMBER, identifer, "MLFSA_WITHMEMBER_CHANNEL");
    }

    if (
      _isUndefined(identifer) &&
      _isEqual(conversationType, "chat-with-service-request")
    ) {
      setter(
        ChatType.WITH_ML_REPRESENTATIVE,
        serviceRequest?.id,
        "MLFSA_WITHREP_CHANNEL"
      );
    }

    if (
      _isUndefined(identifer) &&
      _isEqual(conversationType, "chat-with-global")
    ) {
      setter(ChatType.GENERAL, user?.email, "GLOBAL_CHAT");
    }

    if (
      _isUndefined(identifer) &&
      _isEqual(conversationType, "chat-with-member")
    ) {
      setter(
        ChatType.WITH_MEMBER,
        serviceRequest?.serviceRequestNo,
        "MLFSA_WITHMEMBER_CHANNEL"
      );
    }
  };

  const setter = async (
    chatType: ChatType,
    identifier: any,
    channelName: string
  ) => {
    setChatType(chatType);
    setTwilioSSID(channelName + identifier);
    messageHasReceive(channelName + identifier, chatType);
  };

  const displayImagePhoto = async (media: Array<any>, type: any) => {
    if (!_isUndefined(chatType) && !_isNull(media)) {
      const response: ChatResponse = await props.getMediaChat(
        media[0].sid,
        type
      );

      if (_isEqual(response.statusCode, 200)) {
        let photoURL = response.body.links.content_direct_temporary;
        return photoURL;
      }
    }
    return "";
  };

  const messageHasReceive = async (
    ssid: string,
    type: ChatType,
    url?: string,
    temp?: Array<any>
  ) => {
    if (_isUndefined(url)) {
      const response: ChatResponse = await props.getConversationMessages(
        ssid,
        type
      );
      if (_isEqual(response.statusCode, 404)) {
        const response: ChatResponse = await props.createConversation(
          ssid,
          type
        );
        if (_isEqual(response.statusCode, 201)) {
          setTwilioSSID(ssid);
          messageHasReceive(ssid, type);
        }
      }

      if (_isEqual(response.statusCode, 200)) {
        const { meta, messages: msgs } = response.body;
        if (!_isNull(meta.next_page_url)) {
          let tempMessages: Array<any> = [];
          for (let i = 0; i < msgs.length; i++) {
            const photo = await displayImagePhoto(msgs[i].media, type);
            tempMessages.push({
              _id: uuidGenerator(),
              text: !_isEqual(msgs[i].body, "ImAGe_uPl0Ad") ? msgs[i].body : "",
              image: photo,
              createdAt: msgs[i].date_created,
              user: {
                _id: msgs[i].author,
                name: msgs[i].author,
              },
            });
          }
          messageHasReceive(ssid, type, meta.next_page_url, tempMessages);
        }

        if (_isNull(meta.next_page_url)) {
          let tempMessages: Array<any> = [];
          for (let i = 0; i < msgs.length; i++) {
            const photo = await displayImagePhoto(msgs[i].media, type);
            tempMessages.push({
              _id: uuidGenerator(),
              text: !_isEqual(msgs[i].body, "ImAGe_uPl0Ad") ? msgs[i].body : "",
              image: photo,
              createdAt: msgs[i].date_created,
              user: {
                _id: msgs[i].author,
                name: msgs[i].author,
              },
            });
          }
          setMessages(tempMessages);
          setSpinning(false);
        }
      }
    }

    if (!_isUndefined(url)) {
      // console.log("*** Component Debugger *** get twilio conversation message from url", url);
      const response: ChatResponse = await props.getConversationMessagesFromURL(
        url
      );
      // console.log("*** Component Debugger *** get twilio conversation message from url", response);
      if (_isEqual(response.statusCode, 200)) {
        const { meta, messages: msgs } = response.body;
        if (!_isNull(meta.next_page_url)) {
          messageHasReceive(ssid, type, meta.next_page_url);
        }
        if (_isNull(meta.next_page_url) && !_isUndefined(temp)) {
          // console.log("*** Component Debugger *** get twilio conversation message from tempMessages", temp);
          for (let i = 0; i < msgs.length; i++) {
            const photo = await displayImagePhoto(msgs[i].media, type);
            temp.push({
              _id: uuidGenerator(),
              text: !_isEqual(msgs[i].body, "ImAGe_uPl0Ad") ? msgs[i].body : "",
              image: photo,
              createdAt: msgs[i].date_created,
              user: {
                _id: msgs[i].author,
                name: msgs[i].author,
              },
            });
          }
          setMessages(temp);
          setSpinning(false);
        }
      }
    }
  };

  const uuidGenerator = () => {
    // Public Domain/MIT
    var d = new Date().getTime(); //Timestamp
    var d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
  };

  const onChangeChatText = (e: any) => {
    const { value } = e.target;
    setTextMessage(value);
  };

  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSend();
    }
  };

  const onSend = async () => {
    setTextMessage("");
    // console.log("*** Component Debugger *** identifer", identifer);
    // console.log("*** Component Debugger *** conversationType", conversationType);
    // console.log("*** Component Debugger *** chatType", chatType);
    // console.log("*** Component Debugger *** twilioSSID", twilioSSID);

    if (
      !_isUndefined(identifer) &&
      _isEqual(conversationType, "chat-with-service-request")
    ) {
      const response: ChatResponse = await props.createConversationMessages(
        "MLFSA_WITHREP_CHANNEL" + identifer,
        ChatType[chatType],
        textMessage
      );
      if (_isEqual(response.statusCode, 201)) {
        refresher();
        requestToSendChatNotification(identifer, user?.id);
      }
    }

    if (
      !_isUndefined(identifer) &&
      _isEqual(conversationType, "chat-with-member")
    ) {
      const response: ChatResponse = await props.createConversationMessages(
        "MLFSA_WITHMEMBER_CHANNEL" + identifer,
        ChatType[chatType],
        textMessage
      );
      if (_isEqual(response.statusCode, 201)) {
        refresher();
        requestToSendChatNotification(identifer, memberId?.value);
      }
    }

    if (
      !_isUndefined(identifer) &&
      _isEqual(conversationType, "chat-with-global")
    ) {
      const response: ChatResponse = await props.createConversationMessages(
        "GLOBAL_CHAT" + identifer,
        ChatType[chatType],
        textMessage
      );
      if (_isEqual(response.statusCode, 201)) {
        refresher();
        requestToSendChatNotification("global", user?.id);
      }
    }

    if (
      _isUndefined(identifer) &&
      _isEqual(conversationType, "chat-with-service-request")
    ) {
      const response: ChatResponse = await props.createConversationMessages(
        twilioSSID,
        chatType,
        textMessage
      );
      if (_isEqual(response.statusCode, 201)) {
        refresher();
        requestToSendChatNotification(serviceRequest?.id, user?.id);
      }
    }

    if (
      _isUndefined(identifer) &&
      _isEqual(conversationType, "chat-with-global")
    ) {
      const response: ChatResponse = await props.createConversationMessages(
        twilioSSID,
        chatType,
        textMessage
      );
      if (_isEqual(response.statusCode, 201)) {
        refresher();
        requestToSendChatNotification("global", user?.id);
      }
    }

    if (
      _isUndefined(identifer) &&
      _isEqual(conversationType, "chat-with-member")
    ) {
      const response: ChatResponse = await props.createConversationMessages(
        twilioSSID,
        chatType,
        textMessage
      );
      if (_isEqual(response.statusCode, 201)) {
        refresher();
        requestToSendChatNotification(serviceRequest?.serviceRequestNo,memberId?.value);
      }
    }
  };

  /*
  * Send chat notif to member
  */
  
  const requestToSendChatNotification = (identifer: any, id: any) => {
    props.notifAdminToTech(chatType, identifer, id);
  };

  const onUploading = async (event: any) => {
    var file = event.target.files[0];
    if (_isEqual(conversationType, "chat-with-service-request")) {
      const uploadResponse: ChatResponse = await props.uploadMediaChat(
        file,
        ChatType.WITH_ML_REPRESENTATIVE
      );
      if (_isEqual(uploadResponse.statusCode, 200)) {
        let media = uploadResponse.body;
        const response: ChatResponse = await props.createConversationMessages(
          twilioSSID,
          chatType,
          "ImAGe_uPl0Ad",
          media.sid
        );
        if (_isEqual(response.statusCode, 201)) {
          refresher();
          requestToSendChatNotification(serviceRequest?.id, user?.id);
        }
      }
    }

    if (_isEqual(conversationType, "chat-with-global")) {
      const uploadResponse: ChatResponse = await props.uploadMediaChat(
        file,
        ChatType.GENERAL
      );
      if (_isEqual(uploadResponse.statusCode, 200)) {
        let media = uploadResponse.body;
        const response: ChatResponse = await props.createConversationMessages(
          twilioSSID,
          chatType,
          "ImAGe_uPl0Ad",
          media.sid
        );
        if (_isEqual(response.statusCode, 201)) {
          refresher();
          requestToSendChatNotification("global", user?.id);
        }
      }
    }

    if (_isEqual(conversationType, "chat-with-member")) {
      const uploadResponse: ChatResponse = await props.uploadMediaChat(
        file,
        ChatType.WITH_MEMBER
      );
      if (_isEqual(uploadResponse.statusCode, 200)) {
        let media = uploadResponse.body;
        const response: ChatResponse = await props.createConversationMessages(
          twilioSSID,
          chatType,
          "ImAGe_uPl0Ad",
          media.sid
        );
        if (_isEqual(response.statusCode, 201)) {
          refresher();
          requestToSendChatNotification(
            serviceRequest?.serviceRequestNo,
            memberId?.value
          );
        }
      }
    }
  };
  const closeModal = async () => {
    localStorage.removeItem("chatConversationModal");
    localStorage.removeItem("CHANNEL_SSID");
    props.setShow(false);
  };

  const renderList = (list: Array<any>) => {
    return list.map((item: any, index: number) => {
      // console.log("CONVERSATION RENDER LIST", item)
      return (
        <div
          key={"chat-bubble-" + index}
          className={
            _isEqual(item.user.name, "ADMIN")
              ? "bubble-container-right"
              : "bubble-container-left"
          }
        >
          {!_isEmpty(item.text) && (
            <div
              className="bubble"
              style={
                _isEmpty(item.text)
                  ? {}
                  : { backgroundColor: "#2e71a4", color: "#d2f1ff" }
              }
            >
              {" "}
              {item.text}{" "}
            </div>
          )}
          {_isEmpty(item.text) && (
            <Image
              style={{ width: 200, height: 200, marginTop: 8 }}
              preview={false}
              src={item?.image}
            />
          )}
        </div>
      );
    });
  };

  const mapper = (
    requestFormValues?: Array<RequestFormValue>,
    field?: string
  ) => {
    let text = "";
    requestFormValues &&
      requestFormValues.map((RFV: RequestFormValue) => {
        if (_isEqual(RFV.field.name, field)) {
          if (_isEqual(field, "Service Type")) {
            let str = RFV.value.replace(/\_/g, " ").toLocaleLowerCase();
            text = str.charAt(0).toUpperCase() + str.slice(1);
          } else {
            text = ", " + RFV.value;
          }
        }
      });
    return text;
  };

  const memberId = serviceRequest?.requestFormValues.find(
      (number) => number.field.name === "Member ID"
  );

  const renderTitle = () => (
    <div style={{ width: "100%", display: "flex" }}>
      {"Chat History"}
      <div style={{ marginLeft: "16%" }}>
        {_isEqual(conversationType, "chat-with-global") &&
          user?.fullName + " - General Inquiry"}
        {_isEqual(conversationType, "chat-with-service-request") &&
          serviceRequest?.currentStatus.replace(/_/g, " ") +
            ", " +
            "SR#" +
            serviceRequest?.serviceRequestNo +
            mapper(serviceRequest?.requestFormValues, "Item") +
            mapper(serviceRequest?.requestFormValues, "Property") +
            mapper(serviceRequest?.requestFormValues, "Room")}
        {_isEqual(conversationType, "chat-with-member") &&
          serviceRequest?.currentStatus.replace(/_/g, " ") +
            ", " +
            "SR#" +
            serviceRequest?.serviceRequestNo +
            mapper(serviceRequest?.requestFormValues, "Item") +
            mapper(serviceRequest?.requestFormValues, "Member")}
      </div>
    </div>
  );

  return (
    <Modal
      title={renderTitle()}
      centered
      visible={props.show}
      footer={null}
      onCancel={() => closeModal()}
      width={1000}
      wrapClassName={"chat-modal"}
    >
      <div id="chat-content-scroller" className="chat-content">
        {!_isEqual(messages.length, 0) && renderList(messages)}
      </div>
      <div className="chat-tools">
        <div className="upload-btn-wrapper">
          <Button className="btn chat-btn">
            <PaperClipOutlined />
          </Button>
          <input
            type="file"
            name="myfile"
            accept="image/png, image/jpeg"
            value={fileValue}
            onClick={() => setFileValue("")}
            onChange={onUploading}
          />
        </div>
        <TextArea
          value={textMessage}
          className="chat-textInput"
          showCount
          autoSize={{ minRows: 2, maxRows: 2 }}
          onKeyPress={(e: any) => handleKeypress(e)}
          maxLength={100}
          onChange={(e: any) => onChangeChatText(e)}
          placeholder="Type your message here..."
        />
        <Button
          className="chat-btn"
          loading={isSpinning}
          disabled={_isEmpty(textMessage)}
          style={{ borderTopRightRadius: 4, borderBottomRightRadius: 4 }}
          onClick={() => !_isEmpty(textMessage) && onSend()}
        >
          SEND
        </Button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (states: RootState): ReduxState => {
  return {};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    getConversations: (pageSize, type) =>
      dispatch(getConversations(pageSize, type)),
    createConversation: (sr, type) => dispatch(createConversation(sr, type)),
    removeConversations: (channel, type) =>
      dispatch(removeConversations(channel, type)),
    getConversationMessages: (channel, type) =>
      dispatch(getConversationMessages(channel, type)),
    getConversationMessagesFromURL: (url) =>
      getConversationMessagesFromURL(url),
    createConversationMessages: (channel, type, msg, media) =>
      dispatch(createConversationMessages(channel, type, msg, media)),
    notifAdminToTech: (type, chatId, recipientId) =>
      dispatch(notifAdminToTech(type, chatId, recipientId)),
    uploadMediaChat: (f: any, type) => dispatch(uploadMediaChat(f, type)),
    getMediaChat: (sid, type) => dispatch(getMediaChat(sid, type)),
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(ConversationModalComponent)
);
