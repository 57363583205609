import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { notification } from 'antd';
import { SERVER_URL } from "../../configs/service-config";
import { getRequestWithJson } from "../interceptor";

export const getMembers = (q: string): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = `${SERVER_URL.admin}/members?q=${q}`;
      let result = null;
      await getRequestWithJson(dispatch, endpoint)
         .then((response: any) => {
            result = response;
         })
         .catch((error: any) => {
            console.log('Error Message -> while fetching members', error);
         });
      return result;
   };
};

export const getSubscribers = (q: string): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = `${SERVER_URL.admin}/subscribers?q=${q}`;
      let result = null;
      await getRequestWithJson(dispatch, endpoint)
         .then((response: any) => {
            result = response;
         })
         .catch((error: any) => {
            console.log('Error Message -> while fetching subscribers', error);
         });
      return result;
   };
};

export const getMemberProperties = (memberId: number): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = `${SERVER_URL.admin}/properties?memberId=${memberId}`;
      let result = null;
      await getRequestWithJson(dispatch, endpoint)
         .then((response: any) => {
            result = response;
         })
         .catch((error: any) => {
            console.log('Error Message -> while fetching member properties', error);
         });
      return result;
   };
};



