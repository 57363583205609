import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { SERVER_URL } from "../../configs/service-config";
import { getRequest, getRequestWithJson, postRequest, postRequestWithJson } from "../interceptor";
import { setVendor, setVendorPagination, setVendorsList, setVendorRequests, setVendorTechnicians } from "./action";
import _isUndefined from "lodash/isUndefined";
import _isEqual from "lodash/isEqual";
import _isNull from "lodash/isNull";
import { message } from "antd";
import moment from "moment";
import { getClientId } from "../auth/actions";

export const fetchVendorsInit = (action: string, index: number, status: string, viewableSize: number,): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    const vendorsEndpoint = new URL(SERVER_URL.VENDORS);
    vendorsEndpoint.searchParams.append("clientId", clientId);
    vendorsEndpoint.searchParams.append("action", action);
    vendorsEndpoint.searchParams.append("index", index.toString());
    vendorsEndpoint.searchParams.append("status", status);
    vendorsEndpoint.searchParams.append("viewableSize", viewableSize.toString());
    await getRequestWithJson(dispatch, vendorsEndpoint)
      .then((response: any) => {
        dispatch(setVendorPagination(response.meta));
        dispatch(setVendorsList(response.items));
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };
};

export const fetchVendorsAction = (action: string, index: number, status: string, viewableSize: number, totalSize: number, searchCriteria: any,): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    const vendorsEndpoint = new URL(SERVER_URL.VENDORS);
    vendorsEndpoint.searchParams.append("clientId", clientId);
    vendorsEndpoint.searchParams.append("action", action);
    vendorsEndpoint.searchParams.append("index", index.toString());
    vendorsEndpoint.searchParams.append("status", status);
    vendorsEndpoint.searchParams.append(
      "viewableSize",
      viewableSize.toString()
    );
    vendorsEndpoint.searchParams.append(
      "totalSize",
      totalSize.toString()
    );
    if (searchCriteria.companyName) {
      vendorsEndpoint.searchParams.append("companyName", searchCriteria.companyName);
    }
    if (searchCriteria.city) {
      vendorsEndpoint.searchParams.append("city", searchCriteria.city);
    }
    if (searchCriteria.zipCode) {
      vendorsEndpoint.searchParams.append("zipCode", searchCriteria.zipCode);
    }
    if (searchCriteria.type) {
      vendorsEndpoint.searchParams.append("type", searchCriteria.type);
    }
    if (searchCriteria.techFirstName) {
      vendorsEndpoint.searchParams.append("techFirstName", searchCriteria.techFirstName);
    }
    if (searchCriteria.techLastName) {
      vendorsEndpoint.searchParams.append("techLastName", searchCriteria.techLastName);
    }
    await getRequestWithJson(dispatch, vendorsEndpoint)
      .then((response: any) => {
        dispatch(setVendorPagination(response.meta));
        dispatch(setVendorsList(response.items));
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };
};

export const fetchVendor = (id: any): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const vendorsEndpoint = new URL(SERVER_URL.VENDOR) + `/${id}`;
    {
      id === 0 ? dispatch(setVendor(null)) :
        await getRequestWithJson(dispatch, vendorsEndpoint)
          .then((response: any) => {
            dispatch(setVendor(response))
          })
          .catch((error: any) => {

          });
    };
  }
};

export const postVendor = (vendor: any): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    const vendorsEndpoint = new URL(SERVER_URL.VENDORS);
    const vendorData = {
      clientId: clientId,
      ...vendor,
    };
    await postRequest(dispatch, vendorsEndpoint, { body: JSON.stringify(vendorData) })
      .then((response: any) => {
        message.success("The vendor was saved successfully");
      })
      .catch((error: any) => {
        message.error("There is an error saving the vendor");
      });
  };
};


export const postTechnicianProfilePhoto = async (techProfilePhoto: any, userId: number): Promise<any> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const endpoint = SERVER_URL.TECHNICIANS + `/photo?id=${userId}`;
    let formData = new FormData();
    formData.append("file", techProfilePhoto);
    let result: any = null;
    await postRequestWithJson(dispatch, endpoint, {
      body: formData,
    })
      .then((response) => {
        result = response;
      })
      .catch((error: any) => {
        result = error;
        console.log('Error Message -> while saving technician profile photo', error);
      });
    return result;
  }
};

export const postVendorTechnicianPersonalInfo = (personalInfo: any, clientId: any): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const endpoint = SERVER_URL.USERS;

    const rawData = {
      clientId: clientId,
      ...personalInfo,
    };

    let result: any = null;
    await postRequestWithJson(dispatch, endpoint, {
      body: JSON.stringify(rawData)
    })
      .then((response) => {
        result = response;
      })
      .catch((error: any) => {
        result = error;
        console.log('Error Message -> while saving vendor technician perfonal information', error);
      });
    return result;
  }
};

export const postVendorTechnicianBankInfo = (bankInfo: any): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    const vendorsEndpoint = new URL(SERVER_URL.TECHNICIANS + `/bank-information`);
    const technicianBankInfo = {
      clientId: clientId,
      ...bankInfo,
    };
    await postRequest(dispatch, vendorsEndpoint, { body: JSON.stringify(technicianBankInfo) })
      .then((response: any) => {
      })
      .catch((error: any) => {
        console.log("There is an error saving the vendor technician bank information", error);
      });
  };
};

export const postVendorTechnicianServiceExperience = (serviceExperience: any): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    const vendorsEndpoint = new URL(SERVER_URL.TECHNICIANS + `/service-experience`);
    const technicianServiceExperience = {
      clientId: clientId,
      ...serviceExperience,
    };
    await postRequest(dispatch, vendorsEndpoint, { body: JSON.stringify(technicianServiceExperience) })
      .then((response: any) => {
      })
      .catch((error: any) => {
        console.log("There is an error saving the vendor technician service experience", error);
      });
  };
};

export const postVendorTechnicianAvailability = (availability: any, technician: any): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const vendorsEndpoint = new URL(SERVER_URL.TECHNICIANS + `/availability`);
    const techAvailabilityArray = [];
    for (let x = 0; x < availability.length; x++) {
      techAvailabilityArray.push({
        technician: {
          ...technician,
        },
        id: availability[x].id,
        day: availability[x].value,
        startTime: availability[x].startTime,
        endTime: availability[x].endTime,
        onCall: availability[x].onCall,
        version: availability[x].version,
      });
    }
    await postRequest(dispatch, vendorsEndpoint, { body: JSON.stringify(techAvailabilityArray) })
      .then((response: any) => {
      })
      .catch((error: any) => {
        console.log("There is an error saving the vendor technician availability", error);
      });
  };
};

export const fetchVendorRequests = (id: number): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const vendorsEndpoint = new URL(SERVER_URL.VENDOR_REQUESTS) + `?vendorId=${id}`;
    {
      id === 0 ? dispatch(setVendorRequests([])) :
        await getRequestWithJson(dispatch, vendorsEndpoint)
          .then((response: any) => {
            dispatch(setVendorRequests(response))
          })
          .catch((error: any) => {
            console.log("There is an error getting the vendor", error);
          });
    };
  }
};

export const fetchVendorTechnicians = (id: number): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const vendorsEndpoint = new URL(SERVER_URL.AVENDOR_TECHNICIANS) + `?vendorId=${id}`;
    {
      id === 0 ? dispatch(setVendorTechnicians([])) :
        await getRequestWithJson(dispatch, vendorsEndpoint)
          .then((response: any) => {
            dispatch(setVendorTechnicians(response))
          })
          .catch((error: any) => {
            console.log("There is an error getting the vendor technician", error);
          });
    };
  }
};

