import { 
  SET_DISPATCHER, 
  GET_DISPATCHERS, 
  SET_DISPATCHERS, 
  GET_SELECTED_DISPATCHER_ID, 
  SET_SELECTED_DISPATCHER_ID, 
  SET_REMOVE_DISPATCHER_ID, 
  GET_REMOVE_DISPATCHER_ID, 
  SET_ALL_SELECTED_DISPATCHERS, 
  GET_ALL_SELECTED_DISPATCHERS } from "../action-types";
  
import { DispatcherModel } from "./model";
import { combineReducers } from "redux";

export interface DispatchersState {
  state: DispatcherModel;
};

const INITIAL_STATE = {
  isDispatcherSRLoading: false,
  dispatchers: [],
  removeDispatcherId: null,
  selectedDispatcherId: null,
  allSelectedDispatchers: [],
  dispatchersSRCounts: [],
  dispatcherSR: [],
  dispatcher: null,
  completedRequests: [],
  bankInformation: null,
  serviceExperience: null,
  availabilities: [],
};

const state = (state: DispatcherModel = INITIAL_STATE, action: any): DispatcherModel => {
  switch (action.type) {
    case SET_DISPATCHER:
      return { ...state, dispatcher: action.payload };
    case SET_DISPATCHERS:
      return { ...state, dispatchers: action.payload };
    case SET_REMOVE_DISPATCHER_ID:
      return { ...state, removeDispatcherId: action.payload };
    case SET_SELECTED_DISPATCHER_ID:
      return { ...state, selectedDispatcherId: action.payload };
    case SET_ALL_SELECTED_DISPATCHERS:
      return { ...state, allSelectedDispatchers: action.payload };
    default:
      return state;
  };
};

export default combineReducers<DispatchersState>({ state });