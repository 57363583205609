import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { ClockType, DailyWeekly, Day, Schedules, Time, Toolbar } from "./model";
import { getSchedulerGrid, getSchedulerGridDaily, getSchedulerToolbar, getSevenDays, setClock, setIsLoadingScheduler, setSchedulerGrid, setSchedulerGridDaily, setSevenDays } from "./action";

import _isUndefined from "lodash/isUndefined";
import _isEqual from "lodash/isEqual";
import moment, { Moment } from "moment";
import { Technician } from "../technician/model";
import { getTechniciansGrid } from "../technician/action";
import { getAssignedServiceRequests } from "../service-request/action";
import { RequestFormValue, ServiceRequest } from "../service-request/model";
import { getIndexTimePeriod } from "../service-request/service";

export const schedulerHeaderBuilder = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const toolbar: Toolbar = dispatch(getSchedulerToolbar()).payload;
    if (_isEqual(toolbar.D_W, DailyWeekly.WEEKLY)) {
      let tempWeek: Array<Day> = [];
      const currentDate: any = moment(toolbar.dateFilter, "MMMM Do YYYY");
      let targetDay = currentDate.clone().startOf("week");
      for (let d = 0; d <= 6; d++) {
        tempWeek.push({
          date: moment(targetDay).add(d, 'days').format("MMMM Do YYYY"),
          dd: moment(targetDay).add(d, 'days').format("D"),
          mm: moment(targetDay).add(d, 'days').format("M"),
          day: moment(targetDay).add(d, 'days').format("ddd")
        })
      }
      dispatch(setSevenDays(tempWeek));
    }

    if (_isEqual(toolbar.D_W, DailyWeekly.DAILY)) {
      const currentDate: any = moment(moment(), "MMMM Do YYYY");
      const currentTime = moment(currentDate).subtract(currentDate.minute(), "minutes").format("h:mm A");
      const clock: Array<Time> = await getJsonTime(toolbar, currentTime);
      dispatch(setClock(clock));
    }
  };
};

export const constructSchedulerGrid = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const toolbar: Toolbar = dispatch(getSchedulerToolbar()).payload;
    const technicians: Array<Technician> = dispatch(getTechniciansGrid()).payload;
    if (_isEqual(toolbar.D_W, DailyWeekly.WEEKLY)) {
      let tempGrid: any = [];
      technicians.forEach(() => tempGrid.push([[], [], [], [], [], [], []]));
      dispatch(setSchedulerGrid(tempGrid));
    }
    if (_isEqual(toolbar.D_W, DailyWeekly.DAILY)) {
      let tempGrid: any = [];
      technicians.forEach(() => tempGrid.push([null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]));
      dispatch(setSchedulerGridDaily(tempGrid));
    }
  };
};

export const populateAssignedServiceRequest = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const technicians: Array<Technician> = dispatch(getTechniciansGrid()).payload;
    const assignedServiceRequest: Array<ServiceRequest> = dispatch(getAssignedServiceRequests()).payload;
    const toolbar: Toolbar = dispatch(getSchedulerToolbar()).payload;
    const sevenDays: Array<Day> = dispatch(getSevenDays()).payload;
    let weeklyGrid: Array<Schedules> = dispatch(getSchedulerGrid()).payload;
    let dailyGrid: any = dispatch(getSchedulerGridDaily()).payload;

    technicians.forEach((technician: Technician) => {
      assignedServiceRequest.forEach(async (serviceRequest: ServiceRequest) => {
        if (_isEqual(technician.id, serviceRequest.technicianId)) {
          const technicianIndex = technicians.findIndex((tech) => _isEqual(tech.id, technician.id));
          const dateTimeStamp: Moment = moment(serviceRequest.scheduleDateTimeStamp);
          const timeStamp = dateTimeStamp.minute() < 29
            ? dateTimeStamp.set('minutes', 0)
            : dateTimeStamp.clone().set('minutes', 0).add(30, "minutes");

          const startTime = timeStamp.format('h:mm A');
          const endTime = timeStamp.clone().add(serviceRequest.durationInMinutes, "minutes").format('h:mm A');
          const collectionIndexes: Array<number> = await dispatch(getIndexTimePeriod(startTime, endTime));
          serviceRequest.caculatedWidth = 80 * collectionIndexes.length
          serviceRequest.timeStamp = { startTime, endTime };
          serviceRequest = contructMemberDetails(serviceRequest);

          if (_isEqual(toolbar.D_W, DailyWeekly.WEEKLY)) {
            const serviceRequestDate = dateTimeStamp.format("MMMM Do YYYY");

            const dayIndex = sevenDays.findIndex(day => _isEqual(day.date, serviceRequestDate));
            if (!_isEqual(dayIndex, -1)) {
              weeklyGrid[technicianIndex][dayIndex].push(serviceRequest);
            }
            dispatch(setSchedulerGrid(weeklyGrid));
          }

          if (_isEqual(toolbar.D_W, DailyWeekly.DAILY)) {
            collectionIndexes.forEach((targetCellIndex: number, itemIndex: number) => {
              if (_isEqual(itemIndex, 0)) {
                dailyGrid[technicianIndex][targetCellIndex] = serviceRequest;
              } else {
                dailyGrid[technicianIndex][targetCellIndex] = "x";
              }
            });
            dispatch(setSchedulerGridDaily(dailyGrid));
          }
        }
      });
    });
  };
};


const contructMemberDetails = (serviceRequest: ServiceRequest) => {


  let member = { name: "", propertyAddress: "" }
  serviceRequest.requestFormValues.forEach((requestFormValue: RequestFormValue) => {
    if (_isEqual(requestFormValue.field.name, "Member")) {
      member.name = requestFormValue.value;
    }

    if (_isEqual(requestFormValue.field.shortName, "PRPA")) {
      member.propertyAddress = requestFormValue.value;
    }

    if (_isEqual(requestFormValue.field.name, "Service Type")) {

      serviceRequest.title = requestFormValue.value.replaceAll('_', ' ');
    }

    if (_isEqual(requestFormValue.field.name, "Room")) {
      serviceRequest.title = requestFormValue.value.toUpperCase();;
    }

    if (_isEqual(requestFormValue.field.name, "Item")) {
      serviceRequest.title = requestFormValue.value.toUpperCase();
    }
  });

  serviceRequest.members = member;
  return serviceRequest;
};

const getJsonTime = async (toolbar: Toolbar, time: string) => {
  let clock: Array<Time> = [];
  if (_isEqual(toolbar.clockType, ClockType.TWELVE_HOUR)) {
    switch (time) {
      case "12:00 AM":
      case "1:00 AM":
      case "2:00 AM":
      case "3:00 AM":
      case "4:00 AM":
      case "5:00 AM":
      case "6:00 AM":
      case "7:00 AM":
      case "8:00 AM":
      case "9:00 AM":
      case "10:00 AM":
      case "11:00 AM":
        clock = (await import('../../jsons/clock/12hourAM.json')).default;
        break;
      case "12:00 PM":
      case "1:00 PM":
      case "2:00 PM":
      case "3:00 PM":
      case "4:00 PM":
      case "5:00 PM":
      case "6:00 PM":
      case "7:00 PM":
      case "8:00 PM":
      case "9:00 PM":
      case "10:00 PM":
      case "11:00 PM":
        clock = (await import('../../jsons/clock/12hourPM.json')).default;
        break;
      default:
        break;
    };
  }
  if (_isEqual(toolbar.clockType, ClockType.TWENTYFOUR_HOUR)) {
    clock = (await import('../../jsons/clock/24hours.json')).default;
  }
  return clock;
}