import Card from "./Card";
import styles from "./SubscribersSurvey.module.scss";
import ViewMoreLink from "./ViewMoreLink";
import SurveyProgress from "../../../assets/charts/survey.svg";

export default function SubscribersSurvey() {
  return (
    <Card title="Subscribers Survey" gap="small">
      <div className={styles.container}>
        <div className={styles["circle-progress-container"]}>
          <img
            className={styles.progress}
            src={SurveyProgress}
            alt="Survey Progress"
          />
          <div className={styles.center}>
            <div className={styles.percent}>0%</div>
            <div className={styles.complete}>Complete</div>
          </div>
        </div>

        <div className={styles["grid-results"]}>
          <div className={styles.responded}>0</div>
          <div className={styles.label}>Responded</div>

          <div className={styles.waiting}>0</div>
          <div className={styles.label}>Waiting</div>

          <div className={styles.total}>0</div>
          <div className={styles.label}>Total</div>
        </div>
      </div>

      <ViewMoreLink label="View Survey Insights" />
    </Card>
  );
}
