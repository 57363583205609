import React from "react";
import { Modal, Button } from "antd";
import '../../../assets/stylesheets/layout-style/modal-layout.scss'
import { ServiceRequest } from "../../../redux/service-request/model";

import _isEqual from "lodash/isEqual";

interface Props {
   show: boolean;
   setShow: any;
   cancelSingleSR: any;
   cancelSRWithRelated: any;
   grid: any;
   sr?: ServiceRequest;
}
const CancelServiceRequest: React.FC<Props> = (props): JSX.Element => {
   return (
      <div>
         <Modal
            title={'Cancel Service Requests'}
            visible={props.show}
            centered={true}
            className="modalStyle"
            onCancel={() => props.setShow(false)}
            closable={false}
            footer={null}>
            <div>
               {props.sr && props.sr.technicianIds && props.sr.technicianIds?.length > 1 ? (
                  <p style={{ fontSize: 16 }}>
                     {`SR# ${props.sr?.serviceRequestNo} has multiple technicians assigned. Are you sure you `}
                     {`want to cancel it? Cancelling this request will also result in the `}
                     {`cancellation of all related Service Requests.`}
                  </p>
               ) : (
                  <p style={{ fontSize: 16 }}>
                     {`SR# ${props.sr?.serviceRequestNo} has technician assigned. Are you sure you `}
                     {`want to cancel it? `}
                  </p>
               )}
               <div className={"button-container-right"}>
                  <Button
                     style={{
                        backgroundColor: "white",
                        color: "#3A78B3",
                        marginRight: "4px",
                     }}
                     type="primary"
                     onClick={() => props.setShow(false)}
                  >
                     Close
                  </Button>
                  <Button
                     style={{
                        backgroundColor: '#3A78B3',
                        color: 'white'
                     }}
                     onClick={() => {
                        if(props.sr && props.sr.technicianIds && props.sr.technicianIds?.length > 1 ) {
                           props.cancelSRWithRelated()
                        } else {
                           props.cancelSingleSR()
                        }
                     }} type="primary"
                  >
                     {props.sr && props.sr.technicianIds && props.sr.technicianIds?.length > 1 ? 'Cancel All Related SRs' : 'Cancel SR'}
                  </Button>
               </div>
            </div>
         </Modal>
      </div>
   );
}

export default React.memo(CancelServiceRequest);