import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import { List, Button } from "antd";
import { fetchVendorServiceRequestHistory, fetchVendorTechServiceRequestHistory } from "../../redux/technician/service";
import _isqual from "lodash/isEqual";
import _isUndefined from "lodash/isUndefined";
import moment from "moment";
interface ComponentProps {
   type: string;
   user: any;
   loadingIndex: any;
   setConversationType: any;
   setRequestLoadingIndex: any;
   setConversationPayload: any;
   setTechnician: any;
   setShowConversationModal: any;
}
interface ReduxState {
   vendorServReqHistory: any;
   vendorServTechReqHistory: any;
}

interface DispatchProps {
   fetchVendorServiceRequestHistory: (
      id: number,
      a: string,
      i: number,
      v: number,
      t?: number
   ) => void;
   fetchVendorTechServiceRequestHistory: (
      id: number,
      a: string,
      i: number,
      v: number,
      t?: number
   ) => void;
}

type Props = ComponentProps & ReduxState & DispatchProps;

const VendorSRHistoryComponent: React.FC<Props> = (props): JSX.Element => {
   const [id, setId] = React.useState(0);
   const [list, setList] = React.useState([]);
   const [pageIndex, setPageIndex] = React.useState(1);
   const [pageViewSize, setPageViewSize] = React.useState(5);
   const [totalSize, setTotalSize] = React.useState()

   React.useEffect(() => {
      setId(props.user.id | 0);
      setPageIndex(1);
      setPageViewSize(5);
   }, [props.type, props.user.id]);

   React.useEffect(() => {
      fetcher('init');
   }, [id]);

   React.useEffect(() => {
      if (_isqual(props.type, 'vendor-list')) {
         if (!_isUndefined(props.vendorServReqHistory.items)) {
            setList(props.vendorServReqHistory.items);
         }

         if (!_isUndefined(props.vendorServReqHistory.meta)) {
            setTotalSize(props.vendorServReqHistory.meta.totalSize);
         }
      }
   }, [props.vendorServReqHistory.items]);

   React.useEffect(() => {
      if (_isqual(props.type, 'vendor-tech-list')) {
         if (!_isUndefined(props.vendorServTechReqHistory.items)) {
            setList(props.vendorServTechReqHistory.items);
         }

         if (!_isUndefined(props.vendorServTechReqHistory.meta)) {
            setTotalSize(props.vendorServTechReqHistory.meta.totalSize);
         }
      }

   }, [props.vendorServTechReqHistory.items]);

   const fetcher = (action: string) => {
      if (_isqual(props.type, 'vendor-list')) {
         props.fetchVendorServiceRequestHistory(id, action, pageIndex, 25);
      }

      if (_isqual(props.type, 'vendor-tech-list')) {
         props.fetchVendorTechServiceRequestHistory(id, action, pageIndex, 25);
      }
   }

   const getDate = (request: any) => {
      if (request.scheduleDateTimeStamp) {
         const date = moment(request.scheduleDateTimeStamp).format('MMMM DD, YYYY');
         return date;
      } else {
         return '-';
      }
   }

   return (
      <List
         size="small"
         itemLayout="horizontal"
         pagination={{
            current: pageIndex,
            pageSize: pageViewSize,
            showSizeChanger: true,
            showQuickJumper: true,
            onChange: async (page: any, pageSize: any) => {
               setPageIndex(page);
               setPageViewSize(pageSize);
            },
            pageSizeOptions: ['5', '10', '15', '20', '25']
         }}
         dataSource={list}
         renderItem={(obj: any, ndx: any) => (
            <List.Item style={{ display: "flex", flexDirection: "row" }} key={'vendor-history' + obj.id + ndx}>
               <p style={{ flex: 1 }} >SR # {obj.serviceRequestNo}</p>
               <p style={{ flex: 1 }} >{getDate(obj)}</p>
               <p style={{ flex: 1 }}  >{obj.currentStatus}</p>

               {_isqual(props.type, 'vendor-tech-list') && <Button
                  loading={ndx === props.loadingIndex}
                  onClick={() => {
                     props.setRequestLoadingIndex(ndx);
                     props.setConversationType('chat-with-service-request')
                     props.setConversationPayload(obj);
                     props.setTechnician(props.user);
                     localStorage.setItem("chatConversationModal", "IsOpen");
                     props.setShowConversationModal(true);
                  }}
               >Chat</Button>}
            </List.Item>
         )}
      />
   )
};

const mapStateToProps = (states: RootState): ReduxState => {
   return {
      vendorServReqHistory: states.vendorsReducer.state.vendorServReqHistory,
      vendorServTechReqHistory: states.vendorsReducer.state.vendorServTechReqHistory,
   };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
   return {
      fetchVendorServiceRequestHistory: (id, a, i, v, t) => dispatch(fetchVendorServiceRequestHistory(id, a, i, v, t)),
      fetchVendorTechServiceRequestHistory: (id, a, i, v, t) => dispatch(fetchVendorTechServiceRequestHistory(id, a, i, v, t)),
   }
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(VendorSRHistoryComponent));
