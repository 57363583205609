import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";


import { SearchOutlined, EditOutlined, CloseOutlined, CaretDownOutlined, CaretUpOutlined, UploadOutlined, FormOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Modal, List, message, Tag, Divider, Upload } from "antd";
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';

import { ServiceLocation, ZipCodeStatus } from "../../redux/technician/model";
import { deleteServiceLocation, saveServiceLocation, updateServiceLocationRadiusInMiles } from "../../redux/technician/service";
import { getClientId } from "../../redux/auth/actions";

import _isUndefined from "lodash/isUndefined";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";

import { SERVER_URL } from "../../configs/service-config";

import "./service-location.scss";


const { Search } = Input;

interface ComponentProps {
}
interface ReduxState {
   serviceLocations: Array<ServiceLocation>;
}
interface ReduxDispatchProps {
   saveServiceLocation: (serviceLocation: ServiceLocation) => any;
   deleteServiceLocation: (serviceLocation: ServiceLocation) => any;
   updateServiceLocationRadiusInMiles: (rediusInMiles: number) => any;
   getClientId: () => any;
}
type Props = ComponentProps & ReduxState & ReduxDispatchProps;
const ServiceLocationComponent: React.FC<Props> = (props): JSX.Element => {
   const [saveForm] = Form.useForm();
   const [editForm] = Form.useForm();
   const [coverageForm] = Form.useForm();
   let [serviceLocations, setServiceLocations] = React.useState<Array<ServiceLocation>>([]);
   let [serviceLocation, setServiceLocation] = React.useState<ServiceLocation>();

   const [fileList, setFileList] = React.useState<UploadFile[]>([]);
   const [uploading, setUploading] = React.useState(false);

   const [showModal, setShowModal] = React.useState<boolean>(false);
   const [showCoverageModal, setShowCoverageModal] = React.useState<boolean>(false);
   const [deleteModal, setDeleteModal] = React.useState<boolean>(false);

   const [render, setRender] = React.useState<number>(0);
   const [editMode, setEditMode] = React.useState<boolean>(false);
   const [sort, setSort] = React.useState<String>("ASC");
   const [radiusInMiles, setRadiusInMiles] = React.useState<number>(20);
   const [radiusInMilesAll, setRadiusInMilesAll] = React.useState<number>(20);

   React.useEffect(() => {
      setServiceLocations(props.serviceLocations);
   }, [props.serviceLocations, render]);

   const selectedServiceLocationToEdit = (serviceLocation: ServiceLocation) => {
      setServiceLocation(serviceLocation);
      setRadiusInMiles(serviceLocation.radiusInMiles)
      editForm.setFieldsValue(serviceLocation);
   }

   const incrementRadius = () => {
      setRadiusInMiles(radiusInMiles + 10);
   }

   const decrementRadius = () => {
      if (!_isEqual(radiusInMiles, 0)) {
         setRadiusInMiles(radiusInMiles - 10);
      }
   }

   const incrementRadiusAll = () => {
      setRadiusInMilesAll(radiusInMilesAll + 10);
   }

   const decrementRadiusAll = () => {
      if (!_isEqual(radiusInMilesAll, 0)) {
         setRadiusInMilesAll(radiusInMilesAll - 10);
      }
   }

   const onSort = (sortedBy: String) => {
      setSort(sortedBy);
   }

   const searchFilter = (e: any) => {
      var filteredData = props.serviceLocations.filter((serviceLocation: ServiceLocation) => {
         return serviceLocation.zipcode.startsWith(e.target.value);
      });

      setServiceLocations(filteredData);

      if (_isEmpty(e.target.value)) {
         setServiceLocations(props.serviceLocations);
      }
   }

   const changeZipcode = (e: any) => {
      const result = e.target.value.replace(/[^0-9\+\-\(\)]/gi, '');
      saveForm.setFieldsValue({ zipcode: result });
   }


   const deleteServiceLocation = () => {
      if (!_isUndefined(serviceLocation)) {
         var index = serviceLocations.indexOf(serviceLocation);
         if (index !== -1) {
            serviceLocations.splice(index, 1);
            setServiceLocations(serviceLocations);
            setRender(render + 1);
            props.deleteServiceLocation(serviceLocation);
            closeModal();
            message.success("Successfully deleted.");
         }
      }
   }

   const closeModal = () => {
      saveForm.resetFields();
      setServiceLocation(undefined);
      setShowModal(false);
      setDeleteModal(false);
      setEditMode(false);
   }

   const closeCoverageModal = () => {
      setRadiusInMilesAll(20);
      setServiceLocation(undefined);
      setShowCoverageModal(false);
   }


   const handleUpload = () => {
      const formData = new FormData();
      fileList.forEach(file => {
         formData.append('file', file as RcFile);
      });
      setUploading(true);
      // You can use any AJAX library you like
      fetch(`${SERVER_URL.SERVICE_LOCATIONS}/upload`, {
         method: 'POST',
         body: formData,
      })
         .then(res => res.json())
         .then(() => {
            setFileList([]);
            window.dispatchEvent(new Event("refetchServiceLocations"));
            setRender(render + 1);
            message.success('upload successfully.');
            closeModal();
         })
         .catch(() => {
            message.error('upload failed.');
         })
         .finally(() => {
            setUploading(false);
         });
   };

   const updateAllCoverageAreaRadius = async () => {
      const response = await props.updateServiceLocationRadiusInMiles(radiusInMilesAll);
      if(response.status == 200) {
         message.success("Successfully updated.");
         window.dispatchEvent(new Event("refetchServiceLocations"));
         setRender(render + 1);
      } else {
         message.error("An error occured while updating!");
      }
   }

   const save = async (formData: any) => {
      const clientId = await props.getClientId();
      let body: ServiceLocation = {
         id: !_isUndefined(serviceLocation) ? serviceLocation.id : 0,
         clientId,
         radiusInMiles: radiusInMiles,
         status: ZipCodeStatus.AVAILABLE,
         version: !_isUndefined(serviceLocation) ? serviceLocation.version : 0,
         zipcode: formData.zipcode
      }
      const response = await props.saveServiceLocation(body);
      if (!_isUndefined(response)) {
         if (!_isUndefined(serviceLocation)) {
            message.success("Successfully updated.");
         } else {
            message.success("Successfully saved.");
         }
         window.dispatchEvent(new Event("refetchServiceLocations"));
         setRender(render + 1);
      } else {
         if (!_isUndefined(serviceLocation)) {
            message.error("An error occured while updating!");
         } else {
            message.error("An error occured while saving!");
         }
      }
      closeModal();
   }

   const clearEdit = () => {
      setRadiusInMiles(20)
   }

   const uploadProps: UploadProps = {
      onRemove: file => {
         const index = fileList.indexOf(file);
         const newFileList = fileList.slice();
         newFileList.splice(index, 1);
         setFileList(newFileList);
      },
      beforeUpload: file => {
         setFileList([...fileList, file]);

         return false;
      },
      fileList,
   };


   if (_isEqual(sort, "ASC")) {
      serviceLocations.sort(function (a: any, b: any) { return a.zipcode - b.zipcode });
   }
   if (_isEqual(sort, "DSC")) {
      serviceLocations.sort(function (a: any, b: any) { return a.zipcode - b.zipcode }).reverse();
   }

   return (
      <div className="service-location-container">
         <div className="header">
            <h3>Service Locations</h3>
            <div className="actions">
               <Button
                  type="primary"
                  className="action"
                  size="small"
                  onClick={() => setShowCoverageModal(true)}
               >
                  <FormOutlined style={{ fontSize: 12 }} /> MODIFY SERVICE COVERAGE
               </Button>
               <Button
                  type="primary"
                  className="action"
                  size="small"
                  onClick={() => setShowModal(true)}
               >
                  <PlusOutlined style={{ fontSize: 12 }} /> ADD SERVICE LOCATION
               </Button>
            </div>
         </div>

         <div className="content">
            <div className="filter-container">

               <Input placeholder="Search Filters or Tags" prefix={<SearchOutlined />} allowClear onChange={searchFilter} />
               <div className="sub-header">
                  <div className="sorter" onClick={() => onSort(_isEqual(sort, "ASC") ? "DSC" : "ASC")}>
                     <h3>SORT</h3>
                     <div className="sort-icons">
                        {_isEqual(sort, "ASC") ? <CaretDownOutlined /> : <CaretUpOutlined />}
                     </div>
                  </div>
               </div>
               <List
                  className="list-container"
                  grid={{ gutter: 16, column: 1 }}
                  dataSource={serviceLocations}
                  itemLayout="vertical"
                  renderItem={(item) => (
                     <List.Item
                        className={_isEqual(serviceLocation?.id, item.id) ? "list-item-active" : "list-item"}
                        onClick={() => selectedServiceLocationToEdit(item)}
                     >
                        {item.status == ZipCodeStatus.AVAILABLE ? (
                           <Tag style={{ width: 60 }} color="success">Active</Tag>
                        ) : (
                           <Tag style={{ width: 60 }} color="error">Inactive</Tag>
                        )}
                        <label>{item?.zipcode}</label>
                        {/* &nbsp;&nbsp;&nbsp;&nbsp;
                <EditOutlined
                  onClick={() => selectedServiceLocationToEdit(item)}
                  style={{ cursor: "pointer" }}
                /> */}
                     </List.Item>
                  )}
               />
            </div>

            <div className="form-container">
               <h3>EDIT SERVICE LOCATION</h3>
               <Form
                  name="editForm"
                  className="custom-form"
                  form={editForm}
                  layout="vertical"
                  autoComplete="off"
                  colon={false}
                  onFinish={save}>
                  <Form.Item
                     className="custom-form-item"
                     name={"zipcode"}
                     label="Zip Code"
                     required={false}
                     rules={[
                        {
                           pattern: /^(?:\d*)$/,
                           message: "ZIP Code should contain just number",
                        },
                        {
                           min: 5,
                           message: "ZIP Code length should contain 5 characters",

                        },
                        { required: true, message: "ZIP Code is required!" }
                     ]}
                  >
                     <Input
                        placeholder="ZIP Code"
                        onChange={changeZipcode}
                        maxLength={5}
                     />
                  </Form.Item>

                  <Form.Item
                     className="custom-form-item"
                     name={"radiusInMiles"}
                     label="Service Coverage Radius(miles)"
                     required={false}
                  >
                     <div className="custom-input-toggler">
                        <MinusOutlined className="toggler" onClick={() => decrementRadius()} />
                        <Input
                           className="custom-input"
                           readOnly={true}
                           maxLength={5}
                           value={radiusInMiles}
                        />
                        <PlusOutlined className="toggler" onClick={() => incrementRadius()} />
                     </div>
                  </Form.Item>

                  <div className={"button-container-right"} style={{ marginTop: 64 }}>
                     <Button
                        style={{
                           backgroundColor: "white",
                           color: "#343434",
                           marginRight: "12px",
                           borderColor: "#e8e8e8"
                        }}
                        type="primary"
                        onClick={() => clearEdit()}
                     >
                        CANCEL
                     </Button>
                     <Button
                        style={{ backgroundColor: "#3A78B3", color: " white" }}
                        type="primary"
                        htmlType="submit"
                     >
                        SAVE CHANGES
                     </Button>
                  </div>
               </Form>
            </div>
         </div>


         <Modal
            title={editMode ? "Edit Service Location" : "Add Service Location"}
            visible={showModal}
            confirmLoading={false}
            footer={null}
            className="modalStyle"
            onCancel={closeModal}
            closeIcon={<CloseOutlined style={{ fontSize: "10px", position: 'absolute', top: 20, right: 13, color: "black" }} />}
         >
            <Form name="saveForm" form={saveForm} onFinish={save}>
               <h3>ZIP Code</h3>
               <Form.Item
                  name={"zipcode"}
                  rules={[
                     {
                        pattern: /^(?:\d*)$/,
                        message: "ZIP Code should contain just number",
                     },
                     {
                        min: 5,
                        message: "ZIP Code length should contain 5 characters",

                     },
                     { required: true, message: "ZIP Code is required!" }
                  ]}
               >
                  <Input
                     placeholder="ZIP Code"
                     onChange={changeZipcode}
                     maxLength={5}
                  />
               </Form.Item>

               <Form.Item className="button-container">
                  {editMode && (
                     <div className={"button-container-left"}>
                        <Button
                           type="primary"
                           danger
                           onClick={() => {
                              setDeleteModal(true);
                              setShowModal(false);
                           }}
                        >
                           Delete
                        </Button>
                     </div>
                  )}

                  <div className={"button-container-right"}>
                     <Button
                        style={{
                           backgroundColor: "white",
                           color: "#3A78B3",
                           marginRight: "4px",
                        }}
                        type="primary"
                        onClick={closeModal}
                     >
                        Close
                     </Button>
                     <Button
                        style={{ backgroundColor: "#3A78B3", color: " white" }}
                        type="primary"
                        htmlType="submit"
                     >
                        {editMode ? "Update" : "Save"}
                     </Button>
                  </div>
               </Form.Item>
            </Form>
            <Divider plain>OR</Divider>
            <Space align="center" size={45} style={{ width: "100%" }}>
               <div className="upload-title">Upload Service Locations</div>
               <div className="uploads">
                  <Upload {...uploadProps} multiple={false}>
                     <Button
                        style={{
                           backgroundColor: "white",
                           color: "#3A78B3",
                           marginRight: "4px",
                        }}
                        icon={<UploadOutlined />}>Select File</Button>
                  </Upload>
                  <Button
                     style={{ backgroundColor: "#3A78B3", color: " white" }}
                     type="primary"
                     onClick={handleUpload}
                     disabled={fileList.length === 0}
                     loading={uploading}
                  >
                     {uploading ? 'Uploading' : 'Start Upload'}
                  </Button>
               </div>
            </Space>
         </Modal>

         <Modal
            title={"Delete Service Locations"}
            style={{ maxWidth: 300 }}
            visible={deleteModal}
            className="modalStyle"
            confirmLoading={false}
            footer={null}
            onCancel={closeModal}
            closeIcon={<CloseOutlined style={{ fontSize: "10px", position: 'absolute', top: 20, right: 13, color: "black" }} />}
         >
            <div style={{ alignItems: "center", textAlign: "center" }}>
               <div>Are you sure you want to delete</div>
               <div>service location {serviceLocation?.zipcode}?</div>
            </div>
            <div className={"button-container-center"}>
               <Button
                  style={{
                     backgroundColor: "white",
                     color: "#3A78B3",
                     marginRight: "4px",
                  }}
                  type="primary"
                  onClick={closeModal}
               >
                  Cancel
               </Button>
               <Button onClick={deleteServiceLocation} type="primary" danger>
                  Yes, Delete
               </Button>
            </div>
         </Modal>

         <Modal
            title={"Modify Service Coverage"}
            visible={showCoverageModal}
            className="modalStyle custom-modal"
            confirmLoading={false}
            footer={null}
            onCancel={closeCoverageModal}
            closeIcon={<CloseOutlined style={{ fontSize: "10px", position: 'absolute', top: 20, right: 13, color: "black" }} />}
         >
            <Form
               name="editForm"
               className="custom-form"
               form={coverageForm}
               layout="vertical"
               autoComplete="off"
               colon={false}
               onFinish={() => updateAllCoverageAreaRadius()}>
               <Form.Item
                  className="custom-form-item"
                  name={"radiusInMiles"}
                  label={<b>Service Coverage Radius(miles)</b>}
                  required={false}
               >
                  <div className="custom-input-toggler">
                     <MinusOutlined className="toggler" onClick={() => decrementRadiusAll()} />
                     <Input
                        className="custom-input"
                        readOnly={true}
                        value={radiusInMilesAll}
                     />
                     <PlusOutlined className="toggler" onClick={() => incrementRadiusAll()} />
                  </div>
               </Form.Item>
               <div>* This will modify all service locations</div>
               <Form.Item className="button-container">
                  <div className={"button-container-right"}>
                     <Button
                        style={{
                           backgroundColor: "white",
                           color: "#3A78B3",
                           marginRight: "4px",
                        }}
                        type="primary"
                        onClick={() => closeCoverageModal()}
                     >
                        Close
                     </Button>
                     <Button
                        style={{ backgroundColor: "#3A78B3", color: " white" }}
                        type="primary"
                        htmlType="submit"
                     >
                        {"Save"}
                     </Button>
                  </div>
               </Form.Item>
            </Form>
         </Modal>
      </div>
   );
};

const reduxStateToProps = (states: RootState): ReduxState => {
   return {
      serviceLocations: states.technicianReducer.state.serviceLocations,
   };
};

const reduxDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): ReduxDispatchProps => {
   return {
      saveServiceLocation: (a) => dispatch(saveServiceLocation(a)),
      deleteServiceLocation: (a) => dispatch(deleteServiceLocation(a)),
      updateServiceLocationRadiusInMiles: (r: number) => dispatch(updateServiceLocationRadiusInMiles(r)),
      getClientId: () => dispatch(getClientId())
   };
};

export default React.memo(connect(reduxStateToProps, reduxDispatchToProps)(ServiceLocationComponent))
