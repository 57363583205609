import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { SERVER_URL } from "../../configs/service-config";
import { getRequest, getRequestWithJson } from '../interceptor';
import { ClaimHistory } from "./model";
import { setClaimHistory } from "./action";
import { Toolbar } from "../scheduler/model";
import { getSchedulerToolbar } from "../scheduler/action";
import { getClientId } from "../auth/actions";

export const fetchUnassignedSR = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    const toolbar: Toolbar = dispatch(getSchedulerToolbar()).payload;
    const geData = new URL(SERVER_URL.unAssignedSR);
    geData.searchParams.append("clientId", clientId);
    geData.searchParams.append("filterId", toolbar.typeFilter.toString());

    await getRequest(dispatch, geData)
      .then((response: any) => {
        dispatch(setClaimHistory(response));
      })
      .catch((error) => {
        console.log('Error Message -> while fetch unassigned service request', error);
      });
  };
};

export const getClaimsHistory = (serviceRequestId: number): ThunkAction<Promise<Array<ClaimHistory>>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const claimsURI = new URL(`${SERVER_URL.CLAIMS_HISTORY}?serviceRequestId=${serviceRequestId}`);
    let result: Array<ClaimHistory> = [];
    await getRequestWithJson(dispatch, claimsURI)
      .then((response: Array<ClaimHistory>) => {
        result = response;
      })
      .catch((error) => {
        console.log('Error Message -> while get claims history', error);
      });
    return <Array<ClaimHistory>>result;
  };
};

