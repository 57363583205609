import { SET_COMPLETED_TECHNICIAN_SERVICE_REQUEST, SET_BANK_INFORMATION, SET_SERVICE_EXPERIENCE, SET_AVAILABILITIES, SET_TECHNICIAN, SET_TECHNICIANS_BOARD, SET_TECHNICIANS_GRID, SET_TECHNICIANS_SERVICE_REQUEST_COUNT, SET_TECHNICIAN_SERVICE_REQUEST, SET_TECHNICIAN_SERVICE_REQUEST_LOADING, SET_SELECTED_TECHNICIAN_ID, SET_REMOVE_TECHNICIAN_ID, SET_ALL_SELECTED_TECHNICIANS, SET_TYPE_OF_SERVICES, SET_SERVICE_LOCATIONS } from "../action-types";
import { TechnicianModel } from "./model";
import { combineReducers } from "redux";

export interface TechniciansState {
  state: TechnicianModel;
};

const INITIAL_STATE = {
  isTechnicianSRLoading: false,
  techniciansGRID: [],
  techniciansBOARD: [],
  removeTechnicianId: null,
  selectedTechnicianId: null,
  allSelectedTechnicians: [],
  techniciansSRCounts: [],
  technicianSR: [],
  technician: null,
  completedRequests: [],
  bankInformation: null,
  serviceExperience: null,
  availabilities: [],
  typeOfServices: [],
  serviceLocations: [],
};

const state = (state: TechnicianModel = INITIAL_STATE, action: any): TechnicianModel => {
  switch (action.type) {
    case SET_TECHNICIAN:
      return { ...state, technician: action.payload };
    case SET_TECHNICIANS_BOARD:
      return { ...state, techniciansBOARD: action.payload };
    case SET_TECHNICIANS_GRID:
      return { ...state, techniciansGRID: action.payload };
    case SET_REMOVE_TECHNICIAN_ID:
      return { ...state, removeTechnicianId: action.payload };
    case SET_SELECTED_TECHNICIAN_ID:
      return { ...state, selectedTechnicianId: action.payload };
    case SET_ALL_SELECTED_TECHNICIANS:
      return { ...state, allSelectedTechnicians: action.payload };
    case SET_TECHNICIANS_SERVICE_REQUEST_COUNT:
      return { ...state, techniciansSRCounts: action.payload };
    case SET_TECHNICIAN_SERVICE_REQUEST_LOADING:
      return { ...state, isTechnicianSRLoading: action.payload }
    case SET_TECHNICIAN_SERVICE_REQUEST:
      return { ...state, technicianSR: action.payload, isTechnicianSRLoading: false }
    case SET_BANK_INFORMATION:
      return { ...state, bankInformation: action.payload };
    case SET_SERVICE_EXPERIENCE:
      return { ...state, serviceExperience: action.payload };
    case SET_AVAILABILITIES:
      return { ...state, availabilities: action.payload };
    case SET_COMPLETED_TECHNICIAN_SERVICE_REQUEST:
      return { ...state, completedRequests: action.payload };
    case SET_TYPE_OF_SERVICES:
      return { ...state, typeOfServices: action.payload };
    case SET_SERVICE_LOCATIONS:
      return { ...state, serviceLocations: action.payload }
    default:
      return state;
  };
};

export default combineReducers<TechniciansState>({ state });