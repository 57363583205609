import React from "react";

import { useHistory } from "react-router-dom";

import { Menu, Image, Row, Col, Divider } from "antd";

import { LineChartOutlined, HomeOutlined, ToolOutlined, ShopOutlined, NotificationOutlined, ProfileOutlined, UserOutlined, SettingOutlined } from "@ant-design/icons";

import brand from "../../assets/images/logo.png";

import { VERSION } from "../../configs/service-config";

const SideMenuSection: React.FC = (): JSX.Element => {
  const history = useHistory();
  const [selectedMenu, setSelectedMenu] = React.useState<Array<string>>([
    "Dashboard-menu",
  ]);

  React.useEffect(() => {
    switch (history.location.pathname) {
      // case "/home-management":
      //   setSelectedMenu(["Home-managements-menu"]);
      //   break;
      // case "/service-request":
      //   setSelectedMenu(["Service-requests-menu"]);
      //   break;
      // case "/announcements":
      //   setSelectedMenu(["Announcements-menu"]);
      //   break;
      // case "/reports":
      //   setSelectedMenu(["Reports-menu"]);
      //   break;
      case "/vendors":
        setSelectedMenu(["Vendors-menu"]);
        break;
      case "/scheduler":
        setSelectedMenu(["Scheduler-menu"]);
        break;
      case "/admin":
        setSelectedMenu(["Admin-menu"]);
        break;
      default:
        setSelectedMenu(["Dashboard-menu"]);
        break;
    }
  }, [history.location.pathname]);

  const onSelectMenuItem = (selectedProps: any) => {
    switch (selectedProps.key) {
      // case "Home-managements-menu":
      //   history.push("/home-management");
      //   setSelectedMenu(["Home-managements-menu"]);
      //   break;
      // case "Service-requests-menu":
      //   history.push("/service-request");
      //   setSelectedMenu(["Service-requests-menu"]);
      //   break;
      // case "Announcements-menu":
      //   history.push("/announcements");
      //   setSelectedMenu(["Announcements-menu"]);
      //   break;
      // case "Reports-menu":
      //   history.push("/reports");
      //   setSelectedMenu(["Reports-menu"]);
      //   break;
      //trigger build
      case "Scheduler-menu":
        history.push("/scheduler");
        setSelectedMenu(["Scheduler-menu"]);
        break;
      case "Vendors-menu":
        history.push("/vendors");
        setSelectedMenu(["Vendors-menu"]);
        break;
      case "Admin-menu":
        history.push("/admin");
        setSelectedMenu(["Admin-menu"]);
        break;
      default:
        history.push("/dashboard");
        setSelectedMenu(["Dashboard-menu"]);
        break;
    }
  };

  return (
    <Row className={"side-menu"}>
      <Col className={"fsa-avatar-wrapper"}>
        <Image preview={false} width={100} src={brand} style={{ color: ' red' }} />
        <div className="version">Version {VERSION}</div>
      </Col>
      <Col className={"divider-wrapper"}>
        <Divider type={"horizontal"} />
      </Col>

      <Col className={"side-menu-menu-wrapper"}>
        <Menu
          mode="inline"
          selectedKeys={selectedMenu}
          onSelect={onSelectMenuItem}>
          <Menu.Item key="Dashboard-menu" icon={<LineChartOutlined />}>
            DASHBOARD
          </Menu.Item>
          {/* <Menu.Item key="Home-managements-menu" icon={<HomeOutlined />}>
            HOME MANAGEMENT
          </Menu.Item>
          <Menu.Item key="Service-requests-menu" icon={<ToolOutlined />}>
            SERVICE REQUESTS
          </Menu.Item>
          <Menu.Item key="Announcements-menu" icon={<NotificationOutlined />}>
            ANNOUNCEMENTS
          </Menu.Item>
          <Menu.Item key="Reports-menu" icon={<ProfileOutlined />}>
            REPORTS
          </Menu.Item> */}
          <Menu.Item key="Scheduler-menu" icon={<UserOutlined />}>
            SCHEDULER
          </Menu.Item>
          <Menu.Item key="Vendors-menu" icon={<ShopOutlined />}>
            VENDORS
          </Menu.Item>
          <Menu.Item key="Admin-menu" icon={<SettingOutlined />}>
            ADMIN
          </Menu.Item>
        </Menu>
      </Col>
    </Row>
  );
};

export default React.memo(SideMenuSection);
