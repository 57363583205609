import Card from "./Card";
import styles from "./Payments.module.scss";
import PaymentsChart from "../../../assets/charts/payments.svg";

export default function Payments() {
  return (
    <Card title="Payments" className={styles.payments}>
      <div className={styles.container}>
        <img src={PaymentsChart} alt="Payments Pie Chart" />
        <div className={styles.payments}>
          <div className={styles.row}>
            <div className={styles.bank}>85</div>
            <div className={styles.label}>Bank</div>
          </div>
          <div className={styles.row}>
            <div className={styles["e-wallet"]}>15</div>
            <div className={styles.label}>E-Wallet</div>
          </div>
        </div>
      </div>
    </Card>
  );
}
