import React from "react";
import { connect } from "react-redux";
import { History } from "history";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";

interface OwnProps { history: History; }
interface StateProps { }
interface DispatchProps { }
type Props = OwnProps & StateProps & DispatchProps;
const HomeManagementPage: React.FC<Props> = ({ history }): JSX.Element => {
  return (
    <>
      <h1>HomeManagementPage</h1>
    </>
  );
};

const mapStateToProps = (states: RootState): StateProps => {
  return {};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {};
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(HomeManagementPage));
