import React, { useState, useEffect } from "react";
import { Spin, Table } from "antd";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ServiceRequest } from "../../redux/service-request/model";
import columns from "../../jsons/service-request/service-request-list-table.json";
import { SHOW_TECHNICIAN_LOCATION } from "../../helpers/events";

interface StateProps {
  technicianSR: ServiceRequest[];
  isTechnicianSRLoading: boolean;
}

type Props = StateProps;

const GoogleMaps: React.FC<Props> = ({
  isTechnicianSRLoading,
  technicianSR,
}): JSX.Element => {
  const [row, setRow] = useState<ServiceRequest | null>(null);

  useEffect(() => {
    if (technicianSR.length === 0) {
      setRow(null);
    }
  }, [technicianSR]);

  const setActiveRow = (record: any) => {
    setRow(record);
    window.dispatchEvent(
      new CustomEvent(SHOW_TECHNICIAN_LOCATION, {
        detail: { serviceRequest: record },
      })
    );
  };

  return (
    <div
      style={{ height: 150, cursor: "pointer" }}
      className="map-service-request-list-container"
    >
      <Table
        className={
          technicianSR.length !== 0 ? "map-service-request-list-table" : ""
        }
        rowClassName={(record) =>
          row !== null && row.id === record.id
            ? "map-service-request-list-table-activeRow"
            : ""
        }
        columns={columns}
        dataSource={technicianSR}
        size="small"
        bordered
        onRow={(record) => ({
          onClick: (event) => setActiveRow(record),
        })}
        loading={{ indicator: <Spin />, spinning: isTechnicianSRLoading }}
        pagination={false}
        scroll={{ y: 150 }}
      />
    </div>
  );
};

const mapStateToProps = (states: RootState) => {
  return {
    technicianSR: states.technicianReducer.state.technicianSR,
    isTechnicianSRLoading: states.technicianReducer.state.isTechnicianSRLoading,
  };
};

export default React.memo(connect(mapStateToProps)(GoogleMaps));
