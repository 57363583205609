import React, {useEffect, useState} from "react";

import { FilterOutlined } from '@ant-design/icons';

import { Radio, RadioChangeEvent, Select } from 'antd';

import { connect } from "react-redux";

import { RootState } from "../../redux";

import { ThunkDispatch } from "redux-thunk";

import { Filter, FilterRule, FilterField } from "../../redux/filter/model";

import { setFilter } from "../../redux/filter/action";

import FilterItemSubGroup from "./filter-item-sub-group";

type OwnProps = {
  removable: boolean,
  filterGroupIndex: number,
  deleteGroup: () => void
};

interface StateProps {
  filter?: Filter,
  filterFields: Array<FilterField>;
}

interface DispatchProps {
  setFilter?: (filter:Filter) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

const FilterItemGroup: React.FC<Props> = (props): JSX.Element => {

  const [group, setGroup] = useState<FilterRule>({ruleClass: 'RULE', filterRules: []});
  const [filter, setFilter] = useState<Filter>();
  
  useEffect(() => {
    setFilter(props.filter);
    try {
      if (props.filter) setGroup(props.filter.filterRules[props.filterGroupIndex]);
    } catch (e) {
    }
  }, [
    props.filter,
  ]);


  const addSubGroup = (subGroup:FilterRule) => {

    if (props.filter) {
      let tempGroup = props.filter.filterRules[props.filterGroupIndex];
      const seqNo = tempGroup.filterRules[tempGroup.filterRules.length - 1].seqNo;
      subGroup.seqNo = seqNo ? seqNo + 1 : 0;
      tempGroup.filterRules.push(subGroup);
      if (props.setFilter) props.setFilter({...props.filter});
    }
  }

  const deleteSubGroup = (index:number) => {

    if (props.filter) {

      if (index > 0) { // delete the subgroup
        let tempGroup = props.filter.filterRules[props.filterGroupIndex];
        tempGroup.filterRules.splice(index, 1);
        if (props.setFilter) props.setFilter({...props.filter});
      
      } else { // since it's the first subgroup in the group then delete the group instead
        props.deleteGroup();
      }
    }
  }

  const operationChanged = (value:RadioChangeEvent) => {

    if (props.filter) {
      props.filter.filterRules[props.filterGroupIndex].operator = value.target.value;
      if (props.setFilter) props.setFilter({...props.filter});
    }
  }

  const getOperatorStyle = (highlighted:boolean) => {
    if (highlighted) {
      return {
        background: 'purple',
        borderColor: 'purple',
        color: 'white'
      }
    } else {
      return {};
    }
  }

  return (
    <div className="filter-group">
      {null != group.operator ?
        <div className="group-operator-container">
          <div className="group-operator-buttons">
          <Radio.Group value={group.operator} onChange={operationChanged}>
            <Radio.Button value="AND" style={getOperatorStyle(group.operator === 'AND')}>AND</Radio.Button>
            <Radio.Button value="OR" style={getOperatorStyle(group.operator === 'OR')}>OR</Radio.Button>
          </Radio.Group>
          &nbsp;
          </div> 
        </div>: null}
      <div className="filter-group-container">
        <div className="filter-icon">
          <FilterOutlined />
        </div>
        <div className="rules-container">
          {group.filterRules.map((subGroup, index) => {
            return <FilterItemSubGroup 
                removable={index != 0 || props.removable}
                key={index} 
                filterGroupIndex={props.filterGroupIndex}
                filterSubGroupIndex={index}
                filterGroup={subGroup} 
                addSubGroup={(newSubGroup) => addSubGroup(newSubGroup)}
                deleteSubGroup={() => deleteSubGroup(index)}
                />
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (states: RootState): StateProps => {
  return {
    filter: states.filterReducer.state.filter,
    filterFields: states.filterReducer.state.filterFields,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
 
  return {
    setFilter: (filter) => dispatch(setFilter(filter)),
  };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(FilterItemGroup));
