import { SET_UNASSIGNED_SR, GET_UNASSIGNED_SR } from "../action-types";
import { GetUnassignedSR, SetUnassignedSR, UnassignedSR } from "./model";
import store from "..";

export const getUnassignedSRs = (): GetUnassignedSR => {
  return {
    type: GET_UNASSIGNED_SR,
    payload: store.getState().unassignedSRReducer.state.unassignedSR
  };
};

export const setUnassignedSRs = (payload: Array<UnassignedSR>): SetUnassignedSR => {
  return {
    type: SET_UNASSIGNED_SR,
    payload
  };
};