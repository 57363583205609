import type { Status } from "../types";
import styles from "./StatusChip.module.scss";

interface Props {
  status: Status;
}

export default function StatusChip({ status }: Props) {
  return (
    <div className={`${styles.status} ${styles[titleCaseToKebabCase(status)]}`}>
      {status}
    </div>
  );
}

function titleCaseToKebabCase(input: string): string {
  return input.replace(/\s+/g, "-").toLowerCase();
}
