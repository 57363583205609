import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { SERVER_URL } from "../../configs/service-config";
import { DailyWeekly, SchedulerType, Time, Toolbar } from "../scheduler/model";
import { AssignServiceRequest, RequestFormValue, ServiceRequest } from "./model";
import { getClock, getSchedulerToolbar, getSchedulerType } from "../scheduler/action";
import { deleteRequest, getRequest, getRequestWithJson, postRequest, postRequestWithJson } from "../interceptor";
import { setAssignedServiceRequests } from "./action";
import { getClientId } from "../auth/actions";
import { getSearchType, getSearchValue } from "../filter/action";
import { SearchType } from "../filter/model";
import { notification } from "antd";

import _isUndefined from "lodash/isUndefined";
import _isEqual from "lodash/isEqual";
import _isNull from "lodash/isNull";
import _isEmpty from "lodash/isEmpty";
import moment from "moment";

const response = async (data?: any, type?: string,): Promise<any> => {
   switch (data.status) {
      case 200:
         if (_isEqual(type, 'blob')) {
            return {
               statusCode: 200,
               hasError: false,
               errorMsg: '',
               body: await data.blob(),
            };
         }

         return {
            statusCode: 200,
            hasError: false,
            errorMsg: '',
            body: await data.json(),
         };
      case 201:
         return {
            statusCode: 201,
            hasError: false,
            errorMsg: "",
            body: await data.json(),
         };
      case 500:
         return {
            statusCode: 500,
            hasError: true,
            errorMsg: "Internal Server Error",
            body: await data.json(),
         };
      case 400:
         return {
            statusCode: 400,
            hasError: true,
            errorMsg: "Bad Request",
            body: await data.json(),
         };
      case 401:
         return {
            statusCode: 401,
            hasError: true,
            errorMsg: "Invalid Authentication",
            body: await data.json(),
         };
      case 404:
         return {
            statusCode: 404,
            hasError: true,
            errorMsg: "Not Found",
            body: await data.json(),
         };
      default:
         return {
            statusCode: undefined,
            hasError: true,
            errorMsg: "Cannot read error ",
            body: data,
         };
   }
};

export const fetchAllNotes = (serviceId: string): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = `${SERVER_URL.NOTES}?serviceRequestId=${serviceId}`;
      let result: any = null;
      await getRequest(dispatch, endpoint)
         .then((response) => {
            result = response;
         })
         .catch((error: any) => {
            result = error;
            console.log('Error Message -> while fetching all notes', error);
         });

      const finalResponse = await response(result);
      return finalResponse;
   };
};

export const serviceRequestById = (serviceId: string,): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = SERVER_URL.SERVICE_REQUEST_BYID_ENDPOINT + serviceId;
      let result: any = null;
      await getRequest(dispatch, endpoint)
         .then((response) => {
            result = response;
         })
         .catch((error: any) => {
            result = error;
            console.log('Error Message -> while fetching service request by id', error);
         });

      const finalResponse = await response(result);
      return finalResponse;
   };
};

export const serviceRequestByNo = (serviceNo: string): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = `${SERVER_URL.SERVICE_REQUEST_BYID_ENDPOINT}no/${serviceNo}`
      let result: any = null;
      await getRequest(dispatch, endpoint)
         .then((response) => {
            result = response;
         })
         .catch((error: any) => {
            result = error;
            console.log('Error Message -> while fetching service request by No', error);
         });

      const finalResponse = await response(result);
      return finalResponse;
   };
};

export const getServiceTypeService = (id: string): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = `${SERVER_URL.admin}/service-requests/request-fields?serviceTypeId=${id}`;

      let result: any = null;
      await getRequest(dispatch, endpoint)
         .then((response) => {
            result = response;
         })
         .catch((error: any) => {
            result = error;
            console.log('Error Message -> while fetching service request by No', error);
         });

      const finalResponse = await response(result);
      return finalResponse;
   };
};

export const cancelServiceRequestById = (serviceId: string,): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = SERVER_URL.SERVICE_REQUEST_BYID_ENDPOINT + serviceId;
      let result: any = null;
      await deleteRequest(dispatch, endpoint)
         .then((response) => {
            result = response;
         })
         .catch((error: any) => {
            result = error;
            console.log('Error Message -> while fetching service request by No', error);
         });

      const finalResponse = await response(result);
      return finalResponse;
   };
};

export const cancelServiceRequestWithRelated = (serviceRequestNo: string,): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = SERVER_URL.SERVICE_REQUEST_BYID_ENDPOINT + 'cancel/' + serviceRequestNo;
      let result: any = null;
      await deleteRequest(dispatch, endpoint)
         .then((response) => {
            result = response;
         })
         .catch((error: any) => {
            result = error;
            console.log('Error Message -> while fetching service request by No', error);
         });

      const finalResponse = await response(result);
      return finalResponse;
   };
};

export const assignServiceRequest = (assignSR: AssignServiceRequest): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const assignServiceRequestEndpoint = new URL(SERVER_URL.ASSIGN_SERVICE_REQUEST);
      let result: any = null;
      await postRequestWithJson(dispatch, assignServiceRequestEndpoint, { body: JSON.stringify(assignSR) })
         .then((response: any) => {
            result = response;
         })
         .catch((error: any) => {
            console.log('Error Message -> while assigning service request', error);
         });

      return result;
   };
};

export const unassignServiceRequest = (id: number): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const unassignServiceRequestEndpoint = new URL(SERVER_URL.UNASSIGN_SERVICE_REQUEST + id);
      let result: any = null;
      await postRequestWithJson(dispatch, unassignServiceRequestEndpoint)
         .then((response: any) => {
            result = response;
         })
         .catch((error: any) => {
            console.log('Error Message -> while unassigning service request', error);
         });

      return result;
   };
};

export const fetchAssignedServiceRequest = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const clientId: any = dispatch(getClientId());
      const toolbar: Toolbar = dispatch(getSchedulerToolbar()).payload;
      const fetchAssignnedServiceRequestEndpoint = new URL(SERVER_URL.ASSIGNEDSR);
      const schedulerType: SchedulerType = dispatch(getSchedulerType()).payload;
      const q: string = dispatch(getSearchValue()).payload;
      const searchType: SearchType | null = dispatch(getSearchType()).payload;

      if (_isEqual(toolbar.D_W, DailyWeekly.DAILY)) {
         const currentDate = moment(toolbar.dateFilter).format("YYYYMMDDZ");
         fetchAssignnedServiceRequestEndpoint.searchParams.append("clientId", clientId);
         fetchAssignnedServiceRequestEndpoint.searchParams.append("startDate", currentDate.toString());
         fetchAssignnedServiceRequestEndpoint.searchParams.append("endDate", currentDate.toString());
      }
      if (_isEqual(toolbar.D_W, DailyWeekly.WEEKLY)) {
         const date = toolbar.dateFilter.clone().startOf("week");
         const startDate = moment(date).format("YYYYMMDDZ");
         const endDate = moment(date).add(6, 'days').format("YYYYMMDDZ");
         fetchAssignnedServiceRequestEndpoint.searchParams.append("clientId", clientId);
         fetchAssignnedServiceRequestEndpoint.searchParams.append("startDate", startDate.toString());
         fetchAssignnedServiceRequestEndpoint.searchParams.append("endDate", endDate.toString());
      }

      if (!_isNull(toolbar.typeFilter) && _isEqual(schedulerType, SchedulerType.GRID)) {
         fetchAssignnedServiceRequestEndpoint.searchParams.append("filterId", toolbar.typeFilter.toString());
      }

      if (!_isEmpty(q) && _isEqual(schedulerType, SchedulerType.GRID)) {
         fetchAssignnedServiceRequestEndpoint.searchParams.append("q", q);
         fetchAssignnedServiceRequestEndpoint.searchParams.append("searchType", searchType);
      }

      await getRequestWithJson(dispatch, fetchAssignnedServiceRequestEndpoint)
         .then((response: any) => {
            dispatch(setAssignedServiceRequests(response))
         })
         .catch((error) => {
            console.log('Error Message -> while fetching assigned service request', error);
         });
   };
};

export const fetchCurrentServiceRequest = (technicianId: number): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const toolbar: Toolbar = dispatch(getSchedulerToolbar()).payload;
      const currentDate: any = moment(toolbar.dateFilter, "MMMM Do YYYY, h:mm:ss a");
      let time = currentDate.minute();
      let subtractedDate = null;
      if (currentDate.minute() > 29) {
         subtractedDate = moment(currentDate).subtract(time, "minutes").add(30, "minutes");
      } else {
         subtractedDate = moment(currentDate).subtract(time, "minutes");
      }
      const currentDateTimeStamp = moment(subtractedDate).format("YYYYMMDDhhmmaZ")
      const fetchCurrentServiceRequestEndpoint = new URL(SERVER_URL.TECHNICIAN_CURRENT_SR);
      fetchCurrentServiceRequestEndpoint.searchParams.append("technicianId", technicianId.toString());
      fetchCurrentServiceRequestEndpoint.searchParams.append("dateTimestamp", currentDateTimeStamp);
      const serviceRequest = await getRequest(dispatch, fetchCurrentServiceRequestEndpoint);
      if (serviceRequest) {
         const scheduleDateTimeStamp: any = moment(moment(serviceRequest.scheduleDateTimeStamp), "MMMM Do YYYY, h:mm:ss a");
         const startTime: any = moment(scheduleDateTimeStamp).format("h:mm A");
         const endTime: any = moment(scheduleDateTimeStamp).add(serviceRequest.durationInMinutes, "minutes").format("h:mm A");
         serviceRequest.timeStamp = { startTime, endTime };
         let member = {
            name: "",
            propertyAddress: ""
         }
         if(!_isUndefined(serviceRequest.requestFormValues)) {
            serviceRequest.requestFormValues.forEach((formValues: RequestFormValue) => {
               if (_isEqual(formValues.field.name, "Member Name")) {
                  member.name = formValues.value;
               }
               if (_isEqual(formValues.field.name, "Property Name")) {
                  member.propertyAddress = formValues.value;
               }
               if (_isEqual(formValues.field.name, "Service Type")) {
                  serviceRequest.title = formValues.value.replaceAll('_', ' ');
               }
               if (_isEqual(formValues.field.name, "Item")) {
                  serviceRequest.title = formValues.value.toUpperCase();
               }
            });
         }
         serviceRequest.members = member;
         return serviceRequest;
      } else {
         return null;
      }
   };
};

export const getIndexTimePeriod = (startTime: string, endTime: string): ThunkAction<Promise<Array<number>>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const clock: Array<Time> = dispatch(getClock()).payload;
      const startTimeIndex = clock.findIndex((time) => _isEqual(time.time, startTime));
      const endTimeIndex = clock.findIndex((time, index) => _isEqual(time.time, endTime) && index > startTimeIndex);
      let timeIndexes: Array<number> = [];
      if (_isEqual(startTimeIndex, -1) || _isEqual(endTimeIndex, -1)) { return timeIndexes; }
      const timeRange = clock.slice(startTimeIndex, endTimeIndex);
      timeRange.forEach((time2) => {
         const ndx: number = clock.findIndex((time1) => _isEqual(time1.time, time2.time))
         timeIndexes.push(ndx);
      });
      return timeIndexes;
   };
};

export const getSRCloseForm = (serviceRequestId: string): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = `${SERVER_URL.CLOSE_FORM}?serviceRequestId=${serviceRequestId}`;
      let result: any = null;
      await getRequest(dispatch, endpoint)
         .then((response) => {
            result = response;
         })
         .catch((error: any) => {
            result = error;
            console.log('Error Message -> while fetching all notes', error);
         });

      const finalResponse = await response(result);
      return finalResponse;
   };
};

export const getRandomBGColor = (): any => {
   const color: string = '#' + (Math.random().toString(16) + "000000").substring(2, 8)
   return color;
};

export const updateServiceRequestStatus = (updatedServiceRequestStatus: any): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = `${SERVER_URL.ADMIN_SERVICE_REQUEST}/status`;
      let result: any = null;
      await postRequest(dispatch, endpoint, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify(updatedServiceRequestStatus),
      })
         .then((response) => {
            result = response;
         })
         .catch((error: any) => {
            result = error;
            console.log('Error Message -> while updating service request status', error);
         });

      const finalResponse = await response(result);
      return finalResponse;
   };
};

export const acceptServiceRequest = (serviceId: any, technicianId: any): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = `${SERVER_URL.ACCEPT_SERVICE_REQUEST}`;

      let result: any = null;
      await postRequest(dispatch, endpoint, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            technicianId: technicianId,
            serviceRequestId: serviceId,
         }),
      })
         .then((response) => {
            result = response;
         })
         .catch((error: any) => {
            result = error;
            console.log('Error Message -> while accepting service request', error);
         });

      const finalResponse = await response(result);
      return finalResponse;
   };
};

export const submitCloseForm = (updatedCloseForm: any,): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = `${SERVER_URL.CLOSE_FORM}`;
      let result: any = null;
      await postRequest(dispatch, endpoint, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify(updatedCloseForm),
      })
         .then((response) => {
            result = response;
         })
         .catch((error: any) => {
            result = error;
            console.log('Error Message -> while submitting close form', error);
         });

      const finalResponse = await response(result);
      return finalResponse;
   };
};

export const addNotes = (notesData: any): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = `${SERVER_URL.NOTES}`;
      let result: any = null;
      await postRequest(dispatch, endpoint, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify(notesData),
      })
         .then((response) => {
            result = response;
         })
         .catch((error: any) => {
            result = error;
            console.log('Error Message -> while adding notes', error);
         });

      const finalResponse = await response(result);
      return finalResponse;
   };
};


export const flagException = (exception: any): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = `${SERVER_URL.SERVICE_REQUEST_BYID_ENDPOINT}` + 'exception';
      let result: any = null;
      await postRequest(dispatch, endpoint, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify(exception),
      })
         .then((response) => {
            result = response;
         })
         .catch((error: any) => {
            result = error;
            console.log('Error Message -> while flagging exceptions', error);
         });

      const finalResponse = await response(result);
      return finalResponse;
   };
};

export const saveServiceRequest = (data: any): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = `${SERVER_URL.admin}/admin/service-requests`;
      let result = null;
      await postRequestWithJson(dispatch, endpoint, {
         headers: {
            "Content-Type": "application/json"
         },
         method: "POST",
         body: JSON.stringify(data)
      })
         .then((response: any) => {
            openNotification('Success!', 'Service request created', 'success');
            result = response;
         })
         .catch((error: any) => {
            console.log('Error Message -> while saving service request', error);
         });
      return result;
   };
};

export const postImportServiceRequest = (clientId: number, data: string): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = `${SERVER_URL.admin}/admin/service-requests/upload`;
      openNotification('Please wait!', 'Importing Service Requests...', 'waiting');
      let rawData = {
         data: data.split(',')[1],
         clientId: clientId
      }

      let result = null;
      await postRequest(dispatch, endpoint, {
         headers: {
            "Content-Type": "application/json",
         },
         method: "POST",
         body: JSON.stringify(rawData)
      })
         .then((response: any) => {
            result = response;
            openNotification('Success!', 'Service request created', 'success');
            if (response['status'] == 500) {
               openNotification('Failed!', 'Something went wrong!', 'error');

            }
            if (response['status'] == 200) {
               setTimeout(() => {
                  openNotification('Success!', 'The Service Requests have been imported', 'success');
               }, 1000)

            }
         })
         .catch((error: any) => {
            console.log('Error Message -> while importing service request', error);
         });
      return result;
   };
};

export const bidout = (data: any): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = `${SERVER_URL.admin}/service-requests/bid-out`;
      openNotification('Please wait!', 'Bidding Out', 'waiting');
      let result = null;
      await postRequestWithJson(dispatch, endpoint, {
         headers: {
            "Content-Type": "application/json"
         },
         method: "POST",
         body: JSON.stringify(data)
      })
         .then((response: any) => {
            result = response;
            openNotification('Success!', 'Service request created', 'success');
            if (response['status'] == 500) {
               openNotification('Failed!', 'Something went wrong!', 'error');

            }
            if (response['status'] == 200) {
               setTimeout(() => {
                  openNotification('Success!', 'Service request created', 'success');
               }, 1000)

            }
         })
         .catch((error: any) => {
            openNotification('Bidding Failed!', 'No Technicians Found!', 'error');
            console.log('Error Message -> while bidout', error);
         });
      return result;
   };
};

const openNotification = (title: string, description: string, type: string) => {
   const args = {
      message: title,
      description:
         description,
      duration: 2,
   };
   switch (type) {
      case 'success':
         notification.success(args);
         break;
      case 'error':
         notification.error(args);
         break;
      case 'waiting':
         notification.info(args);
         break;
      default:
         notification.success(args);
         break;
   }
};