import React, { useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import { Button, DatePicker, Radio, RadioChangeEvent, Tooltip } from "antd";
import { CaretLeftOutlined, CaretRightOutlined, PlusOutlined, CloseSquareFilled } from "@ant-design/icons";
import FilterShelves from "../filter-builder/filter-shelves";
import { Toolbar } from "../../redux/scheduler/model";
import { Filter } from "../../redux/filter/model";
import { setSchedulerToolbar } from "../../redux/scheduler/action";
import LegendComponent from "./legend";

import _isEqual from "lodash/isEqual";
import _isNull from "lodash/isNull";
import moment from "moment";
import { fetchFilterFields, fetchUserFilters } from "../../redux/filter/service";
import { setSearchSRNumber } from "../../redux/filter/action";

interface OwnProps { isScheduler: boolean, type: string }
interface StateProps { toolbar: Toolbar; filters: Array<Filter> }
interface DispatchProps { setToolbar: (params: Toolbar) => void; setFilterFields: (filterClass: string) => void; setUserFilters: () => void; setSearchSRNumber: (q: string) => void; }
type Props = OwnProps & StateProps & DispatchProps;
const ToolbarComponent: React.FC<Props> = ({ isScheduler, toolbar, filters, setToolbar, setFilterFields, setUserFilters, setSearchSRNumber, type }): JSX.Element => {
  const [isFilterShelvesShow, setIsFilterShelvesShow] = useState(false);
  const [filterName, setFilterName] = useState("")
  const filterEl = React.useRef<HTMLInputElement>(null);

  const onChangePresetFilter = (event: RadioChangeEvent) => {
    const { value: typeFilter } = event.target;
    const { dateFilter, clockType, D_W } = toolbar;
    setToolbar({ typeFilter, dateFilter, clockType, D_W });
    setSearchSRNumber("");
    window.dispatchEvent(new Event("reloadScheduler"));
    window.dispatchEvent(new Event("reloadSchedulerSideBar"));
  };

  const onChangeDailyWeekly = (event: RadioChangeEvent) => {
    const { value: D_W } = event.target;
    const { typeFilter, dateFilter, clockType } = toolbar;
    setToolbar({ typeFilter, dateFilter, clockType, D_W });
    window.dispatchEvent(new Event("reloadSchedulerGridComponent"));
  };

  const onChangeClockType = (event: RadioChangeEvent) => {
    const { value: clockType } = event.target;
    const { typeFilter, dateFilter, D_W } = toolbar;
    setToolbar({ typeFilter, dateFilter, clockType, D_W });
    window.dispatchEvent(new Event("reloadSchedulerGridComponent"));
  };

  const onChangeDate = (date: any) => {
    const dateFilter = moment(date, "LL");
    const { typeFilter, clockType, D_W } = toolbar;
    setToolbar({ typeFilter, dateFilter, clockType, D_W });
    window.dispatchEvent(new Event("reloadScheduler"));
  };

  const onImportSR = () => {
    window.dispatchEvent(new Event("openImportRequest"));
  };

  const onCreateSR = () => {
    window.dispatchEvent(new Event("openCreateRequest"));
  };

  function setFilterShelves(): void {

    const isFilterShelvesShowed = !isFilterShelvesShow;
    setIsFilterShelvesShow(isFilterShelvesShowed);
    setFilterFields(type);
    setUserFilters();
  }

  const filterScroll = (arrow: string) => {
    if (_isEqual(arrow, 'left')) {
      if (filterEl && filterEl.current) {
        filterEl.current.scrollLeft = filterEl.current.scrollLeft - 100;
      }
    }
    if (_isEqual(arrow, 'right')) {
      if (filterEl && filterEl.current) {
        filterEl.current.scrollLeft = filterEl.current.scrollLeft + 100;
      }
    }
  }

  const changeFilterName = (name: any) => {
    setFilterName(name)
  }

  const removeFilter = () => {
    setFilterName("")
    const { dateFilter, clockType, D_W } = toolbar;
    setToolbar({ typeFilter: null, dateFilter, clockType, D_W });
    window.dispatchEvent(new Event("reloadScheduler"));
    window.dispatchEvent(new Event("reloadSchedulerSideBar"));
  }

  const presetFilterBuilder = () => {
    return (
      <div className='toolbar-ls-preset-filter'>
        <CaretLeftOutlined onClick={() => filterScroll('left')} className='toolbar-ls-preset-filter-arrow-l' />
        <Radio.Group ref={filterEl} className='toolbar-ls-preset-filter-content'
          style={window.screen.height < 865 ? { maxWidth: '380px' } : { maxWidth: '400px' }}
          value={toolbar.typeFilter}
          onChange={onChangePresetFilter}
        >
          <Radio.Button value={0}>ALL</Radio.Button>
          {filters.map((filter: Filter, index: number) => (_isEqual(filter.preset, true) && (_isEqual(filter.filterClass, type) || _isEqual(filter.filterClass, 'BOTH'))) && <Radio.Button key={index} value={filter.id}>{filter.name}</Radio.Button>)}
        </Radio.Group>
        <CaretRightOutlined onClick={() => filterScroll('right')} className='toolbar-ls-preset-filter-arrow-r' />
      </div>
    )
  }

  const toolbarRightContent = () => {

    if (_isEqual(isScheduler, true)) {
      return (
        <div className='toolbar-rs'>
          <Radio.Group
            className='toolbar-rs-filter'
            value={toolbar.D_W}
            onChange={onChangeDailyWeekly}
          >
            <Radio.Button value="Daily">Daily</Radio.Button>
            <Radio.Button value="Weekly">Weekly</Radio.Button>
          </Radio.Group>
          <Radio.Group
            className='toolbar-rs-filter'
            value={toolbar.clockType}
            onChange={onChangeClockType}
          >
            <Radio.Button value="12hour">12-hour</Radio.Button>
            <Radio.Button value="24hour">24-hour</Radio.Button>
          </Radio.Group>
          <div className='toolbar-rs-btns'>

            <Button
              style={{ 'backgroundColor': '#3A78B3', 'color': ' white' }}
              className={"create-btn"}
              onClick={onImportSR}>
              Import SR
            </Button>

            <Button
              style={{ 'backgroundColor': '#3A78B3', 'color': ' white' }}
              className={"create-btn"}
              icon={<PlusOutlined />}
              onClick={onCreateSR}>
              Create SR
            </Button>
          </div>
        </div>
      )
    }

    if (_isEqual(isScheduler, false)) {
      return (
        <div className='toolbar-rs'>
          <LegendComponent />
        </div>
      )
    }
  }

  return (
    <div className='toolbar'>
      <div className='toolbar-ls'>
        <div className='toolbar-ls-filter'>
          <section className={"text-bold2"}>Filter:</section>
          <section className="pointer" onClick={_ => setFilterShelves()}>
            &#160;&#160;{filterName ? filterName
              : "My New Filter "}&#160;
          </section>
          {filterName ?
            <Tooltip title="Remove filter" placement="right" >
              <CloseSquareFilled style={{
                marginLeft: '5px',
                fontSize: '20px',
                marginTop: '2px',
                color: '#1e5c8bff',
                cursor: "pointer"
              }} onClick={() => removeFilter()}
              />
            </Tooltip>
            : null}
        </div>
        {isFilterShelvesShow &&
          (<FilterShelves
            changeFilterName={changeFilterName}
            setFilterShelves={setFilterShelves}
            isFilterShelvesShow={isFilterShelvesShow}
            type={type}
          />)}
        {presetFilterBuilder()}
        <DatePicker
          className='toolbar-date-filter'
          value={moment(toolbar.dateFilter, "LL")}
          picker="week"
          format={"LL"}
          allowClear={false}
          onChange={onChangeDate}
        />
      </div>
      {toolbarRightContent()}
    </div>
  )
};

const mapStateToProps = (states: RootState): StateProps => {
  return {
    toolbar: states.schedulerReducer.state.toolbar,
    filters: states.filterReducer.state.filters
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    setToolbar: (params) => dispatch(setSchedulerToolbar(params)),
    setFilterFields: (filterClass) => dispatch(fetchFilterFields(filterClass)),
    setUserFilters: () => dispatch(fetchUserFilters()),
    setSearchSRNumber: (q) => dispatch(setSearchSRNumber(q))
  };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(ToolbarComponent));


