import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { SERVER_URL } from "../../configs/service-config";
import { Filter } from "./model";
import { setFilters, setFilterFields, setUserFilters, setLoadingUserFilters, setLoadingFilterFields } from "./action";
import { getRequest, getRequestWithJson, postRequest, postRequestWithJson } from '../interceptor'
import { getClientId } from "../auth/actions";

export const fetchFilters = (q?: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		const clientId: any = dispatch(getClientId());
		const saveFilterURI = new URL(SERVER_URL.filters);
		saveFilterURI.searchParams.append("clientId", clientId);
		if (q) saveFilterURI.searchParams.append("q", q);
		getRequestWithJson(dispatch, saveFilterURI).then((response: any) => {
			dispatch(setFilters(response));
		}).catch((error) => {
			console.log('Error Message -> while fetching filters', error);
		});
	};
};

export const fetchFilterFields = (filterClass: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(setLoadingFilterFields(true))
		const clientId: any = dispatch(getClientId());
		const fetchFilterFieldsURI = new URL(SERVER_URL.filterFields);
		fetchFilterFieldsURI.searchParams.append("clientId", clientId);
		fetchFilterFieldsURI.searchParams.append("filterClass", filterClass);
		getRequestWithJson(dispatch, fetchFilterFieldsURI).then((response: any) => {
			dispatch(setFilterFields(response));
		}).catch((error) => {
			console.log('Error Message -> while fetching filter fields', error);
		});
	};
};

export const saveFilter = (filter: Filter): ThunkAction<Promise<Filter>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		const saveFilterURI = new URL(SERVER_URL.filters);
		let result: any = null;
		await postRequestWithJson(dispatch, saveFilterURI, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(filter)
		})
			.then((response) => {
				result = response;
			})
			.catch((error: any) => {
				console.log('Error Message -> while saving filter', error);
			});
		return <Filter>result;
	};
};

export const fetchUserFilters = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(setLoadingUserFilters(true))
		const fetchUserFiltersURI = new URL(SERVER_URL.userFilters);
		fetchUserFiltersURI.searchParams.append("userId", "2");
		await getRequestWithJson(dispatch, fetchUserFiltersURI).then((response: any) => {
			dispatch(setUserFilters(response))
		}).catch((error) => {
			console.log('Error Message -> while fetching user filters', error);
		});
	};
};

