import React, { useState, useEffect, useRef } from "react";
import GoogleMap from "google-map-react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../redux";
import useSupercluster from "use-supercluster";
import CSS from "csstype";
import SockJS from "sockjs-client";
import Stomp from "stompjs";

// Components
import LocationMarker from "./marker";
import MarkerBox from "./marker-box";

// Actions
import {
  setRemoveTechnicianId,
  setSelectedTechnicianId,
  setTechniciansBoard,
} from "../../redux/technician/action";

// Services
import { getRandomBGColor } from "../../redux/service-request/service";
import { fetchTechniciansServiceRequest } from "../../redux/technician/service";

// Lodash
import _isUndefined from "lodash/isUndefined";
import _isEqual from "lodash/isEqual";

// Configs
import { GOOGLE_KEY, SERVER_URL } from "../../configs/service-config";
import { ServiceRequest } from "../../redux/service-request/model";
import { MapSettings } from "../../helpers/models/map-model";

interface StateProps {
  srTechnicians: any;
  serviceRequest: any;
  technicians: any;
  setLocationDetails: any;
}
interface childrenProps {
  children: JSX.Element;
  lat: number;
  lng: number;
}
const BlankMarker: React.FC<childrenProps> = ({
  children,
  lat,
  lng,
}): JSX.Element => {
  return children;
};

interface LocationProps {
  fullName: string;
  email: string;
  mobileNumber: string;
  lat: number;
  lng: number;
  id: string;
  type: any;
  srNo: any;
}
interface DispatchProps {
  setRemoveTechnicianId: any;
  setSelectedTechnicianId: any;
  getRandomBGColor: any;
  fetchTechnicianServiceRequest: any;
  setTechniciansBoard: any;
}
type Props = StateProps & DispatchProps;

const ServiceRequestMap: React.FC<Props> = (props): JSX.Element => {
  const [technician, setTechnician] = useState<any>(null);
  const [polylinesArray, setPolylinesArray] = useState<any>([]);
  const [initTech, setInitTech] = React.useState<boolean>(false);
  const [
    serviceRequest,
    setServiceRequest,
  ] = React.useState<ServiceRequest | null>(null);
  const [
    defaultMapSettings,
    setDefaultMapSettings,
  ] = React.useState<MapSettings>({
    center: {
      lat: 29.424122,
      lng: -98.493629,
    },
    zoom: 10,
  });

  const [mapDetails, setMapDetails] = React.useState({
    mapsLoaded: false,
    map: null,
    maps: null,
  });
  const [data, setData] = React.useState<any>([]);
  const [locationInfo, setLocationInfo] = React.useState<LocationProps | null>(
    null
  );
  const [isOpenWindow, setInfoBox] = React.useState(false);
  const [zoom, setZoom] = React.useState(10);
  const [bounds, setBounds] = React.useState([] as any);
  const mapRef = useRef<google.maps.Map>();

  useEffect(() => {
    window.addEventListener(
      "locationReceived",
      locationReceived
    );
    return () => {
      window.removeEventListener(
        "locationReceived",
        locationReceived
      );
    };
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      setMapSettings();
      updateMapData();
      setServiceRequest(props.serviceRequest);
    }, 100);
  }, [props.serviceRequest]);

  React.useEffect(() => {
    setTimeout(() => {
      setMapSettings();
      updateMapData();
    }, 100);
  }, [technician]);

  React.useEffect(() => {
    if (mapDetails.mapsLoaded) {
      apiIsLoaded(mapRef.current, mapDetails.maps);
    }
  }, [serviceRequest, technician]);

  const clusterStyle: CSS.Properties = {
    color: "#fff",
    background: "#1978c8",
    borderRadius: "50%",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const points = data.map((technician: any) => ({
    properties: {
      fullName: technician.fullName || technician.technicianName,
      email: technician.email,
      mobileNumber: technician.mobileNumber,
      id: technician.id,
      currentStatus: technician.currentStatus || technician.status,
      type: technician.type,
      serviceRequestNo: technician.serviceRequestNo,
    },
    geometry: {
      coordinates: [technician.lng, technician.lat],
    },
  }));

  //setup the cluster
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 100, maxZoom: 23 },
  });

  const locationReceived = (event: any) => {
    const { technician } = event.detail;
    if (technician) {
      setInitTech(true)
      setTechnician(technician)
    }
  }
  // Setup the settings of the map
  const setMapSettings = async () => {
    const { serviceRequest } = props;
    if (serviceRequest) {
      setDefaultMapSettings({ ...defaultMapSettings });
    } else {
      setDefaultMapSettings({
        center: {
          lat: 29.424122,
          lng: -98.493629,
        },
        zoom: 10,
      });
    }
  };

  const updateMapData = () => {
    if (initTech) {
      if (technician) {
        const existing = data.find((d: any) => d.id == technician.technicianId);
        if (existing) {
          existing.lat = technician.lat;
          existing.lng = technician.lng;
          setData(data);
        }
      }
    } else {
      setData([...props.srTechnicians, props.serviceRequest]);
    }
  };
  // Rerender the polylines on the map
  const apiIsLoaded = async (map: any, maps: any) => {
    const { serviceRequest, setLocationDetails } = props;
    if (polylinesArray.length > 0) {
      polylinesArray.map((d: any) => {
        for (let x = 0; x < d.directions.length; x++) {
          d.directions[x].setMap(null);
        }
      });
      setPolylinesArray([]);
    }
    let poly: any = {
      id: null,
      directions: [],
    };
    let bounds = await new window.google.maps.LatLngBounds();
    let startPoint;
    let endPoint;
    if (serviceRequest && data.length > 0) {
      for (let x = 0; x < data.length; x++) {
        if (data[x].type === "technician") {
          startPoint = new google.maps.LatLng({
            lat: data[x].lat,
            lng: data[x].lng,
          });
          endPoint = new google.maps.LatLng({
            lat: serviceRequest.lat,
            lng: serviceRequest.lng,
          });
          await bounds.extend(startPoint);
          await bounds.extend(endPoint);
          const directionsService = await new google.maps.DirectionsService();
          directionsService.route(
            {
              origin: startPoint,
              destination: endPoint,
              optimizeWaypoints: true,
              travelMode: google.maps.TravelMode.DRIVING,
            },
            async (result: any, status: any) => {
              if (status === google.maps.DirectionsStatus.OK && result) {
                const distanceString = result.routes[0].legs[0].distance.value;
                const distance = (distanceString / 1609.344)
                  .toFixed(2)
                  .toString();
                const duration = result.routes[0].legs[0].duration.text;
                setLocationDetails(data[x].id, distance, duration);
                const routePolyline = await new window.google.maps.Polyline({
                  path: result.routes[0].overview_path,
                  strokeColor: "#3A78B3",
                  strokeOpacity: 1,
                  strokeWeight: 5,
                });
                routePolyline.setMap(map);
                poly.id = data[x].id;
                poly.directions = [...poly.directions, routePolyline];
                setPolylinesArray([...polylinesArray, poly]);
                if (!initTech) {
                  mapRef.current?.fitBounds(bounds);
                }
              } else {
                setDefaultMapSettings({
                  center: {
                    lat: serviceRequest.lat,
                    lng: serviceRequest.lng,
                  },
                  zoom: 10,
                });
              }
            }
          );
        }
      }
    }
    setInitTech(false);
  };

  return (
    <div style={{ flex: 1 }}>
      <GoogleMap
        bootstrapURLKeys={{ key: GOOGLE_KEY }}
        center={defaultMapSettings.center}
        zoom={defaultMapSettings.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          mapRef.current = map;
          apiIsLoaded(map, maps);
          setMapDetails({ ...mapDetails, map, maps, mapsLoaded: true });
        }}
        onChange={async ({ zoom, bounds, center }) => {
          setDefaultMapSettings({
            center: {
              lat: center.lat,
              lng: center.lng,
            },
            zoom,
          });
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount,
          } = cluster.properties;

          if (isCluster && !props.serviceRequest) {
            return (
              <BlankMarker
                key={cluster.properties.id}
                lat={latitude}
                lng={longitude}
              >
                <div
                  style={{
                    ...{
                      width: `${10 + (pointCount / points.length) * 30}px`,
                      height: `${10 + (pointCount / points.length) * 30}px`,
                    },
                    ...clusterStyle,
                  }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20
                    );
                    mapRef.current?.setZoom(expansionZoom);
                    mapRef.current?.panTo({ lat: latitude, lng: longitude });
                  }}
                >
                  {pointCount}
                </div>
              </BlankMarker>
            );
          } else {
            return (
              <LocationMarker
                key={cluster.properties.id}
                lat={latitude}
                lng={longitude}
                type={cluster.properties.type}
                id={cluster.properties.id}
                currentStatus={cluster.properties.currentStatus}
                onClick={() => {
                  setLocationInfo({
                    email: cluster.properties.email,
                    fullName: cluster.properties.fullName,
                    mobileNumber: cluster.properties.mobileNumber,
                    lat: latitude,
                    lng: longitude,
                    id: cluster.properties.id,
                    type: cluster.properties.type,
                    srNo: cluster.properties.serviceRequestNo,
                  });
                  setInfoBox(true);
                }}
              />
            );
          }
        })}
        {/* {mapDetails.mapsLoaded ? afterMapLoadChanges() : ''}   */}
        {isOpenWindow && locationInfo && (
          <MarkerBox
            onClosed={() => setInfoBox(false)}
            lat={locationInfo?.lat}
            lng={locationInfo?.lng}
            type={locationInfo?.type}
            fullName={locationInfo?.fullName}
            srNo={locationInfo?.srNo}
            email={locationInfo?.email}
            mobileNumber={locationInfo?.mobileNumber}
            id={locationInfo.id}
          />
        )}
      </GoogleMap>
    </div>
  );
};

const mapStateToProps = (states: RootState) => {
  return {
    removeTechnicianId: states.technicianReducer.state.removeTechnicianId,
    selectedTechnician: states.technicianReducer.state.selectedTechnicianId,
    technicians: states.technicianReducer.state.techniciansBOARD,
    technicianSR: states.technicianReducer.state.technicianSR,
    allSelectedTechnicians:
      states.technicianReducer.state.allSelectedTechnicians,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    fetchTechnicianServiceRequest: (ids: any) =>
      dispatch(fetchTechniciansServiceRequest(ids)),
    setRemoveTechnicianId: (id: any) => dispatch(setRemoveTechnicianId(id)),
    setSelectedTechnicianId: (id: any) => dispatch(setSelectedTechnicianId(id)),
    getRandomBGColor: () => getRandomBGColor(),
    setTechniciansBoard: (a: any) => dispatch(setTechniciansBoard(a)),
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(ServiceRequestMap)
);
