import React, { useState } from "react";

import {  Form, Input, message } from "antd";

import { SubmitButtonComponent } from "..";

import { useHistory } from "react-router-dom";

import { connect } from "react-redux";

import { RootState } from "../../redux";

import { ThunkDispatch } from "redux-thunk";
import { changePassword, validatePassword } from "../../redux/auth/service";

const formLayout = {
   wrapperCol: {
      xs: {
         offset: 0,
         span: 24,
      },
      sm: {
         offset: 3,
         span: 18,
      },
   },
};

interface OwnProps {
}

interface StateProps {
}

interface DispatchProps {
  changePassword: (password: any, history: any) => any
  validatePassword: (password: any) => any
}

type Props = OwnProps & StateProps & DispatchProps;

const ChangePasswordForm: React.FC<Props> = ({ changePassword, validatePassword }): JSX.Element => {
  
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm();

  const onSubmit = async (values: any) => {
    setIsLoading(true)
    const validation = await validatePassword(values.oldPassword)
    if (validation) {
      if (values.newPassword === values.retypeNewPassword) {
        await changePassword(values.newPassword, history)
        await message.success('Password changed successfully', 3);
        setIsLoading(false)
        history.push("/scheduler");
      } else {
        message.error("New password and confirm password dont match")
        setIsLoading(false)
      }
    } else {
      message.error('Old password does not match')
    }
  };

const onSubmitErr = (errorInfo: any) => {
    console.log('ERROR', errorInfo);
};

  return (
    <Form
      form={form}
      {...formLayout}
      name={"ResetPasswordForm"}
      onFinish={onSubmit}
      onFinishFailed={onSubmitErr}
    >
      <Form.Item
        className="form-item-password"
        name="oldPassword"
        rules={[{ required: true, message: 'Please enter your old password!' }]}
      >
        <Input.Password placeholder="Please enter old password" />
      </Form.Item>
      <Form.Item
        className="form-item-password"
        name="newPassword"
        rules={[{ required: true, message: 'Please enter new password!' }]}
      >
        <Input.Password placeholder="Please enter new password"/>
      </Form.Item>
      <Form.Item
        className="form-item-password"
        name="retypeNewPassword"
        rules={[
          { required: true, message: 'Please re-type new password!' },
          // { validator: }
        ]}
      >
        <Input.Password placeholder="Please confirm new password"/>
      </Form.Item>
      <Form.Item>
        <SubmitButtonComponent label={"SUBMIT"} loading={isLoading} />
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (states: RootState): StateProps => {
  return {
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    validatePassword: (password) => dispatch(validatePassword(password)),
    changePassword: (password, history) => dispatch(changePassword(password, history))
  };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm))
