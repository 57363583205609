import styles from "./PeriodSelectorButton.module.scss";
import ExpandMoreIcon from "../../../assets/fsa-icons/svg/expand-more.svg";

export default function PeriodSelectorButton() {
  return (
    <button className={styles.btn}>
      <div>This month</div>
      <img src={ExpandMoreIcon} alt="Expand More" />
    </button>
  );
}
