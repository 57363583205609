import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { AuthGuardComponent } from "..";

import {
  DashboardPage,
  HomeManagementPage,
  ServiceRequestPage,
  AnnouncementPage,
  ReportsPage,
  SchedulerPage,
  VendorsPage,
  AdminPage,
  LoginPage,
  ChangePasswordPage,
  ResetPasswordPage,
} from "../../pages";

const RouterComponent: React.FC = () => {
  return (
    <Router>
      <Switch>
        <AuthGuardComponent
          exact
          strict
          sensitive
          path={"/dashboard"}
          component={DashboardPage}
        />
        {/* <AuthGuardComponent
          exact
          strict
          sensitive
          path={"/home-management"}
          component={HomeManagementPage}
        /> */}
        {/* <AuthGuardComponent
          exact
          strict
          sensitive
          path={"/service-request"}
          component={ServiceRequestPage}
        /> */}
        {/* <AuthGuardComponent
          exact
          strict
          sensitive
          path={"/announcements"}
          component={AnnouncementPage}
        /> */}
        {/* <AuthGuardComponent
          exact
          strict
          sensitive
          path={"/reports"}
          component={ReportsPage}
        /> */}
        <AuthGuardComponent
          exact
          strict
          sensitive
          path={"/scheduler"}
          component={SchedulerPage}
        />
        <AuthGuardComponent
          exact
          strict
          sensitive
          path={"/vendors"}
          component={VendorsPage}
        />
        <AuthGuardComponent
          exact
          strict
          sensitive
          path={"/admin"}
          component={AdminPage}
        />
        <Route
          exact
          strict
          sensitive
          path="/login/:serviceRequestNo?/:accessToken?"
          component={LoginPage}
        />
        <AuthGuardComponent
          exact
          strict
          sensitive
          path="/change-password"
          component={ChangePasswordPage}
        />
        <Route
          exact
          strict
          sensitive
          path="/reset-password"
          component={ResetPasswordPage}
        />
        <Redirect exact from="/" to="/dashboard" />
      </Switch>
    </Router>
  );
};

export default React.memo(RouterComponent);
