import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import {
  Tabs,
} from "antd";
import { AddVendor, VendorsList, AddVendorTechnician, SetTechnicianPassword } from "../../components";
import _isqual from "lodash/isEqual";
const { TabPane } = Tabs;

interface ComponentProps {}
interface ReduxState {}
interface DispatchProps {}
type Props = ComponentProps & ReduxState & DispatchProps;
const VendorsPage: React.FC<Props> = (props): JSX.Element => {
  const [type, setType] = React.useState("vendorList");
  const [email, selectEmail] = React.useState(null)

  return (
    <Tabs className="scheduler-page" type="card">
      <TabPane key={"vendors"} tab="Vendors">
        <div
          className="background-color-white"
          style={{
            position: "relative",
            borderRadius: 16,
            padding: 16,
            height: "inherit",
            overflowY: "scroll",
            minWidth: 1000,
          }}
        >
          {type === "vendorList" ? <VendorsList setType={setType} /> : null}
          {type === "addVendor" ? <AddVendor setType={setType} /> : null}
          {type === "addVendorTechnician" ? (
            <AddVendorTechnician setType={setType} selectEmail={selectEmail}/>
          ) : null}
           {type === "setTechnicianPassword" ? (
            <SetTechnicianPassword setType={setType} email={email}/>
          ) : null}
        </div>
      </TabPane>
    </Tabs>
  );
};

const mapStateToProps = (states: RootState): ReduxState => {
  return {};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {};
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(VendorsPage)
);
