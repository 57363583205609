import { combineReducers } from "redux";

import { SET_ASSIGNED_SERVICE_REQUESTS, SET_CURRENT_SERVICE_REQUESTS } from "../action-types";

import { ServiceRequestActions, ServiceRequestModel } from "./model";

export interface ServiceRequestState {
  state: ServiceRequestModel;
};

const INITIAL_STATE = {
  assignedServiceRequests: [],
};

const state = (
  state: ServiceRequestModel = INITIAL_STATE,
  action: ServiceRequestActions
): ServiceRequestModel => {
  switch (action.type) {
    case SET_ASSIGNED_SERVICE_REQUESTS:
      return { ...state, assignedServiceRequests: action.payload };
    default:
      return state;
  };
};

export default combineReducers<ServiceRequestState>({ state });