import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";

// OTHERS
import { SERVER_URL } from "../../configs/service-config";
import { getRequest, getRequestWithJson } from '../interceptor';

//  STATE
import { setUnassignedSRs } from "./action";
import { SchedulerType, Toolbar } from "../scheduler/model";
import { getSchedulerToolbar, getSchedulerType } from "../scheduler/action";

import _isNull from "lodash/isNull";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import { getClientId } from "../auth/actions";
import { getSearchType, getSearchValue } from "../filter/action";
import { SearchType } from "../filter/model";

export const fetchUnassignedSR = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const clientId: any = dispatch(getClientId());
    const toolbar: Toolbar = dispatch(getSchedulerToolbar()).payload;
    const schedulerType: SchedulerType = dispatch(getSchedulerType()).payload;
    const q: string = dispatch(getSearchValue()).payload;
    const searchType: SearchType = dispatch(getSearchType()).payload;

    const geData = new URL(SERVER_URL.unAssignedSR);
    geData.searchParams.append("clientId", clientId);

    if (!_isNull(toolbar.typeFilter) && _isEqual(schedulerType, SchedulerType.GRID)) {
      geData.searchParams.append("filterId", toolbar.typeFilter.toString());
    }

    if (!_isEmpty(q) && _isEqual(schedulerType, SchedulerType.GRID)) {
      geData.searchParams.append("q", q);
      geData.searchParams.append("searchType", searchType);
    }

    await getRequestWithJson(dispatch, geData)
      .then((response: any) => {
        dispatch(setUnassignedSRs(response));
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };
};

