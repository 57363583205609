import Card from "./Card";
import styles from "./MembersSubsActivities.module.scss";
import ViewMoreLink from "./ViewMoreLink";

interface Activity {
  label: string;
  color: string;
  value: number;
}

interface Props {
  activities: Activity[];
}

export default function MembersSubsActivities({ activities }: Props) {
  return (
    <Card
      title="Members & Subscribers Activities"
      gap="small"
      className={styles.activities}>
      <div className={styles.container}>
        {activities.map((a, index) => (
          <div key={index} className={styles.activity}>
            <div className={styles.label}>{a.label}</div>
            <div
              style={{ backgroundColor: a.color, width: a.value }}
              className={styles.bar}
            />
            <div>{a.value}</div>
          </div>
        ))}
      </div>
      <ViewMoreLink label={"View More Details"} />
    </Card>
  );
}
