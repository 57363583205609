import React, { useState } from "react";
import { Modal, Input, Button, Spin, message, Form } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

interface Props {
  visible: boolean;
  serviceRequest: any;
  setExceptionVisible: any;
  flagException: any;
  setOpen: any;
}

const Exception: React.FC<Props> = (props): JSX.Element => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState<any>(null);

  const flagServiceRequest = async () => {
    setLoading(true);
    const response = await props.flagException({
      serviceRequestId: serviceRequest.id,
      notes,
    });
    if (response.statusCode === 200) {
      message.success(
        `You successfully flagged sr #${props.serviceRequest.serviceRequestNo} as an exception.`
      );
      form.resetFields();
      props.setExceptionVisible(false);
      props.setOpen(false);
    } else {
      message.error(
        `An error occured while flagging the service request #${props.serviceRequest.serviceRequestNo}.`
      );
    }
    setLoading(false);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 18, color: "white" }} spin />
  );

  const { visible, serviceRequest, setExceptionVisible } = props;
  return (
    <Modal
      visible={visible}
      centered={true}
      onCancel={() => {
        setExceptionVisible(false);
      }}
      className={"request-modal"}
      closable={false}
      footer={null}
      width={"40%"}
    >
      <div style={{ flex: 1, padding: 20 }}>
        <Form name="form" form={form} onFinish={flagServiceRequest}>
          <div
            style={{ textAlign: "center", fontSize: 25, fontWeight: "bold" }}
          >
            SR # {serviceRequest.serviceRequestNo}
          </div>
          <p>This SR is an exception because:</p>
          <Form.Item
            name={`notes`}
            rules={[
              {
                required: true,
                message: "Exception note is required!",
              },
            ]}
            style={{ marginTop: 10 }}
          >
            <Input.TextArea
              value={notes}
              placeholder="State reasons why this SR is an exception"
              onChange={(e: any) => setNotes(e.target.value)}
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <Button
              style={{
                backgroundColor: "#3A78B3",
                color: " white",
                marginRight: 10,
                width: "20%",
              }}
              type="primary"
              size="large"
              htmlType="submit"
            >
              {loading ? <Spin indicator={antIcon} /> : "Submit"}
            </Button>
            <Button
              style={{
                backgroundColor: "white",
                color: "#3A78B3",
                marginRight: "4px",
                width: "15%",
              }}
              size="large"
              htmlType="button"
              type="primary"
              onClick={() => {
                setExceptionVisible(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default Exception;
