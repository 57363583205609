import { useEffect, useState } from "react";
import Card from "./Card";
import LineEdgedTable from "./LineEdgedTable";
import styles from "./OutstandingServiceRequest.module.scss";
import ViewMoreLink from "./ViewMoreLink";

interface Props {
  data: Record<string, string | number>[];
}

interface ServiceRequest {
  name: string;
  propertyId: string;
  serviceType: string;
  daysOverdue: number;
}

export default function OutstandingServiceRequests({ data }: Props) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 896);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 895px)");

    const handleMediaQueryChange = (e: MediaQueryListEvent) => {
      setIsMobile(e.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    setIsMobile(mediaQuery.matches);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  return (
    <Card
      className={styles.outstanding}
      title="Outstanding Service Requests"
      gap="small">
      {isMobile &&
        data.map((d, index) => (
          <div key={index} className={styles["service-request"]}>
            <div className={styles["service-request-col"]}>
              <div className={styles["service-request-name"]}>{d.name}</div>
              <div className={styles["service-request-id"]}>{d.propertyId}</div>
              <div className={styles["service-request-type"]}>
                {d.serviceType}
              </div>
            </div>

            <div
              className={
                styles["service-request-days"]
              }>{`${d.daysOverdue} days`}</div>
          </div>
        ))}

      {!isMobile && (
        <LineEdgedTable
          viewMoreLabel="View All (25)"
          columns={["Name", "Property Id", "Service Type", "Days Overdue"]}
          data={data}
        />
      )}

      <ViewMoreLink label="View All (25)" />
    </Card>
  );
}
