import React, { useState, useEffect, useRef } from "react";
import GoogleMap from "google-map-react";
import useSupercluster from "use-supercluster";
import CSS from "csstype";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../redux";

// Configs
import { GOOGLE_KEY, SERVER_URL } from "../../configs/service-config";

// Components
import LocationMarker from "./marker";
import MarkerBox from "./marker-box";

// models
import { Technician } from "../../redux/technician/model";
import {
  ServiceRequest,
  ServiceRequestCount,
} from "../../redux/service-request/model";

// Lodash
import _isEqual from "lodash/isEqual";
import _isNull from "lodash/isNull";
import _isUndefined from "lodash/isUndefined";

// Actions
import {
  setRemoveTechnicianId,
  setSelectedTechnicianId,
  setTechniciansBoard,
} from "../../redux/technician/action";

// Services
import { getRandomBGColor } from "../../redux/service-request/service";
import { fetchTechniciansServiceRequest } from "../../redux/technician/service";
import { SHOW_TECHNICIAN_LOCATION } from "../../helpers/events";
import { MapSettings } from "../../helpers/models/map-model";

type addInfo = {
  type?: string;
  fullName?: string;
  technicianName?: string;
  email?: string;
  mobileNumber?: string;
  status?: string;
  serviceRequestNo?: string;
};

type NewTechnician = Technician & addInfo;

type NewTechnicianSR = ServiceRequest & addInfo;

interface Polyline {
  id: number | null;
  directions: any[];
  color: string;
}
interface StateProps {
  technicians: Technician[];
  technicianSR: ServiceRequest[];
  removeTechnicianId: any;
  selectedTechnician: any;
  allSelectedTechnicians: any;
}
interface childrenProps {
  children: JSX.Element;
  lat: number;
  lng: number;
}

// const BlankMarker: FC<childrenProps> = ({
//   children,
//   lat,
//   lng,
// }): JSX.Element => {
//   return children;
// };
interface LocationProps {
  fullName: string;
  email: string;
  mobileNumber: string;
  lat: number;
  lng: number;
  id: string;
  type: string;
  srNo: string;
}
interface DispatchProps {
  setRemoveTechnicianId: (id: number | null) => any;
  setSelectedTechnicianId: (id: number | null) => any;
  fetchTechnicianServiceRequest: (ids: number[]) => any;
  setTechniciansBoard: (technicians: Technician[]) => any;
  getRandomBGColor: () => any;
}
type Props = StateProps & DispatchProps;

const GoogleMaps: React.FC<Props> = (props): JSX.Element => {
  const [polylinesArray, setPolylinesArray] = useState<any>([]);
  const [technician, setTechnician] = useState<any>(null);
  const [initTech, setInitTech] = useState<boolean>(false);
  const [defaultMapSettings, setDefaultMapSettings] = useState<MapSettings>({
    center: {
      lat: 29.424122,
      lng: -98.493629,
    },
    zoom: 10,
  });

  const [mapDetails, setMapDetails] = useState({
    mapsLoaded: false,
    map: null,
    maps: null,
  });
  const [data, setData] = useState<any>([]);
  const [locationInfo, setLocationInfo] = useState<LocationProps | null>(null);
  const [isOpenWindow, setInfoBox] = useState(false);
  const [zoom, setZoom] = useState(10);
  const [bounds, setBounds] = useState([] as any);
  const mapRef = useRef<google.maps.Map>();

  useEffect(() => {
    window.addEventListener(
      "locationReceived",
      locationReceived
    );
    return () => {
      window.removeEventListener(
        "locationReceived",
        locationReceived
      );
    };
  }, []);

  useEffect(() => {
    window.addEventListener(SHOW_TECHNICIAN_LOCATION, routeTechLocation);
    return () => {
      window.removeEventListener(SHOW_TECHNICIAN_LOCATION, routeTechLocation);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setMapSettings();
      updateMapData();
    }, 100);
    if (mapDetails.mapsLoaded) {
      apiIsLoaded(mapRef.current, mapDetails.maps);
      }
  }, [props.technicianSR, technician]);

  const locationReceived = (event: any) => {
    const { technician } = event.detail;
    if (technician) {
      setInitTech(true)
      setTechnician(technician)
    }
  }
  // const clusterStyle: CSS.Properties = {
  //   color: "#fff",
  //   background: "#1978c8",
  //   borderRadius: "50%",
  //   padding: "10px",
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  // };

  //  Display the technicians and their service requests on the map
  const updateMapData = async () => {
    const { technicianSR, technicians, allSelectedTechnicians } = props;
    let technicianArr: NewTechnician[] = [];
    let technicianSRArr: NewTechnicianSR[] = [];

    if (initTech) {
      if (technician) {
        const existing = data.find((d: any) => d.id == technician.technicianId)
        if (existing) {
          existing.lat = technician.lat
          existing.lng = technician.lng 
          setData(data);
        }
    }
    } else {
      if (technicianSR.length > 0) {
        for (let x = 0; x < technicianSR.length; x++) {
          const findSR = await technicians.find(
            (tech) => tech.id === technicianSR[x].technicianId
          );
          if (findSR) {
            technicianSRArr.push({
              ...technicianSR[x],
              email: findSR.email,
              mobileNumber: findSR.mobileNumber,
              type: "SR",
            });
          }
        }
        for (let i = 0; i < allSelectedTechnicians.length; i++) {
          technicianSRArr.push({
            ...allSelectedTechnicians[i],
            type: "technician",
          });
        }
        setData(technicianSRArr);
      } else {
        for (let x = 0; x < props.technicians.length; x++) {
          technicianArr.push({
            ...technicians[x],
            type: "technician",
          });
        }
        setData(technicianArr);
      }
    }
  };

  // Fill the info of technician and sr's to be displayed on the map
  const points = data.map((technician: NewTechnician | NewTechnicianSR) => ({
    properties: {
      fullName: technician.fullName || technician.technicianName,
      email: technician.email,
      mobileNumber: technician.mobileNumber,
      id: technician.id,
      currentStatus: technician.currentStatus || technician.status,
      type: technician.type,
      serviceRequestNo: technician.serviceRequestNo,
    },
    geometry: {
      coordinates: [technician.lng, technician.lat],
    },
  }));

  //setup the cluster
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 100, maxZoom: 23 },
  });

  const routeTechLocation = ({ detail }: any) => {
    const { serviceRequest } = detail;
    if (serviceRequest) {
      let number = zoom;
      clusters.map((cluster) => {
        for (let x = 1; x < 12; x++) {
          if (cluster.properties.id !== serviceRequest.id) {
            clusters.map((c) => {
              if (c.properties.id !== serviceRequest.id) {
                number = number + 1;
              }
            });
          }
        }
      });
      setDefaultMapSettings({
        center: {
          lat: serviceRequest.lat,
          lng: serviceRequest.lng,
        },
        zoom: number,
      });
      setZoom(number);
    }
  };

  // Setup the settings of the map
  const setMapSettings = () => {
    if (props.technicianSR.length > 0) {
      setDefaultMapSettings({ ...defaultMapSettings });
    } else {
      setDefaultMapSettings({
        center: {
          lat: 29.424122,
          lng: -98.493629,
        },
        zoom: 10,
      });
    }
  };

  // Rerender the polylines on the map
  const apiIsLoaded = async (map: any, mapDetails: any) => {
    console.log(polylinesArray)
    const {
      removeTechnicianId,
      selectedTechnician,
      technicianSR,
      setRemoveTechnicianId,
      setSelectedTechnicianId,
      getRandomBGColor,
    } = props;

    let poly: Polyline = {
      id: null,
      directions: [],
      color: getRandomBGColor(),
    };

    let bounds = await new window.google.maps.LatLngBounds();

    let startPoint;
    let endPoint;

    if (data.length > 0) {
      if (removeTechnicianId || technician) {
        if (technician) {
          const existing = polylinesArray.find(
            (f: any) => f.id === technician.technicianId
          );
          if (existing) {
            existing.directions[0].setMap(null);
          }
        } else {
          const existing = await polylinesArray.find(
            (f: any) => f.id === removeTechnicianId
          );
          if (existing) {
            for (let x = 0; x < existing.directions.length; x++) {
              existing.directions[x].setMap(null);
            }
            const newPolylines = await polylinesArray.filter(
              (f: any) => f.id !== removeTechnicianId
            );
            setPolylinesArray(newPolylines);
          }
        }
      }
      if (selectedTechnician || technician) {
        let newPath;
        if (technician) {
          newPath = await technicianSR.filter(
            (f: any) => f.technicianId === technician.technicianId
          );
          newPath.unshift(technician);
        } else {
          newPath = await technicianSR.filter(
            (f: any) => f.technicianId === selectedTechnician.id
          );
          newPath.unshift(selectedTechnician);
        }
        if (newPath.length > 1) {
          if (technician) {
            const polylinesArrayCopy = polylinesArray;
            const existing = polylinesArrayCopy.find(
              (f: any) => f.id === technician.technicianId
            );
            startPoint = new google.maps.LatLng({
              lat: newPath[0].lat,
              lng: newPath[0].lng,
            });
            endPoint = new google.maps.LatLng({
              lat: newPath[1].lat,
              lng: newPath[1].lng,
            });
            await bounds.extend(startPoint);
            await bounds.extend(endPoint);
            const directionsService = await new google.maps.DirectionsService();
            directionsService.route(
              {
                origin: startPoint,
                destination: endPoint,
                optimizeWaypoints: true,
                travelMode: google.maps.TravelMode.DRIVING,
              },
              async (result, status) => {
                if (status === google.maps.DirectionsStatus.OK && result) {
                  if (existing) {
                    const routePolyline = await new window.google.maps.Polyline({
                      path: result.routes[0].overview_path,
                      strokeColor: existing && existing.color,
                      strokeOpacity: 1,
                      strokeWeight: 5,
                    });
                    routePolyline.setMap(map);
                    existing.directions[0] = routePolyline;
                    setPolylinesArray(polylinesArrayCopy);
                  }
                }
              }
            );
          } else {
            if (newPath.length === 1) {
              startPoint = new google.maps.LatLng({
                lat: newPath[0].lat,
                lng: newPath[0].lng,
              });
              endPoint = new google.maps.LatLng({
                lat: newPath[0].lat,
                lng: newPath[0].lng,
              });
              await bounds.extend(startPoint);
              await bounds.extend(endPoint);
            } else {
              for (let i = 0; i < newPath.length - 1; i++) {
                startPoint = new google.maps.LatLng({
                  lat: newPath[i].lat,
                  lng: newPath[i].lng,
                });
                endPoint = new google.maps.LatLng({
                  lat: newPath[i + 1].lat,
                  lng: newPath[i + 1].lng,
                });
                await bounds.extend(startPoint);
                await bounds.extend(endPoint);
                const directionsService = await new google.maps.DirectionsService();
                directionsService.route(
                  {
                    origin: startPoint,
                    destination: endPoint,
                    optimizeWaypoints: true,
                    travelMode: google.maps.TravelMode.DRIVING,
                  },
                  async (result, status) => {
                    if (status === google.maps.DirectionsStatus.OK && result) {
                      const routePolyline = await new window.google.maps.Polyline(
                        {
                          path: result.routes[0].overview_path,
                          strokeColor: poly.color,
                          strokeOpacity: 1,
                          strokeWeight: 5,
                        }
                      );
                      routePolyline.setMap(map);
                      poly.id = selectedTechnician.id;
                      poly.directions.push(routePolyline);
                      setPolylinesArray([...polylinesArray, poly]);
                    }
                  }
                );
              }
            }
          }
          if (!initTech) {
            await mapRef.current?.fitBounds(bounds);
          }
          setRemoveTechnicianId(null);
          setSelectedTechnicianId(null);
          setTechnician(null);
          setInitTech(false);
        }
      }
   }
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMap
        bootstrapURLKeys={{ key: GOOGLE_KEY }}
        center={defaultMapSettings.center}
        zoom={defaultMapSettings.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          mapRef.current = map;
          setMapDetails({ ...mapDetails, map, maps, mapsLoaded: true });
        }}
        onChange={async ({ zoom, bounds, center }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
      >
        {data.map((d: any) => {
          return (
            <LocationMarker
              key={d.id}
              lat={d.lat}
              lng={d.lng}
              type={d.type}
              id={d.id}
              currentStatus={d.currentStatus || d.status}
              onClick={() => {
                setLocationInfo({
                  email: d.email,
                  fullName: d.fullName || d.technicianName,
                  mobileNumber: d.mobileNumber,
                  lat: d.lat,
                  lng: d.lng,
                  id: d.id,
                  type: d.type,
                  srNo: d.serviceRequestNo,
                });
                setInfoBox(true);
              }}
            />
          );
        })}
        {isOpenWindow && locationInfo && (
          <MarkerBox
            onClosed={() => setInfoBox(false)}
            lat={locationInfo?.lat}
            lng={locationInfo?.lng}
            type={locationInfo?.type}
            fullName={locationInfo?.fullName}
            srNo={locationInfo?.srNo}
            email={locationInfo?.email}
            mobileNumber={locationInfo?.mobileNumber}
            id={locationInfo.id}
          />
        )}
      </GoogleMap>
    </div>
  );
};

const mapStateToProps = (states: RootState) => {
  return {
    removeTechnicianId: states.technicianReducer.state.removeTechnicianId,
    selectedTechnician: states.technicianReducer.state.selectedTechnicianId,
    technicians: states.technicianReducer.state.techniciansBOARD,
    technicianSR: states.technicianReducer.state.technicianSR,
    allSelectedTechnicians:
      states.technicianReducer.state.allSelectedTechnicians,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    fetchTechnicianServiceRequest: (ids: number[]) =>
      dispatch(fetchTechniciansServiceRequest(ids)),
    setRemoveTechnicianId: (id: number | null) =>
      dispatch(setRemoveTechnicianId(id)),
    setSelectedTechnicianId: (id: number | null) =>
      dispatch(setSelectedTechnicianId(id)),
    getRandomBGColor: () => getRandomBGColor(),
    setTechniciansBoard: (technicians: Technician[]) =>
      dispatch(setTechniciansBoard(technicians)),
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(GoogleMaps)
);
