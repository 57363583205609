import {
  GET_CLOCK,
  GET_SCHEDULER_GRID,
  GET_SCHEDULER_GRID_D,
  GET_SCHEDULER_TOOLBAR,
  GET_SCHEDULER_TYPE,
  GET_SEVEN_DAYS,
  SET_CLOCK,
  SET_ISLOADING_SCHEDULER,
  SET_SCHEDULER_GRID,
  SET_SCHEDULER_GRID_D,
  SET_SCHEDULER_TOOLBAR,
  SET_SCHEDULER_TYPE,
  SET_SEVEN_DAYS
} from "../action-types";

export enum ClockType {
  TWENTYFOUR_HOUR = "24hour",
  TWELVE_HOUR = "12hour"
};

export enum DailyWeekly {
  DAILY = "Daily",
  WEEKLY = "Weekly"
};

export interface Toolbar {
  typeFilter: any;
  dateFilter: any;
  D_W: DailyWeekly;
  clockType: ClockType;
};

export interface Time {
  time: string;
  display: string;
  value: string;
};

export interface Day {
  dd: string;
  mm: string;
  day: string;
  date: string;
};

export enum SchedulerType {
  BOARD = "BOARD",
  GRID = "GRID"
};

export type Schedules = Array<Array<any>>;

export interface SchedulerModel {
  isLoading: boolean;
  clock: Array<Time>;
  sevenDays: Array<Day>;
  toolbar: Toolbar;
  grid: Array<Schedules>;
  gridD: Schedules;
  schedulerType: SchedulerType;
};
/** Set Is Loading */
export type SetIsLoadingScheduler = {
  type: typeof SET_ISLOADING_SCHEDULER;
  payload: boolean;
};
/** Get Scheduler Type */
export type GetSchedulerType = {
  type: typeof GET_SCHEDULER_TYPE,
  payload: SchedulerType
};

/** Set Scheduler Type */
export type SetSchedulerType = {
  type: typeof SET_SCHEDULER_TYPE,
  payload: SchedulerType
};

/** Get Clock */
export type GetClock = {
  type: typeof GET_CLOCK;
  payload: Array<Time>;
};

/** Set Clock */
export type SetClock = {
  type: typeof SET_CLOCK;
  payload: Array<Time>;
};

/** Get Seven Days */
export type GetSevenDays = {
  type: typeof GET_SEVEN_DAYS;
  payload: Array<Day>;
};

/** Set Seven Days */
export type SetSevenDays = {
  type: typeof SET_SEVEN_DAYS;
  payload: Array<Day>;
};

/** Get Scheduler Toolbar */
export type GetSchedulerToolbar = {
  type: typeof GET_SCHEDULER_TOOLBAR;
  payload: Toolbar;
};

/** Set Scheduler Toolbar */
export type SetSchedulerToolbar = {
  type: typeof SET_SCHEDULER_TOOLBAR;
  payload: Toolbar;
};

/** Get Scheduler Grid */
export type GetSchedulerGrid = {
  type: typeof GET_SCHEDULER_GRID;
  payload: Array<Schedules>;
};

/** Set Scheduler Grid */
export type SetSchedulerGrid = {
  type: typeof SET_SCHEDULER_GRID;
  payload: Array<Schedules>;
};

/** Get Scheduler Grid Daily */
export type GetSchedulerGridDaily = {
  type: typeof GET_SCHEDULER_GRID_D;
  payload: Schedules;
};

/** Set Scheduler Grid Daily */
export type SetSchedulerGridDaily = {
  type: typeof SET_SCHEDULER_GRID_D;
  payload: Schedules;
};

/** Export Scheduler Actions  */
export type SchedulerActions =
  SetIsLoadingScheduler |
  GetSchedulerType |
  SetSchedulerType |
  GetClock |
  SetClock |
  GetSevenDays |
  SetSevenDays |
  GetSchedulerToolbar |
  SetSchedulerToolbar |
  GetSchedulerGrid |
  SetSchedulerGrid |
  GetSchedulerGridDaily |
  SetSchedulerGridDaily;