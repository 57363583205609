import Card from "./Card";
import styles from "./Revenue.module.scss";
import RevenueChart from "../../../assets/charts/revenue.svg";

export default function Revenue() {
  return (
    <Card
      title={"Average Revenue / Job"}
      gap="medium"
      className={styles.revenue}>
      <div className={styles.container}>
        <div className={styles.jobs}>
          <div>$25.24</div>
          <div>/ 54 Jobs</div>
        </div>

        <div className={styles.averages}>
          <div className={styles.mtd}>
            <div>$25.14</div>
            <div>Average MTD</div>
          </div>
          <div className={styles.ytd}>
            <div>$155.55</div>
            <div>Average YTD</div>
          </div>
        </div>
      </div>

      <img src={RevenueChart} alt="Revenue Chart" />
    </Card>
  );
}
