import React from "react";
import { connect } from "react-redux";
import { History } from "history";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import { Row } from "antd"

import ChangePasswordForm from "../../components/change-password/change-password";

interface OwnProps { history: History; }
interface StateProps { }
interface DispatchProps { }
type Props = OwnProps & StateProps & DispatchProps;
const ChangePasswordPage: React.FC<Props> = ({ history }): JSX.Element => {

  return (
    <Row align="middle" justify="center" style={{marginTop: 100}}>
    <div className="form-password-container">
      <h1 className="form-password-header">Change Password</h1>
      <div className="form-password-subContainer">
        <ChangePasswordForm />
        </div>
      </div>
     </Row>    
  );
};

const mapStateToProps = (states: RootState): StateProps => {
  return {};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {};
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage));
