import React, { useState, useEffect } from "react";
import { MinusCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, List, message, Popconfirm } from "antd";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import "./service-type.scss";
import { TypeOfService } from "../../redux/technician/model";
import { deleteTypeOfService, saveTypeOfService } from "../../redux/technician/service";
import { setTypeOfServices } from "../../redux/technician/action";

import _isUndefined from "lodash/isUndefined";

interface ComponentProps {
}
interface ReduxState {
   typeOfServices: Array<TypeOfService>;
}
interface DispatchProps {
   saveTypeOfService: (name: string) => any;
   setTypeOfServices: (typeOfServices: Array<TypeOfService>) => any;
   deleteTypeOfService: (typeOfService: TypeOfService) => void;
}
type Props = ComponentProps & ReduxState & DispatchProps;

const ServiceTypeComponent: React.FC<Props> = (props): JSX.Element => {

   const [showModal, setShowModal] = useState<boolean>(false);
   let [typeOfServices, setTypeOfServices] = useState<Array<TypeOfService>>([]);
   const [saveForm] = Form.useForm();
   const [render, setRender] = useState<number>(0);

   useEffect(() => {
      setTypeOfServices(props.typeOfServices);
   }, [props.typeOfServices, render]);

   const save = async (formData: any) => {
      const typeOfService: TypeOfService = await props.saveTypeOfService(formData.name);
      if (!_isUndefined(typeOfService)) {
         window.dispatchEvent(new Event("refetchTypeOfService"));
         typeOfServices.push(typeOfService);
         setTypeOfServices(typeOfServices);
         setRender(render + 1);
         saveForm.resetFields();
         message.success("Successfully saved.");
      } else {
         message.error("An error occured while saving!");
         saveForm.resetFields();
         setShowModal(false);
      }
   }

   const nameChanged = (e: any) => {
      saveForm.setFieldsValue({ name: e.target.value });
   }

   const deleteServType = (typeOfService: TypeOfService) => {
      var index = typeOfServices.indexOf(typeOfService);
      if (index !== -1) {
         typeOfServices.splice(index, 1);
         setTypeOfServices(typeOfServices);
         setRender(render + 1);
         props.deleteTypeOfService(typeOfService);
         message.success("Successfully deleted.");
      }
   }

   return (
     <div className="service-type-container">
       <div className="service-type-list-container">
         <div className="header">
           <h3>Service Types</h3>
           <Button
             type="primary"
             style={{ background: "purple", borderColor: "purple" }}
             size="small"
             onClick={() => setShowModal(true)}
           >
             + SERVICE TYPE
           </Button>
         </div>
         <List
           className="service-type-list"
           grid={{ gutter: 16, column: 1 }}
           dataSource={typeOfServices}
           itemLayout="vertical"
           renderItem={(item) => (
             <List.Item>
               <label>&#x2022; {item?.name}</label>
               <Popconfirm
                 placement="bottom"
                 title={"Are you sure to delete?"}
                 onConfirm={() => deleteServType(item)}
                 okText="Yes"
                 cancelText="No"
               >
                 &nbsp;&nbsp;
                 <MinusCircleOutlined style={{ cursor: "pointer" }} />
               </Popconfirm>
             </List.Item>
           )}
         />
       </div>

       <Modal
         title="Add Service Type"
         visible={showModal}
         confirmLoading={false}
         footer={null}
         className="modalStyle"
         onCancel={() => setShowModal(false)}
         closeIcon={<CloseOutlined style={{ fontSize:"10px", position:'absolute', top: 20, right: 13, color:"black"}} />}
       >
         <Form name="addServiceType" form={saveForm} onFinish={save}>
           <Form.Item
             name={"name"}
             rules={[
               { required: true, message: "Name of Service is required!" },
             ]}
           >
             <h3>Name of Service</h3>
             <Input
               placeholder="E.g. plumbing, fencing"
               onChange={nameChanged}
               maxLength={255}
             />
           </Form.Item>
           <Form.Item className={"dispatcher-button-container"}>
             <div className={"dispatcher-button-container"}>
               <Button
                 style={{
                   backgroundColor: "white",
                   color: "#3A78B3",
                   marginRight: "4px",
                 }}
                 type="primary"
                 onClick={() => setShowModal(false)}
               >
                 Close
               </Button>
               <Button
                 style={{ backgroundColor: "#3A78B3", color: " white" }}
                 type="primary"
                 htmlType="submit"
               >
                 Save
               </Button>
             </div>
           </Form.Item>
         </Form>
       </Modal>
     </div>
   );
};

const mapStateToProps = (states: RootState): ReduxState => {
   return {
      typeOfServices: states.technicianReducer.state.typeOfServices,
   };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
   return {
      saveTypeOfService: (name) => dispatch(saveTypeOfService(name)),
      setTypeOfServices: (typeOfServices) => dispatch(setTypeOfServices(typeOfServices)),
      deleteTypeOfService: (typeOfService) => dispatch(deleteTypeOfService(typeOfService))
   };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(ServiceTypeComponent))
