import React from "react";
import { CaretDownOutlined, CloseOutlined } from "@ant-design/icons";

interface Props { }
const legends = [
  { title: "Available / Not on a Current SR", backgroundColor: "#389e0d" },
  { title: "Pending Appt Time", backgroundColor: "#fa8c16" },
  { title: "Pending Parts", backgroundColor: "#d46b08" },
  { title: "Offline / Not Available", backgroundColor: "#8c8c8c" },
  { title: "En-route", backgroundColor: "#1d39c4" },
  { title: "Running Late", backgroundColor: "#ff4d4f" },
  { title: "On-Site", backgroundColor: "#a0d911" },
  { title: "Issue", backgroundColor: "#610b00" },
];

const LegendComponent: React.FC<Props> = ({ }): JSX.Element => {
  const [legend, setLegend] = React.useState(true);

  const renderLegendItems = () => {
    return legends.map((legend, index) => (
      <div key={`${legend}_${index}`}
        className={"legend-sr"}
      >
        <span
          className={"legend-color-status"}
          style={{ backgroundColor: legend.backgroundColor }}
        />
        {legend.title}
      </div>
    ))
  }


  return (
    <div className='legend-container'>
      <div className='legend'>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LEGEND:
        {!legend ? (
          <CaretDownOutlined
            className={"legend-icon"}
            onClick={() => setLegend(!legend)}
          />
        ) : (
          <CloseOutlined
            className={"legend-icon"}
            onClick={() => setLegend(!legend)}
          />
        )}
        <div className={!legend ? 'legend-content-hidden' : 'legend-content'}>
          {renderLegendItems()}
        </div>
      </div>
    </div>
  )
};

export default React.memo(LegendComponent);






