import {
  GET_CLOCK,
  GET_SCHEDULER_GRID,
  GET_SCHEDULER_GRID_D,
  GET_SCHEDULER_TOOLBAR,
  GET_SCHEDULER_TYPE,
  GET_SEVEN_DAYS,
  SET_CLOCK,
  SET_ISLOADING_SCHEDULER,
  SET_SCHEDULER_GRID,
  SET_SCHEDULER_GRID_D,
  SET_SCHEDULER_TOOLBAR,
  SET_SCHEDULER_TYPE,
  SET_SEVEN_DAYS
} from "../action-types";

import {
  Day,
  GetClock,
  GetSchedulerGrid,
  GetSchedulerGridDaily,
  GetSchedulerToolbar,
  GetSevenDays,
  SchedulerType,
  Schedules,
  SetClock,
  SetIsLoadingScheduler,
  SetSchedulerGrid,
  SetSchedulerGridDaily,
  SetSchedulerToolbar,
  SetSchedulerType,
  SetSevenDays,
  Time,
  Toolbar
} from "./model";

import store from "..";

export const setIsLoadingScheduler = (payload: boolean): SetIsLoadingScheduler => {
  return {
    type: SET_ISLOADING_SCHEDULER,
    payload
  };
};

export const setSchedulerType = (payload: SchedulerType): SetSchedulerType => {
  return {
    type: SET_SCHEDULER_TYPE,
    payload
  };
};

export const getSchedulerType = () => {
  return {
    type: GET_SCHEDULER_TYPE,
    payload: store.getState().schedulerReducer.state.schedulerType
  };
};

export const getClock = (): GetClock => {
  return {
    type: GET_CLOCK,
    payload: store.getState().schedulerReducer.state.clock
  };
};

export const setClock = (payload: Array<Time>): SetClock => {
  return {
    type: SET_CLOCK,
    payload
  };
};

export const getSevenDays = (): GetSevenDays => {
  return {
    type: GET_SEVEN_DAYS,
    payload: store.getState().schedulerReducer.state.sevenDays
  };
};

export const setSevenDays = (payload: Array<Day>): SetSevenDays => {
  return {
    type: SET_SEVEN_DAYS,
    payload
  };
};

export const getSchedulerToolbar = (): GetSchedulerToolbar => {
  return {
    type: GET_SCHEDULER_TOOLBAR,
    payload: store.getState().schedulerReducer.state.toolbar
  };
};

export const setSchedulerToolbar = (payload: Toolbar): SetSchedulerToolbar => {
  return {
    type: SET_SCHEDULER_TOOLBAR,
    payload
  };
};

export const getSchedulerGrid = (): GetSchedulerGrid => {
  return {
    type: GET_SCHEDULER_GRID,
    payload: store.getState().schedulerReducer.state.grid
  };
};

export const setSchedulerGrid = (payload: Array<Schedules>): SetSchedulerGrid => {
  return {
    type: SET_SCHEDULER_GRID,
    payload
  };
};

export const getSchedulerGridDaily = (): GetSchedulerGridDaily => {
  return {
    type: GET_SCHEDULER_GRID_D,
    payload: store.getState().schedulerReducer.state.gridD
  };
};

export const setSchedulerGridDaily = (payload: Schedules): SetSchedulerGridDaily => {
  return {
    type: SET_SCHEDULER_GRID_D,
    payload
  };
};