import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { SERVER_URL } from "../../configs/service-config";
import { Dispatcher } from "./model";
import { postRequest, deleteRequest, getRequestWithJson, postRequestWithJson } from "../interceptor";
import { setDispatchers } from "./action";
import _isUndefined from "lodash/isUndefined";
import _isEqual from "lodash/isEqual";
import _isNull from "lodash/isNull";
import { message } from "antd";
import { getClientId } from "../auth/actions";

export const fetchDispatchers = (): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		const clientId: any = dispatch(getClientId());
		const fetchDispatchersEndpoint = new URL(SERVER_URL.DISPATCHERS);
		fetchDispatchersEndpoint.searchParams.append("clientId", clientId);
		await getRequestWithJson(dispatch, fetchDispatchersEndpoint)
			.then(response => {
				dispatch(setDispatchers(response));
			})
			.catch((error) => {
				console.log('Error Message -> while fetching dispatcher', error);
			});
	};
};

export const saveDispatcher = (dispatcher: Dispatcher): ThunkAction<Promise<Dispatcher>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		const saveDispatcherURI = new URL(SERVER_URL.DISPATCHERS);
		let savedDispatcher: any = null;
		await postRequestWithJson(dispatch, saveDispatcherURI, { body: JSON.stringify(dispatcher) })
			.then((response: any) => {
				savedDispatcher = response;
			})
			.catch((error: any) => {
				savedDispatcher = error;
				console.log('Error Message -> while saving dispatcher', error);
			});
		return savedDispatcher;
	};
}

export const deleteDispatcher = (dispatcher: Dispatcher): ThunkAction<Promise<Dispatcher>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		const deleteDispatcherURI = new URL(`${SERVER_URL.DISPATCHER}/${dispatcher.id}`);
		let deletedDispatcher: any = null;
		await deleteRequest(dispatch, deleteDispatcherURI)
			.then((response: any) => {
				deletedDispatcher = response;
			})
			.catch((error: any) => {
				console.log('Error Message -> while deleting dispatcher', error);
			});
		return deletedDispatcher;
	};
};


export const resetPasswordViaEmail = (email: string): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		const clientId: any = dispatch(getClientId());
		const endpoint = SERVER_URL.RESET_PASSWORD_ENDPOINT;
		await postRequest(dispatch, endpoint.toString(), {
			method: "POST",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				clientId: clientId,
				email: email,
				resetMode: "RESET"
			}),
		})
			.then(() => {
				message.success("The password was reset successfully, please check your email.");
			})
			.catch((error: any) => {
				message.error("An error occured while resetting the password");
				console.log('Error Message -> while resetting the password', error);
			});
	};
};








