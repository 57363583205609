import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { History } from "history";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import { Typography, Alert, Form, Input, Select, message } from "antd";
import {
  AuthenticationCardComponent,
  BackgroundImageComponent,
  SubmitButtonComponent,
} from "../../components";

import { ErrorMessageModel } from "../../redux/error-msg/model";
import { LoginFormFields } from "../../redux/auth/model";
import { formLoginSubmission } from "../../redux/auth/service";
import { setLoginErrorMessage } from "../../redux/error-msg/action";
import { Toolbar } from '../../redux/scheduler/model'
import _isEqual from "lodash/isEqual";
import _isUndefined from "lodash/isUndefined";

import "./LoginPage.scss";
import {
  setClientId,
  setSessionCredentials,
  setSessionCredentialsToStorage,
} from "../../redux/auth/actions";
import { setSchedulerToolbar } from "../../redux/scheduler/action";
import { serviceRequestByNo } from "../../redux/service-request/service";
import moment from "moment";

const { Text } = Typography;

const { Option } = Select;

const formLayout = {
  wrapperCol: {
    xs: {
      offset: 0,
      span: 24,
    },
    sm: {
      offset: 3,
      span: 18,
    },
  },
};

const formBottomLayout = {
  wrapperCol: {
    xs: {
      offset: 0,
      span: 24,
    },
    sm: {
      offset: 7,
      span: 10,
    },
  },
};

interface State {}
interface ComponentProps {
  history: History;
}
interface ReduxState {
  isAuthenticating: boolean;
  errorMessage: ErrorMessageModel;
  toolbar: any;
}
interface DispatchProps {
  formLoginSubmission: (formFields: LoginFormFields, history: History) => void;
  setLoginErrorMessage: (c: number, msg: string, msgD: string) => void;
  setClient: (id: string) => void;
  setSessionCredentials: (sessionCredentials: any) => void;
  setSessionCredentialsToStorage: (sessionCredentials: any) => void;
  setToolbar: (params: Toolbar) => void;
  serviceRequestByNo: (no: any) => any;
}

type Props = ComponentProps & ReduxState & DispatchProps;
class LoginPage extends React.Component<Props, State> {
  async componentDidMount() {

    const {
      match,
      history,
      setSessionCredentials,
      setSessionCredentialsToStorage,
      serviceRequestByNo
    }: any = this.props;

    if (!_isUndefined(match)) {
      const {
        params: { accessToken, serviceRequestNo },
      } = match;

      if (!_isUndefined(accessToken)) {
        setSessionCredentials({ accessToken });
        setSessionCredentialsToStorage({ accessToken });
        history.push("/dashboard");
        const response = await serviceRequestByNo(serviceRequestNo);
        if (_isEqual(response.statusCode, 200)) {
          localStorage.setItem("redirectSR", serviceRequestNo)
          this.onChangeDailyWeekly("Daily", response.body.scheduleDateTimeStamp, "24hour");
        } else {
          message.error(
            "Service Request does not exist."
          );
        }
      }
    }

 this.props.setClient("1");
  }

  onChangeDailyWeekly = async (value: any, scheduleDate: any, clockType: any) => {
    const { toolbar, setToolbar, history} = this.props;
    const D_W = value
    const formatDate = moment(scheduleDate).format("LL");
    const dateFilter = formatDate
    const { typeFilter } = toolbar;
    await setToolbar({ typeFilter, dateFilter, clockType, D_W });
    history.push("/dashboard")
  };

  renderAuthenticationErrorMessage = () => {
    const { errorMessage } = this.props;
    if (_isEqual(errorMessage.code, 0)) return <></>;
    return (
      <div className={"mb-3"}>
        <Alert
          message={<Text style={{ fontSize: 18 }}>{errorMessage.message}</Text>}
          type="error"
          closable
        />
      </div>
    );
  };

  onLoginSubmission = (values: any) => {
    const { history, formLoginSubmission } = this.props;
    formLoginSubmission(values, history);
  };

  onLoginSubmissionHasError = (err: any) => {
    console.log("**DEBUGGER** Login Page Error -> ", err);
  };

  onLoginFormFieldChange = () => {
    const { setLoginErrorMessage } = this.props;
    setLoginErrorMessage(0, "", "");
  };

  public render(): JSX.Element {
    return (
      <BackgroundImageComponent>
        <AuthenticationCardComponent>
          <Text
            style={{
              display: "flex",
              padding: 16,
              fontSize: 22,
            }}
          >
            Login with your given credentials to enter the ManageLife Portal.
          </Text>
          {this.renderAuthenticationErrorMessage()}
          <Form
            name={"LoginForm"}
            onFieldsChange={this.onLoginFormFieldChange}
            onFinish={this.onLoginSubmission}
            onFinishFailed={this.onLoginSubmissionHasError}
            {...formLayout}
          >
            <Form.Item>
              <Select
                defaultValue={"1"}
                size={"middle"}
                placeholder="Select a option and change input text above"
                onChange={(id: string) => this.props.setClient(id)}
                allowClear
              >
                <Option value={"1"}>Managelife</Option>
                <Option value={"2"}>UFS</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="username"
              rules={[
                { required: true, message: "Please input your email address!" },
                {
                  type: "email",
                  message: "Please input a valid email address!",
                },
              ]}
            >
              <Input size={"middle"} placeholder="Email address" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password size={"middle"} placeholder="Password" />
            </Form.Item>

            <Form.Item {...formBottomLayout} style={{ marginBottom: 0 }}>
              <SubmitButtonComponent
                label={"LOGIN"}
                loading={this.props.isAuthenticating}
              />
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }}>
              <Link to="/reset-password">
                <Text className={"reset-password-link"}>
                  Forgot your password?
                </Text>
              </Link>
            </Form.Item>
          </Form>
        </AuthenticationCardComponent>
      </BackgroundImageComponent>
    );
  }
}

const mapStateToProps = (states: RootState): ReduxState => {
  return {
    isAuthenticating: states.auth.state.isAuthenticating,
    errorMessage: states.errorMsg.state,
    toolbar: states.schedulerReducer.state.toolbar,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    formLoginSubmission: (fields, history) =>
      dispatch(formLoginSubmission(fields, history)),
    setLoginErrorMessage: (code, msg, msgDetails) =>
      dispatch(setLoginErrorMessage(code, msg, msgDetails)),
    setClient: (id) => dispatch(setClientId(id)),
    setSessionCredentials: (sessionCredentials: any) =>
      dispatch(setSessionCredentials(sessionCredentials)),
    setSessionCredentialsToStorage: (sessionCredentials: any) =>
      dispatch(setSessionCredentialsToStorage(sessionCredentials)),
    setToolbar: (params) => dispatch(setSchedulerToolbar(params)),
    serviceRequestByNo: (no) => dispatch(serviceRequestByNo(no)),
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage)
);
