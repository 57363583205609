import React from "react";
import { Typography, Image, Card, Row, Col } from "antd";
import Logo from "../../assets/images/logo2.png";
import "./AuthenticationCardComponent.scss";
const { Text } = Typography;

interface Props {
   children: JSX.Element | JSX.Element[];
}

const AuthenticationCardComponent: React.FC<Props> = ({ children }): JSX.Element => {
   return (
      <Row
         style={{
            display: 'flex',
            width: '100%'
         }}
      >
         <Col
            xs={1}
            sm={2}
            md={4}
            lg={6}
            xl={10}
            xxl={12}
         />
         <Col
            style={{
               display: 'flex',
               alignItems: 'center',
               // backgroundColor: 'blue',
               padding: '10% 4%'
            }}
            xs={22}
            sm={18}
            md={16}
            lg={14}
            xl={11}
            xxl={9}>
            <Card
               className='authentication-card'
               style={{
                  borderRadius: 8,
                  width: '100%',
                  padding: 32
               }}
               bordered={false}
            >
               <Image
                  style={{ width: 300 }}
                  preview={false}
                  src={Logo}
               />
               <Text
                  className='font-title-color'
                  style={{
                     display: 'flex',
                     justifyContent: 'center',
                     fontSize: 20,
                     fontStyle: 'italic',
                     fontWeight: 700
                  }}
               >
                  Life Made Simple</Text>
               {children}
            </Card>
         </Col>
      </Row>
   );
};

export default React.memo(AuthenticationCardComponent);
