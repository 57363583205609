import React from "react";
import { connect } from "react-redux";
import { History } from "history";
import { useHistory, Link } from "react-router-dom";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import { PageHeader, Dropdown, Menu, Button, Avatar } from 'antd';
import { NotificationSection } from "../../components";
import { UserDetails } from "../../redux/auth/model";
import { LogoutOutlined, UserOutlined, DownOutlined, KeyOutlined } from '@ant-design/icons';
import { removeSessionFromStorage } from "../../redux/auth/actions";

interface OwnProps { }
interface StateProps { userDetails: UserDetails; }
interface DispatchProps { removeSessionFromStorage: (history: History) => any; }
type Props = OwnProps & StateProps & DispatchProps;
const PageHeaderSection: React.FC<Props> = ({ userDetails, removeSessionFromStorage, }): JSX.Element => {
  const history = useHistory();
  return (
    <PageHeader
      className={"page-header"}
      ghost={false}
      title=""
      extra={[
        <NotificationSection key={"page-header-notification"} />,
        <Dropdown key={'page-header-dpd'} trigger={['click']} overlay={(
          <Menu>
            <Menu.Item key="1" icon={<KeyOutlined />}>
              <Link to="/change-password">Change Password</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<LogoutOutlined />} onClick={() => removeSessionFromStorage(history)}>Logout</Menu.Item>
          </Menu>
        )}>
          <Button>
            <Avatar
              className={"page-header-avatar"}
              size={42}
              icon={<UserOutlined />}
            />
            <div className={"page-header-details"}>
              <div className={"page-header-user"}>{userDetails.fullName}</div>
              <div className={"page-header-sub-label"}>{userDetails.position.name}</div>
            </div>
            <DownOutlined className={"page-header-dp-icon"} />
          </Button>
        </Dropdown>,
      ]}
    />
  );
};

const mapStateToProps = (states: RootState): StateProps => {
  return {
    userDetails: states.auth.state.userDetails
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    removeSessionFromStorage: (history) => dispatch(removeSessionFromStorage(history)),
  };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(PageHeaderSection));
