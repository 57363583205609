import { combineReducers } from "redux";
import { SET_CLAIM_HISTORY } from "../action-types";
import { ClaimHistoryActions, ClaimHistories } from "./model";

export interface ClaimHistoryState {
  state: ClaimHistories;
}

const INITIAL_STATE = {
  claimHistory: [],
};

const state = (
  state: ClaimHistories = INITIAL_STATE,
  action: ClaimHistoryActions
): ClaimHistories => {
  switch (action.type) {
    case SET_CLAIM_HISTORY:
      return { ...state, claimHistory: action.payload };
    default:
      return state;
  }
};

export default combineReducers<ClaimHistoryState>({ state });
