import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Typography, Alert } from "antd";
import { History } from "history";
import { RootState } from "../../redux";
import { BackgroundImageComponent, AuthenticationCardComponent, ResetPasswordForm } from "../../components";
import { ErrorMessageModel } from "../../redux/error-msg/model";
import _isEmpty from "lodash/isEmpty";
import { setResetPassword } from "../../redux/auth/service";
const { Text } = Typography;

interface OwnProps { history: History; }
interface StateProps { isAuthenticating: boolean; message: ErrorMessageModel; }
interface DispatchProps { doResetPassword: (email: string, history: History) => void; }
type Props = OwnProps & StateProps & DispatchProps;
const ResetPasswordPage: React.FC<Props> = ({
  history,
  message,
  isAuthenticating,
  doResetPassword,
}): JSX.Element => {
  const submit = (values: any) => {
    const { email } = values;
    doResetPassword(email, history);
  };

  const submitFailed = (errorInfo: any) => {
    console.log("*Debugger login submission failed", errorInfo);
  };

  const onChangeFields = () => {
    console.log("*Debugger onchange fields");
  };

  const renderLoginTitle = (): JSX.Element => (
    <Text className={"login-form-title"}>
      Please enter your email address:
    </Text>
  );

  const renderDisplayError = (): JSX.Element => {
    if (!_isEmpty(message.message)) {
      return (
        <div className={"mb-3"}>
          <Alert
            message={<Text style={{ fontSize: 18 }}>{message.message}</Text>}
            type="error"
            closable
          />
        </div>
      );
    }
    return <></>;
  };

  return (
    <BackgroundImageComponent>
      <AuthenticationCardComponent>
        {renderLoginTitle()}
        {renderDisplayError()}
        <ResetPasswordForm
          onSubmit={submit}
          onSubmitErr={submitFailed}
          onChange={onChangeFields}
          isLoading={isAuthenticating}
        />
      </AuthenticationCardComponent>
    </BackgroundImageComponent>
  );
};

const mapStateToProps = (states: RootState): StateProps => {
  return {
    isAuthenticating: states.auth.state.isAuthenticating,
    message: states.errorMsg.state,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    doResetPassword: (email, history) => {
      dispatch(setResetPassword(email, history));
    },
  };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage));
