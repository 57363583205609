import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import { Image, Form, Input, Row, message } from "antd";
import BackBlueArrow from "../../assets/images/backbluearrow.png";
import _isqual from "lodash/isEqual";
import "moment-timezone";
import _isEqual from "lodash/isEqual";
import _isNull from "lodash/isNull";
import _isEmpty from "lodash/isEmpty";
import { SubmitButtonComponent } from "..";
import { setTechnicianPasswordService } from "../../redux/auth/service";
interface ComponentProps {
  email: any;
  setType: any;
}
interface ReduxState {}
interface DispatchProps {
  setTechnicianPassword: (email: string, password: string) => any;
}
type Props = ComponentProps & ReduxState & DispatchProps;
const SetTechnicianPassword: React.FC<Props> = (props): JSX.Element => {
  const wrapperRef = useRef<any>(null);

  const formLayout = {
    wrapperCol: {
      xs: {
        offset: 0,
        span: 24,
      },
      sm: {
        offset: 3,
        span: 18,
      },
    },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    if (values.newPassword === values.retypeNewPassword) {
      const response = await props.setTechnicianPassword(props.email, values.newPassword);
      if (response.status === 200) {
        message.success("Password set successfully.")
        props.setType("addVendorTechnician")
      } else {
        message.error("An error occured while setting the password.");
      }
      setIsLoading(false)
    } else {
      message.error("New password and confirm password dont match.");
      setIsLoading(false);
    }
  };

  const onSubmitErr = (errorInfo: any) => {
    console.log("ERROR", errorInfo);
  };
  return (
    <div className="add-vendor-container" ref={wrapperRef}>
      <div
        style={{ color: "#5d97bc", cursor: "pointer" }}
        onClick={() => props.setType("addVendorTechnician")}
      >
        <Image
          style={{ width: 30, marginRight: 10 }}
          preview={false}
          src={BackBlueArrow}
        />
        Back
      </div>
      <Row align="middle" justify="center" style={{ marginTop: 20 }}>
        <div className="form-password-container" style={{ height: 320 }}>
          <h1 className="form-password-header">Set Password</h1>
          <div className="form-password-subContainer">
            <Form
              form={form}
              {...formLayout}
              name={"SetPasswordForm"}
              onFinish={onSubmit}
              onFinishFailed={onSubmitErr}
            >
              <Form.Item
                className="form-item-password"
                name="newPassword"
                rules={[
                  { required: true, message: "Please enter new password!" },
                ]}
              >
                <Input.Password placeholder="Please enter new password" />
              </Form.Item>
              <Form.Item
                className="form-item-password"
                name="retypeNewPassword"
                rules={[
                  { required: true, message: "Please re-type new password!" },
                  // { validator: }
                ]}
              >
                <Input.Password placeholder="Please confirm new password" />
              </Form.Item>
              <Form.Item>
                <SubmitButtonComponent label={"SUBMIT"} loading={isLoading} />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Row>
    </div>
  );
};

const mapStateToProps = (states: RootState): ReduxState => {
  return {};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    setTechnicianPassword: (email: string, password: string) =>
      dispatch(setTechnicianPasswordService(email, password)),
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(SetTechnicianPassword)
);
