import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import _isEqual from "lodash/isEqual";
import moment from "moment";

import BidOutLayout from "../layouts/modal/bid-out-layout";
import ServiceRequestDetailsLayout from "../layouts/modal/service-request-details-layout";

// STATE
import { UnassignedSR } from "../../redux/unassign-service-request/model";
import { fetchUnassignedSR } from "../../redux/unassign-service-request/service";
import { setUnassignedSRs, getUnassignedSRs } from "../../redux/unassign-service-request/action";

// ANTD
import {
  UserOutlined,
  HomeFilled,
  MoreOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { Tag, Collapse, Popover, Button, Modal } from "antd";

import _isNull from "lodash/isNull";
import { ServiceRequest } from "../../redux/service-request/model";
import { cancelServiceRequestById, unassignServiceRequest } from "../../redux/service-request/service";
import { getAssignedServiceRequests, setAssignedServiceRequests } from "../../redux/service-request/action";
import EditServiceRequestLayout from "../layouts/modal/edit-service-request-layout";
const { Panel } = Collapse;
const { confirm } = Modal;
interface OwnProps {
  setClaimHistoryShow: (isClaimHistoryComponent: boolean, requestId?: number) => void;
}

interface StateProps {
  unassignedSR: Array<UnassignedSR>;
}

interface DispatchProps {
  fetchUnassignedSR: () => void;
  setUnassignedSRs: (unassignedSR: Array<UnassignedSR>) => void;
  getUnassignedSRs: () => any;
  unassignServiceRequest: (id: number) => any;
  getAssignedServiceRequests: () => any;
  setAssignedServiceRequests: (serviceRequests: Array<ServiceRequest>) => void;
  cancelServiceRequestById: (id: any) => any;
}

type Props = OwnProps & StateProps & DispatchProps;

const SchedulerSidebar: React.FC<Props> = ({
  unassignedSR,
  fetchUnassignedSR,
  setClaimHistoryShow,
  unassignServiceRequest,
  setAssignedServiceRequests,
  cancelServiceRequestById,
  getUnassignedSRs
}): JSX.Element => {

  const [activeCollapse, setActiveCollapse] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    fetchUnassignedSR();
    window.addEventListener("reloadSchedulerSideBar", updateUnassignedSR);
    return () => window.removeEventListener("reloadSchedulerSideBar", updateUnassignedSR);

  }, []);

  React.useEffect(() => {
    window.addEventListener("scrollToUnassignedSR", onScrollPendingServiceRequest)
    window.addEventListener("newUnscheduledRequestCreated", addUnassignedSR);
    window.addEventListener("bidOutCompleted", updateUnassignedSR);
    return () => {
      window.removeEventListener("scrollToUnassignedSR", onScrollPendingServiceRequest);
      window.removeEventListener("newUnscheduledRequestCreated", addUnassignedSR);
      window.removeEventListener("bidOutCompleted", updateUnassignedSR);
    }
  }, [unassignedSR]);

  const addUnassignedSR = (event: Event) => {
    //@ts-ignore
    fetchUnassignedSR();
  }

  const updateUnassignedSR = (event: Event) => {

    fetchUnassignedSR();
    /*
    //@ts-ignore
    const {request} = event.detail;
    const index = unassignedSR.findIndex((unassigned: UnassignedSR) => {
      return request.id == unassigned.id
    });
    //unassignedSR[index] = request;
    //setUnassignedSRs(unassignedSR);
    //setForceRender(forceRender + 1);
    */
  }


  const onScrollPendingServiceRequest = (param: any) => {
    if (!_isEqual(unassignedSR.length, 0)) {
      unassignedSR?.map((service: UnassignedSR) => {
        const srElement: HTMLElement | null = document.getElementById(`Service Request #${service.serviceRequestNo} Unassigned`);
        if (!_isNull(srElement)) {
          srElement.style.border = 'unset'
        }
      })

      const title = param.detail.title;
      const currentElement: HTMLElement | null = document.getElementById(title);
      if (!_isNull(currentElement)) {
        currentElement.style.border = '1px solid black';
        currentElement.scrollIntoView({ block: "start", inline: "nearest" });
      }
    }
  }

  const openBidOut = (serviceRequest: UnassignedSR) => {
    window.dispatchEvent(new CustomEvent('bidOutRequested', { detail: { request: serviceRequest } }));
  }

  const openServiceRequestDetails = (serviceRequest: UnassignedSR) => {
    window.dispatchEvent(new CustomEvent('serviceRequestDetailsRequested', { detail: { request: serviceRequest } }));
 }

  const onChangeActiveCollapse = (activeItem: any) => {
    setActiveCollapse(activeItem);
  };

  function getTimeDiff(val: any, durationTime: number) {
    let formatDuration = "";
    if (durationTime) {
      const newDurationTime = durationTime / 60;
      formatDuration = `${newDurationTime.toString()} ${newDurationTime < 2 ? "hr" : "hrs"
        }`;

      const fromDuration = moment(val).format("h:mm a");
      const toDuration = moment(val)
        .add(newDurationTime, "hours")
        .format("h:mm a");
      return `${fromDuration}-${toDuration} ${formatDuration}`;
    }
    return null;
  }

  function getFieldValue(shortName: string, formValues: any[]): string {

    for (let i = 0; i < formValues.length; i++) {
      const formValue = formValues[i];
      if (formValue.field.shortName === shortName) {
        return formValue.value;
      }
    }
    return '';
  }

  const onStartDragging = (event: React.DragEvent<HTMLDivElement>, serviceRequest: any) => {
    event.dataTransfer.setData('service-request', JSON.stringify(serviceRequest));
  }

  const onDraggingOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  };

  const onDraggingEnd = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const stringdata = event.dataTransfer.getData("unassigning-service-request");
    const unassignedServiceRequest: ServiceRequest = JSON.parse(stringdata);
    confirm({
      title: 'Please confirm this action...',
      icon: <ExclamationCircleOutlined />,
      content: (
        <span>Are you sure you want to unassign this service request ?</span>
      ),
      onOk: async () => {
        const response: Array<UnassignedSR> = await unassignServiceRequest(unassignedServiceRequest.id);
        setUnassignedSRs(response);
        let assignedServiceRequests: Array<ServiceRequest> = getAssignedServiceRequests().payload;
        const ndx: any = assignedServiceRequests.findIndex(assignedSR => _isEqual(assignedSR.id, unassignedServiceRequest.id));
        if (!_isEqual(ndx, -1)) {
          assignedServiceRequests.splice(ndx, 1);
          setAssignedServiceRequests([...assignedServiceRequests]);
          window.dispatchEvent(new Event("reloadSchedulerGridComponent"));
        }
      },
      onCancel: () => {
        console.log('Cancel')
      }
    });

  }

  const getHeader = (
    index: number,
    activeCollapse: any,
    service: UnassignedSR
  ) => {
    return (
      <div
        className={`ss-card-header py-2 px-3 pointer 
          ${activeCollapse ? "br-t-3" : "br-3"} 
          ${service.currentStatus == 'BID_OUT'
            ? "ss-bidout-header"
            : "ss-pending-header"
          }`}
        key={index}
      >
        <div className="fs-3 text-bold1">
          {getFieldValue('PRP', service.requestFormValues)}
        </div>
        <div className="fs-small">SR# {service.serviceRequestNo}</div>
        <div className="fs-small">{service.scheduleDateTimeStamp ? moment(service.scheduleDateTimeStamp).format('DD-MMM-YYYY hh:mma') : 'N/A'}</div>
        <div className="flex">
          <section className="half-width float-left fs-small">
            {service.durationInMinutes} min
          </section>
          <div className="half-width float-left" style={{ marginRight: -16 }}>
            <section className="float-right">
              <Tag
                color={`${service.currentStatus == 'BID_OUT' ? "#aa00d4" : "#f00"}`}
                className="br-normal fs-xsmall text-white">
                {service.currentStatus}
              </Tag>
            </section>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="scheduler-sidebar"
      onDragOver={(e) => onDraggingOver(e)}
      onDragEnd={(e) => onDraggingEnd(e)}
      onDrop={(e) => onDrop(e)}
    >
      <div className="ss-header px-1">
        <div className="text-bold2 mt-3 fs-1 text-black">PENDING SRs:</div>
      </div>

      {!_isEqual(unassignedSR.length, 0) && _isEqual(loading, false) &&
        unassignedSR.map((service: UnassignedSR, index: number) => (

          <div
            id={`Service Request #${service.serviceRequestNo} Unassigned`}
            key={`scheduler-sidebar-${index}`}
            onDragOver={(e) => onDraggingOver(e)}
            onDragStart={(e) => onStartDragging(e, service)}
            onDragEnd={(e) => onDraggingEnd(e)}
            onDrop={(e) => onDrop(e)}
            draggable={
              !_isEqual(service.currentStatus, "BID_OUT") &&
              !_isEqual(service.currentStatus, "SERVICE_COMPLETED") &&
              !_isEqual(service.currentStatus, "COMPLETED") &&
              !_isEqual(service.currentStatus, "INVOICE_SUBMITTED")
            }>
            <Collapse
              key={index}
              accordion
              bordered={false}
              onChange={onChangeActiveCollapse}
              activeKey={activeCollapse}
              className={`my-3 ss-card mx-1 ${activeCollapse ? "br-t-4" : "br-4"} 
                ${service.currentStatus == 'BID_OUT' ? "ss-card-bidout" : "ss-card-pending"}`}>
              <Panel
                key={index}
                showArrow={false}
                header={getHeader(index, activeCollapse, service)}>
                <div className="ss-body">
                  <div className="fs-small my-1">
                    <UserOutlined />
                    &#160;&#160;{getFieldValue('MEM', service.requestFormValues)}
                  </div>
                  <div className="fs-small my-1">
                    <HomeFilled />
                    &#160;&#160;{getFieldValue('PRPA', service.requestFormValues)}
                  </div>
                  <Popover
                    key={index}
                    className="ss-bidout pointer"
                    placement="rightBottom"
                    content={
                      <div>
                        <Button type="text" className="disp-block" onClick={() => setClaimHistoryShow(false, service.id)}>
                          Open Claim History
                        </Button>
                        <Button type="text" className="disp-block" onClick={() => openServiceRequestDetails(service)}>
                          Open SR Details
                        </Button>
                        <Button type="text" className="disp-block" style={{ width: '100%', textAlign: 'left' }} disabled={service.currentStatus == 'BID_OUT'} onClick={() => openBidOut(service)}>
                          Bidout
                        </Button>
                        <Button type="text" className="disp-block" onClick={() => {
                          Modal.confirm({
                            title: 'Confirm',
                            icon: <ExclamationCircleOutlined />,
                            content: <div>
                              <p>Are you sure to cancel {`SR# ${service.serviceRequestNo}`}? </p>
                            </div>,
                            okText: 'ok',
                            cancelText: 'cancel',
                            onOk: async () => {
                              setLoading(true)
                              let unassignedServiceReq: Array<UnassignedSR> = getUnassignedSRs().payload;
                              const ndx: any = unassignedServiceReq.findIndex(assignedSR => _isEqual(assignedSR.id, service.id));
                              await cancelServiceRequestById(service.id);
                              if (!_isEqual(ndx, -1)) {
                                unassignedServiceReq.splice(ndx, 1);
                                setUnassignedSRs([...unassignedServiceReq]);
                              }
                              setLoading(false)
                            }
                          });
                        }}>
                          Cancel Service Request
                        </Button>
                      </div>
                    }
                  >
                    <MoreOutlined />
                  </Popover>
                </div>
              </Panel>
            </Collapse>
          </div>
        ))}
      <BidOutLayout />
      <ServiceRequestDetailsLayout />
      <EditServiceRequestLayout/>
    </div>
  );
};

const mapStateToProps = (states: RootState): StateProps => {
  return {
    unassignedSR: states.unassignedSRReducer.state.unassignedSR,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    fetchUnassignedSR: () => dispatch(fetchUnassignedSR()),
    setUnassignedSRs: (srs: Array<UnassignedSR>) => dispatch(setUnassignedSRs(srs)),
    getUnassignedSRs: () => dispatch(getUnassignedSRs()),
    getAssignedServiceRequests: () => dispatch(getAssignedServiceRequests()),
    unassignServiceRequest: (id) => dispatch(unassignServiceRequest(id)),
    setAssignedServiceRequests: (sr) => dispatch(setAssignedServiceRequests(sr)),
    cancelServiceRequestById: (id) => dispatch(cancelServiceRequestById(id))
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(SchedulerSidebar)
);
