import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { History } from "history";
import { BASIC_AUTH, SERVER_URL } from "../../configs/service-config";
import { getRequest, getRequestWithJson, postRequest, postRequestWithJson } from "../interceptor";
import {
   setIsAuthenticating,
   setResetPasswordfields,
   setSessionCredentials,
   setSessionCredentialsToStorage,
   setUserDetails,
   getUserDetails,
   setClientId,
   getClientId,
} from "./actions";
import { LoginFormFields, SessionCredentials, UserDetails } from "./model";
import { setLoginErrorMessage } from "../error-msg/action";
import _isNull from "lodash/isNull";
import _isEqual from "lodash/isEqual";
import _isUndefined from "lodash/isUndefined";
import { message } from "antd";

export const refreshAccessToken = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
      if (!_isNull(dataStore)) {
         const { refreshToken }: SessionCredentials = JSON.parse(dataStore);
         const authenticatingService = new URL(SERVER_URL.LOGIN_ENDPOINT);
         authenticatingService.searchParams.append("grant_type", "refresh_token");
         authenticatingService.searchParams.append("access_type", "admin");
         authenticatingService.searchParams.append("refresh_token", refreshToken);

         const options = {
            method: 'POST',
            headers: {
               Authorization: BASIC_AUTH
            },
         }
         const response = await fetch(authenticatingService.toString(), options);
         if (response['status'] == 200) {
            const results = await response.json();
            dispatch(setSessionCredentials(results));
            dispatch(setSessionCredentialsToStorage({ refreshToken: results.refresh_token, accessToken: results.access_token }));
         } else {
            console.log('Error Message -> ', response)
            localStorage.removeItem("SESSION_CREDENTIALS");
            window.location.href = window.location.origin + "/login";
         }
      }
   };
};

export const initializeUserDetails = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      await dispatch(fetchUserDetails());
   };
};

export const fetchUserDetails = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const clientId: any = dispatch(getClientId());
      if (!_isNull(clientId)) {
         const endpoint = `${SERVER_URL.USER_DETAILS_ENDPOINT}?clientId=${clientId}`;
         await getRequestWithJson(dispatch, endpoint)
            .then((response: UserDetails) => {
               if (!_isUndefined(response.clientId)) {
                  dispatch(setClientId(response.clientId));
               }
               dispatch(setUserDetails(response));
            })
            .catch((error: any) => {
               console.log('Error Message -> while fetching user details', error);
            });
      }
   };
};

export const fetchUserByEmail = (email: string): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const clientId: any = dispatch(getClientId());
      const fetchUserDetailsService = new URL(SERVER_URL.USER_BY_EMAIL);
      fetchUserDetailsService.searchParams.append("clientId", "1");
      fetchUserDetailsService.searchParams.append("email", email);
      let result = null;

      await getRequest(dispatch, fetchUserDetailsService)
         .then((response: any) => {
            result = response;
         })
         .catch((error: any) => {
            console.log('Error Message -> while fetching user by email', error);
         });
      return result;
   };
};

export const formLoginSubmission = (loginFormFields: LoginFormFields, history: History): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      dispatch(setIsAuthenticating(true));
      const authenticatingService = new URL(SERVER_URL.LOGIN_ENDPOINT);
      authenticatingService.searchParams.append("grant_type", "password");
      authenticatingService.searchParams.append("access_type", "admin");
      authenticatingService.searchParams.append(
         "username",
         loginFormFields.username
      );
      authenticatingService.searchParams.append(
         "password",
         loginFormFields.password
      );
      await postRequestWithJson(dispatch, authenticatingService)
         .then((response: any) => {
            dispatch(setSessionCredentials(response));
            dispatch(
               setSessionCredentialsToStorage({
                  refreshToken: response.refresh_token,
                  accessToken: response.access_token,
               })
            );
            dispatch(setIsAuthenticating(false));
            history.push("/scheduler");
         })
         .catch((error: any) => {
            dispatch(setIsAuthenticating(false));
            dispatch(
               setLoginErrorMessage(
                  error.status,
                  "Invalid email or password",
                  "An error occur in login service"
               )
            );
            console.log('Error Message -> while login submission', error);
         });
   };
};

export const setResetPassword = (email: string, history?: History): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const clientId: any = dispatch(getClientId());
      dispatch(setResetPasswordfields(email));
      dispatch(setIsAuthenticating(true));
      await postRequest(dispatch, SERVER_URL.resetPassword, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            clientId: clientId,
            email,
            loginMode: "RESET",
         }),
      })
         .then((response) => {
            message.success("Reset password successfully");
            if (history) history.push("/login");
         })
         .catch((error: any) => {
            dispatch(
               setLoginErrorMessage(
                  error.status,
                  "Invalid email",
                  "An error occur in login service"
               )
            );
            console.log('Error Message -> while set reset password', error);
         });
      dispatch(setIsAuthenticating(false));
   };
};

export const resetWithSetPassword = (email: string, password: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const clientId: any = dispatch(getClientId());

      let jsonRaw = {
         clientId: clientId,
         email,
         password,
         loginMode: null,
      }

      const requestOptions = {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify(jsonRaw),
      }
      const resetWithSetPasswordURL = new URL(SERVER_URL.RESET_WITH_SETPASSWORD);
      const resetWithSetPasswordResponse = await postRequest(dispatch, resetWithSetPasswordURL, requestOptions);
      return resetWithSetPasswordResponse;
   };
};

export const validatePassword = (password: string): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = SERVER_URL.VALIDATE_PASSWORD;
      const userDetails = dispatch(getUserDetails()).payload;
      let result = false;
      await postRequest(dispatch, endpoint.toString(), {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            id: userDetails.id,
            password,
         }),
      })
         .then(() => {
            result = true;
         })
         .catch((error: any) => {
            console.log('Error Message -> while validate password', error);
         });

      return result;
   };
};

export const changePassword = (password: string, history: History): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const endpoint = SERVER_URL.CHANGE_PASSWORD_ENDPOINT;
      const userDetails = dispatch(getUserDetails()).payload;
      await postRequest(dispatch, endpoint.toString(), {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            id: userDetails.id,
            password,
         }),
      })
         .then(() => { })
         .catch((error: any) => {
            message.error("An error occured while changing the password", 3);
            console.log('Error Message -> while validate password', error);
         });
   };
};

export const setTechnicianPasswordService = (email: string, password: string): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const clientId: any = dispatch(getClientId());
      const endpoint = SERVER_URL.SET_TECHNICIAN_PASSWORD_ENDPOINT;
      let result = null;
      await postRequest(dispatch, endpoint.toString(), {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            clientId: clientId,
            email,
            password,
            resetMode: "RESET",
         }),
      })
         .then((response) => {
            result = response;
         })
         .catch((error: any) => {
            message.error("An error occured while changing the password", 3);
            console.log('Error Message -> while set technician password', error);
         });
      return result;
   };
};

