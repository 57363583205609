import { GET_ASSIGNED_SERVICE_REQUESTS, SET_ASSIGNED_SERVICE_REQUESTS } from "../action-types";
import { GetAssignedServiceRequests, ServiceRequest, SetAssignedServiceRequests } from "./model";

import store from "..";

export const setAssignedServiceRequests = (payload: Array<ServiceRequest>): SetAssignedServiceRequests => {
  return {
    type: SET_ASSIGNED_SERVICE_REQUESTS,
    payload
  };
};

export const getAssignedServiceRequests = (): GetAssignedServiceRequests => {
  return {
    type: GET_ASSIGNED_SERVICE_REQUESTS,
    payload: store.getState().serviceRequestReducer.state.assignedServiceRequests
  };
};