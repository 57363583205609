import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import {
  Typography,
  Popover,
  Collapse,
  Tag,
  message,
  Modal,
  Button,
} from "antd";
import {
  ExclamationCircleOutlined,
  UserOutlined,
  HomeFilled,
  CalendarFilled,
  PaperClipOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {
  ClockType,
  SchedulerType,
  Schedules,
  Time,
  Toolbar,
} from "../../redux/scheduler/model";
import { Technician } from "../../redux/technician/model";
import { cardColorCode, getStatusColorCode } from "../../helpers/constants";
import "./SchedulerGridComponent.scss";

import _isNull from "lodash/isNull";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import _isUndefined from "lodash/isUndefined";
import _debounce from "lodash/debounce";
import moment, { Moment } from "moment";
import {
  AssignServiceRequest,
  ServiceRequest,
} from "../../redux/service-request/model";
import { UnassignedSR } from "../../redux/unassign-service-request/model";
import {
  assignServiceRequest,
  cancelServiceRequestById,
  cancelServiceRequestWithRelated,
  fetchAssignedServiceRequest,
  getIndexTimePeriod,
} from "../../redux/service-request/service";
import {
  getAssignedServiceRequests,
  setAssignedServiceRequests,
} from "../../redux/service-request/action";
import { constructSchedulerGrid } from "../../redux/scheduler/service";
import {
  getSchedulerGridDaily,
  setSchedulerGrid,
} from "../../redux/scheduler/action";
import CancelServiceRequest from "../layouts/modal/cancel-service-request";
import { NumericDictionary } from "lodash";

const { Text } = Typography;
const { Panel } = Collapse;
const { confirm } = Modal;

interface ComponentProps {
  reload: any;
}
interface ReduxState {
  assignedServiceRequests: Array<ServiceRequest>;
  technicians: Array<Technician>;
  grid: Schedules;
  clock: Array<Time>;
  toolbar: Toolbar;
  schedulerType: SchedulerType;
  unassignedSR: Array<UnassignedSR>;
}
interface DispatchProps {
  getIndexTimePeriod: (startTime: string, endTime: string) => void;
  assigningServiceRequest: (serviceRequest: AssignServiceRequest) => any;
  getAssignedServiceRequests: () => any;
  setAssignedServiceRequests: (serviceRequests: Array<ServiceRequest>) => void;
  setSchedulerGridDaily: (grid: Schedules) => void;
  getSchedulerGridDaily: () => any;
  cancelServiceRequestById: (id: any) => any;
  cancelServiceRequestWithRelated: (srNumber: any) => any;
  fetchAssignedServiceRequest: () => void;
}

type Props = ComponentProps & ReduxState & DispatchProps;
const GridSchedulerDaily: React.FC<Props> = (props): JSX.Element => {
  const [technicians, setTechnicians] = React.useState<Array<Technician>>([]);
  const [srNo, setSrNo] = React.useState<any>(null);
  const [messageError, setMessageError] = React.useState("");
  const [grid, setGrid] = React.useState<Schedules>([]);
  const [timeline, setTimeline] = React.useState<Array<Time>>();
  const [activeCollapse, setActiveCollapse] = React.useState([]);
  const [toolbar, setToolbar] = React.useState<Toolbar>();
  const [showCancelModal, setShowCancelModal] = React.useState<boolean>(false);
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);

  const [activeSR, setActiveSR] = React.useState<ServiceRequest>();

  React.useEffect(() => {
    setViewToCurrentTime();
  }, [timeline]);

  React.useEffect(() => {
    setTechnicians(props.technicians);
    setGrid(props.grid);
    setTimeline(props.clock);
    setToolbar(props.toolbar);
  }, [props.grid]);

  React.useEffect(() => {
    displayError();
  }, [messageError]);


  React.useEffect(() => {
    window.addEventListener("redirectSR", redirectSR);
    return () => {
      window.removeEventListener("redirectSR", redirectSR);
    }
  }, []);

  React.useEffect(() => calculateCellHeight(), [props.schedulerType, grid]);

  const onChangeActiveCollapse = (activeItem: any) =>
    setActiveCollapse(activeItem);

  const calculateCellHeight = () => {
    if (!_isEqual(technicians.length, 0)) {
      technicians.forEach((technician: Technician) => {
        const weeklyCellElement: HTMLElement | null = document.getElementById(
          `daily_grid_cell_${technician.id}`
        );
        const technicianElement: HTMLElement | null = document.getElementById(
          `technician_ref_${technician.id}`
        );
        if (!_isNull(weeklyCellElement) && !_isNull(technicianElement)) {
          technicianElement.style.minHeight = `${weeklyCellElement.offsetHeight}px`;
        }
      });
    }
  };

  const setViewToCurrentTime = () => {
    if (!_isEqual(timeline?.length, 0)) {
      timeline?.map((time) => {
        const timeElement: HTMLElement | null = document.getElementById(
          `daily_cell_time_${time.time}`
        );

        if (!_isNull(timeElement)) {
          timeElement.style.borderLeft = "unset";
        }
      });
    }

    const currentDate: any = moment(moment(), "MMMM Do YYYY, h:mm:ss a");
    let currentTime = null;
    if (currentDate.minute() > 29) {
      currentTime = moment(currentDate)
        .subtract(currentDate.minute(), "minutes")
        .add(30, "minutes")
        .format("h:mm A");
    } else {
      currentTime = moment(currentDate)
        .subtract(currentDate.minute(), "minutes")
        .format("h:mm A");
    }

    const currentTimeElement: HTMLElement | null = document.getElementById(
      `daily_cell_time_${currentTime}`
    );

    const srNo = localStorage.getItem("redirectSR");
    const canFindSR = props.assignedServiceRequests.find((sr) => sr.serviceRequestNo === srNo || sr.serviceRequestNo === `${srNo}`)
    if (
      !_isNull(currentTimeElement) &&
      _isEqual(currentTimeElement.id, `daily_cell_time_${currentTime}`)
    ) {
      currentTimeElement.style.borderLeft = "1px solid red";
      if (_isNull(srNo) || _isUndefined(canFindSR)) {
        currentTimeElement.scrollIntoView({ block: "end" });
      }
    }

    if (!_isNull(srNo)) {
      if (_isUndefined(canFindSR)) {
        const canFindSRA = props.assignedServiceRequests.find((sr) => sr.serviceRequestNo === `${srNo}`)
        const canFindSRA2 = props.unassignedSR.find((sr) => sr.serviceRequestNo === `${srNo}`)
        if (!_isUndefined(canFindSRA)) {
          setSrNo(canFindSRA.serviceRequestNo)
        } else if (!_isUndefined(canFindSRA2)) {
          setSrNo(canFindSRA2.serviceRequestNo)
        } else {
          setMessageError("Cannot find Service Request in Schedule Board.")
        }
      }
      else {
        setSrNo(canFindSR.serviceRequestNo)
      }
    }


  };

  const handleModal = (
    id: number,
    vendorId: number,
    time: Time,
    techIndex: any,
    cellIndex: any
  ) => {
    if (_isEqual(grid[techIndex][cellIndex + 1], null)) {
      let event = new CustomEvent("openCreateRequest", {
        detail: {
          technicianId: id,
          vendorId: vendorId,
          time: time,
          date: props.toolbar.dateFilter,
        },
      });
      window.dispatchEvent(event);
    }
  };


  const openServiceRequestDetails = (
    serviceRequest: ServiceRequest,
    technicians: Array<Technician>
  ) => {
    window.dispatchEvent(
      new CustomEvent("serviceRequestDetailsRequested", {
        detail: { request: serviceRequest, technicians: technicians },
      })
    );
  };

  const openEditServiceRequest = (serviceRequest: ServiceRequest) => {
    window.dispatchEvent(
      new CustomEvent("editServiceRequestDetailRequested", {
        detail: { request: serviceRequest },
      })
    );
  };

  const displayError = async () => {
    if (messageError !== "") {
      await message.error(messageError);
      localStorage.removeItem("redirectSR");
      setMessageError("");
    }
  };

  const redirectSR = () => {
    const currentElement: HTMLElement | null = document.getElementById(`daily_focus_${srNo}`);
    if (!_isNull(currentElement)) {
      currentElement.scrollIntoView({ block: "center", inline: "nearest", behavior: 'auto' });
    }
    localStorage.removeItem("redirectSR");
  };

  const cancelSingleSR = async () => {
    if (!_isUndefined(activeSR)) {
      let asr: Array<ServiceRequest> = props.getAssignedServiceRequests().payload;
      const ndx: any = asr.findIndex((sr) => _isEqual(sr.id, activeSR?.id));
      if (!_isEqual(ndx, -1)) {
        asr.splice(ndx, 1);
        props.setAssignedServiceRequests(asr);
        const response = await props.cancelServiceRequestById(activeSR?.id);
        if (_isEqual(response.statusCode, 200)) {
          setShowCancelModal(false);
          window.dispatchEvent(new Event("reloadSchedulerGridComponent"));
        }
      }
    }
  }

  const cancelSRWithRelated = async () => {
    if (!_isUndefined(activeSR)) {
      let asr: Array<ServiceRequest> = props.getAssignedServiceRequests().payload;
      const ndx: any = asr.findIndex((sr) => _isEqual(sr.id, activeSR?.id));
      if (!_isEqual(ndx, -1)) {
        asr.splice(ndx, 1);
        props.setAssignedServiceRequests(asr);
        const response = await props.cancelServiceRequestWithRelated(activeSR?.serviceRequestNo);
        if (_isEqual(response.statusCode, 200)) {
          window.dispatchEvent(new Event("reloadSchedulerGridComponent"));
          setShowCancelModal(false);
        }
      }
    }
  }

  const renderCell = (
    obj: Technician,
    technicianCellIndex: number,
    timeIndex: number
  ) => {
    if (
      !_isUndefined(timeline) &&
      !_isEqual(timeline.length, 0) &&
      !_isUndefined(grid) &&
      !_isUndefined(grid[technicianCellIndex]) &&
      !_isEqual(grid[technicianCellIndex][timeIndex], null) &&
      !_isEqual(grid[technicianCellIndex][timeIndex], "x")
    ) {
      if (
        _isEqual(grid[technicianCellIndex][timeIndex].serviceRequestNo, srNo)
      ) {
        redirectSR();
      }
      return (
        <div
          id={`daily_focus_${grid[technicianCellIndex][timeIndex].serviceRequestNo}`}
          style={{
            display: "flex",
            flexDirection: "column",
            height: 100,
            minHeight: 100,
            width: grid[technicianCellIndex][timeIndex].caculatedWidth + 78,
          }}
        >
          <Collapse
            className="daily-grid-cell-collapse"
            onChange={onChangeActiveCollapse}
            activeKey={activeCollapse}
            style={{
              border: _isEqual(
                grid[technicianCellIndex][timeIndex].serviceRequestNo,
                srNo
              )
                ? "3px solid #3a78b3ff"
                : "none",
              height: "inherit",
              width: grid[technicianCellIndex][timeIndex].caculatedWidth,
              backgroundColor: cardColorCode(
                grid[technicianCellIndex][timeIndex].currentStatus
              ),
              zIndex: _isEqual(
                activeCollapse,
                `daily_grid_cell_panel_${grid[technicianCellIndex][timeIndex].id}_${obj.id}`
              )
                ? 12
                : 8,
            }}
            accordion
          >
            <Panel
              key={`daily_grid_cell_panel_${grid[technicianCellIndex][timeIndex].id}_${obj.id}`}
              showArrow={false}
              header={
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {_isEqual(
                    grid[technicianCellIndex][timeIndex].linked,
                    true
                  ) ? (
                    <PaperClipOutlined
                      style={{
                        position: "absolute",
                        alignSelf: "flex-end",
                      }}
                    />
                  ) : null}
                  <Text
                    ellipsis={true}
                    className={
                      _isEqual(
                        grid[technicianCellIndex][timeIndex].currentStatus,
                        "SERVICE_COMPLETED"
                      ) ||
                        _isEqual(
                          grid[technicianCellIndex][timeIndex].currentStatus,
                          "INVOICE_SUBMITTED"
                        )
                        ? "font-color-white sr-header"
                        : "font-color-2 sr-header"
                    }
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      width: 142,
                    }}
                    onClick={(event: any) => {
                      event.stopPropagation();
                      openServiceRequestDetails(
                        grid[technicianCellIndex][timeIndex],
                        props.technicians
                      );
                    }}
                  >
                    {grid[technicianCellIndex][timeIndex].title}
                  </Text>
                  <Text
                    className={
                      _isEqual(
                        grid[technicianCellIndex][timeIndex].currentStatus,
                        "SERVICE_COMPLETED"
                      ) ||
                        _isEqual(
                          grid[technicianCellIndex][timeIndex].currentStatus,
                          "INVOICE_SUBMITTED"
                        )
                        ? "font-color-white"
                        : "font-color-2"
                    }
                    style={{ fontSize: 12 }}
                  >
                    {`SR# ${grid[technicianCellIndex][timeIndex].serviceRequestNo}`}{" "}
                    <br />
                    {`${grid[technicianCellIndex][timeIndex].timeStamp.startTime}-${grid[technicianCellIndex][timeIndex].timeStamp.endTime}`}
                  </Text>
                  <Tag
                    className={
                      _isEqual(
                        grid[technicianCellIndex][timeIndex].currentStatus,
                        "ENROUTE"
                      ) ||
                        _isEqual(
                          grid[technicianCellIndex][timeIndex].currentStatus,
                          "SERVICE_COMPLETED"
                        ) ||
                        _isEqual(
                          grid[technicianCellIndex][timeIndex].currentStatus,
                          "INVOICE_SUBMITTED"
                        )
                        ? "font-color-white"
                        : "font-color-2"
                    }
                    style={{
                      position: "absolute",
                      bottom: 8,
                      right: 4,
                      fontSize: 10,
                      fontWeight: 700,
                      alignSelf: "flex-end",
                      backgroundColor: getStatusColorCode(
                        grid[technicianCellIndex][timeIndex].currentStatus
                      ),
                    }}
                  >
                    {grid[technicianCellIndex][timeIndex].currentStatus.replace(
                      /_/g,
                      " "
                    )}
                  </Tag>
                </div>
              }
            >
              <UserOutlined />
              &#160;&#160;
              <Text className="font-color-2" style={{ fontSize: 11 }}>
                {grid[technicianCellIndex][timeIndex].members?.name}
              </Text>{" "}
              <br />
              <Text className="font-color-2" style={{ fontSize: 11, width: 120, display: 'inline-block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                <HomeFilled />    {grid[technicianCellIndex][timeIndex].members?.propertyAddress}
              </Text>{" "}
              <br />
              <CalendarFilled />
              &#160;&#160;
              <Text className="font-color-2" style={{ fontSize: 11 }}>
                {moment(
                  grid[technicianCellIndex][timeIndex].createDateTimeStamp
                ).format("LL")}
              </Text>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Popover
                  placement="rightBottom"
                  visible={showTooltip}
                  onVisibleChange={() => setShowTooltip(!showTooltip)}
                  content={
                    <div>
                      <Button
                        type="text"
                        className="disp-block"
                        onClick={() => {
                          setShowCancelModal(true);
                          setShowTooltip(false);
                          setActiveSR(grid[technicianCellIndex][timeIndex]);
                        }}
                      >
                        Cancel Service Request
                      </Button>
                      {grid[technicianCellIndex][timeIndex].currentStatus !==
                        "INVOICE_SUBMITTED" &&
                        grid[technicianCellIndex][timeIndex].currentStatus !==
                        "COMPLETED" ? (
                        <Button
                          type="text"
                          className="disp-block"
                          onClick={(e) => {
                            openEditServiceRequest(
                              grid[technicianCellIndex][timeIndex]
                            );
                          }}
                        >
                          Edit Service Request
                        </Button>
                      ) : null}
                      <CancelServiceRequest
                        show={showCancelModal}
                        setShow={setShowCancelModal}
                        cancelSingleSR={cancelSingleSR}
                        cancelSRWithRelated={cancelSRWithRelated}
                        sr={activeSR}
                        grid={grid}
                      />
                    </div>
                  }
                >
                  <MoreOutlined style={{ fontSize: 16 }} />
                </Popover>
              </div>
            </Panel>
          </Collapse>

        </div>
      );
    }
    return null;
  };

  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    serviceRequest: any,
    time: any,
    technicianCellIndex: any
  ) => {
    event.dataTransfer.setData(
      "service-request",
      JSON.stringify(serviceRequest)
    );
    event.dataTransfer.setData(
      "unassigning-service-request",
      JSON.stringify(serviceRequest)
    );
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>, id: number) => {
    event.preventDefault();
  };

  const onDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const onDrop = (
    event: React.DragEvent<HTMLDivElement>,
    technicianId: number,
    vendorId: number,
    time: Time
  ) => {
    const stringdata = event.dataTransfer.getData("service-request");
    const serviceRequest: ServiceRequest = JSON.parse(stringdata);
    if (
      !_isEqual(serviceRequest.currentStatus, "ONSITE") &&
      !_isEqual(serviceRequest.currentStatus, "COMPLETED") &&
      !_isEqual(serviceRequest.currentStatus, "SERVICE_COMPLETED") &&
      !_isEqual(serviceRequest.currentStatus, "INVOICE_SUBMITTED")
    ) {
      const currentTechnicianIndex: any = technicians.findIndex((tech) =>
        _isEqual(tech.id, technicianId)
      );

      confirm({
        title: "Please confirm this action...",
        icon: <ExclamationCircleOutlined />,
        content: (
          <span>
            Are you sure you want to assign to{" "}
            {technicians[currentTechnicianIndex].fullName} at {time.time}?
          </span>
        ),
        onOk: async () => {
          const timeStamp = moment(time.time, "hh:mm A");
          const startTime = timeStamp.format("h:mm A");
          const endTime = timeStamp
            .clone()
            .add(serviceRequest.durationInMinutes, "minutes")
            .format("h:mm A");

          const collectionIndexes: any = await props.getIndexTimePeriod(
            startTime,
            endTime
          );
          if (checkIfEmptyTimeslot(collectionIndexes, currentTechnicianIndex)) {
            let assignedServiceRequests: Array<ServiceRequest> = props.getAssignedServiceRequests()
              .payload;

            const response: ServiceRequest = await props.assigningServiceRequest(
              {
                newTechnicianId: technicianId,
                vendorId: vendorId,
                scheduledDateTimestamp:
                  moment(toolbar?.dateFilter).format("YYYYMMDD") +
                  timeStamp.format("hhmmaZ"),
                serviceRequestId: serviceRequest.id,
              }
            );
            const assignedIndex: any = assignedServiceRequests.findIndex(
              (assignedSR) => _isEqual(assignedSR.id, response.id)
            );

            if (!_isEqual(assignedIndex, -1)) {
              assignedServiceRequests[assignedIndex] = response;
            } else {
              assignedServiceRequests.push(response);
            }

            props.setAssignedServiceRequests([...assignedServiceRequests]);
            window.dispatchEvent(new Event("reloadSchedulerGridComponent"));
            window.dispatchEvent(new Event("reloadSchedulerSideBar"));
          } else {
            _debounce(message.error("This time slot is not available"), 1000);
          }
        },
        onCancel: () => {
          console.log("Cancel");
        },
      });
    }
  };

  const checkIfEmptyTimeslot = (
    collectionIndexes: any,
    technicianIndex: number
  ) => {
    let isOk: boolean = true;
    collectionIndexes.forEach((cellIndex: number) => {
      if (!_isEqual(grid[technicianIndex][cellIndex], null)) {
        isOk = false;
      }
    });
    return isOk;
  };
  return (
    <>
      {!_isEqual(technicians.length, 0) &&
        technicians.map((objTech: Technician, technicianCellIndex: number) => (
          <div
            key={`daily_grid_cell_${technicianCellIndex}`}
            id={`daily_grid_cell_${objTech.id}`}
            style={{
              display: "flex",
              flexDirection: "row",
              minHeight: 100,
            }}
          >
            {!_isEqual(timeline?.length, 0) &&
              timeline?.map((objTime: Time, timeIndex: number) => (
                <div
                  className="border-color-bottom border-color-right"
                  key={`daily_grid_cell_inner${technicianCellIndex}_${timeIndex}`}
                  onDoubleClick={() =>
                    !_isUndefined(grid) &&
                      !_isEqual(grid[technicianCellIndex][timeIndex], null)
                      ? null
                      : !_isEqual(timeline.length - 1, timeIndex) &&
                      handleModal(
                        objTech.id,
                        objTech.vendor?.id,
                        objTime,
                        technicianCellIndex,
                        timeIndex
                      )
                  }
                  onDragOver={(e) => onDragOver(e, objTech.id)}
                  onDragEnd={(e) => onDragEnd(e)}
                  onDrop={(e) =>
                    onDrop(e, objTech.id, objTech.vendor?.id, objTime)
                  }
                  onDragStart={(e) =>
                    onDragStart(
                      e,
                      grid[technicianCellIndex][timeIndex],
                      timeIndex,
                      technicianCellIndex
                    )
                  }
                  draggable={
                    !_isNull(grid[technicianCellIndex][timeIndex]) &&
                    !_isEqual(grid[technicianCellIndex][timeIndex].currentStatus, "ONSITE") &&
                    !_isEqual(grid[technicianCellIndex][timeIndex].currentStatus, "COMPLETED") &&
                    !_isEqual(grid[technicianCellIndex][timeIndex].currentStatus, "SERVICE_COMPLETED") &&
                    !_isEqual(grid[technicianCellIndex][timeIndex].currentStatus, "INVOICE_SUBMITTED")
                  }
                  style={{
                    height: 100,
                    minWidth: 80,
                    width: 80,
                    cursor: "pointer",
                    backgroundColor: _isEqual(timeline.length - 1, timeIndex)
                      ? "#d3d3d3"
                      : "",
                  }}
                >
                  {renderCell(objTech, technicianCellIndex, timeIndex)}
                </div>
              ))}
          </div>
        ))}
    </>
  );
};

const mapStateToProps = (states: RootState): ReduxState => {
  return {
    assignedServiceRequests:
      states.serviceRequestReducer.state.assignedServiceRequests,
    technicians: states.technicianReducer.state.techniciansGRID,
    grid: states.schedulerReducer.state.gridD,
    clock: states.schedulerReducer.state.clock,
    toolbar: states.schedulerReducer.state.toolbar,
    schedulerType: states.schedulerReducer.state.schedulerType,
    unassignedSR: states.unassignedSRReducer.state.unassignedSR,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    getIndexTimePeriod: (startTime, endTime) =>
      dispatch(getIndexTimePeriod(startTime, endTime)),
    assigningServiceRequest: (assignedServiceRequest) =>
      dispatch(assignServiceRequest(assignedServiceRequest)),
    getAssignedServiceRequests: () => dispatch(getAssignedServiceRequests()),
    setAssignedServiceRequests: (serviceRequest: Array<ServiceRequest>) =>
      dispatch(setAssignedServiceRequests(serviceRequest)),
    setSchedulerGridDaily: (grid) => dispatch(setSchedulerGrid(grid)),
    getSchedulerGridDaily: () => dispatch(getSchedulerGridDaily()),
    cancelServiceRequestById: (id) => dispatch(cancelServiceRequestById(id)),
    cancelServiceRequestWithRelated: (srNumber: any) => dispatch(cancelServiceRequestWithRelated(srNumber)),
    fetchAssignedServiceRequest: () => dispatch(fetchAssignedServiceRequest()),
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(GridSchedulerDaily)
);
