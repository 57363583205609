import { createStore, combineReducers, applyMiddleware } from "redux";

import thunk from "redux-thunk";

import auth, { AuthState } from "./auth/reducer";
import errorMsg, { ErrorMessageState } from "./error-msg/reducer";
import serviceRequestReducer, { ServiceRequestState } from "./service-request/reducer";
import unassignedSRReducer, { UnAssignedSRState } from "./unassign-service-request/reducer";
import schedulerReducer, { SchedulerState } from "./scheduler/reducer";
import dispatcherReducer, { DispatchersState } from "./dispatcher/reducer";
import technicianReducer, { TechniciansState } from "./technician/reducer";
import filterReducer, { FiltersState } from "./filter/reducer";
import notificationsReducer, { NotificationsState } from "./notifications/reducer"
import claimHistoryReducer, { ClaimHistoryState } from "./claim-history/reducer"
import vendorsReducer, { VendorsState } from './vendors/reducer'

export interface RootState {
  auth: AuthState;
  errorMsg: ErrorMessageState;
  serviceRequestReducer: ServiceRequestState;
  unassignedSRReducer: UnAssignedSRState;
  notificationsReducer: NotificationsState;
  dispatcherReducer: DispatchersState;
  technicianReducer: TechniciansState;
  schedulerReducer: SchedulerState;
  filterReducer: FiltersState;
  claimHistoryReducer: ClaimHistoryState;
  vendorsReducer: VendorsState
}

export default createStore(combineReducers<RootState>({
  auth,
  errorMsg,
  serviceRequestReducer,
  unassignedSRReducer,
  schedulerReducer,
  dispatcherReducer,
  technicianReducer,
  filterReducer,
  notificationsReducer,
  claimHistoryReducer,
  vendorsReducer
}), applyMiddleware(thunk));
