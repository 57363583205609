// Auth
export const SET_SESSION_CREDENTIALS = "SET_SESSION_CREDENTIALS"
export const SET_IS_AUTHENTICATING = "SET_IS_AUTHENTICATING";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_RESET_PASSWORD = "SET_RESET_PASSWORD";
export const SET_CLIENT_ID = "SET_CLIENT_ID";

export const GET_CLIENT_ID = "GET_CLIENT_ID";
export const GET_USER_DETAILS = "GET_USER_DETAILS";

//Error Message
export const SET_LOGIN_ERROR_MESSAGE = "SET_LOGIN_ERROR_MESSAGE";

// SERVICE REQUEST
export const GET_ASSIGNED_SERVICE_REQUESTS = "GET_ASSIGNED_SERVICE_REQUESTS";
export const SET_ASSIGNED_SERVICE_REQUESTS = "SET_ASSIGNED_SERVICE_REQUESTS";
export const GET_CURRENT_SERVICE_REQUESTS = "GET_CURRENT_SERVICE_REQUESTS";
export const SET_CURRENT_SERVICE_REQUESTS = "SET_CURRENT_SERVICE_REQUESTS";

// UNASSIGNED SR
export const SET_UNASSIGNED_SR = "SET_UNASSIGNED_SR";
export const GET_UNASSIGNED_SR = "GET_UNASSIGNED_SR";

// TECHNICIANS
export const SET_TECHNICIAN = "SET_TECHNICIAN";
export const GET_TECHNICIAN = "GET_TECHNICIAN";
export const SET_ALL_SELECTED_TECHNICIANS = "SET_ALL_SELECTED_TECHNICIANS"
export const GET_ALL_SELECTED_TECHNICIANS = "GET_ALL_SELECTED_TECHNICIANS"
export const SET_REMOVE_TECHNICIAN_ID = "SET_REMOVE_TECHNICIAN_ID";
export const GET_REMOVE_TECHNICIAN_ID = "GET_REMOVE_TECHNICIAN_ID";
export const SET_SELECTED_TECHNICIAN_ID = "SET_SELECTED_TECHNICIAN_ID";
export const GET_SELECTED_TECHNICIAN_ID = "GET_SELECTED_TECHNICIAN_ID";
export const SET_TECHNICIANS_GRID = "SET_TECHNICIANS_GRID";
export const GET_TECHNICIANS_GRID = "GET_TECHNICIANS_GRID";
export const SET_TECHNICIANS_BOARD = "SET_TECHNICIANS_BOARD";
export const GET_TECHNICIANS_BOARD = "GET_TECHNICIANS_BOARD";
export const SET_TECHNICIAN_SERVICE_REQUEST_LOADING = "SET_TECHNICIAN_SERVICE_REQUEST_LOADING"
export const SET_TECHNICIANS_SERVICE_REQUEST_COUNT = "SET_TECHNICIANS_SERVICE_REQUEST_COUNT";
export const GET_TECHNICIANS_SERVICE_REQUEST_COUNT = "GET_TECHNICIANS__SERVICE_REQUEST_COUNT";
export const SET_TECHNICIAN_SERVICE_REQUEST = " SET_TECHNICIAN_SERVICE_REQUEST"
export const GET_TECHNICIAN_SERVICE_REQUEST = "GET_TECHNICIAN_SERVICE_REQUEST";
export const SET_COMPLETED_TECHNICIAN_SERVICE_REQUEST = "SET_COMPLETED_TECHNICIAN_SERVICE_REQUEST";
export const SET_BANK_INFORMATION = "SET_BANK_INFORMATION";
export const SET_SERVICE_EXPERIENCE = "SET_SERVICE_EXPERIENCE";
export const SET_AVAILABILITIES = "SET_AVAILABILITIES";
export const SET_TYPE_OF_SERVICES = "SET_TYPE_OF_SERVICES";
export const SET_SERVICE_LOCATIONS = "SET_SERVICE_LOCATIONS";

// Dispatchers

export const SET_DISPATCHER = "SET_DISPATCHER";
export const GET_DISPATCHERS = "GET_DISPATCHERS";
export const SET_DISPATCHERS = "SET_DISPATCHERS";
export const GET_SELECTED_DISPATCHER_ID = "GET_SELECTED_DISPATCHER_ID";
export const SET_SELECTED_DISPATCHER_ID = "SET_SELECTED_DISPATCHER_ID";
export const SET_REMOVE_DISPATCHER_ID = "SET_REMOVE_DISPATCHER_ID";
export const GET_REMOVE_DISPATCHER_ID = "GET_REMOVE_DISPATCHER_ID";
export const SET_ALL_SELECTED_DISPATCHERS = "SET_ALL_SELECTED_DISPATCHERS";
export const GET_ALL_SELECTED_DISPATCHERS = "GET_ALL_SELECTED_DISPATCHERS";

// FILTERS
export const SET_LOADING_USER_FILTERS = "SET_LOADING_USER_FILTERS"
export const SET_LOADING_FILTER_FIELDS = "SET_LOADING_FILTER_FIELDS"
export const SET_FILTER = "SET_FILTER";
export const SET_FILTERS = "SET_FILTERS";
export const GET_USER_FILTERS = "GET_USER_FILTERS";
export const SET_USER_FILTERS = "SET_USER_FILTERS"
export const SET_FILTER_FIELDS = "SET_FILTER_FIELDS";
export const GET_FILTERS = "GET_FILTERS";
export const SET_SEARCH_SR_NUMBER = "SET_SEARCH_SR_NUMBER";
export const GET_SEARCH_SR_NUMBER = "GET_SEARCH_SR_NUMBER";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
export const GET_SEARCH_VALUE = "GET_SEARCH_VALUE";
export const SET_SEARCH_TYPE = "SET_SEARCH_TYPE";
export const GET_SEARCH_TYPE = "GET_SEARCH_TYPE";

// SCHEDULER
export const SET_ISLOADING_SCHEDULER = "SET_ISLOADING_SCHEDULER";
export const SET_CLOCK = "SET_CLOCK";
export const GET_CLOCK = "GET_CLOCK";
export const SET_SEVEN_DAYS = "SET_SEVEN_DAYS";
export const GET_SEVEN_DAYS = "GET_SEVEN_DAYS";
export const SET_SCHEDULER_TOOLBAR = "SET_SCHEDULER_TOOLBAR";
export const GET_SCHEDULER_TOOLBAR = "GET_SCHEDULER_TOOLBAR";
export const SET_SCHEDULER_GRID = "SET_SCHEDULER_GRID";
export const GET_SCHEDULER_GRID = "GET_SCHEDULER_GRID";
export const SET_SCHEDULER_GRID_D = "SET_SCHEDULER_GRID_D";
export const GET_SCHEDULER_GRID_D = "GET_SCHEDULER_GRID_D";
export const SET_SCHEDULER_TYPE = "SET_SCHEDULER_TYPE";
export const GET_SCHEDULER_TYPE = "GET_SCHEDULER_TYPE";

//NOTIFICATIONS
export const GET_ALL_UNREAD_NOTIFICATIONS = "GET_ALL_UNREAD_NOTIFICATIONS"
export const SET_ALL_UNREAD_NOTIFICATIONS = "SET_ALL_UNREAD_NOTIFICATIONS"
export const READ_NOTIFICATION = "READ_NOTIFICATION"

// CLAIM HISTORY
export const GET_CLAIM_HISTORY = "GET_CLAIM_HISTORY"
export const SET_CLAIM_HISTORY = "SET_CLAIM_HISTORY"

// VENDORS
export const GET_VENDOR = "GET_VENDOR"
export const SET_VENDOR = "SET_VENDOR"
export const GET_VENDORS = "GET_VENDORS"
export const SET_VENDORS = "SET_VENDORS"
export const GET_VENDOR_PAGINATION = "GET_VENDOR_PAGINATION"
export const SET_VENDOR_PAGINATION = "SET_VENDOR_PAGINATION"
export const GET_VENDOR_REQUESTS = "GET_VENDOR_REQUESTS"
export const SET_VENDOR_REQUESTS = "SET_VENDOR_REQUESTS"
export const GET_VENDOR_TECHNICIANS = "GET_VENDOR_TECHNICIANS"
export const SET_VENDOR_TECHNICIANS = "SET_VENDOR_TECHNICIANS"

export const GET_VENDOR_SERVICE_REQUEST_HISTORY = "GET_VENDOR_SERVICE_REQUEST_HISTORY";
export const SET_VENDOR_SERVICE_REQUEST_HISTORY = "GET_VENDOR_SERVICE_REQUEST_HISTORY";

export const GET_VENDOR_TECH_SERVICE_REQUEST_HISTORY = "GET_VENDOR_TECH_SERVICE_REQUEST_HISTORY";
export const SET_VENDOR_TECH_SERVICE_REQUEST_HISTORY = "SET_VENDOR_TECH_SERVICE_REQUEST_HISTORY";



