import React, {useState, useEffect} from "react";

import { Tag, Input, Tooltip } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { FilterTag } from "../../redux/filter/model";

interface OwnProps {
    tags:Array<FilterTag>;
    onTagsChanged: (tags:Array<FilterTag>) => void;
}

interface StateProps {
}

interface DispatchProps {
}

type Props = OwnProps & StateProps & DispatchProps;

const FilterTags: React.FC<Props> = (props): JSX.Element => {

    let input = React.createRef<HTMLDivElement>();
    let editInput = React.createRef<HTMLDivElement>();

    let [tags, setTags] = useState<Array<FilterTag>>(props.tags ? props.tags : []);
    let [inputVisible, setInputVisible] = useState<boolean>(false);
    let [inputValue, setInputValue] = useState<string>('');
    let [editInputIndex, setEditInputIndex] = useState<number>(-1);
    let [editInputValue, setEditInputValue] = useState<string>('');

    //let [input, setInput] = useState<any>();
    //let [editInput, setEditInput] = useState<any>();

    const handleClose = (removedTag:FilterTag) => {
        const filteredTags = tags.filter((filterTag) => filterTag.tag != removedTag.tag);
        setTags(filteredTags);
    };

    const showInput = () => {
        setInputVisible(true);
        setTimeout(() => {
            input.current?.focus();
        }, 1000);
    };

    const handleInputChange = (e:any) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        
        const index = tags.findIndex((tag:FilterTag) => {return tag.tag == inputValue});
        if (inputValue && index === -1) {
        tags = [...tags, {tag: inputValue}];
        }
        setTags(tags);
        setInputVisible(false);
        setInputValue('');

        props.onTagsChanged(tags);
    };

    const handleEditInputChange = (e:any) => {
        setEditInputValue(e.target.value);
    };

    const handleEditInputConfirm = () => {
        
        const newTags = [...tags];
        newTags[editInputIndex] = {tag: editInputValue};
        setTags(newTags);
        setEditInputIndex(-1);
        setEditInputValue('');
    };

    const saveInputRef = (inputRef:any) => {
        input = inputRef;
        //setInput(input);
    };

    const saveEditInputRef = (inputRef:any) => {
        editInput = inputRef;
        //setEditInput(input);
    };

    return (
    <div className="filter-tag-items">
    {tags.map((tag, index) => {
        if (editInputIndex === index) {
        return (
            <Input
            ref={saveEditInputRef}
            key={tag.tag}
            size="small"
            className="tag-input"
            value={editInputValue}
            onChange={handleEditInputChange}
            onBlur={handleEditInputConfirm}
            onPressEnter={handleEditInputConfirm}/>
        );
        }

        const isLongTag = tag.tag.length > 20;

        const tagElem = (
        <Tag
            className="edit-tag"
            key={tag.tag}
            closable={index !== 0}
            onClose={() => handleClose(tag)}>
            <span
            onDoubleClick={e => {
                if (index !== 0) {
                setEditInputIndex(index);
                setEditInputValue(tag.tag);
                editInput.current?.focus();
                e.preventDefault();
                }
            }}>
            {isLongTag ? `${tag.tag.slice(0, 20)}...` : tag.tag}
            </span>
        </Tag>
        );
        return isLongTag ? (
        <Tooltip title={tag.tag} key={tag.tag}>
            {tagElem}
        </Tooltip>
        ) : (
        tagElem
        );
    })}
    {inputVisible && (
        <Input
            ref={saveInputRef}
            type="text"
            size="small"
            className="tag-input"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}/>
    )}
    {!inputVisible && (
        <Tag className="site-tag-plus" onClick={showInput}>
        <PlusOutlined /> New Tag
        </Tag>
    )}
    </div>);
}

export default React.memo(FilterTags);