import React from "react";
import {
  HomeFilled,
  MailFilled,
  PhoneFilled,
  CloseOutlined,
} from "@ant-design/icons";
import CSS from "csstype";
import { GOOGLE_KEY } from "../../configs/service-config";
interface LocationProps {
  fullName?: string;
  email?: string;
  mobileNumber?: string;
  lat?: number;
  lng?: number;
  id?: string;
  onClosed: any;
  type: string;
  srNo: string;
}
const Rowstyle: CSS.Properties = {
  display: "flex",
  flexDirection: "row",
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingTop: "10px",
  paddingBottom: "5px",
};
const iconStyle: CSS.Properties = {
  fontSize: "16px",
  paddingLeft: "5px",
  paddingRight: "5px",
  color: "grey",
};
const MarkerBox: React.FC<LocationProps> = ({
  fullName,
  email,
  mobileNumber,
  lat,
  lng,
  id,
  type,
  srNo,
  onClosed,
}): JSX.Element => {
  const [address, setAddress] = React.useState<String>("Unknown Location");

  const getAddress = async () => {
    const header = {
      headers: {
        Accept: "application/json",
      },
      method: "GET",
    };

    const geocodeURI = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_KEY}`;
    const request = new Request(geocodeURI, header);
    const response = await fetch(request);
    const location = await response.json();
    if (location.results.length > 0) {
      setAddress(location.results[0].formatted_address);
    } else {
      setAddress("Unknown Location");
    }
  };

  getAddress();

  return (
    <div className="relative">
      <div className="arrow-left"></div>
      <div
        style={{
          width: "220px",
          marginLeft: "30px",
          backgroundColor: "#FFFFFF",
          paddingBottom: 16,
        }}
      >
        <div style={{ backgroundColor: "#CCCCFF" }} className="px-2 py-2">
          <CloseOutlined style={{ float: "right" }} onClick={onClosed} />
          <div>
            <h2>{fullName}</h2>
            <h3>{type === "SR" ? `SR # ${srNo}` : `TID # ${id}`}</h3>
          </div>
        </div>
        <div style={Rowstyle}>
          <HomeFilled style={iconStyle} />
          <h3>{address}</h3>
        </div>
        <div style={Rowstyle}>
          <MailFilled style={iconStyle} />
          <h3>{email}</h3>
        </div>
        <div style={Rowstyle}>
          <PhoneFilled style={iconStyle} />
          <h3>{mobileNumber}</h3>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MarkerBox);
