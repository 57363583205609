import { ThunkDispatch, ThunkAction } from "redux-thunk";

import { AnyAction } from "redux";

import { SERVER_URL } from "../../configs/service-config"

import { setAllUnreadNotifications, readNotification } from "./action";

import _isEqual from "lodash/isEqual";
import _isUndefined from "lodash/isUndefined";

import { deleteRequest, getRequest, getRequestWithJson } from "../interceptor";
import { getClientId } from "../auth/actions";

export const fetchAllUnreadNotifications = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		const clientId: any = dispatch(getClientId());
		const fetchNotificationsService = new URL(SERVER_URL.Notifications);
		fetchNotificationsService.searchParams.append("clientId", clientId);
		await getRequestWithJson(dispatch, fetchNotificationsService)
			.then((response: any) => {
				dispatch(setAllUnreadNotifications(response));
			})
			.catch((error: any) => {
				console.log('Error Message -> while fetching all unread notifications', error);
			});
	};
};

export const deleteReadNotification = (id: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		const readNotificationService = new URL(`${SERVER_URL.Notifications}/${id}`);
		await deleteRequest(dispatch, readNotificationService)
			.then((response: any) => {
				dispatch(readNotification(response))
			})
			.catch((error: any) => {
				console.log('Error Message -> while deleting read notifications', error);
			});
	};
};


