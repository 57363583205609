import React from "react";

import { Link } from "react-router-dom";

import { Form, Input, Typography } from "antd";

import { SubmitButtonComponent } from "..";

const { Text } = Typography;

const formLayout = {
  wrapperCol: {
    xs: {
      offset: 0,
      span: 24,
    },
    sm: {
      offset: 3,
      span: 18,
    },
  },
};

const formBottomLayout = {
  wrapperCol: {
    xs: {
      offset: 0,
      span: 24,
    },
    sm: {
      offset: 8,
      span: 8,
    },
  },
};

interface Props {
  onSubmit: any;
  onChange: any;
  onSubmitErr: any;
  isLoading: boolean;
}

const ResetPasswordForm: React.FC<Props> = ({
  onSubmit,
  onChange,
  onSubmitErr,
  isLoading,
}): JSX.Element => {
  return (
    <Form
      {...formLayout}
      name={"ResetPasswordForm"}
      onFieldsChange={onChange}
      onFinish={onSubmit}
      onFinishFailed={onSubmitErr}
    >
      <Form.Item
        name="email"
        rules={[
          { required: true, message: "Please input your email address!" },
          { type: "email", message: "Please input a valid email address!" },
        ]}
      >
        <Input size={"large"} placeholder="Email address" />
      </Form.Item>

      <Form.Item {...formBottomLayout} style={{ marginBottom: 8 }}>
        <SubmitButtonComponent label={"SUBMIT"} loading={isLoading} />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Link to="/login">
          <Text className={"reset-password-link"}>Back to login?</Text>
        </Link>
      </Form.Item>
    </Form>
  );
};

export default React.memo(ResetPasswordForm);
