import { SET_TECHNICIAN, GET_TECHNICIANS_BOARD, GET_TECHNICIANS_GRID, GET_TECHNICIANS_SERVICE_REQUEST_COUNT, SET_TECHNICIANS_BOARD, SET_TECHNICIANS_GRID, SET_TECHNICIANS_SERVICE_REQUEST_COUNT, SET_TYPE_OF_SERVICES, SET_SERVICE_LOCATIONS } from "../action-types";
import { ServiceRequest, ServiceRequestCount } from "../service-request/model";

export interface Vendor {
  id: number,
};

export interface Skill {
  id: number,
  skill: string
};

export enum ZipCodeStatus {
  AVAILABLE = "AVAILABLE",
  NOT_AVAILABLE = "NOT_AVAILABLE"
};

export interface Technician {
  id: number;
  vendor: Vendor,
  firstName: string;
  lastName: string;
  fullName: string;
  mobileNumber: string;
  email: string;
  region: any;
  position: any;
  location: any;
  lat: number,
  lng: number,
  currentStatus: string,
  skills: Array<Skill>;
};

export interface TypeOfService {
  id: number;
  clientId: number;
  name: string;
  active: boolean;
  version: number;
}

export interface ServiceLocation {
  id: number;
  clientId: number;
  zipcode: string;
  radiusInMiles: number;
  status: ZipCodeStatus;
  version: number;
}

export interface TechnicianModel {
  technician?: any;
  removeTechnicianId: any;
  selectedTechnicianId: any;
  allSelectedTechnicians: any;
  techniciansGRID: Array<Technician>;
  techniciansBOARD: Array<Technician>;
  techniciansSRCounts: Array<ServiceRequestCount>;
  technicianSR: Array<ServiceRequest>;
  isTechnicianSRLoading: boolean;
  completedRequests: any[],
  bankInformation: any,
  serviceExperience: any,
  availabilities: any[],
  typeOfServices: Array<TypeOfService>,
  serviceLocations: Array<ServiceLocation>,
};

export type SetTechnician = {
  type: typeof SET_TECHNICIAN;
  payload: any;
};

export type SetTypeOfServices = {
  type: typeof SET_TYPE_OF_SERVICES;
  payload: Array<TypeOfService>;
};

export type SetServiceLocations = {
  type: typeof SET_SERVICE_LOCATIONS;
  payload: Array<ServiceLocation>;
}

/** Get Technicians */
export type GetTechniciansGrid = {
  type: typeof GET_TECHNICIANS_GRID;
  payload: Array<Technician>;
};

/** Set Technicians */
export type SetTechniciansGrid = {
  type: typeof SET_TECHNICIANS_GRID;
  payload: Array<Technician>;
};


/** Get Technicians */
export type GetTechniciansBoard = {
  type: typeof GET_TECHNICIANS_BOARD;
  payload: Array<Technician>;
};

/** Set Technicians */
export type SetTechniciansBoard = {
  type: typeof SET_TECHNICIANS_BOARD;
  payload: Array<Technician>;
};


/** Get service request count of Technician */
export type GetSRCountTechnicians = {
  type: typeof GET_TECHNICIANS_SERVICE_REQUEST_COUNT;
  payload: Array<ServiceRequestCount>;
};

/** Set service request count of Technician */
export type SetSRCountTechnicians = {
  type: typeof SET_TECHNICIANS_SERVICE_REQUEST_COUNT;
  payload: Array<ServiceRequestCount>;
};

/** Get service requests of Technician */
export type GetTechnicianSR = {
  type: typeof GET_TECHNICIANS_SERVICE_REQUEST_COUNT;
  payload: Array<ServiceRequest>;
};

/** Set service requests of Technician */
export type SetTechnicianSR = {
  type: typeof SET_TECHNICIANS_SERVICE_REQUEST_COUNT;
  payload: Array<ServiceRequest>;
};

/** Export Service Request Actions  */
export type TechniciansActions =
  GetTechniciansGrid |
  SetTechniciansGrid |
  GetTechniciansBoard |
  SetTechniciansBoard |
  GetTechnicianSR |
  SetTechnicianSR |
  GetSRCountTechnicians |
  SetSRCountTechnicians |
  SetTypeOfServices |
  SetServiceLocations;

