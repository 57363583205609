import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import {
  Button,
  Input,
  Select,
  List,
  Rate,
  Avatar,
  Typography,
} from "antd";

import {
  SearchOutlined,
  UserOutlined,
  CaretDownOutlined,
  CaretLeftOutlined
} from "@ant-design/icons";
import _isqual from "lodash/isEqual";
import { fetchVendorsInit, fetchVendorsAction, fetchVendor } from "../../redux/vendors/service";
import { setTechnician, setBankInformation, setServiceExperience, setAvailabilities } from "../../redux/technician/action";
import ImportTechLayout from "../../components/layouts/modal/import-tech-layout";

const { Text } = Typography;

interface ComponentProps {
  setType: any;
}
interface ReduxState {
  vendors: any;
  vendorPagination: any;
}
interface DispatchProps {
  fetchVendor: (id: number) => void,
  fetchVendors: (
    action: string,
    index: number,
    status: string,
    viewableSize: number
  ) => void;
  fetchVendorsAction: (
    action: string,
    index: number,
    status: string,
    viewableSize: number,
    totalSize: number,
    searchCriteria: any
  ) => void;
  setTechnician: (technician: any) => void;
  setBankInformation: (bankInfo: any) => void;
  setServiceExperience: (experience: any) => void;
  setAvailabilities: (availabilities: any) => void;
}
type Props = ComponentProps & ReduxState & DispatchProps;

const VendorsList: React.FC<Props> = (props): JSX.Element => {
  useEffect(() => {
    localStorage.removeItem("CHANNEL_SSID");
    localStorage.removeItem("chatConversationModal");
    props.fetchVendors("init", 1, "inactive", 25);
  }, []);

  useEffect(() => {
    setVendors(props.vendors)
  }, [props.vendors])

  const [location, openLocation] = useState(false)
  const [vendors, setVendors] = useState([])
  const [pageOptions, setPageOptions] = useState<any>({
    page: 1,
    pageSize: 25,
    pageSizeOptions: [5, 10, 15, 20, 25],
    pageTotal: 1,
  });

  const [searchCriteria, setSearchCriteria] = useState<any>({});

  // const renderSpinner = () => {
  //   return (
  //     <Spin
  //       style={{
  //         position: "absolute",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         backgroundColor: "white",
  //         top: 0,
  //         right: 0,
  //         marginLeft: 240,
  //         left: 0,
  //         bottom: 0,
  //       }}
  //     />
  //   );
  // };

  const openVendor = async () => {
    await props.fetchVendor(0)
    props.setType("addVendor")
  }

  const selectVendor = async (id: number) => {
    await props.fetchVendor(id)
    props.setType("addVendor")
  }

  const openTechnician = async () => {

    props.setBankInformation(null);
    props.setServiceExperience(null);
    props.setAvailabilities(null);

    props.setTechnician({
      status: 'PENDING_PROFILE',
      rating: 0,
      payRate: 0,
      cancellationFee: 0,
      tripCharge: 0
    });

    await props.fetchVendor(0);

    props.setType("addVendorTechnician");
  }

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onChange: async (page: any, pageSize: any) => {
      setPageOptions({ ...pageOptions, page, pageSize })
      props.fetchVendorsAction("index", page, "", pageSize, props.vendorPagination.totalSize, searchCriteria);
    },
    pageSizeOptions: pageOptions.pageSizeOptions,
    showTotal: (total: any, range: any) =>
      `${props.vendorPagination !== null ? props.vendorPagination.start : 0
      } to ${props.vendorPagination !== null ? props.vendorPagination.end : 0
      } of ${props.vendorPagination !== null
        ? props.vendorPagination.totalSize
        : pageOptions.totalSize
      }`,
  };

  const pagination = {
    ...paginationOptions,
    total: props.vendorPagination !== null
      ? props.vendorPagination.totalSize
      : pageOptions.totalSize,
    current: pageOptions.page,
    pageSize:
      props.vendorPagination !== null
        ? props.vendorPagination.viewableSize
        : 25,
  };

  const onImportTechnician = () => {
    window.dispatchEvent(new Event("openImportTech"));
  };

  const onChange = (event: any) => {
    searchCriteria[event.name] = event.value;
    setSearchCriteria(searchCriteria);
  }

  const onSearch = () => {
    props.fetchVendorsAction("index", 1, "", props.vendorPagination.viewableSize, props.vendorPagination.totalSize, searchCriteria);
  }

  return (
    <div style={{ padding: 50 }}>
      <div style={{ float: "right", display: "flex" }}>
        <Button
          style={{
            marginRight: 20,
            color: "white",
            backgroundColor: "#3a78b3",
          }}
        >
          <p
            style={{ opacity: 0.7 }}
            onClick={openVendor}
          >
            +Add New Vendor
          </p>
        </Button>
        <Button style={{ color: "white", backgroundColor: "#008000" }}>
          <p
            style={{ opacity: 0.7 }}
            onClick={openTechnician}
          >
            +Add New Vendor Technician
          </p>
        </Button>
        <Button className="button-import" onClick={onImportTechnician}>
          <p style={{ opacity: 0.7 }}>+Import Technicians</p>
        </Button>
      </div>
      <div style={{ marginTop: 50, display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1, marginRight: '50px' }}>
          {/*<h3 style={{ fontWeight: "bold", color: "gray" }}>
            SHOW ALL VENDORS
          </h3>*/}
          <h3 style={{ fontWeight: "bold", color: "gray" }}>
            SEARCH VENDORS BY
          </h3>
          {/*
          <p style={{ fontSize: "17px", opacity: "0.9" }}>
            SEARCH VENDORS BY
          </p>*/}
          <Input
            onChange={(e) => onChange(e.target)}
            name="companyName"
            placeholder="Company Name Search"
            suffix={<SearchOutlined />}
            style={{ width: "300px" }}
          />
          <div
            style={{
              width: "300px",
              height: location ? "150px" : "35px",
              border: "1px solid lightgrey",
              marginTop: "10px",
              cursor: "pointer",
            }}
          >
            <div style={{ marginLeft: "20px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ color: "gray", fontSize: "16px" }}>Location</p>
                {location ?
                  <CaretDownOutlined
                    style={{
                      fontSize: "20px",
                      marginRight: "10px",
                      marginTop: "3px",
                    }}
                    onClick={() => openLocation(!location)}
                  /> :
                  <CaretLeftOutlined
                    style={{
                      fontSize: "20px",
                      marginRight: "10px",
                      marginTop: "3px",
                    }}
                    onClick={() => openLocation(!location)}
                  />
                }
              </div>
              {location ?
                <div>
                  <Input
                    name="zipCode"
                    onChange={(e) => onChange(e.target)}
                    placeholder="Enter Zip Code"
                    size="small"
                    style={{ width: "180px" }}
                  />
                  <Input
                    name="city"
                    onChange={(e) => onChange(e.target)}
                    placeholder="Or Enter City"
                    size="small"
                    style={{ width: "180px", marginTop: "10px" }}
                  />
                  <Input
                    name="state"
                    onChange={(e) => onChange(e.target)}
                    placeholder="Or Enter State"
                    size="small"
                    style={{ width: "180px", marginTop: "10px" }}
                  />
                </div>
                : null
              }
            </div>
          </div>
          <Input
            name="type"
            onChange={(e) => onChange(e.target)}
            placeholder="Type"
            suffix={<SearchOutlined />}
            style={{ width: "300px", marginTop: "10px" }}
          />
          <Input
            name="techFirstName"
            onChange={(e) => onChange(e.target)}
            placeholder="Technician First Name"
            suffix={<SearchOutlined />}
            style={{ width: "300px", marginTop: "10px" }}
          />
          <Input
            name="techLastName"
            onChange={(e) => onChange(e.target)}
            placeholder="Technician Last Name"
            suffix={<SearchOutlined />}
            style={{ width: "300px", marginTop: "10px" }}
          />
          <br />
          <Button className="button-search" onClick={onSearch}>
            <p style={{ opacity: 0.7 }}>Search</p>
          </Button>
        </div>
        <div style={{ flex: 3 }}>
          <h3>Top Results</h3>
          <div style={{ marginTop: 20 }}>
            <List
              grid={{ gutter: 16, column: 4 }}
              pagination={pagination}
              dataSource={vendors}
              renderItem={(item: any) => (
                <List.Item>
                  <div
                    onClick={() => selectVendor(item.id)}
                    className="vendor-list-item-container"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: 10,
                      }}
                    >
                      <Avatar
                        icon={<UserOutlined />}
                        style={{ backgroundColor: "#4d4d4dff", flex: 0.2, marginTop: 10 }}
                      />
                      <div
                        style={{
                          marginLeft: 10,
                          flex: 0.8,
                          display: 'flex',
                          flexDirection: 'column',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden'
                        }}
                      >
                        <Text ellipsis={true} style={{ fontSize: "17px" }}>{item.companyName}</Text>
                        <Text ellipsis={true}>{item.companyAddress}</Text>
                        <Rate
                          allowHalf
                          defaultValue={item.rating}
                          disabled
                          style={{ position: "absolute", bottom: 10, color: "#3a78b3", fontSize: 12 }}
                        />
                      </div>
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </div>
        </div>
      </div>
      <ImportTechLayout />
    </div>
  );
};

const mapStateToProps = (states: RootState): ReduxState => {
  return {
    vendors: states.vendorsReducer.state.vendors,
    vendorPagination: states.vendorsReducer.state.vendorPagination,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    fetchVendor: (id: number) => dispatch(fetchVendor(id)),
    fetchVendors: (
      action: string,
      index: number,
      status: string,
      viewableSize: number
    ) => dispatch(fetchVendorsInit(action, index, status, viewableSize)),
    fetchVendorsAction: (action: string,
      index: number,
      status: string,
      viewableSize: number,
      totalSize: number,
      searchCriteria: any) => dispatch(fetchVendorsAction(action, index, status, viewableSize, totalSize, searchCriteria)),
    setTechnician: (technician: any) => dispatch(setTechnician(technician)),
    setBankInformation: (bankInfo: any) => dispatch(setBankInformation(bankInfo)),
    setServiceExperience: (experience: any) => dispatch(setServiceExperience(experience)),
    setAvailabilities: (availabilities: any) => dispatch(setAvailabilities(availabilities)),
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(VendorsList)
);
