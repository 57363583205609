import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import { Route, Redirect } from "react-router-dom";
import { PageLayout, SideMenuSection, PageHeaderSection } from "..";
import { getSessionCredentialsFromStorage } from "../../redux/auth/actions";
import { initializeUserDetails } from "../../redux/auth/service";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";

interface OwnProps { component: any; exact: any; strict: any; path: string; sensitive: any; }
interface StateProps { }
interface DispatchProps { getSessionCredentialsFromStorage: () => void; initializeUserDetails: () => void; }
type Props = OwnProps & StateProps & DispatchProps;
const AuthGuardComponent: React.FC<Props> = ({
  initializeUserDetails,
  getSessionCredentialsFromStorage,
  component,
  ...rest
}): JSX.Element => {
  const isAuthenticated = !_isEmpty(getSessionCredentialsFromStorage());

  React.useEffect(() => {
    if (isAuthenticated) {
      initializeUserDetails();
    }
  }, [initializeUserDetails, isAuthenticated]);

  const routeComponent = (props: any) =>
    _isEqual(isAuthenticated, true) ? (
      <PageLayout
        renderLSComponent={<SideMenuSection />}
        renderRSComponent={
          <>
            <PageHeaderSection />
            {React.createElement(component, props)}
          </>
        }
      />
    ) : (
      <Redirect to={"/login"} />
    );

  return <Route {...rest} render={routeComponent} />;
};

const mapStateToProps = (states: RootState): StateProps => {
  return {};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    getSessionCredentialsFromStorage: () => dispatch(getSessionCredentialsFromStorage()),
    initializeUserDetails: () => dispatch(initializeUserDetails())
  };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(AuthGuardComponent));
