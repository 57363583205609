import {
  SET_VENDOR,
  SET_VENDORS,
  SET_VENDOR_PAGINATION,
  SET_VENDOR_REQUESTS,
  SET_VENDOR_SERVICE_REQUEST_HISTORY,
  SET_VENDOR_TECHNICIANS,
  SET_VENDOR_TECH_SERVICE_REQUEST_HISTORY,
} from "../action-types";
import { VendorsModel, VendorsActions } from "./model";
import { combineReducers } from "redux";

export interface VendorsState {
  state: VendorsModel;
}

const INITIAL_STATE = {
  selectedVendor: null,
  vendors: [],
  vendorPagination: null,
  vendorRequests: [],
  vendorTechnicians: [],
  vendorServReqHistory: [],
  vendorServTechReqHistory: [],
};

const state = (state = INITIAL_STATE, action: any): VendorsModel => {
  switch (action.type) {
    case SET_VENDOR:
      return { ...state, selectedVendor: action.payload }
    case SET_VENDORS:
      return { ...state, vendors: action.payload };
    case SET_VENDOR_PAGINATION:
      return { ...state, vendorPagination: action.payload };
    case SET_VENDOR_REQUESTS:
      return { ...state, vendorRequests: action.payload };
    case SET_VENDOR_TECHNICIANS:
      return { ...state, vendorTechnicians: action.payload };
    case SET_VENDOR_SERVICE_REQUEST_HISTORY:
      return { ...state, vendorServReqHistory: action.payload };
    case SET_VENDOR_TECH_SERVICE_REQUEST_HISTORY:
      return { ...state, vendorServTechReqHistory: action.payload };

    default:
      return state;
  }
};

export default combineReducers<VendorsState>({ state });
