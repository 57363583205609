import React from "react";
import { connect } from "react-redux";
import { History } from "history";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import DashboardGrid from "../../components/dashboard/components/DashboardGrid";

interface OwnProps {
  history: History;
}
interface StateProps {}
interface DispatchProps {}
type Props = OwnProps & StateProps & DispatchProps;
const DashboardPage: React.FC<Props> = ({ history }): JSX.Element => {
  React.useEffect(() => {
    localStorage.removeItem("CHANNEL_SSID");
    localStorage.removeItem("chatConversationModal");
  }, []);

  return (
    <>
      <DashboardGrid />
    </>
  );
};

const mapStateToProps = (states: RootState): StateProps => {
  return {};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {};
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(DashboardPage)
);
