import StarIcon from "../../../assets/fsa-icons/svg/star.svg";
import type { Status } from "../types";
import styles from "./LineEdgedTable.module.scss";
import StatusChip from "./StatusChip";
import ViewMoreLink from "./ViewMoreLink";

interface Props {
  columns: string[];
  data: Record<string, string | number>[];
  viewMoreLabel?: string;
  tableLayout?: "auto" | "fixed";
}

export default function LineEdgedTable({
  tableLayout = "auto",
  columns,
  data,
  viewMoreLabel,
}: Props) {
  return (
    <table className={`${styles.table} ${styles[tableLayout]}`}>
      <thead>
        <tr>
          {columns.map((header, index) => (
            <th
              key={index}
              scope="col"
              className={styles[titleCaseToCamelCase(header)]}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {Object.keys(row).map((column, columnIndex) => {
              if (column === "status") {
                return (
                  <td key={columnIndex}>
                    <div className={styles.status}>
                      <StatusChip status={row[column] as Status} />
                    </div>
                  </td>
                );
              }

              if (column === "rating") {
                return (
                  <td key={columnIndex}>
                    <div className={styles.rating}>
                      <div>{(row[column] as number).toPrecision(3)}</div>
                      <img src={StarIcon} alt="Star Icon" />
                    </div>
                  </td>
                );
              }

              if (column === "serviceType") {
                return (
                  <td key={columnIndex}>
                    <div className={styles.serviceType}>{row[column]}</div>
                  </td>
                );
              }

              return (
                <td key={columnIndex} className={styles[column]}>
                  {row[column]}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function titleCaseToCamelCase(input: string): string {
  const camelCase = input.replace(/\s(.)/g, (_, char) => char.toUpperCase());
  return camelCase.charAt(0).toLowerCase() + camelCase.slice(1);
}
