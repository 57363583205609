import React, { useState, useEffect } from "react";

import { Button, Form, Input, message, Modal } from "antd";

import { ExclamationCircleOutlined, UserOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';

import { connect } from "react-redux";

import { RootState } from "../../redux";

import { ThunkDispatch } from "redux-thunk";

import { Dispatcher } from "../../redux/dispatcher/model";

import { DispatcherList } from "..";

import { setDispatchers } from "../../redux/dispatcher/action";
import { saveDispatcher, deleteDispatcher } from "../../redux/dispatcher/service";
import { resetWithSetPassword, setResetPassword } from "../../redux/auth/service";

import { getClientId } from "../../redux/auth/actions";

import "./dispatchers.scss";

interface OwnProps {
}

interface StateProps {
  dispatcher?: Dispatcher,
  dispatchers: Array<Dispatcher>
}

interface DispatchProps {
  saveDispatcher: (dispatcher: Dispatcher) => Promise<Dispatcher>;
  deleteDispatcher: (dispatcher: Dispatcher) => Promise<Dispatcher>;
  resetPassword: (dispatcher: Dispatcher) => void;
  setDispatchers: (dispatcher: Array<Dispatcher>) => void;
  getClientId: () => any;
  resetWithSetPassword: (email: string, password: string) => any;
}

type Props = OwnProps & StateProps & DispatchProps;

const Dispatchers: React.FC<Props> = (props): JSX.Element => {

  const [passwordModal, setPasswordModal] = useState<boolean>(false);
  const [passwordIsSaving, setPasswordIsSaving] = useState<boolean>(false);

  const [savingAllowed, setSavingAllowed] = useState<boolean>(false);
  const [resettingAllowed, setResettingAllowed] = useState<boolean>(false);

  const [confirmSaving, setConfirmSaving] = useState<boolean>(false);
  const [confirmResetting, setConfirmResetting] = useState<boolean>(false);
  const [confirmDeleting, setConfirmDeleting] = useState<boolean>(false);

  const [confirmNameSaving, setConfirmNameSaving] = useState<boolean>(false);
  const [newDispatcherVisible, setNewDispatcherVisible] = useState<boolean>(false);
  const [dispatcher, setDispatcher] = useState<Dispatcher>();

  const [addForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const [setPwForm] = Form.useForm();

  useEffect(() => {

    setDispatcher(props.dispatcher);
    if (props.dispatcher) {
      editForm.setFieldsValue({ firstName: props.dispatcher.firstName });
      editForm.setFieldsValue({ middleName: props.dispatcher.middleName });
      editForm.setFieldsValue({ lastName: props.dispatcher.lastName });
      editForm.setFieldsValue({ mobileNumber: props.dispatcher.mobileNumber });
      editForm.setFieldsValue({ email: props.dispatcher.email });
    }
    isSavingAllowed();
    isResettingAllowed();
  }, [
    props.dispatcher,
  ]);

  const openAddDispatcher = () => {
    addForm.resetFields();
    setDispatcher(undefined);
    setNewDispatcherVisible(true);
  }

  const openConfirmDelete = () => {

    Modal.confirm({
      title: 'Delete Dispatcher',
      icon: <ExclamationCircleOutlined />,
      content: 'Do you want to delete dispatcher ' + props.dispatcher?.fullName + '?',
      onOk() {
        deleteDispatcher();
      },
      onCancel() {
      },
    });
  };

  const cancelNewDispatcher = () => {
    setNewDispatcherVisible(false);
  }

  const firstNameChanged = (e: any) => {
    const result = e.target.value.replace(/[^a-z\s]/gi, '');
    addForm.setFieldsValue({ firstName: result });
    editForm.setFieldsValue({ firstName: result });
  }

  const middleNameChanged = (e: any) => {
    const result = e.target.value.replace(/[^a-z\s]/gi, '');
    addForm.setFieldsValue({ middleName: result });
    editForm.setFieldsValue({ middleName: result });
  }

  const lastNameChanged = (e: any) => {
    const result = e.target.value.replace(/[^a-z\s]/gi, '');
    addForm.setFieldsValue({ lastName: result });
    editForm.setFieldsValue({ lastName: result });
  }

  const mobileNumberChanged = (e: any) => {
    //const result = e.target.value.replace(/[^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}]/gi, '');
    const result = e.target.value.replace(/[^0-9\+\-\(\)]/gi, '');
    addForm.setFieldsValue({ mobileNumber: result });
    editForm.setFieldsValue({ mobileNumber: result });
  }

  const emailChanged = (e: any) => {
    // const result = e.target.value.replace(/[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gi, '');
    const result = e.target.value;
    addForm.setFieldsValue({ email: result });
    editForm.setFieldsValue({ email: result });
  }

  /**
   * Add a new dispatcher.
   */
  const addDispatcher = async (formData: any) => {

    const clientId: any = props.getClientId();

    const newDispatcher: Dispatcher = {
      id: 0,
      clientId: clientId,
      firstName: formData.firstName ? formData.firstName : '',
      middleName: formData.middleName,
      lastName: formData.lastName ? formData.lastName : '',
      mobileNumber: formData.mobileNumber ? formData.mobileNumber : '',
      email: formData.email ? formData.email : '',
      status: 'ACTIVE'
    }

    setConfirmNameSaving(true);

    const dispatcher: Dispatcher = await props.saveDispatcher(newDispatcher);

    if (dispatcher.status === 'ACTIVE') {
      let dispatchers = [...props.dispatchers];
      dispatchers.push(dispatcher);
      props.setDispatchers(dispatchers);
      message.success('The dispatcher was added successfully.', 3);
    } else if (dispatcher.status == '400') {
      message.error('A dispatcher with the given email address already exists.', 3);
    } else {
      message.error('An unknown error occurred.', 3);
    }

    setNewDispatcherVisible(false);
    setConfirmNameSaving(false);
  }

  const saveDispatcher = async (formData: any) => {

    if (dispatcher) {

      setConfirmSaving(true);

      dispatcher.firstName = formData.firstName ? formData.firstName : '';
      dispatcher.middleName = formData.middleName;
      dispatcher.lastName = formData.lastName ? formData.lastName : '';
      dispatcher.mobileNumber = formData.mobileNumber ? formData.mobileNumber : '';
      dispatcher.email = formData.email ? formData.email : '';

      const savedDispatcher: Dispatcher = await props.saveDispatcher(dispatcher);
      setDispatcher(savedDispatcher);

      const ndx = props.dispatchers.findIndex((dispatcher: Dispatcher) => {
        return dispatcher.id === savedDispatcher.id;
      });

      let dispatchers = [...props.dispatchers];
      dispatchers[ndx] = savedDispatcher;
      props.setDispatchers(dispatchers);

      setConfirmSaving(false);

      message.success('The dispatcher was saved successfully.', 3);
    }
  }

  const deleteDispatcher = async () => {

    if (props.dispatcher) {

      setConfirmDeleting(true);

      const deletedDispatcher: Dispatcher = await props.deleteDispatcher(props.dispatcher);

      const ndx = props.dispatchers.findIndex((dispatcher: Dispatcher) => {
        return dispatcher.id == props.dispatcher?.id;
      });

      let dispatchers = [...props.dispatchers];
      dispatchers.splice(ndx, 1);
      props.setDispatchers(dispatchers);

      setDispatcher(undefined);

      setConfirmDeleting(false);

      message.success('The dispatcher was removed successfully.', 3);
    }
  }

  const doResetPassword = async () => {

    if (props.dispatcher) {

      setConfirmResetting(true);

      props.resetPassword(props.dispatcher);
      // setDispatcher(savedDispatcher);

      setConfirmResetting(false);
    }
  }

  const doResetWithPassword = async (formFields: any) => {
    if (dispatcher) {
      setPasswordIsSaving(true);
      const response = await props.resetWithSetPassword(dispatcher?.email, formFields.password);
      console.log("**DEBUGGER** - Reset With Set Password ", response);
      if (response.code == 0) {
        message.success('The dispatcher password has successfully set.', 3);
      } else {
        message.error('An unknown error occurred.', 3);
      }
    }
    cancelResetWithPassword();
  }

  const cancelResetWithPassword = () => {
    setPwForm.resetFields();
    setPasswordModal(false);
    setPasswordIsSaving(false);
  }

  const isSavingAllowed = () => {
    if (props.dispatcher) {
      setSavingAllowed(true);
    } else {
      setSavingAllowed(false);
    }
  }

  const isResettingAllowed = () => {
    if (props.dispatcher) {
      setResettingAllowed(true);
    } else {
      setResettingAllowed(false);
    }
  }

  return (
    <div className="dispatcher-builder">
      <div className="dispatcher-list-container">
        <div className="header">
          <h3>Dispatchers</h3>
        </div>
        <div className="dispatcher-list">
          <DispatcherList />
        </div>
      </div>

      <div className="dispatcher-detail">

        <div className="header">
          <div></div>
          <Button type="primary" style={{ background: 'purple', borderColor: 'purple' }} size="small" onClick={openAddDispatcher}>
            + DISPATCHER
          </Button>
        </div>

        <div className="dispatcher-detail-container">

          {dispatcher &&
            <>
              <div className="details">
                <Form
                  name="editForm"
                  form={editForm}
                  onFinish={saveDispatcher}>

                  <label>First Name *</label>
                  <Form.Item name={'firstName'} rules={[{ required: true }]}>
                    <Input placeholder="First name" prefix={<UserOutlined />} onChange={firstNameChanged} maxLength={120} />
                  </Form.Item>

                  <label>Middle Name</label>
                  <Form.Item name={'middleName'}>
                    <Input placeholder="Middle name" prefix={<UserOutlined />} onChange={middleNameChanged} maxLength={120} />
                  </Form.Item>

                  <label>Last Name *</label>
                  <Form.Item name={'lastName'} rules={[{ required: true }]}>
                    <Input placeholder="Last name" prefix={<UserOutlined />} onChange={lastNameChanged} maxLength={120} />
                  </Form.Item>

                  <label>Mobile Number</label>
                  <Form.Item name={'mobileNumber'}>
                    <Input placeholder="Mobile number" prefix={<PhoneOutlined />} onChange={mobileNumberChanged} maxLength={15} />
                  </Form.Item>

                  <label>Email *</label>
                  <Form.Item name={'email'} rules={[{ required: true }]}>
                    <Input placeholder="Email *" prefix={< MailOutlined />} onChange={emailChanged} maxLength={120} />
                  </Form.Item>

                  <Form.Item>
                    <div className="controls">
                      <Button type="ghost" loading={confirmDeleting} onClick={openConfirmDelete}>DELETE</Button>&nbsp;
                      <Button type="primary" htmlType="submit" style={savingAllowed ? { background: '#1e5c8bff', borderColor: '#1e5c8bff' } : { background: 'lightgray', borderColor: 'lightgray' }} loading={confirmSaving} disabled={!savingAllowed}>SAVE</Button>
                      <Button type="primary" style={resettingAllowed ? { background: '#1e5c8bff', borderColor: '#1e5c8bff', marginLeft: 4 } : { background: 'lightgray', borderColor: 'lightgray', marginLeft: 4 }} loading={confirmResetting} disabled={!resettingAllowed} onClick={doResetPassword}>RESET PASSWORD</Button>

                      <Button
                        type="primary"
                        style={resettingAllowed ? { background: '#1e5c8bff', borderColor: '#1e5c8bff', marginLeft: 4 } : { background: 'lightgray', borderColor: 'lightgray', marginLeft: 4 }}
                        disabled={!resettingAllowed}
                        onClick={() => setPasswordModal(true)}
                      >SET PASSWORD</Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </>
          }

        </div>
      </div>

      <Modal
        title="Set Dispatcher Password"
        visible={passwordModal}
        confirmLoading={false}
        footer={null}
        onCancel={cancelResetWithPassword}>
        <Form
          form={setPwForm}
          onFinish={doResetWithPassword}>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input a new password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item className={'dispatcher-button-container'}>
            <div className={'dispatcher-button-container'}>
              <Button style={{ 'backgroundColor': 'white', 'color': '#3A78B3', 'marginRight': '4px' }}
                type="primary"
                onClick={cancelResetWithPassword}>
                Cancel
              </Button>
              <Button loading={passwordIsSaving} disabled={passwordIsSaving} style={{ 'backgroundColor': '#3A78B3', 'color': ' white' }}
                type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        bodyStyle={{ height: 410 }}
        title="Enter Dispatcher Details"
        visible={newDispatcherVisible}
        confirmLoading={confirmNameSaving}
        footer={null}
        onCancel={cancelNewDispatcher}>

        <Form
          name="addForm"
          form={addForm}
          onFinish={addDispatcher}>

          <Form.Item name={'firstName'} rules={[{ required: true }]}>
            <Input placeholder="First name *" prefix={<UserOutlined />} onChange={firstNameChanged} maxLength={120} />
          </Form.Item>

          <Form.Item name={'middleName'}>
            <Input placeholder="Middle name" prefix={<UserOutlined />} onChange={middleNameChanged} maxLength={120} />
          </Form.Item>

          <Form.Item name={'lastName'} rules={[{ required: true }]}>
            <Input placeholder="Last name *" prefix={<UserOutlined />} onChange={lastNameChanged} maxLength={120} />
          </Form.Item>

          <Form.Item name={'mobileNumber'}>
            <Input placeholder="Mobile number" prefix={<PhoneOutlined />} onChange={mobileNumberChanged} maxLength={15} />
          </Form.Item>
          <Form.Item name={'email'} rules={[{ required: true }]}>
            <Input placeholder="Email *" prefix={< MailOutlined />} onChange={emailChanged} maxLength={120} />
          </Form.Item>

          <br /><br />

          <Form.Item className={'dispatcher-button-container'}>
            <div className={'dispatcher-button-container'}>
              <Button style={{ 'backgroundColor': 'white', 'color': '#3A78B3', 'marginRight': '4px' }}
                type="primary"
                onClick={() => setNewDispatcherVisible(false)}>
                Cancel
              </Button>
              <Button style={{ 'backgroundColor': '#3A78B3', 'color': ' white' }}
                type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
        <br /><br /><br />
      </Modal>
    </div>
  );
};

const mapStateToProps = (states: RootState): StateProps => {
  return {
    dispatcher: states.dispatcherReducer.state.dispatcher,
    dispatchers: states.dispatcherReducer.state.dispatchers,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    saveDispatcher: async (dispatcher: Dispatcher) => dispatch(saveDispatcher(dispatcher)),
    deleteDispatcher: async (dispatcher: Dispatcher) => dispatch(deleteDispatcher(dispatcher)),
    resetPassword: (dispatcher: Dispatcher) => dispatch(setResetPassword(dispatcher.email, undefined)),
    setDispatchers: (dispatchers: Array<Dispatcher>) => dispatch(setDispatchers(dispatchers)),
    getClientId: () => dispatch(getClientId()),
    resetWithSetPassword: (email, password) => dispatch(resetWithSetPassword(email, password))
  };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Dispatchers))
