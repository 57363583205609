import {
  GetFilters,
  SetFilters,
  SetFilterFields,
  SetFilter,
  Filter,
  FilterField,
  GetUserFilters,
  SetUserFilters,
  SetLoadingUserFilters,
  SetLoadingFilterFields,
  SetSearchSRNumber,
  GetSearchSRNumber,
  SetSearchValue,
  GetSearchValue,
  SetSearchType,
  GetSearchType,
  SearchType
} from "./model";

import store from "..";

import { GET_FILTERS, GET_USER_FILTERS, SET_FILTER, SET_FILTERS, SET_FILTER_FIELDS, SET_USER_FILTERS, SET_LOADING_USER_FILTERS, SET_LOADING_FILTER_FIELDS, SET_SEARCH_SR_NUMBER, GET_SEARCH_SR_NUMBER, SET_SEARCH_VALUE, GET_SEARCH_VALUE, SET_SEARCH_TYPE, GET_SEARCH_TYPE } from "../action-types";

export const setSearchType = (payload: SearchType): SetSearchType => {
  return {
    type: SET_SEARCH_TYPE,
    payload
  }
};

export const getSearchType = (): GetSearchType => {
  return {
    type: GET_SEARCH_TYPE,
    payload: store.getState().filterReducer.state.searchType
  }
};

export const setSearchValue = (payload: string): SetSearchValue => {
  return {
    type: SET_SEARCH_VALUE,
    payload
  }
};

export const getSearchValue = (): GetSearchValue => {
  return {
    type: GET_SEARCH_VALUE,
    payload: store.getState().filterReducer.state.searchValue
  }
};

export const setSearchSRNumber = (payload: string): SetSearchSRNumber => {
  return {
    type: SET_SEARCH_SR_NUMBER,
    payload
  }
};

export const getSearchSRNumber = (): GetSearchSRNumber => {
  return {
    type: GET_SEARCH_SR_NUMBER,
    payload: store.getState().filterReducer.state.searchSRNumber
  }
};

export const getFilters = (): GetFilters => {
  return {
    type: GET_FILTERS,
    payload: store.getState().filterReducer.state.filters
  };
};

export const setFilter = (payload: Filter): SetFilter => {
  return {
    type: SET_FILTER,
    payload
  };
};

export const setFilters = (payload: Array<Filter>): SetFilters => {
  return {
    type: SET_FILTERS,
    payload
  };
};

export const getUserFilters = (payload: Array<Filter>): GetUserFilters => {
  return {
    type: GET_USER_FILTERS,
    payload
  };
};

export const setUserFilters = (payload: Array<Filter>): SetUserFilters => {
  return {
    type: SET_USER_FILTERS,
    payload
  };
};

export const setFilterFields = (payload: Array<FilterField>): SetFilterFields => {
  return {
    type: SET_FILTER_FIELDS,
    payload
  };
};

export const setLoadingUserFilters = (payload: any): SetLoadingUserFilters => {
  return {
    type: SET_LOADING_USER_FILTERS,
    payload
  }
}

export const setLoadingFilterFields = (payload: any): SetLoadingFilterFields => {
  return {
    type: SET_LOADING_FILTER_FIELDS,
    payload
  }
}


