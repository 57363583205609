import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Card from "./Card";
import styles from "./TopListCard.module.scss";
import ViewMoreLink from "./ViewMoreLink";

interface Info {
  label: string;
  value: number;
}

interface Props {
  title: string;
  data: Info[];
  className?: string;
}

export default function TopListCard({ title, data, className }: Props) {
  return (
    <Card title={title} gap="medium" className={className}>
      {data.map((d, i) => (
        <div key={i} className={styles.list}>
          <div>{i + 1}</div>
          <div className={styles.label}>{d.label}</div>
          <div className={styles.num}>{d.value}</div>
        </div>
      ))}
      <ViewMoreLink />
    </Card>
  );
}
