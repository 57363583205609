import React, { useState } from "react";
import { Modal, Input, Button, Spin, message, Form } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

interface Props {
  visible: boolean;
  serviceRequest: any;
  setViewNotesVisible: any;
  selectedNote: any;
  setOpen: any;
}

const ViewNote: React.FC<Props> = (props): JSX.Element => {

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 18, color: "white" }} spin />
  );

  const { visible, serviceRequest, setViewNotesVisible, selectedNote } = props;
  return (
    <Modal
      visible={visible}
      centered={true}
      onCancel={() => {
        setViewNotesVisible(false);
      }}
      className={"request-modal"}
      closable={false}
      footer={null}
      width={"40%"}
    >
      {selectedNote ? <div style={{ flex: 1, padding: 20 }}>
        <div style={{ fontSize: 28, fontWeight: "bold" }}>NOTES</div>
        <div style={{ textAlign: "center", fontSize: 25, fontWeight: "bold" }}>
          SR # {serviceRequest.serviceRequestNo}
        </div>
        <Input.TextArea
          value={selectedNote.notes}
          disabled={true}
          placeholder="State your notes here"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <Button
            style={{
              backgroundColor: "#3A78B3",
              color: " white",
              marginRight: 10,
              width: "10%"
            }}
            type="primary"
            size="large"
            htmlType="button"
            onClick={() => {
              setViewNotesVisible(false);
            }}
          >
            Ok
          </Button>
        </div>
      </div> : null }
    </Modal>
  );
};

export default ViewNote;
