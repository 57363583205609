import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { ThunkDispatch } from "redux-thunk";
import { DailyWeekly, Schedules, Toolbar } from "../../redux/scheduler/model";
import { ServiceRequest } from "../../redux/service-request/model";
import { Technician } from "../../redux/technician/model";
import { constructSchedulerGrid, populateAssignedServiceRequest, schedulerHeaderBuilder } from "../../redux/scheduler/service";
import TechnicianListComponent from "../TechnicianList/TechnicianListComponent";
import GridSchedulerHeader from "./GridSchedulerHeader";
import GridSchedulerWeekly from "./GridSchedulerWeekly";
import GridSchedulerDaily from "./GridSchedulerDaily";
import "./SchedulerGridComponent.scss";
import _isEqual from "lodash/isEqual";
import { fetchAssignedServiceRequest } from "../../redux/service-request/service";


interface ComponentProps { }
interface ReduxState {
   toolbar: Toolbar;
   technicians: Array<Technician>;
   assignedServiceRequests: Array<ServiceRequest>;
   gridW: Array<Schedules>;
   gridD: Schedules;
}
interface DispatchProps {
   schedulerHeaderBuilder: () => void;
   constructSchedulerGrid: () => void;
   populateAssignedServiceRequest: () => void;
   fetchAssignedServiceRequest: () => void;
}
type Props = ComponentProps & ReduxState & DispatchProps;
const SchedulerGridComponent: React.FC<Props> = (props): JSX.Element => {
   const scrollTimeElement = React.useRef<HTMLInputElement>(null);
   let scrollRightElement = React.useRef<HTMLInputElement>(null);
   
   React.useEffect(() => {
      props.fetchAssignedServiceRequest();
   }, [localStorage.getItem("redirectSR")])
   
   React.useEffect(() => {
      onInitSchedulerGridComponent();
      window.addEventListener("reloadSchedulerGridComponent", onInitSchedulerGridComponent);
      return () => {
         window.removeEventListener("reloadSchedulerGridComponent", onInitSchedulerGridComponent);
      }
   }, [props.technicians, props.assignedServiceRequests]);

   const onInitSchedulerGridComponent = () => {
      props.schedulerHeaderBuilder();
      props.constructSchedulerGrid();
      props.populateAssignedServiceRequest();
   }

   const onScrollTime = () => {
      if ((scrollTimeElement && scrollTimeElement.current) && (scrollRightElement && scrollRightElement.current)) {
         scrollRightElement.current.scrollLeft = scrollTimeElement.current.scrollLeft;
      }
   }

   const onScrollContentRight = () => {
      if ((scrollTimeElement && scrollTimeElement.current) && (scrollRightElement && scrollRightElement.current)) {
         scrollTimeElement.current.scrollLeft = scrollRightElement.current.scrollLeft;
      }
   }

   return (
      <div style={{
         position: 'relative',
         width: _isEqual(props.toolbar.D_W, DailyWeekly.DAILY) ? 'calc(100% - 200px)' : '100%',
         height: window.screen.height < 865 ? '95%' : '100%'
      }}>
         <div
            className='border-color-top'
            style={{
               display: 'flex',
               flexDirection: 'row',
               position: 'relative',
               zIndex: 3,
               top: 40,
               width: 'calc(100% + 20px)',
               height: 'calc(100% - 40px)',
               overflowY: 'scroll'
            }}>
            <div style={{
               width: 240,
               minWidth: 240,
               height: 'calc(100% - 40px)',
            }}>
               <TechnicianListComponent
                  activeTab={'schedule'}
                  showTechId={false}
                  showSRCount={false} />
            </div>
            <div
               onScroll={onScrollContentRight}
               ref={scrollRightElement}
               style={_isEqual(props.toolbar.D_W, DailyWeekly.DAILY)
                  ? {
                     width: 'auto',
                     height: 'fit-content',
                     overflowX: 'hidden',
                     overflowY: 'hidden'
                  }
                  : {
                     width: '100%',
                     height: 'fit-content',
                     overflowX: 'hidden',
                  }}>
               {_isEqual(props.toolbar.D_W, DailyWeekly.WEEKLY) && (<GridSchedulerWeekly />)}
               {_isEqual(props.toolbar.D_W, DailyWeekly.DAILY) && (<GridSchedulerDaily reload={onInitSchedulerGridComponent} />)}
               <div style={{ minHeight: 80 }}></div>
            </div>
         </div>
         <GridSchedulerHeader onScrollTime={onScrollTime} scrollTimeElement={scrollTimeElement} />
      </div>
   );
}

const mapStateToProps = (states: RootState): ReduxState => {
   return {
      toolbar: states.schedulerReducer.state.toolbar,
      technicians: states.technicianReducer.state.techniciansGRID,
      assignedServiceRequests: states.serviceRequestReducer.state.assignedServiceRequests,
      gridW: states.schedulerReducer.state.grid,
      gridD: states.schedulerReducer.state.gridD,
   };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
   return {
      schedulerHeaderBuilder: () => dispatch(schedulerHeaderBuilder()),
      constructSchedulerGrid: () => dispatch(constructSchedulerGrid()),
      populateAssignedServiceRequest: () => dispatch(populateAssignedServiceRequest()),
      fetchAssignedServiceRequest: () => dispatch(fetchAssignedServiceRequest()),
   };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(SchedulerGridComponent));



















