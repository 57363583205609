import { SERVER_URL, TWILIO_BASE_URL, TWILIO_CONVERSATION_MLFSA_GENERAL_SERVICE, TWILIO_CONVERSATION_MLFSA_REPRESENTATIVE_MEMBER_SERVICE, TWILIO_CONVERSATION_MLFSA_REPRESENTATIVE_SERVICE, TWILIO_MEDIA_URL, TWILIO_SID, TWILIO_TOKEN } from "../../configs/service-config";
import _isEqual from "lodash/isEqual";
import { AnyAction } from 'redux';
import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { UserDetails } from "../auth/model";
export interface ChatResponse {
   statusCode: any;
   hasError: boolean;
   errorMsg: string,
   body: any;
};

export enum ChatType {
   GENERAL = "GENERAL",
   WITH_ML_REPRESENTATIVE = "WITH_ML_REPRESENTATIVE",
   WITH_MEMBER = "WITH_MEMBER",
   WITH_SERVICE_REQUEST = "WITH_SERVICE_REQUEST"
};

const response = async (data?: any, type?: string,): Promise<ChatResponse> => {
   switch (data.status) {
      case 200:
         if (_isEqual(type, 'blob')) {
            return {
               statusCode: 200,
               hasError: false,
               errorMsg: '',
               body: await data.blob(),
            };
         }

         return {
            statusCode: 200,
            hasError: false,
            errorMsg: '',
            body: await data.json(),
         };
      case 201:
         return {
            statusCode: 201,
            hasError: false,
            errorMsg: "",
            body: await data.json(),
         };
      case 204:
         return {
            statusCode: 204,
            hasError: false,
            errorMsg: "",
            body: [],
         };
      case 500:
         return {
            statusCode: 500,
            hasError: true,
            errorMsg: "Internal Server Error",
            body: await data.json(),
         };
      case 400:
         return {
            statusCode: 400,
            hasError: true,
            errorMsg: "Bad Request",
            body: await data.json(),
         };
      case 401:
         return {
            statusCode: 401,
            hasError: true,
            errorMsg: "Invalid Authentication",
            body: await data.json(),
         };
      case 404:
         return {
            statusCode: 404,
            hasError: true,
            errorMsg: "Not Found",
            body: await data.json(),
         };
      default:
         return {
            statusCode: undefined,
            hasError: true,
            errorMsg: "Cannot read error ",
            body: data,
         };
   }
};

export const notifAdminToTech = (chatType: ChatType, chatId: string, recipientId: any): ThunkAction<Promise<ChatResponse>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {

      let endpoint = ""

      if (_isEqual(chatType, ChatType.GENERAL) || _isEqual(chatType, ChatType.WITH_ML_REPRESENTATIVE)) {
         endpoint = SERVER_URL.NOTIF_ADMIN_TO_TECH_ENDPOINT;
      }

      if (_isEqual(chatType, ChatType.WITH_MEMBER)) {
         endpoint = SERVER_URL.NOTIF_ADMIN_TO_MEMBER_ENDPOINT;
      }
      
      const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
      const { accessToken }: any = JSON.parse(dataStore); 

      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        method: "POST",
        body: JSON.stringify({
          chatId,
          recipientId,
          senderName: "ML Representative",
        }),
      };

      try {
         const req = new Request(endpoint.toString(), options);
         const res = await fetch(req);
         const finalResponse = await response(res);
         return finalResponse;
      } catch (error) {
         const finalResponse = await response(error);
         return finalResponse;
      }
   };
};


export const getConversations = (pageSize: number, chatType: ChatType) => {
   return async () => {

      let endpoint = "";

      if (_isEqual(chatType, ChatType.GENERAL)) {
         endpoint = TWILIO_BASE_URL + '/Services/' + TWILIO_CONVERSATION_MLFSA_GENERAL_SERVICE + '/Conversations';
      }

      if (_isEqual(chatType, ChatType.WITH_ML_REPRESENTATIVE)) {
         endpoint = TWILIO_BASE_URL + '/Services/' + TWILIO_CONVERSATION_MLFSA_REPRESENTATIVE_SERVICE + '/Conversations';
      }

       if (_isEqual(chatType, ChatType.WITH_MEMBER)) {
         endpoint = TWILIO_BASE_URL + '/Services/' + TWILIO_CONVERSATION_MLFSA_REPRESENTATIVE_MEMBER_SERVICE + '/Conversations';
      }

      const options = {
         headers: {
            Authorization: "Basic " + Base64.btoa(TWILIO_SID + ":" + TWILIO_TOKEN)
         },
         method: "GET"
      };

      try {
         const req = new Request(endpoint.toString(), options);
         const res = await fetch(req);
         const finalResponse = await response(res);
         return finalResponse;
      } catch (error) {
         const finalResponse = await response(error);
         return finalResponse;
      };

   };
};

export const createConversation = (serviceRequest: string, chatType: ChatType): ThunkAction<Promise<ChatResponse>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {


      let endpoint = "";

      if (_isEqual(chatType, ChatType.GENERAL)) {
         endpoint = TWILIO_BASE_URL + '/Services/' + TWILIO_CONVERSATION_MLFSA_GENERAL_SERVICE + '/Conversations';
      }

      if (_isEqual(chatType, ChatType.WITH_ML_REPRESENTATIVE)) {
         endpoint = TWILIO_BASE_URL + '/Services/' + TWILIO_CONVERSATION_MLFSA_REPRESENTATIVE_SERVICE + '/Conversations';
      }

      if (_isEqual(chatType, ChatType.WITH_MEMBER)) {
         endpoint = TWILIO_BASE_URL + '/Services/' + TWILIO_CONVERSATION_MLFSA_REPRESENTATIVE_MEMBER_SERVICE + '/Conversations';
      }

      var formBody: any = [];
      formBody.push(encodeURIComponent('UniqueName') + "=" + encodeURIComponent(serviceRequest));
      formBody.push(encodeURIComponent('FriendlyName') + "=" + encodeURIComponent(`${serviceRequest} Channel`));
      formBody = formBody.join("&");

      const options = {
         headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            Authorization: "Basic " + Base64.btoa(TWILIO_SID + ":" + TWILIO_TOKEN)
         },
         method: "POST",
         body: formBody
      };

      try {
         const req = new Request(endpoint.toString(), options);
         const res = await fetch(req);
         const finalResponse = await response(res);
         return finalResponse;
      } catch (error) {
         const finalResponse = await response(error);
         return finalResponse;
      };

   };
};

export const getConversationMessages = (channel: String, chatType: ChatType): ThunkAction<Promise<ChatResponse>, {}, {}, AnyAction> => {
   return async () => {
      let endpoint = "";

      if (_isEqual(chatType, ChatType.GENERAL)) {
         endpoint = TWILIO_BASE_URL + '/Services/' + TWILIO_CONVERSATION_MLFSA_GENERAL_SERVICE + '/Conversations/' + channel + '/Messages';
      }

      if (_isEqual(chatType, ChatType.WITH_ML_REPRESENTATIVE)) {
         endpoint = TWILIO_BASE_URL + '/Services/' + TWILIO_CONVERSATION_MLFSA_REPRESENTATIVE_SERVICE + '/Conversations/' + channel + '/Messages';
      }

      if (_isEqual(chatType, ChatType.WITH_MEMBER)) {
         endpoint = TWILIO_BASE_URL + '/Services/' + TWILIO_CONVERSATION_MLFSA_REPRESENTATIVE_MEMBER_SERVICE + '/Conversations/' + channel + '/Messages';
      }
      const options = {
         headers: {
            Authorization: "Basic " + Base64.btoa(TWILIO_SID + ":" + TWILIO_TOKEN)
         },
         method: "GET"
      };

      try {
         const req = new Request(endpoint.toString(), options);
         const res = await fetch(req);
         const finalResponse = await response(res);
         return finalResponse;
      } catch (error) {
         const finalResponse = await response(error);
         return finalResponse;
      };
   };
};

export const getConversationMessagesFromURL = async (url: String) => {
   let endpoint = url;
   const options = {
      headers: {
         Authorization: "Basic " + Base64.btoa(TWILIO_SID + ":" + TWILIO_TOKEN)
      },
      method: "GET"
   };

   try {
      const req = new Request(endpoint.toString(), options);
      const res = await fetch(req);
      const finalResponse = await response(res);
      return finalResponse;
   } catch (error) {
      const finalResponse = await response(error);
      return finalResponse;
   };
};


export const removeConversations = (channel: String, chatType: ChatType): ThunkAction<Promise<ChatResponse>, {}, {}, AnyAction> => {
   return async () => {
      let endpoint = "";

      if (_isEqual(chatType, ChatType.GENERAL)) {
         endpoint = TWILIO_BASE_URL + '/Services/' + TWILIO_CONVERSATION_MLFSA_GENERAL_SERVICE + '/Conversations/' + channel;
      }

      if (_isEqual(chatType, ChatType.WITH_ML_REPRESENTATIVE)) {
         endpoint = TWILIO_BASE_URL + '/Services/' + TWILIO_CONVERSATION_MLFSA_REPRESENTATIVE_SERVICE + '/Conversations/' + channel;
      }

          if (_isEqual(chatType, ChatType.WITH_MEMBER)) {
         endpoint = TWILIO_BASE_URL + '/Services/' + TWILIO_CONVERSATION_MLFSA_REPRESENTATIVE_MEMBER_SERVICE + '/Conversations' + channel;
      }

      const options = {
         headers: {
            Authorization: "Basic " + Base64.btoa(TWILIO_SID + ":" + TWILIO_TOKEN)
         },
         method: "DELETE"
      };

      try {
         const req = new Request(endpoint.toString(), options);
         const res = await fetch(req);
         const finalResponse = await response(res);
         return finalResponse;
      } catch (error) {
         const finalResponse = await response(error);
         return finalResponse;
      };
   };
};

export const createConversationMessages = (channel: string, chatType: ChatType, body: string, media?: any): ThunkAction<Promise<ChatResponse>, {}, {}, AnyAction> => {
   return async () => {

      let endpoint = "";

      if (_isEqual(chatType, ChatType.GENERAL)) {
         endpoint = TWILIO_BASE_URL + '/Services/' + TWILIO_CONVERSATION_MLFSA_GENERAL_SERVICE + '/Conversations/' + channel + '/Messages';
      }

      if (_isEqual(chatType, ChatType.WITH_ML_REPRESENTATIVE)) {
         endpoint = TWILIO_BASE_URL + '/Services/' + TWILIO_CONVERSATION_MLFSA_REPRESENTATIVE_SERVICE + '/Conversations/' + channel + '/Messages';
      }

     if (_isEqual(chatType, ChatType.WITH_MEMBER)) {
         endpoint = TWILIO_BASE_URL + '/Services/' + TWILIO_CONVERSATION_MLFSA_REPRESENTATIVE_MEMBER_SERVICE + '/Conversations/' + channel + '/Messages';
      }

      var formBody: any = [];

      if (media) {
         formBody.push(encodeURIComponent('Author') + "=" + encodeURIComponent('ADMIN'));
         formBody.push(encodeURIComponent('Body') + "=" + encodeURIComponent(body));
         formBody.push(encodeURIComponent('MediaSid') + "=" + encodeURIComponent(media));
      }

      if (!media) {
         formBody.push(encodeURIComponent('Author') + "=" + encodeURIComponent('ADMIN'));
         formBody.push(encodeURIComponent('Body') + "=" + encodeURIComponent(body));
      }

      formBody = formBody.join("&");

      const options = {
         headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            Authorization: "Basic " + Base64.btoa(TWILIO_SID + ":" + TWILIO_TOKEN)
         },
         method: "POST",
         body: formBody
      };

      try {
         const req = new Request(endpoint.toString(), options);
         const res = await fetch(req);
         const finalResponse = await response(res);
         return finalResponse;
      } catch (error) {
         const finalResponse = await response(error);
         return finalResponse;
      };

   };
};

export const uploadMediaChat = (file: any, chatType: ChatType): ThunkAction<Promise<ChatResponse>, {}, {}, AnyAction> => {
   return async () => {
      const formData = new FormData();
      formData.append("file", file);

      const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
      const { accessToken }: any = JSON.parse(dataStore);

      let endpoint = "";
      if (_isEqual(chatType, ChatType.GENERAL)) {
        endpoint = `${SERVER_URL.TWILIO_MEDIA}/conversation/${TWILIO_CONVERSATION_MLFSA_GENERAL_SERVICE}/media`;
      }

      if (_isEqual(chatType, ChatType.WITH_ML_REPRESENTATIVE)) {
        endpoint = `${SERVER_URL.TWILIO_MEDIA}/conversation/${TWILIO_CONVERSATION_MLFSA_REPRESENTATIVE_SERVICE}/media`;
      }

         if (_isEqual(chatType, ChatType.WITH_MEMBER)) {
           endpoint = `${SERVER_URL.TWILIO_MEDIA}/conversation/${TWILIO_CONVERSATION_MLFSA_REPRESENTATIVE_MEMBER_SERVICE}/media`;
         }

      const options = {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        method: "POST",
        body: formData,
      };

      try {
         const req = new Request(endpoint, options);
         const res = await fetch(req);
         const finalResponse = await response(res);
         return finalResponse;
      } catch (error) {
         const finalResponse = await response(error);
         return finalResponse;
      };
   };
};


export const getMediaChat = (SSID: any, chatType: ChatType): ThunkAction<Promise<ChatResponse>, {}, {}, AnyAction> => {
   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {

      const dataStore: any = localStorage.getItem("SESSION_CREDENTIALS");
      const { accessToken }: any = JSON.parse(dataStore);

      let endpoint = "";
      if (_isEqual(chatType, ChatType.GENERAL)) {
          endpoint = `${SERVER_URL.TWILIO_MEDIA}/conversation/${TWILIO_CONVERSATION_MLFSA_GENERAL_SERVICE}/media/${SSID}`;
      }

      if (_isEqual(chatType, ChatType.WITH_ML_REPRESENTATIVE)) {
          endpoint = `${SERVER_URL.TWILIO_MEDIA}/conversation/${TWILIO_CONVERSATION_MLFSA_REPRESENTATIVE_SERVICE}/media/${SSID}`;
      }

          if (_isEqual(chatType, ChatType.WITH_MEMBER)) {
         endpoint = `${SERVER_URL.TWILIO_MEDIA}/conversation/${TWILIO_CONVERSATION_MLFSA_REPRESENTATIVE_MEMBER_SERVICE}/media/${SSID}`
      }

      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        method: "GET",
      };

      try {
         const req = new Request(endpoint.toString(), options);
         const res = await fetch(req);

         const finalResponse = await response(res);
         return finalResponse;
      } catch (error) {
         const finalResponse = await response(error);
         return finalResponse;
      };
   };
};


const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
const Base64 = {
   btoa: (input: string = '') => {
      let str = input;
      let output = '';

      for (let block = 0, charCode, i = 0, map = chars;
         str.charAt(i | 0) || (map = '=', i % 1);
         output += map.charAt(63 & block >> 8 - i % 1 * 8)) {

         charCode = str.charCodeAt(i += 3 / 4);

         if (charCode > 0xFF) {
            throw new Error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");
         }

         block = block << 8 | charCode;
      }

      return output;
   },

   atob: (input: string = '') => {
      let str = input.replace(/=+$/, '');
      let output = '';

      if (str.length % 4 == 1) {
         throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
      }
      for (let bc = 0, bs = 0, buffer, i = 0;
         buffer = str.charAt(i++);

         ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
            bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
      ) {
         buffer = chars.indexOf(buffer);
      }

      return output;
   }
};

export default Base64;